import {yieldToMain} from "../ASYNCUtils";
import animalsDB from "../../database/animalsDB";
import eventsDB from "../../database/eventsDB";
import memoizeOne from "memoize-one";
import {groupBy} from "lodash";

class ControlList {
    constructor({FarmID, List}) {
        this._FarmID = FarmID;
        this._List = List;
        this._Events = null;
        this._MaxFrameTime = 20;
        this._Deadline = performance.now() + this._MaxFrameTime;
        this.TryMakeUiResponsive.Init = (maxFrameTime = 20) => {
            this._Deadline = performance.now() + maxFrameTime;
            this._MaxFrameTime = maxFrameTime;
        }

    }

    GetAnimals = ({animalType, showDead = false, showDeleted = false} = {}) => {
        return animalsDB.getAllAnimals(this._FarmID, animalType, showDead, false, showDeleted);
    }

    GetAnimalByID = (AnmID, {showDeleted  = false}) => {
        return animalsDB.getAnimalById(AnmID, {findDeleted: !!showDeleted});
    }

    _groupBy = memoizeOne((events) => {
        return groupBy(events, (o) => o.AnmID);
    })

    GetEventsForAnimal = (AnmID) => {
        return this._groupBy(this.GetAllEvents())[AnmID] ?? [];
    }

    GetAllEvents = () => {
        if (!this._Events) {
            this._Events = eventsDB.getAllEventsForFarm(this._FarmID);
            this._Events.sort((e1, e2) => e1.EvTime - e2.EvTime)
        }
        return this._Events;
    };


    // do poczytania: https://web.dev/articles/optimize-long-tasks
    TryMakeUiResponsive = async () => {
        if (navigator.scheduling?.isInputPending() || performance.now() >= this._Deadline) {
            await yieldToMain();
            this._Deadline = performance.now() + this._MaxFrameTime;
            return false;
        }
        return true;
    }

    GetList = () => {
        return this._List;
    }
}

export default ControlList;