import { createSelector, createStructuredSelector } from "reselect";
import * as RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import { get, isEmpty, uniqBy } from "lodash";
import * as UserTypes from "@wesstron/utils/Api/constants/userTypes";
import { enhancedComparer } from "../utils/TextUtils";
import i18next from "i18next";

export const UserSortType = {
    BY_LOGIN_ASC: "SORT_BY_USER_LOGIN_ASC",
    BY_LOGIN_DESC: "SORT_BY_USER_LOGIN_DESC",
    BY_LAST_LOGIN_DATE_ASC: "SORT_BY_USER_LAST_LOGIN_ASC",
    BY_LAST_LOGIN_DATE_DESC: "SORT_BY_USER_LAST_LOGIN_DESC",
    BY_ACCOUNT_STATUS_ASC: "SORT_BY_USER_ACCOUNT_STATUS_ASC",
    BY_ACCOUNT_STATUS_DESC: "SORT_BY_USER_ACCOUNT_STATUS_DESC",
    BY_EXPIRATION_DATE_ASC: "SORT_BY_EXPIRATION_DATE_ASC",
    BY_EXPIRATION_DATE_DESC: "SORT_BY_EXPIRATION_DATE_DESC"
};

export const getAllUsers = state => state.user.allUsers;

export const getEmployees = state => state.user.employees;

export const getEmployeesLoading = state => state.user.fetchingEmployees;

export const getTranslators = state => state.user.translators;

export const getUser = state => state.user.user;

export const getUserSub = state => state.user.attributes.sub;

export const getUserObject = state => state.user;

export const getUserSortView = state => state.user.userSort;

export const getUserFarmFilter = state => state.user.userFarmFilter;

export const getUserTextFilter = state => state.user.userTextFilter;

export const getUserTypeFilter = state => state.user.userTypeFilter;

export const getUserLanguagesFilter = state => state.user.userLanguagesFilter;

export const getSelectedEmployee = state => state.user.selected;

export const getSelectedServiceAccount = state => state.user.selectedServiceAccount || { LocalUserID: "NewService" };

export const getSelectedSupportAccount = state => state.user.selectedSupportAccount;

export const getSelectedTranslatorAccount = state => state.user.selectedTranslator;

export const getLocalUserID = (state, props) => !!props && props.LocalUserID;

export const getFarms = (state) => state.user.user.FarmData;

export const getServiceAccounts = (state) => state.user.serviceAccounts;

export const getSupportAccounts = (state) => state.user.supportAccounts;

export const getServiceClientMap = (state) => state.user.serviceClientMap;

export const getClientID = state => state.user.user.ClientID;

export const getClientsWithoutFarms = (state) => get(state, "user.user.clientsWithoutFarms", []);

const showClientsWithoutFarms = (state, props) => get(props, "withoutFarms", false)

export const getEmployeeByLocalUserID = createSelector([getEmployees, getTranslators, getServiceAccounts, getSupportAccounts, getLocalUserID], (employees, translators, services, support, localUserID) => {
    return [...employees, ...translators, ...services, ...support].find((e) => e.LocalUserID === localUserID);
});

export const checkIfUserHasPrivilegedAccessSelector = createSelector([getUser], (user) => {
    return [UserTypes.SERVICE, UserTypes.OWNER, UserTypes.MANAGER].includes(user.UserType);
})

export const getAllUsersWithoutService = createSelector(getAllUsers, users => users.filter(item => !item.service));

export const makeGetAllUsersWithoutService = () => getAllUsersWithoutService;

export const getPossibleClients = createSelector([getFarms, getClientsWithoutFarms, showClientsWithoutFarms], (farms = [], clientsWithoutFarms = [], shouldShowClientsWithoutFarms) => {
    let clients = {};
    for (let farm of farms) clients[farm.ClientID] = { ClientID: farm.ClientID, Alias: farm.Alias };
    if (shouldShowClientsWithoutFarms) {
        for (let client of clientsWithoutFarms) clients[client.ClientID] = client;
    }
    return Object.values(clients);
})

export const getTotalEmployees = createSelector(getUser, getEmployees, (currentUser, employees) => {
    const results = { ALL: 0 };
    Object.values(UserTypes).filter((type) => type !== UserTypes.SERVICE).forEach(value => {
        results[value] = 0;
    });
    employees.filter((e) => {
        const generalReadEmployee = e.roles.find((r) => r.Role === RoleTypes.GENERAL_READ);
        if (!!generalReadEmployee) {
            const currentUserLocalRole = currentUser.Roles.find((r) => r.Role === RoleTypes.GENERAL_READ || r.Role === RoleTypes.SERVICE);
            const hasTheSameAccess = generalReadEmployee.LocalRights.find((lr) => currentUserLocalRole.LocalRights.find((clr) => clr.FarmID === lr.FarmID));
            if (!hasTheSameAccess) return false;
        }
        return true;
    }).forEach((employee) => {
        results[employee.userType]++;
        if (employee.userType !== UserTypes.SERVICE && employee.userType !== UserTypes.TRANSLATOR) results.ALL++;
    });
    return results;
});

const filterByFarmID = (employee, farmFilter) => {
    const generalReadRole = employee.roles.find((role) => role.Role === RoleTypes.GENERAL_READ);
    if (!!generalReadRole) return generalReadRole.LocalRights.some((lr) => lr.FarmID === farmFilter);
    return false;
};

const filterByText = (employee, textFilter) => {
    const { firstname = "", surname = "", email } = employee;
    const userName = `${firstname} ${surname}`.toLowerCase().trim() || email;
    return [userName, employee.phoneNumber || employee.phone, (employee.login || employee.LocalMqttLogin).toLowerCase()].filter((o) => !!o).some((o) => o.includes(textFilter.toLowerCase()));
};

const filterByLanguage = (translator, languageFilter) => {
    const destinationLanguages = get(translator, "Langs.Dst", ["*"]);
    if (languageFilter.length === 0) return false;
    if (destinationLanguages.includes("*")) return true;
    return destinationLanguages.some((dl) => languageFilter.includes(dl));
};

const getSortFunction = (sortView) => {
    let sortFunction = () => 0;
    switch (sortView) {
        case UserSortType.BY_LOGIN_ASC:
        case UserSortType.BY_LOGIN_DESC: {
            sortFunction = (o1, o2) => enhancedComparer(`${get(o1, "firstname")}${get(o1, "surname")}`, `${get(o2, "firstname")}${get(o2, "surname")}`, {
                ascending: sortView.endsWith("_ASC")
            });
            break;
        }
        case UserSortType.BY_LAST_LOGIN_DATE_ASC:
        case UserSortType.BY_LAST_LOGIN_DATE_DESC: {
            sortFunction = (o1, o2) => enhancedComparer(get(o1, "userLastLogDate", 0) || get(o1, "DtaLstLog", 0), get(o2, "userLastLogDate", 0) || get(o2, "DtaLstLog", 0), {
                numeric: true,
                ascending: sortView.endsWith("_ASC")
            });
            break;
        }
        case UserSortType.BY_ACCOUNT_STATUS_ASC:
        case UserSortType.BY_ACCOUNT_STATUS_DESC:
            sortFunction = (o1, o2) => enhancedComparer(get(o1, "enable"), get(o2, "enable"), {
                ascending: sortView.endsWith("_ASC")
            });
            break;
        case UserSortType.BY_EXPIRATION_DATE_ASC:
        case UserSortType.BY_EXPIRATION_DATE_DESC:
            sortFunction = (o1, o2) => enhancedComparer(get(o1, "expiresAt"), get(o2, "expiresAt"), {
                ascending: sortView.endsWith("_ASC")
            });
            break;
        default:
            break;
    }
    return sortFunction;
}

export const getUsersViewEmployees = createSelector(getUser, getEmployees, getUserSortView, getUserFarmFilter,
    getUserTextFilter, getUserTypeFilter, (currentUser, employees, sortView, farmFilter, textFilter, typeFilter) => {
        const filters = {
            typeFilter: item => item.userType === typeFilter,
            farmFilter: item => filterByFarmID(item, farmFilter),
            textFilter: item => filterByText(item, textFilter)
        };
        const selectedFilters = [
            { value: farmFilter, filter: filters["farmFilter"] },
            { value: textFilter, filter: filters["textFilter"] },
            { value: typeFilter, filter: filters["typeFilter"] }
        ].filter((o) => !!o.value);
        return employees.filter((employee) => {
            const generalReadEmployee = employee.roles.find((r) => r.Role === RoleTypes.GENERAL_READ);
            if (!!generalReadEmployee && !!currentUser) {
                const currentUserLocalRole = currentUser.Roles.find((r) => r.Role === RoleTypes.GENERAL_READ || r.Role === RoleTypes.SERVICE);
                const hasTheSameAccess = generalReadEmployee.LocalRights.find((lr) => currentUserLocalRole.LocalRights.find((clr) => clr.FarmID === lr.FarmID));
                if (!hasTheSameAccess) return false;
            }
            return employee.userType !== UserTypes.TRANSLATOR && employee.LocalUserID !== currentUser.LocalUserID && selectedFilters.every((f) => f.filter(employee));
        }).sort(getSortFunction(sortView)).slice(0);
    });


export const getUsersViewTranslators = createSelector(getTranslators, getUserSortView, getUserTextFilter, getUserLanguagesFilter, (translators, sortView, textFilter, languagesFilter) => {
    const filters = {
        textFilter: item => filterByText(item, textFilter),
        languagesFilter: item => filterByLanguage(item, languagesFilter)
    };
    const selectedFilters = [
        { value: textFilter, filter: filters["textFilter"] },
        { value: languagesFilter, filter: filters["languagesFilter"] }
    ];
    return translators.filter((translator) => selectedFilters.every((f) => f.filter(translator))).sort(getSortFunction(sortView)).slice(0);
});

export const getServiceViewServicesAccounts = createSelector(getServiceAccounts, getUserSortView, getUserTextFilter, (accounts, sortView, textFilter) => {
    const filters = {
        textFilter: item => filterByText(item, textFilter)
    };
    const selectedFilters = [
        { value: textFilter, filter: filters["textFilter"] }
    ];
    return accounts.filter((serviceAccount) => selectedFilters.every((f) => f.filter(serviceAccount))).sort(getSortFunction(sortView)).slice(0);
});

export const getSupportViewSupportAccounts = createSelector(getSupportAccounts, getUserSortView, getUserTextFilter, (accounts, sortView, textFilter) => {
    const filters = {
        textFilter: item => filterByText(item, textFilter)
    };
    const selectedFilters = [
        { value: textFilter, filter: filters["textFilter"] }
    ];
    return accounts.filter((supportAccount) => selectedFilters.every((f) => f.filter(supportAccount))).sort(getSortFunction(sortView)).slice(0);
});

export const getServiceClientMapView = createSelector(getServiceClientMap, (serviceClientMap) => {
    const masterServiceMap = get(serviceClientMap, "masterServiceMap", {});
    const serviceMap = get(serviceClientMap, "serviceMap", {});
    const initialized = !isEmpty(masterServiceMap) || !isEmpty(serviceMap);
    return {
        masterServiceMap,
        serviceMap,
        initialized
    };
});

export const getOwnerData = createSelector(getEmployees, (employees) => {
    let owner = employees.find(item => item.userType === UserTypes.OWNER);
    // console.log(farm);
    return {
        firstName: owner?.firstname,
        lastName: owner?.surname,
        address: owner?.address,

    }
})

export const getEmployeeList = createSelector(getEmployees, getUser, (employees, user) => {
    let array = employees.filter(({
        userName,
        firstname,
        surname
    }) => !userName.includes("erased") && (firstname || surname));
    if (!array.find(item => item.LocalUserID === user.LocalUserID)) {
        array.push(user);
    }
    return array;
})

export const makeGetEmployeeList = () => getEmployeeList;

export const getEmployeeOptionList = createSelector(getEmployees, getUser, (employees, user) => {
    return uniqBy([{
        value: user.LocalUserID,
        name: i18next.t("me")
    }, ...employees.filter(({
        userName,
        firstname,
        surname
    }) => !userName.includes("erased") && (firstname || surname)).map(({
        LocalUserID,
        firstname,
        surname
    }) => ({
        value: LocalUserID,
        name: `${firstname || ""} ${surname || ""}`
    }))], "value");
});

export const getServiceList = createSelector(getAllUsers, (users) => {
    return users.filter(item => !!item.service)
});

export const makeGetServiceList = () => getServiceList;

export const hasPrivilegedAccess = createSelector(getUser, (user) => [UserTypes.SERVICE, UserTypes.OWNER, UserTypes.MANAGER].includes(user.UserType));

export const isService = createSelector(getUser, user => user?.UserType === UserTypes.SERVICE);

export const makeIsService = () => createSelector(getUser, user => user?.UserType === UserTypes.SERVICE);

export const isTV = createSelector(getUser, user => user.UserType === UserTypes.TV);

const isChangingClientID = state => state.user.isChangingClientID;

const isAuthenticating = state => state.user.isAuthenticating;

export const globalLoadingUserData = createStructuredSelector({ isChangingClientID, isAuthenticating });