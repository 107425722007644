import { Auth } from "aws-amplify";
import { dismissNotifications } from "reapop";
import Paths from "../api/paths";
import config from "../conf/config";
import store from "../store/store";
import { myID } from "./generateID";
// import {redirect} from "../actions/historyActions";
import queryString from "querystring";
import { addSyncAPI } from "../actions/syncActions";
import { getBugsnagClient } from "../views/fallback-error-view/FallbackErrorView";
import { isCypressOffline } from "../utils/CypressUtils";
import { discardBugsnagError } from "../utils/bugsnagUtils/BugsnagUtils";

Auth.configure({
    // Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: config.cognito.REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: config.cognito.USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    /**
     // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
     mandatorySignIn: false,
     // OPTIONAL - Configuration for cookie storage
     cookieStorage: {
            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: '.yourdomain.com',
            // OPTIONAL - Cookie path
            path: '/',
            // OPTIONAL - Cookie expiration in days
            expires: 365,
            // OPTIONAL - Cookie secure flag
            secure: true
        }
     */
    // }
});

export async function authUser() {
    try {
        Auth.currentSession()
            .then((data) => console.log("authUser data", data))
            .catch((err) => console.log(err));
        let user = await Auth.currentAuthenticatedUser();
        // nie wiem czy to jest potrzebne, wczesniej bylo sprawdzanie czy credentalsy sa aktualne, teraz odswiezam token
        Auth.currentUserCredentials()
            .then(async (credentials) => {
                console.log("credentials", credentials);
                if (credentials.expired) {
                    await refreshSession();
                }
            })
            .catch((e) => {
                console.error("currentUserCredentialsError", e);
            });
        if (user) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        return Promise.reject(e);
    }
}

export async function getUserToken() {
    try {
        const session = await Auth.currentSession();
        let accessToken = session.getAccessToken();
        return accessToken.getJwtToken();
    } catch (e) {
        console.error(e);
        try {
            Auth.signOut();
        } catch (e) {
            console.error(e);
        }
    }
}

export async function getUserIdToken() {
    try {
        const session = await Auth.currentSession();
        let idToken = session.getIdToken();
        return idToken.getJwtToken();
    } catch (e) {
        console.error(e);
    }
}

export async function getCurrentUser() {
    try {
        return await Auth.currentAuthenticatedUser({ bypassCache: true });
    } catch (e) {
        console.error(e);
        return null;
    }
}

export async function signOutUser() {
    try {
        await Auth.signOut();
    } catch (e) {
        console.error(e);
    }
    // store.dispatch(redirect("/"));
    store.dispatch(dismissNotifications());
}

// function getAwsCredentials(userToken) {
//     const authenticator = `cognito-idp.${config.cognito
//         .REGION}.amazonaws.com/${config.cognito.USER_POOL_ID}`;
//
//     AWS.config.update({region: config.cognito.REGION});
//
//     AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//         IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
//         Logins: {
//             [authenticator]: userToken
//         }
//     });
//
//     return AWS.config.credentials.getpromise;
// }

function saveOfflineSync({
    path,
    method,
    headers,
    body,
    query
}) {
    // nie zapisuj:
    // - zapytan typu GET
    // - zapytan z iot w sciezce
    // - gdy na widoku wprowadzania danych przez gridy
    if (
        method !== "GET" &&
        !path.includes("iot") &&
        !location.href.includes("eventChooser") &&
        !location.href.includes("settings/devices/scan") &&
        !path.startsWith("offline") &&
        !path.startsWith("userapi") &&
        !path.includes("/sns/")
    ) {
        store.dispatch(addSyncAPI(path, method, headers, query, body));
    }
}

function notifyBugsnag(e, { path, method, headers, queryParams, body, noAuthorization, signal, query, token, result = {} }) {
    const bugsnagClient = getBugsnagClient();
    if (bugsnagClient) {
        const state = store.getState();
        const { user: { user: { LocalUserID, ClientID } }, location: { farm }, online: { status } } = state;
        const replacedPath = path.replace(farm, "{FarmID}").replace(LocalUserID, "{LocalUserID}").replace(ClientID, "{ClientID}");
        if (status && !discardBugsnagError(e)) {
            bugsnagClient.notify(
                new Error(replacedPath),
                (event) => {
                    event.severity = "warn";
                    event.addMetadata("feedback", {
                        messageType: e.message,
                        data: {
                            method,
                            headers,
                            queryParams,
                            body,
                            noAuthorization,
                            signal,
                            query,
                            token,
                            result
                        },
                        error: e,
                    });
                },
                () => { }
            );
        }
    }
}

export async function invokeApig({
    path,
    method = "GET",
    headers = {},
    queryParams = {},
    body,
    noAuthorization,
    signal,
    shouldParse = true,
    forceFetch = false,
}) {
    // i18next.setDefaultNamespace("translation"); // todo bedzie common
    let token;
    if (!noAuthorization) {
        token = await getUserToken();
    }
    if (body && typeof body === "object") {
        headers["Content-Type"] = "application/json";
    }
    body = body ? (shouldParse ? JSON.stringify(body) : body) : body;
    headers.authorization = noAuthorization ? "" : token;
    if (forceFetch) {
        headers["Cache-Control"] = "max-age=0";
    }

    let query = queryString.stringify(queryParams);
    console.log(headers, typeof body);
    if (isCypressOffline()) {
        saveOfflineSync({ method, body, headers, path, query });
        return;
    }
    try {
        const results = await fetch(
            config.apiGateway.URL + path + (query ? "?" + query : ""),
            {
                // const results = await fetch(signedRequest.url, {
                method,
                headers,
                body,
                signal,
            }
        );
        if (!results.ok) {
            const r = await results.json();
            notifyBugsnag({ message: "API Call error", name: "Inoke a pig error" }, {
                path,
                method,
                headers,
                queryParams,
                body,
                noAuthorization,
                signal,
                query,
                token,
                result: r,
                status: results.status,
                resultsBody: results.body
            });
            return Promise.reject(new Error(r));
        }
        if (results.headers.get("Content-Type") === "text/plain") {
            return results.text();
        }
        if (results.headers.get("Content-Type") !== "application/json") {
            let base64 = await results.text();
            let buff = Buffer.from(base64, "base64");
            let blob = new Blob([buff], {
                type: results.headers.get("Content-Type"),
            });
            let url = window.URL.createObjectURL(blob);
            console.log(results.headers.get("Content-Disposition"));
            let filename = results.headers
                .get("Content-Disposition")
                .split("filename=")[1];
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return;
        }
        // console.log('RESULT ivokeapig', results);
        return results.json();
    } catch (e) {
        console.error(e);
        if (e.name !== "AbortError") {
            saveOfflineSync({ method, body, path, headers, query });
        }
        notifyBugsnag(e, {
            path,
            method,
            headers,
            queryParams,
            body,
            noAuthorization,
            signal,
            query,
            token
        });
        return Promise.reject(e);
    }
}

export async function createAndUpdateGroup(
    newAnimal,
    oldAnimal,
    event,
    clientID,
    localUserID
) {
    try {
        //console.log(newAnimal);
        //dodanie nowego zwierzęcia
        await invokeApig({
            ...Paths.createAnimal({
                farmID: oldAnimal.FarmID,
                localUserID: localUserID,
                clientID: clientID,
            }),
            body: newAnimal,
        });
        //nadpisanie starego zwierzęcia
        await invokeApig({
            ...Paths.updateAnimal({
                farmID: oldAnimal.FarmID,
                localUserID: localUserID,
                clientID: clientID,
            }),
            body: oldAnimal,
        });
        //pobranie listy eventów starego zwierzęcia
        let eventsResponse = await invokeApig({
            ...Paths.listEvent({ farmID: oldAnimal.FarmID }),
            queryParams: { FarmID: oldAnimal.FarmID, AnmID: oldAnimal.AnmID },
        });
        eventsResponse.map((e) => {
            e.EvID = myID();
            e.AnmID = newAnimal.AnmID;
        });
        let events = eventsResponse.concat(event);
        let e = {
            events: events,
        };
        //dodanie eventów dla nowego zwierzęcia
        await invokeApig({
            ...Paths.createEvent(),
            body: e,
        });
    } catch (e) {
        //console.log("catch promise fetch",e);
        return Promise.reject(new Error("Failed to create or update"));
    }
}

export async function createAndUpdateNotificattions(
    newAnimal,
    oldAnimal,
    event
) {
    try {
        //console.log(newAnimal);
        //dodanie nowego zwierzęcia
        // let newAnimalRes = await invokeApig({
        //     path: "api/animal",
        //     method: "POST",
        //     body: newAnimal
        // });
        //nadpisanie starego zwierzęcia
        // let oldAnimalRes = await invokeApig({
        //     path: "api/animal/" + oldAnimal.FarmID,
        //     method: "PUT",
        //     body: oldAnimal
        // });
        //pobranie listy eventów starego zwierzęcia
        let eventsResponse = await invokeApig({
            ...Paths.listEvent({ farmID: oldAnimal.FarmID }),
            method: "GET",
            queryParams: { FarmID: oldAnimal.FarmID, AnmID: oldAnimal.AnmID },
        });
        eventsResponse.map((e) => {
            e.EvID = myID();
            e.AnmID = newAnimal.AnmID;
        });
        // let events = eventsResponse.concat(event);
        // let e = {
        //     events: events
        // };
        //dodanie eventów dla nowego zwierzęcia
        // let addEvents = await invokeApig({
        //     path: "api/events",
        //     method: "POST",
        //     body: e
        // })
    } catch (e) {
        //console.log("catch promise fetch",e);
        return Promise.reject(new Error("Failed to create or update"));
    }
}

export function refreshSession() {
    return new Promise(async (resolve, reject) => {
        try {
            let session = await Auth.currentSession();
            let refreshToken = session.getRefreshToken();
            let user = await getCurrentUser();
            console.log(user, refreshToken);
            user.refreshSession(refreshToken, (err, data) => {
                console.log(err, data);
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        } catch (e) {
            reject(e);
        }
    });
}

/*
export async function getContent(language = 'en') {
    return content.filter(obj => obj.lang === language)[0];
}*/
