import { invert } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { animalListMapFields, animalListMapSQL, mapSQL } from '../../../../constans/sqlSelects';
import { getMapSQLData, getQueryCaller } from '../../../../selectors/animalParametersSelector';
import TableGrid from "../../../basics/table-grid/TableGrid";
import AnimalReferenceDocumentModalGrid from '../../../grids/animal-reference-document/AnimalReferenceDocumentModalGrid';

export default function FilterAnimalDrawerContent({ animals }) {

    const { t } = useTranslation();

    const mapSQLData = useSelector(getMapSQLData);
    const callQuery = useSelector(getQueryCaller);

    const AnmIDs = useMemo(() => animals.map(({ AnmID }) => AnmID), [animals]);

    const name = useMemo(() => {
        const invertedMapSQL = invert(mapSQL);
        return invertedMapSQL[mapSQLData.sql];
    }, [mapSQLData.sql]);

    const result = useMemo(() => {
        if (AnmIDs.length === 0) return [];
        const sql = name ? animalListMapSQL[name] : mapSQLData.sql ? mapSQLData.sql + " AND AnmID IN @(?) ORDER BY AnmNo1::NUMBER ASC, AnmNo1 ASC" : null;
        if (!sql) return [];
        return callQuery(sql, [...mapSQLData.params, AnmIDs]);
    }, [AnmIDs, callQuery, mapSQLData, name]);

    const headers = useMemo(() => {
        if (!name) {
            return [
                { name: t("animalNumber"), field: "AnmID", component: <AnimalReferenceDocumentModalGrid /> },
                { name: t("value"), field: "value" }
            ];
        }
        return [
            { name: t("animalNumber"), field: "AnmID", component: <AnimalReferenceDocumentModalGrid /> },
            ...animalListMapFields[name].map(row => ({
                name: t(row.translationKey),
                field: row.field
            }))
        ];
    }, [name, t]);

    if (result.length === 0) return null;
    return (
        <TableGrid headers={headers} data={result} shouldIndex showPagination paginationItems={10} smallPagination />
    );
}
