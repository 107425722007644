import LicPackageKeys from "@wesstron/utils/Api/constants/licPackageKeys";
import LicPackageLevels from "@wesstron/utils/Api/constants/licPackageLevels";
import RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import UserTypes from "@wesstron/utils/Api/constants/userTypes";
import { get, memoize } from "lodash";
import { createSelector } from 'reselect';
import { extractDevPlcmnts } from "../utils/DeviceLocationUtils";
import { userIsService } from "../utils/NewRolesUtils2";
import { displayComponent } from "../utils/WithRolesUtils";
import { getManageBuildingsList } from "./buildingsSelector";

const getUser = (state) =>
    state.user.user;

const getFarm = (state) =>
    state.location.farm;

const getSpecificLicenseName = (state, props) =>
    props.licenseName;

const getServiceRole = (state, props) =>
    get(props, "isService", true);

const getSpecificRoles = (state, props) =>
    props.roles;

export const makeGetLicensePackageSelector = () => {
    return createSelector(
        [getUser, getFarm, getServiceRole],
        (user, farm, hasServiceRole) => {
            const basic = (name) => `${name}Basic`;
            const extended = (name) => `${name}Ext`;
            const f2bRepresentation = [
                [LicPackageKeys.ADMINISTRATION, "adm"],
                [LicPackageKeys.ALARM, "al"],
                [LicPackageKeys.CAGE, "cg"],
                [LicPackageKeys.CHAIN, "ch"],
                [LicPackageKeys.CLIMATE, "cl"],
                [LicPackageKeys.COUNTER, "cnt"],
                [LicPackageKeys.DISPENSER_5G, "di5G"],
                [LicPackageKeys.DISPENSER, "di"],
                [LicPackageKeys.PORKER, "pork"],
                [LicPackageKeys.SMALL_CAGE, "sc"],
                [LicPackageKeys.SILO, "sl"],
                [LicPackageKeys.SOW, "sow"],
                [LicPackageKeys.FILES, "files"],
                [LicPackageKeys.OCR, "ocr"],
                [LicPackageKeys.FARM_MAP, "farmMap"],
                [LicPackageKeys.IPSUM, "ipsum"],
                [LicPackageKeys.NUTRI_PRO, "nutriPRO"]
            ];
            const result = {};
            let isService = get(user, "Roles", []).find(r => r.Role === RoleTypes.SERVICE);
            for (let [key, name] of f2bRepresentation) {
                result[key] = LicPackageLevels.NO_ACCESS;
                if (isService && hasServiceRole) {
                    result[key] = LicPackageLevels.EXTENDED;
                    continue;
                }
                if (!!get(user, `Packages[${farm}].${basic(name)}`)) {
                    result[key] = LicPackageLevels.BASIC;
                    if (!!get(user, `Packages[${farm}].${extended(name)}`)) {
                        result[key] = LicPackageLevels.EXTENDED;
                    }
                }
            }
            return result;
        }
    )
};

export const getLicensePackage = makeGetLicensePackageSelector();

export const getLicenseByName = createSelector([getLicensePackage, getSpecificLicenseName], (licenses, name) => {
    return licenses[name] || LicPackageLevels.NO_ACCESS;
});

export const hasAccessByRoles = createSelector([getUser, getSpecificRoles, getFarm], (user, roles, farmID) => {
    if ([UserTypes.SERVICE, UserTypes.OWNER].includes(user.UserType)) return true;
    let hasAccess = false;
    for (const { Role, LocalRights } of get(user, "Roles", [])) {
        if (roles.includes(Role) && LocalRights.some(({ FarmID }) => FarmID === farmID)) {
            hasAccess = true;
        }
    }
    return hasAccess;
})

export const roleSelector = createSelector([getFarm, getUser, getManageBuildingsList], (_farm, _user, _buildingsList) => {
    const isService = userIsService(_user, _farm);
    const displayComponentByDevice = memoize(({ roles = [], device = null, index = null } = {}) => {
        return displayComponent(roles, null, device ? extractDevPlcmnts(device, index) : null, _farm, _user, _buildingsList, isService);
    }, (o) => `${JSON.stringify(o.roles)}_${o.device?.DevID || null}_${o.device?.DtaModTime || null}_${o.index || null}`);
    const displayComponentByPlacementID = memoize(({ roles = [], placementId = null } = {}) => {
        return displayComponent(roles, null, [placementId], _farm, _user, _buildingsList, isService);
    }, (o) => `${JSON.stringify(o.roles)}_${o?.placementId || null}`);
    return {
        hasRole: displayComponentByDevice,
        hasRoleByPlacementID: displayComponentByPlacementID,
        isService
    }
});


export const isReadOnly = (state, role, device, index = null) => {
    return !roleSelector(state).hasRole({ roles: [role], device, index });
}

export const isReadOnlyByPlacementID = (state, role, placementId) => {
    return !roleSelector(state).hasRoleByPlacementID({ roles: [role], placementId })
}

const getExpectedRole = (_, role) => role;

const getDevices = (_, __, devices) => devices;

export const makeIsMultiDeviceReadOnly = () => createSelector(getUser, getExpectedRole, getDevices, getFarm, getManageBuildingsList, (user, role, devices, farm, buildingsList) => {
    const isService = userIsService(user, farm);
    for (let device of devices) {
        if (!displayComponent([role], null, extractDevPlcmnts(device), farm, user, buildingsList, isService)) return true;
    }
    return false;
})