import React, { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedAnimalForDocuments } from '../../../selectors/animalDocumentsSelectors';
import { getAnimalParametersFirstFetching, getQueryCaller } from '../../../selectors/animalParametersSelector';
import { getGroupMortalityChartData } from '../../../utils/GroupUtils';
import ChartTemplate from '../ideal-charts/charts/ChartTemplate';
import moment from 'moment';
import CollapsableContainer from '../containers/CollapsableContainer';
import LoadingComponent from '../../loading/LoadingComponent';
import { useTranslation } from 'react-i18next';

function CustomTooltip({ label, payload }) {
    return (
        <div className="compare-insertion-tooltip recharts-default-tooltip">
            <div>{moment(label).format("DD.MM.YY")}</div>
            <ul>
                {
                    !!payload && payload.map((item, i) => (
                        <li key={i} style={{ color: item.fill }}>
                            <span>{item.name}: {item.formatter ? item.formatter(item.value) : item.value}{item.unit}</span>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}

function GroupMortalityRenderer() {

    const { t } = useTranslation();

    const ref = useRef(null);

    const queryCaller = useSelector(getQueryCaller);
    const group = useSelector(getSelectedAnimalForDocuments);

    const data = useMemo(() => {
        return getGroupMortalityChartData(queryCaller, group.AnmGrp, group.DtaDltTime);
    }, [group]); // eslint-disable-line react-hooks/exhaustive-deps

    const percentFormatter = useCallback((value) => {
        if(!isFinite(value)) return
        return value.toFixed(2);
    }, []);

    const dataDef = useMemo(() => {
        return [
            {
                dataKey: "insertedAnimals",
                color: "success",
                name: t("animalCount"),
                yAxisId: "right",
                opacity: .2
            },
            {
                dataKey: "mortality",
                color: "error",
                name: t("mortality"),
                yAxisId: "left",
                unit: "%",
                formatter: percentFormatter
            },
            {
                dataKey: "weanerMortality",
                color: "warning",
                name: t("planTypes.weanerMortality"),
                yAxisId: "left",
                unit: "%",
                formatter: percentFormatter
            },
            {
                dataKey: "finisherMortality",
                color: "info",
                name: t("planTypes.porkerMortality"),
                yAxisId: "left",
                unit: "%",
                formatter: percentFormatter
            },
            {
                dataKey: "giltMortality",
                color: "purple",
                name: t("giltMortality"),
                yAxisId: "left",
                unit: "%",
                formatter: percentFormatter
            },
        ];
    }, [percentFormatter, t]);

    // const dateFormatter = useCallback((value) => {
    //     return moment(value).format("L");
    // }, []);

    return (
        <ChartTemplate
            overrideDesktop={true}
            show={true}
            domain={[0, "dataMax+5"]}
            data={data} error={false}
            YaxisName={t("mortality")}
            rightYaxisName={t("animalCount")}
            name={t("mortality")}
            loading={false}
            dataDef={dataDef}
            customTooltip={CustomTooltip}
            dataSet={data}
            defaultExpanded={true}
            saveAsExcell={t("mortality")}
            forwardRef={ref} />
            
    );
}

export default function GroupMortality() {

    const { t } = useTranslation();

    const firstFetchingParams = useSelector(getAnimalParametersFirstFetching);

    if (firstFetchingParams) return (
        <CollapsableContainer.Card header={t("mortality")} defaultExpanded>
            <div className="h-0 mh-15rem position-relative">
                <LoadingComponent isLoading />
            </div>
        </CollapsableContainer.Card>
    );
    return <GroupMortalityRenderer />;
}
