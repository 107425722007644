import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { makeGetAnimalsInGroup } from "../../../selectors/animalDocumentsSelectors";
import GroupAnimalList from "../GroupAnimalList";
import AnimalModificationList from "../animal-modification-list/AnimalModificationList";
import AnimalDocumentsComments from "../comments/AnimalDocumentsComments";
import FallGrid from "../fall/FallGrid";
import FatteningAnimalsGrid from "../fattening-animals/FatteningAnimalsGrid";
import GraftingProgramGridCard from "../grafting/GraftingProgramGridCard";
import GroupGauges from "../group-gauges/GroupGauges";
import DeviceCharts from "../ideal-charts/DeviceCharts";
import EventCharts from "../ideal-charts/EventCharts";
import useAggregatedLoader from "../ideal-charts/useAggregatedLoader";
import useAnimalGroupInfo from "../ideal-charts/useAnimalGroupInfo";
import ReclassifyGrid from "../reclassify/ReclassifyGrid";
import SellGrid from "../sell/SellGrid";
import TransferGrid from "../transfer/TransferGrid";
import WeightingsGrid from "../weighting/WeightingGroupChart";
import RemovedCounter from "../removed/RemovedCounter";

export default function GroupCard() {

    const groupInfo = useAnimalGroupInfo();
    useAggregatedLoader("water", groupInfo.start, groupInfo.end);
    useAggregatedLoader("forage", groupInfo.start, groupInfo.end);
    useAggregatedLoader("temperature", groupInfo.start, groupInfo.end);

    const getAnimalsInGroup = useMemo(() => makeGetAnimalsInGroup(), []);

    const selectorProps = useMemo(() => ({ getDeletedAnimals: true }), []);

    const animals = useSelector((state) => getAnimalsInGroup(state, selectorProps));

    const animalIds = useMemo(() => animals.map((o) => o.AnmID), [animals]);

    return (
        <>
            <GroupGauges groupInfo={groupInfo} />
            <GroupAnimalList />
            <FatteningAnimalsGrid />
            <DeviceCharts groupInfo={groupInfo} />
            <WeightingsGrid />
            <EventCharts />
            <TransferGrid isGroup getDeletedAnimals />
            <RemovedCounter isGroup getDeletedAnimals />
            <SellGrid isGroup />
            <FallGrid isGroup />
            <AnimalDocumentsComments />
            <ReclassifyGrid />
            <GraftingProgramGridCard isGroup />
            {/* <AnimalParameters /> */}
            <AnimalModificationList animalIds={animalIds} />
        </>
    );
}
