import {createSelector} from "reselect";
import {get, isNil} from "lodash";
import {getSortedBackgroundItems, parseFarmMapFromSVG} from "../utils/FarmMapUtils";
import {isFiniteNumber} from "../utils/MathUtils";
import {getFarmID} from "./farmSelector";

const getMaps = (state) => state.settings.farmMap;

const getLevelNumber = (state, {selectedLevel}) => selectedLevel

export const getFarmMap = createSelector([getMaps, getFarmID], (_farmMap, _currentFarmId) => {
    const currentMap = _farmMap.get(_currentFarmId);
    const levels = [];
    Object.entries((get(currentMap, "SetData.Levels") || {})).forEach(([name, mapString]) => {
        if (isFiniteNumber(+name)) {
            const parsed = parseFarmMapFromSVG(mapString);
            if (!isNil(parsed)) {
                levels.push({
                    name: name,
                    value: parsed
                })
            }
        }
    })
    return levels.sort((o1, o2) => o1.name - o2.name);
});

export const getFarmMapLevels = createSelector([getMaps, getFarmID], (_farmMap, _currentFarmId) => {
    console.time("getFarmMapLevels")
    const currentMap = _farmMap.get(_currentFarmId);
    const levels = {};
    let width = 1024, height = 1024;
    for (let [level, object] of Object.entries(get(currentMap, "SetData.Levels") || {})) {
        const parsed = parseFarmMapFromSVG(object);
        if (parsed) {
            const viewBox = parsed.attributes.viewBox.value.split(" ");
            width = +viewBox[2];
            height = +viewBox[3];
            levels[level] = [];
            const keys = level === "bg" ? ["others"] : ["buildings", "sectors", "chambers", "devices"];
            for (let key of keys) {
                const children = get(parsed, `children.${key}.children`, []);
                for (let child of children) {
                    const params = {
                        nodeName: child.nodeName,
                        type: key
                    };
                    for (let j = 0; j < get(child, "attributes.length", 0); j++) {
                        const {name, value} = child.attributes.item(j) || {};
                        if (name) {
                            params[name] = value;
                        }
                    }
                    levels[level].push(params)
                }
            }
        }
    }
    console.timeEnd("getFarmMapLevels")
    return {levels, width, height};
})

export const getBackground = createSelector([getMaps, getFarmID], (_farmMap, _currentFarmId) => {
    const currentMap = _farmMap.get(_currentFarmId);
    const parsed = parseFarmMapFromSVG(get(currentMap, "SetData.Levels.bg", ""));
    const items = get(parsed, `children.others.children`, []);
    const background = [];
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const params = {};
        for (let j = 0; j < get(item, "attributes.length", 0); j++) {
            const attribute = item.attributes.item(j);
            if (attribute && !["style"].includes(attribute.name)) {
                params[attribute.name] = attribute.value;
            }
        }
        if (item.id) {
            const name = item.id.split("_")[0];
            background.push({
                type: "path",
                params: {
                    d: params.d,
                    fill: `url(#${name})`
                },
                _id: item.id
            })
        }
    }
    return getSortedBackgroundItems(background, "_id");
})

export const getSelectedLevel = createSelector([getFarmMap, getLevelNumber], (_farmMap, _selectedLevel) => {
    console.log("_selectedLevel", _selectedLevel, _farmMap)
    return _farmMap.find(o => `${o.name}` === `${_selectedLevel}`) || null;
})
