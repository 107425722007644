import {
    editAnimalGroups,
    updateAnimalGroupsNotificationFailure,
    updateAnimalGroupsNotificationSuccess
} from "../../../api/animalGroups/editAnimalGroups";

export function submit(values, dispatch, props) {
    const {insertions, _Comment} = values;
    const {mergedInsertions: {insertionsIDs = []} = {}, group: {AnmGrp} = {}} = props;
    const insertionsToChange = {};
    Object.entries(insertions).forEach(([key, value]) => {
        if (key.startsWith("mergedInsertions")) {
            const insertionDetails = key.split("_");
            insertionsToChange[`${insertionDetails[1]}_${insertionDetails[2]}`] = value;
        }
    });
    const row = {
        AnmGrp,
        insertionsIDs,
        insertionsToChange,
        updateAnimals: false
    };
    if (_Comment) {
        row.comment = _Comment;
    }
    return editAnimalGroups([row]).then((res) => {
        updateAnimalGroupsNotificationSuccess(res);
        props.handleHide();
    }).catch((err) => {
        updateAnimalGroupsNotificationFailure(err);
    });
}

export function validate(values, props) {
    const {t, locationDays: {detailedData = []} = {}} = props;
    const {insertions = {}} = values;
    const errors = {};
    const mergedInsertionKeys = Object.keys(insertions).filter((key) => key.startsWith("mergedInsertions"));
    const getInsertionData = (key) => {
        const insertionData = key.split("_");
        return {timestamp: +insertionData[1], anmID: insertionData[2]};
    };
    mergedInsertionKeys.forEach((propertyValue, index) => {
        const anmCnt = insertions[propertyValue];
        const {anmID, timestamp} = getInsertionData(propertyValue);
        const nextTimeStamp = (index !== (mergedInsertionKeys.length - 1)) ? +mergedInsertionKeys.find((key) => getInsertionData(key).anmID === anmID) : undefined;
        let minimumInsertedAnimals = 0;
        for (const row of detailedData) {
            if (!row.date) continue;
            if (row.date >= timestamp && (nextTimeStamp ? (row.date <= +nextTimeStamp) : true)) {
                for (const key in row) {
                    if (row[key]?.anmID === anmID && row[key]?.type === "error") {
                        minimumInsertedAnimals += Math.abs(row[key]?.amount ?? 0);
                    }
                }
            }
        }
        if (anmCnt < minimumInsertedAnimals) {
            errors[propertyValue] = t("errors.lessVal", {count: minimumInsertedAnimals});
        } else if (anmCnt <= 0) {
            errors[propertyValue] = t("errors.noLessOrEq0");
        }
    });
    return errors;
}