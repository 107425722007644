export const ValidationDefaultValues = {
    requiredComments: false,
    boarOnlyOnBoarChamber: true,
    inseminationOnMating: true,
    parturitionOnDelivery: true,
    separationOnDelivery: true,
    tattooGiltsWithOneBoar: false,
    // nowe
    parturitionWithoutInsemination: true,
    parturitionWithPositiveBalance: true,
    usgWithoutInsemination: true,
    checkDaysFromInseminationToUSG: false,
    separationWithoutParturition: true,
    maxHealthyCnt: 50,
    maxWeakCnt: 50,
    maxMummyCnt: 50,
    maxDeadCnt: 50,
    daysFromInseminationToParturition: true,
    daysFromInseminationToUSG: true,
    transferAnimalWithPiglets: true,
    noPregnancyWithoutInsemination: true,
    separationWithMorePigletsThanAnimalHas: true,
    averagePigletWeightForParturition: [1000, 4000],
    averagePigletWeightForSeparation: [5000, 10000],
    averagePigletWeightForSale: [20000, 40000],
    averagePorkerWeightForSale: [100000, 120000],
    partialSeparationReport: true,
    blockDifferentSpermInCycle: true,
    parturitionAfterParturition: true,
    requireToSubmitEstimatedWeight: true,
    maxIntervalBetweenSeparations: 14,
    maxCycleInseminations: 0,
    removeAnimalWithEvents: true,
    // taski
    markTaskAsCompleted: false,
    markTaskAsCompletedAfterDate: false
};