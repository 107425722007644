import {
    resurrectAnimal,
    resurrectAnimalNotificationFailure,
    resurrectAnimalNotificationSuccess, showRemoveSaleInfo
} from "../../../api/animal/resurrectAnimal";

export function submit(values, dispatch, props) {
    const {animal} = props;
    return resurrectAnimal({
        AnmID: animal.AnmID,
        Comment: values.comment
    }, {FarmID: animal.FarmID}).then(res => {
        resurrectAnimalNotificationSuccess(res);
        props.handleHide();
        showRemoveSaleInfo(res, dispatch);
    }).catch(e => {
        resurrectAnimalNotificationFailure(e);
    });
}

export function validate(values, props) {
    const {animal, t, selectedItem} = props;
    const {animalNumber, comment} = values;
    const errors = {};
    if (animalNumber !== (selectedItem.GrNo1 ? selectedItem.GrNo1 : animal.AnmNo1)) {
        errors.animalNumber = t("errors.validAnimalNumber");
    }
    if (!comment) {
        errors.comment = t("required");
    }
    return errors;
}
