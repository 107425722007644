import CollapsableContainer from "../../containers/CollapsableContainer";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { get, isArray, isNil, isNumber, memoize } from "lodash";
import moment from "moment";
import { Col, Fade } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getColorByName } from "../../../../utils/ColorUtils";
import { utcDateFormatter } from "../../../../utils/DateTimeUtils";
import { formatLocationName } from "../../../../utils/global-formatters/formatLocationName";
import { Browser } from "../../../basics/browser-detection/Browser";
import Button from "../../../basics/button/Button";
import Card from "../../../basics/card/Card";
import Chart from "../../../basics/chart/Chart";
import Highlight from "../../../basics/highlight/Highlight";
import Placeholder from "../../../basics/placeholder/Placeholder";
import "./_chart-template.scss";
import useDomain from "../useDomain";
import { autoDomain } from "./utils";
import useReferenceLines from "../useReferenceLines";

const TooltipWithError = ({ label, payload }) => {
    const { t } = useTranslation();

    const ignoreKeys = useRef(["error"]);

    const details = payload?.[0]?.payload?.details;

    const getByKey = useCallback((key) => {
        return payload ? payload.find(({ dataKey }) => dataKey === key) : null;
    }, [payload]);

    const [valuePayload, errorPayload] = useMemo(() => {
        return ["value", "error"].map(getByKey);
    }, [getByKey]);

    const formatValue = useCallback((v) => {
        return isArray(v.value) ? v.value.join("~") : v.value;
    }, []);

    return (
        <div className="compare-insertion-tooltip recharts-default-tooltip">
            <div>{moment(label).format("DD.MM.YY")}</div>
            <ul>
                {
                    !!payload && payload.map((item, i) => (
                        !ignoreKeys.current.includes(item.dataKey) ?
                            <li key={i} style={{ color: item.fill }}>
                                <span>{item.name}: {formatValue(item)}{item.unit}</span>
                            </li>
                            :
                            null
                    ))
                }
            </ul>
            <small>
                <ul>
                    {
                        !!details &&
                        details.map(({
                            locationId,
                            value,
                            error,
                            count
                        }) => (valuePayload && value) || (errorPayload && error) ? (
                            <li key={locationId} style={{ color: error ? getColorByName("error") : valuePayload?.fill }}>
                                <span>
                                    {formatLocationName(locationId)}:{" "}
                                    {
                                        value !== null &&
                                        <>
                                            {formatValue({ value })}{valuePayload?.unit}
                                        </>
                                    }
                                    {
                                        value === null && <i className={"fas fa-fw fa-times"} />
                                    }
                                    {
                                        !!count &&
                                        <small className={"ms-2"}>
                                            <Highlight><i className={"fas fa-fw fa-pig"} /> {count}
                                            </Highlight>
                                        </small>
                                    }
                                </span>
                            </li>
                        ) : null)
                    }
                </ul>
                <div className="opacity-50">
                    <i className="fa-solid fa-magnifying-glass me-1" />
                    <span>{t("deviceRows.feeding.selfblock.showDetails")}</span>
                </div>
            </small>
        </div>
    );
};


const TemplateDesktop = ({
    id,
    forwardRef,
    name,
    defaultExpanded,
    error,
    loading,
    dataSet,
    dataDef,
    domain,
    YaxisName,
    customTooltip,
    changeDomainButton = true,
    onClick,
    rightYaxisName,
    saveAsExcell,
}) => {

    const { t } = useTranslation();

    const showPlaceholder = loading ? (!dataSet || !dataSet.length) : false;

    const domainX = useDomain();
    const referenceLines = useReferenceLines(domainX);

    const [domainYIndex, setDomainYIndex] = useState(0);

    const domainY = useMemo(() => {
        return [domain, autoDomain][domainYIndex];
    }, [domainYIndex, domain]);

    const changeDomain = useCallback(() => {
        setDomainYIndex((prevIndex) => (prevIndex + 1) % 2);
    }, []);

    console.log(domainX, domainY);
    const onChartClick = useCallback((data) => {
        if (!data) return;
        const { activePayload } = data;
        if (!activePayload?.[0] || !onClick) return;
        const payload = activePayload[0].payload;
        onClick(payload);
    }, [onClick]);

    return (
        <>
            <CollapsableContainer.Card
                id={id}
                forwardRef={forwardRef}
                header={name}
                passProps={{
                    disabled: !!error
                }}
                defaultExpanded={defaultExpanded}>
                <div className={"h-0 mh-15rem"}>
                    {
                        showPlaceholder && <Placeholder type={"chart"} />
                    }
                    {
                        !showPlaceholder &&
                        <>
                            {
                                changeDomainButton &&
                                <Button className={"position-absolute z-index-2"} type={"button"} buttonStyle={"round"}
                                    onClick={changeDomain}
                                    icon={<i className={"fas fa-arrows-up-down"} />} />
                            }
                            <Chart
                                noDataFoundType={error ? "failedLoad" : "noDataFound"}
                                Yaxis={{
                                    name: YaxisName || "Y",
                                    domain: domainY || ["dataMin", "dataMax"],
                                    yAxisId: "left"
                                }}
                                secondYaxis={rightYaxisName ? {
                                    name: rightYaxisName || "Y",
                                    // domain: domainY || ["dataMin", "dataMax"],
                                    yAxisId: "right"
                                } : null}
                                Xaxis={{
                                    domain: domainX,
                                    type: "number",
                                    dataKey: "time",
                                    name: "",
                                    formatter: utcDateFormatter
                                }}
                                data={dataSet} type={"Composed"}
                                syncId={"idealChart"}
                                syncMethod={"value"}
                                isAnimationActive={false}
                                tooltipContent={customTooltip || TooltipWithError}
                                dataDef={dataDef}
                                onChartClick={onChartClick}
                                saveAsExcell={saveAsExcell}
                                referenceLines={referenceLines} />
                            {
                                referenceLines.length === 0 &&
                                <div className="chart-template-no-data">
                                    <h5>{t("chartDisplayNoBirthTime")}</h5>
                                    <div>{t("chartDisplayNoBirthTimeText")}</div>
                                </div>
                            }
                        </>
                    }

                </div>
            </CollapsableContainer.Card>
        </>

    );
};

const TemplateMobile = ({
    forwardRef, name, error, loading, dataSet, dataDef, domain, YaxisName, customTooltip
}) => {

    const { t } = useTranslation();

    const [expanded, setExpanded] = useState(false);

    // gets latest data from an array
    const currentData = useMemo(() => {
        const yesterday = +moment.utc().startOf("day").subtract(1, "day");
        let currentIndex = dataSet.findIndex(({ time }) => time >= yesterday);
        if (currentIndex === -1) currentIndex = dataSet.length - 1;
        return dataDef.map(({ dataKey, unit, name, _role }) => {
            return {
                value: get(dataSet[currentIndex], dataKey),
                unit,
                name,
                role: _role,
                time: get(dataSet[currentIndex], "time")
            };
        });
    }, [dataSet, dataDef]);

    const byRole = useMemo(() => memoize((role) => {
        return currentData.find((d) => d.role === role);
    }), [currentData]);

    const [expected, read] = [byRole("expected"), byRole("read"), byRole("range")];

    // showing an expected value takes too much space on mobile
    // we will be showing user only a difference between the value we got and the expected one
    let diff = 0;
    if (expected?.value) {
        // make sure the value is a primitive number so we can calculate diff
        if (isNumber(read?.value)) {
            diff = +(expected.value - read.value).toFixed(2);
        }
    }

    // mark item as valid if there's a non-nullish value
    const valid = !isNil(read?.value);
    // show loading only if we have no data
    const showLoading = loading && !dataSet.length;
    // show no data sign only if theres an error or the value is not valid
    const showNoData = loading ? false : error || !valid;
    // show date of a latest read
    const showTime = !!read?.time;

    const domainX = useDomain();
    const referenceLines = useReferenceLines(domainX);

    return (
        <>
            <Col xs={12} sm={6} md={4} lg={4} xl={3} ref={forwardRef} aria-expanded={expanded}>
                <Card type={"col"} disabled={loading ? !valid : error}
                    className={"mh-15rem h-0 d-flex flex-column justify-content-between"}
                    onClick={(showLoading || error) ? null : () => setExpanded((s) => !s)}>
                    <div className="d-flex justify-content-between pointer">
                        <h5>{name}&nbsp;</h5>
                    </div>
                    <div className={"d-flex flex-column align-items-center justify-content-center"}>
                        {
                            isNil(read?.value) &&
                            <div className={"h1 d-flex justify-content-center align-items-center"}>
                                <i className={`fas fa-fw ${loading ? "fa-spin fa-spinner" : "fa-times"}`} />
                            </div>
                        }
                        {
                            !isNil(read?.value) &&
                            <div className={"d-flex align-items-center"}>
                                <span className={"h3"}>
                                    {isArray(read.value) ? read.value.join("~") : read.value}
                                    {read.unit}
                                </span>
                                {
                                    diff > 0 &&
                                    <small className={"color-warning"}>
                                        <i className={"fas fa-arrow-up"} />
                                        {diff}
                                    </small>
                                }
                                {
                                    diff < 0 &&
                                    <small className={"color-blue"}>
                                        <i className={"fas fa-arrow-down"} />
                                        {diff}
                                    </small>
                                }
                            </div>
                        }
                        {
                            showLoading && t("loading")
                        }
                        {
                            showTime && <div>{utcDateFormatter(read.time)}</div>
                        }
                        {
                            showNoData && <small className={"opacity-50"}>{t("noData")}</small>
                        }
                    </div>
                    <div className={"align-self-center"}>
                        {
                            (!showLoading || error) &&
                            <i className={"fas fa-chevron-down opacity-50"} />
                        }
                        &nbsp;
                    </div>
                </Card>
                <Fade in={expanded} mountOnEnter unmountOnExit>
                    <Card className={"chart-overlay"}>
                        <Button
                            type={"button"}
                            onClick={() => setExpanded((s) => !s)}
                            buttonStyle={"round"}
                            icon={<i className={"fas fa-times"} />}
                        />
                        <Chart
                            showLegend={false}
                            Yaxis={{
                                name: YaxisName || "Y",
                                domain: domain || ["dataMin", "dataMax"]
                            }}
                            Xaxis={{ domain: domainX, dataKey: "time", name: "", formatter: utcDateFormatter }}
                            data={dataSet} type={"Composed"}
                            tooltipContent={customTooltip || TooltipWithError}
                            syncId={"idealChart"}
                            syncMethod={"value"}
                            dataDef={dataDef}
                            referenceLines={referenceLines} />

                        {
                            referenceLines.length === 0 &&
                            <div className="chart-template-no-data">
                                <h5>{t("chartDisplayNoBirthTime")}</h5>
                                <div>{t("chartDisplayNoBirthTimeText")}</div>
                            </div>
                        }
                    </Card>
                </Fade>
            </Col>
        </>

    );
};

const ChartTemplate = (props) => {
    if (props.overrideDesktop || Browser.is.Desktop()) {
        return (
            <TemplateDesktop {...props} />
        );
    }
    return (
        <TemplateMobile {...props} />
    );
};


export default ChartTemplate;