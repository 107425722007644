import { invokeApig } from "../libs/awsLib";
import buildingsDB from "../database/buildingsDB";
import { checkIfUserHasRole } from "./NewRolesUtils";
import * as RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import devicesDB from "../database/devicesDB";
import settingsDB from "../database/settingsDB";
import dictionariesDB from "../database/dictionariesDB";
import groupsDB from "../database/groupsDB";
import Paths from "../api/paths";
import reportsDB from "../database/reportsDB";
import salesDB from "../database/salesDB";
import economyDB from "../database/economyDB";
import breedingCountersDB from "../database/breedingCountersDB";

export async function getDictionaries(ClientID, signal) {
    let clientDictionaries = await invokeApig({
        ...Paths.listDictionaries({ clientID: ClientID }),
        method: 'GET',
        queryParams: { DtaModTime: dictionariesDB.getModificationTime(ClientID).DtaModTime },
        signal
    });
    if (clientDictionaries.length > 0) {
        dictionariesDB.insertIntoDictionaries(clientDictionaries);
    }
}

export async function getBuildings(FarmID, signal) {
    try {
        let buildings = await invokeApig({
            ...Paths.listLocation({ farmID: FarmID }),
            queryParams: {
                FarmID: FarmID,
                DtaModTime: parseInt(buildingsDB.getModificationTime(FarmID).DtaModTime),
                newAPI: true
            },
            signal
        });
        if (buildings.length > 0) {
            buildingsDB.insertIntoBuildings(buildings);
            return true;
        }
    } catch (e) {
        console.error(e);
    }
    return false;
}

export async function getDevices(FarmID, user, signal) {
    try {
        if (checkIfUserHasRole(RoleTypes.GENERAL_READ, FarmID, user.Roles)) {
            let devices = await invokeApig({
                ...Paths.listDevices({ farmID: FarmID }),
                queryParams: {
                    DtaModTime: parseInt(devicesDB.getModificationTime(FarmID).DtaModTime),
                    table: "Devices"
                },
                signal
            });
            if (devices.length > 0) {
                devicesDB.insertIntoDevices(devices);
                return true;
            }
        }
    } catch (e) {
        console.error(e);
    }
    return false;
}

export async function getSettings(ClientID, FarmID, signal) {
    const array = await invokeApig({
        ...Paths.listSettings({
            clientID: ClientID
        }),
        queryParams: { DtaModTime: settingsDB.getModificationTime(FarmID).DtaModTime },
        signal
    });
    if (array.length > 0) {
        settingsDB.insertIntoSettings(array);
        return true;
    }
    return false;
}

export async function loadGroups(FarmID, signal) {
    try {
        let groups = await invokeApig({
            ...Paths.listGroups({ farmID: FarmID }),
            queryParams: { DtaModTime: groupsDB.getModificationTime(FarmID).DtaModTime },
            signal
        });
        console.log("GROUPS xDDDD", groups);
        if (groups?.items.length > 0) {
            groupsDB.insertIntoGroups(groups.items);
            return true;
        }
    } catch (e) {
        console.error(e);
    }
    return false;
}

export async function loadReports(ClientID, LocalUserID, signal) {
    try {
        if (!checkIfUserHasRole(RoleTypes.REPORT_READ)) return false;
        let reports = await invokeApig({
            ...Paths.getReports({ clientID: ClientID, localUserID: LocalUserID }),
            queryParams: { DtaModTime: reportsDB.getModificationTime().DtaModTime },
            signal
        });
        if (reports.items.length > 0) {
            reportsDB.insertAthenaReports(reports.items);
            return true;
        }
    } catch (e) {
        console.error(e);
    }
    return false;
}

export async function getSales(ClientID, LocalUserID, FarmID, user, signal, forceFetch = false) {
    try {
        if (checkIfUserHasRole(RoleTypes.SALE_READ, FarmID, user.Roles)) {
            let sales = await invokeApig({
                ...Paths.getSales({ clientID: ClientID, localUserID: LocalUserID }),
                queryParams: { DtaModTime: salesDB.getModificationTime().DtaModTime },
                signal,
                forceFetch
            });
            if (sales.length > 0) {
                salesDB.insertSales(sales);
                return true;
            }
        }
    } catch (e) {
        console.error(e);
    }
    return false;
}

export async function getEconomy(ClientID, LocalUserID, FarmID, user, signal, forceFetch = false) {
    try {
        if (checkIfUserHasRole(RoleTypes.ECONOMY_READ, FarmID, user?.Roles)) {
            let economy = await invokeApig({
                ...Paths.getEconomy({ clientID: ClientID, localUserID: LocalUserID }),
                queryParams: { DtaModTime: economyDB.getModificationTime().DtaModTime },
                signal,
                forceFetch
            });
            if (economy.length > 0) {
                economyDB.insertEconomy(economy);
                return true;
            }
        }
    } catch (e) {
        console.error(e);
    }
    return false;
}

export async function getBreedingCounters(ClientID, LocalUserID, FarmID, user, signal) {
    try {
        if (checkIfUserHasRole(RoleTypes.BREEDING, FarmID, user?.Roles)) {
            let breedingCounters = await invokeApig({
                ...Paths.getCounters({ clientID: ClientID, localUserID: LocalUserID }),
                queryParams: { DtaModTime: breedingCountersDB.getModificationTime().DtaModTime },
                signal
            });
            if (breedingCounters.length > 0) {
                breedingCountersDB.insertBreedingCounters(breedingCounters);
                return true;
            }
        }
    } catch (e) {
        console.error(e);
    }
    return false;
}
