import {invokeApig} from "../libs/awsLib";
import Paths from "../api/paths";
import snsDB from "../database/snsDB";
import {clearToken} from "../utils/FirebaseUtils";

export async function registerSNSEndpoint(token, user) {
    let result = await invokeApig({
        ...Paths.connectSNS(user.ClientID, user.LocalUserID),
        body: {
            Token: token
        }
    });
    snsDB.saveSNS(result.endpointARN);
}

export async function unsubscribeSNSTopic(user) {
    let endpointARN = snsDB.getSNS();
    if (endpointARN) {
        await invokeApig({
            ...Paths.disconnectSNS(user.ClientID, user.LocalUserID),
            body: {
                "EndpointArn": endpointARN
            }
        });
        snsDB.clearDB();
        await clearToken();
    }
}
