import React, { Component } from "react";
import { connect } from "react-redux";
import "./_farm-map-component.scss";
import Patterns from "../farm-map-components/patterns";
import BackgroundSVG from "../farm-map-editor/BackgroundSVG";
import {
    getAngleBetweenTwoPoints,
    getDistanceBetweenTwoPoints,
} from "../svg-editor/utils/utils";
import memoizeOne from "memoize-one";
import LevelSelect from "./components/LevelSelect";
import {
    cloneDeep,
    isBoolean,
    isFunction,
    isString,
    throttle,
    debounce
} from "lodash";
import {
    onBeforeRenderAll,
    onBeforeRenderAnimalFilter,
    onBeforeRenderClimates,
    onBeforeRenderElectrics,
    onBeforeRenderLights,
    onBeforeRenderWater,
} from "./utils";
import TypeSelect from "./components/TypeSelect";
import LocationDrawer from "./components/LocationDrawer";
import SelectedItem from "./components/SelectedItem";
import SearchAnimal from "./components/SearchAnimal";
import NewIOT from "../../IOT/NewIOT";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "../../utils/MobileUtils";
import { getRemToPx } from "../../utils/DOMUtils";
import {
    PRE_PROCESS_SCALING,
} from "../../utils/FarmMapUtils";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { isBetween, rotateCartesian } from "../../utils/MathUtils";
import * as MatrixUtils from "transformation-matrix";
import Rotate from "./components/farm-map-controls/Rotate";
import ElementList from "./components/ElementList";
import FilterAnimalControl from "./components/animal-controls/FilterAnimalControl";
import Scale from "../svg-editor/utils/Scale";
import { catchify, getReferenced } from "../../utils/Utils";
import withRoles from "../withRoles";
import RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import GlobalControlPanel from "./components/global-controls/GlobalControlPanel";
import { Browser } from "../basics/browser-detection/Browser";
import Zoom from "./components/farm-map-controls/Zoom";
import FarmMapControlContainer from "./components/farm-map-controls/FarmMapControlContainer";
import { makeAnimations } from "./components/animations";
import LicPackageLevels from "@wesstron/utils/Api/constants/licPackageLevels";
import LicPackageKeys from "@wesstron/utils/Api/constants/licPackageKeys";
import { farmMapDataSelector } from "../../selectors/farmMapDataSelector";
import { getAnimalColorFunc } from "../../selectors/farmMapSelector";
import Gradients from "../farm-map-components/patterns/gradients/Gradients";

const TOUCH_ACCEL = 1.5;

function makeMapStateToProps() {
    return function mapStateToProps(state) {
        return {
            farmData: farmMapDataSelector(state),
            getAnimalColor: getAnimalColorFunc(state)
        };
    };
}

class FarmMapComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLevel: 0,
            isMoving: false,
            mapType: "chambers",
            selectedId: null,
            camera: { x: 0, y: 0, zoom: 1, rotate: 0 },
            __selectionTime__: 0,
        };
        this.portal = React.createRef();
        this.cache = {};
        this.touchBefore = [];
        this.mobile = isMobile();
        this.scaleOptions = new Scale();
        if (this.mobile) {
            this.scaleOptions.makeSafe();
        }
        this.localCamera = cloneDeep(this.state.camera);
        this.isChrome = Browser.is.Chrome();
        this.transitionType = "auto";
        NewIOT.resetCounters();
        this.animations = makeAnimations(this.setAnimationState);
    }

    setAnimationState = (func) => {
        this.setState(func);
    };

    setLevel = (level) => {
        this.setState({
            currentLevel: level,
        });
    };
    setSelectedId = (value) => {
        this.setState((state) => ({
            selectedId: isString(value) ? value : value?.id || null,
            selectedType: isString(value)
                ? "location"
                : ["devices", "groups"].includes(value?.type)
                    ? "device"
                    : value?.type === "animals"
                        ? "animal"
                        : "location",
            animate: isString(value),
            animateType: isString(value) ? "search" : "click",
            mapType:
                isString(value) && !["chambers", "all"].includes(state.mapType)
                    ? "chambers"
                    : state.mapType,
            __selectionTime__: Date.now(),
        }));
    };

    openModal = (value) => {
        this.setState({
            selectedId: value?.location?.id || null,
            animateType: "longclick",
        });
    };

    setMapType = (mapType) => {
        this.setState({
            mapType: mapType,
        });
    };

    onBeforeRenderAnimals = (getAnimalColor) => memoizeOne((params) => {
        return onBeforeRenderAnimalFilter(params, getAnimalColor);
    });

    getOnBeforeRender = () => {
        const { onBeforeRender, getAnimalColor } = this.props;
        const { mapType } = this.state;
        if (isFunction(onBeforeRender)) return onBeforeRender;
        switch (mapType) {
            case "climates":
                return onBeforeRenderClimates;
            case "electric":
                return onBeforeRenderElectrics;
            case "water":
                return onBeforeRenderWater;
            case "lights":
                return onBeforeRenderLights;
            case "animals":
                return this.onBeforeRenderAnimals(getAnimalColor);
            default:
                return onBeforeRenderAll;
        }
    };

    getOmitNames = () => {
        const { onBeforeRender } = this.props;
        const { mapType } = this.state;
        if (isFunction(onBeforeRender)) return [];
        switch (mapType) {
            case "lights":
            case "climates":
            case "electric":
            case "water":
                return getReferenced([
                    "buildings",
                    "sectors",
                    "standings",
                    "groups",
                    "animals",
                ]);
            default:
                return getReferenced(["buildings", "sectors"]);
        }
    };

    firstZoom = () => {
        const { farmData: { levelBounds }, selectedId } = this.props;
        if (levelBounds && levelBounds[0]) {
            const { x1, x2, y1, y2 } = levelBounds[0];
            this.transitionType = "INSTANT";
            this.setViewportAt(x1, y1, x2, y2);
        }
        if (selectedId) {
            this.transitionType = "DELAYED_SMOOTH_ZOOM";
            this.setSelectedId(selectedId);
        }
    };

    componentDidMount() {
        const element = this.portal.current;
        if (this.mobile) {
            element.addEventListener("touchstart", this.pointerDown);
            element.addEventListener("touchend", this.pointerUp);
            element.addEventListener("touchcancel", this.pointerUp);
            element.addEventListener("touchmove", this.touchMove);
        } else {
            element.addEventListener("wheel", this.wheel);
            element.addEventListener("mousedown", this.pointerDown);
            document.addEventListener("mouseup", this.pointerUp);
            document.addEventListener("mousemove", this.pointerMove);
        }
        window.addEventListener("resize", this.onResize);
        this.firstZoom();
    }

    onResize = throttle(() => {
        this.cache = {};
        this.forceUpdate();
    }, 125);

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { camera } = this.state;
        if (camera !== prevState.camera && !this.move) {
            this.saveCamera.cancel();
            this.localCamera = cloneDeep(camera);
        }
    }

    componentWillUnmount() {
        this.animations.destroy();
        this.saveCamera.cancel();
        const element = this.portal.current;
        if (this.mobile) {
            element.removeEventListener("touchstart", this.pointerDown);
            element.removeEventListener("touchend", this.pointerUp);
            element.removeEventListener("touchcancel", this.pointerUp);
            element.removeEventListener("touchmove", this.touchMove);
        } else {
            element.removeEventListener("wheel", this.wheel);
            element.removeEventListener("mousedown", this.pointerDown);
            document.removeEventListener("mouseup", this.pointerUp);
            document.removeEventListener("mousemove", this.pointerMove);
        }
        window.removeEventListener("resize", this.onResize);
    }

    showControls = () => {
        return !isFunction(this.props.onBeforeRender);
    };

    getItems = memoizeOne((data, level) => {
        return data[level] || [];
    });

    _realViewBox = memoizeOne((width = 0, height = 0, mat) => {
        const { applyToPoint, inverse } = MatrixUtils;
        const matInverse = inverse(mat);
        const viewPortParams = [
            applyToPoint(matInverse, [0, 0]),
            applyToPoint(matInverse, [0, height]),
            applyToPoint(matInverse, [width, 0]),
            applyToPoint(matInverse, [width, height]),
        ];
        let minX = viewPortParams[0][0],
            minY = viewPortParams[0][1],
            maxX = viewPortParams[0][0],
            maxY = viewPortParams[0][1];
        for (let i = 1; i < viewPortParams.length; i++) {
            minX = Math.min(viewPortParams[i][0], minX);
            minY = Math.min(viewPortParams[i][1], minY);
            maxX = Math.max(viewPortParams[i][0], maxX);
            maxY = Math.max(viewPortParams[i][1], maxY);
        }
        const boundsOffset = 15;
        minX -= boundsOffset;
        minY -= boundsOffset;
        maxY += boundsOffset;
        maxX += boundsOffset;
        return {
            minX,
            maxX,
            minY,
            maxY,
        };
    });

    getRealViewBox = () => {
        const width = this.getPortalWidth();
        const height = this.getPortalHeight();
        const mat = this.getMatrix();
        return this._realViewBox(width, height, mat);
    };

    showSmalls = () => {
        const {
            camera: { zoom },
            mapType,
        } = this.state;
        const { onBeforeRender } = this.props;
        return isFunction(onBeforeRender) ||
            ["chambers", "all", "animals"].includes(mapType)
            ? zoom > 1.2
            : false;
    };

    setLocalCameraAsState = () => {
        if (this.localCamera) {
            this.setState({
                camera: cloneDeep(this.localCamera)
            });
        }
    };

    updateCamera = (fn) => {
        if (this.localCamera) {
            this.localCamera = fn(this.localCamera);
            catchify(() => {
                const matrix = this.getMatrix({ camera: this.localCamera });
                const container = this.portal.current.getElementsByClassName("svg-container")[0];
                container.style.transform = `${MatrixUtils.toCSS(matrix)}`;
            })();
            this.saveCamera();
        }
    };

    saveCamera = debounce(this.setLocalCameraAsState, 300);

    pointerDown = (e) => {
        const { selectedId } = this.props;
        this.move = true;
        this.moving = false;
        this.touchBefore = [];
        this.movementX = 0;
        this.movementY = 0;
        this.angle = null;
        // if the selectedId is overridden by a prop make sure no one can change it
        if (e.target.nodeName === "svg" && !selectedId) {
            this.setState({
                selectedId: null,
            });
        }
        this.forceUpdate();
    };

    touchMove = (e) => {
        if (e.preventDefault) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (this.move) {
            this.moving = true;
            const newTouches = [];
            for (let i = 0; i < e.touches.length; i++) {
                newTouches.push({
                    pageX: e.touches[i].pageX,
                    pageY: e.touches[i].pageY,
                });
            }
            // newTouches[1] = this.touchBefore[1] || newTouches[0]
            const type =
                newTouches.length === 1
                    ? "move"
                    : newTouches.length === 2
                        ? "zoom/rotate"
                        : "none";
            switch (type) {
                case "move": {
                    const { pageX, pageY } = this.touchBefore[0] || newTouches[0];
                    const movementX =
                        TOUCH_ACCEL * (newTouches[0].pageX - pageX),
                        movementY = TOUCH_ACCEL * (newTouches[0].pageY - pageY);
                    this.touchBefore = cloneDeep(newTouches);
                    this.pointerMove({ movementX, movementY });
                    break;
                }
                case "zoom/rotate": {
                    const pointLastA = this.touchBefore[1]
                        ? this.touchBefore[0] || newTouches[0]
                        : newTouches[0];
                    const pointLastB = this.touchBefore[1] || newTouches[1];
                    const pointA = newTouches[0];
                    const pointB = newTouches[1];
                    const hasBothTouches =
                        !!this.touchBefore[1] && !!this.touchBefore[0];
                    this.touchBefore = cloneDeep(newTouches);
                    if (hasBothTouches) {
                        const distance = getDistanceBetweenTwoPoints(
                            pointA.pageX,
                            pointA.pageY,
                            pointB.pageX,
                            pointB.pageY
                        );
                        const distanceBefore = getDistanceBetweenTwoPoints(
                            pointLastA.pageX,
                            pointLastA.pageY,
                            pointLastB.pageX,
                            pointLastB.pageY
                        );
                        const angle = getAngleBetweenTwoPoints(
                            pointA.pageX,
                            pointA.pageY,
                            pointB.pageX,
                            pointB.pageY
                        );
                        const zoomRatio = Math.abs(distance / distanceBefore);
                        if (this.angle === null) {
                            this.touchType = null;
                            this.startAngle = angle;
                            this.angle = this.localCamera.rotate - angle;
                        } else if (this.touchType === null) {
                            if (
                                isBetween(
                                    angle,
                                    this.startAngle - 10,
                                    this.startAngle + 10
                                )
                            ) {
                                if (zoomRatio > 1.05 || zoomRatio < 0.95) {
                                    this.touchType = "zoom";
                                }
                            } else {
                                this.touchType = "rotate";
                            }
                        }
                        if (this.touchType !== null) {
                            this.updateCamera((camera) => ({
                                ...camera,
                                rotate:
                                    this.touchType === "rotate"
                                        ? this.angle + angle
                                        : camera.rotate,
                                zoom:
                                    this.touchType === "zoom"
                                        ? this.scaleOptions.setCurrent(
                                            camera.zoom * zoomRatio
                                        ).scale
                                        : camera.zoom,
                            }));
                        }
                    }
                    break;
                }
                default:
                    break;
            }
        }

    };

    setZoom = (newZoom) => {
        this.setState((state) => ({
            camera: {
                ...state.camera,
                zoom: newZoom,
            },
        }));
    };

    pointerMove = (e) => {
        if (this.move) {
            this.moving = true;
            const { movementX, movementY } = e;
            const { zoom, rotate } = this.localCamera;
            const { x, y } = rotateCartesian(movementX, movementY, -rotate);
            this.movementX += x / zoom;
            this.movementY += y / zoom;
            if (e.preventDefault) {
                e.preventDefault();
                e.stopPropagation();
            }
            const xm = this.movementX,
                ym = this.movementY;
            this.movementX = 0;
            this.movementY = 0;
            this.updateCamera((cam) => ({
                ...cam,
                x: cam.x - xm,
                y: cam.y - ym,
            }));
        }
    };

    pointerUp = () => {
        this.move = false;
        this.moving = false;
        this.angle = null;
        this.forceUpdate();
        NewIOT.getCounters(true);
    };

    setScale = (next = true, portalX = 0, portalY = 0) => {
        this.setState((state) => {
            const prevZoom = state.camera.zoom;
            const nextZoom = isBoolean(next)
                ? this.scaleOptions
                    .setCurrent(prevZoom)
                [next ? "next" : "prev"]().scale
                : this.scaleOptions.setCurrent(next * prevZoom).scale;
            // todo: dorobic tutaj translate zeby nie zoomowalo do srodka zawsze na dekstopie?
            return {
                camera: { ...state.camera, zoom: nextZoom },
                animate: false,
            };
        });
    };

    setViewportAt = (x1, y1, x2, y2) => {
        if (!this.portal.current) return;
        this.animations.stop();
        const portalWidth = this.getPortalWidth(),
            portalHeight = this.getPortalHeight();
        const width = x2 - x1,
            height = y2 - y1;
        const scaleW = width / portalWidth,
            scaleH = height / portalHeight;
        const isSmall = width < 40 || height < 40;
        const zoom = this.scaleOptions.setCurrent(
            (this.mobile ? 0.7 : isSmall ? 0.2 : 0.8) / Math.max(scaleH, scaleW)
        ).scale;
        const x = x1 + width / 2,
            y = y1 + height / 2;
        const { animateType } = this.state;
        let transitionType = this.transitionType;
        this.transitionType = "auto";
        if (transitionType === "auto") {
            transitionType = "NORMAL";
            if (animateType === "search") {
                transitionType = "ZOOM_AND_MOVE";
            }
        }
        this.animations.run(transitionType, { x, y, zoom });
    };

    wheel = (e) => {
        const { deltaY } = e;
        if (!this.move && this.portal.current) {
            const next = deltaY < 0;
            const prevZoom = this.localCamera.zoom;
            this.zoom = isBoolean(next)
                ? this.scaleOptions
                    .setCurrent(prevZoom)
                [next ? "next" : "prev"]().scale
                : this.scaleOptions.setCurrent(next * prevZoom).scale;
            const { x: prevX, y: prevY } = this.localCamera;
            const nextZoom = this.zoom;
            // get zoom factor
            const factor = prevZoom / nextZoom;
            // get world position which is pointed by cursor
            const { x, y } = this.screenToWorldPoint(e.clientX, e.clientY);
            // (this is a modified formula from FarmMapEditor)
            const newX = prevX * factor + (1 - factor) * x;
            const newY = prevY * factor + (1 - factor) * y;
            if (this.state.animate) {
                this.setState({
                    animate: true
                });
            }
            this.updateCamera((camera) => ({
                ...camera,
                zoom: nextZoom,
                x: newX,
                y: newY,
            }));
            e.preventDefault();
        }
    };

    getPortalHeight = () => {
        const { portalHeight } = this.props;
        if (!this.cache["height"])
            this.cache["height"] =
                portalHeight ||
                this.portal.current?.getBoundingClientRect().height ||
                0;
        return this.cache["height"];
    };

    getPortalWidth = () => {
        const { portalWidth } = this.props;
        if (!this.cache["width"])
            this.cache["width"] =
                portalWidth ||
                this.portal.current?.getBoundingClientRect().width ||
                0;
        return this.cache["width"];
    };

    /**
     * gets point on the map which corresponds to passed screen point
     * @param pointX
     * @param pointY
     * @return {{x: number, y: number}|{x: *, y: *}}
     */
    screenToWorldPoint = (pointX, pointY) => {
        if (this.portal.current) {
            // 1. get inverse matrix
            const { applyToPoint, inverse } = MatrixUtils;
            const inverseMat = inverse(this.getMatrix({ camera: this.localCamera }));
            // 2. get viewBox size and cache it
            if (!this.cache["getBoundingClientRect"])
                this.cache["getBoundingClientRect"] =
                    this.portal.current.getBoundingClientRect();
            const viewBox = this.cache["getBoundingClientRect"];
            // 3. convert screen point to viewBox point
            // 4. apply inverse matrix to viewBox point
            const [newX, newY] = applyToPoint(inverseMat, [
                pointX - viewBox.x,
                pointY - viewBox.y,
            ]);
            return { x: newX, y: newY };
        }
        return { x: 0, y: 0 };
    };

    getMatrix = ({ camera } = this.state) => {
        const { rotate, x, y, zoom } = camera;
        const { translate, rotateDEG, scale, compose } =
            MatrixUtils;
        let width = this.getPortalWidth(),
            height = this.getPortalHeight();
        // offset zeby wysrodkowac widok
        const offsetX = width / 2;
        const offsetY = height / 2;
        const px = -x + offsetX,
            py = -y + offsetY;
        // smooth matrix mial pomoc z blurami gdy mamy doczynienia z liczbami zmiennoprzecinkowymi ale jak smoothujemy to przyblizenia nie sa plynne
        return compose(
            translate(px, py),
            rotateDEG(rotate, x, y),
            scale(zoom, zoom, x, y)
        );
    };

    setRotate = (value) => {
        this.updateCamera((camera) => ({
            ...camera,
            rotate: value,
        }));
    };

    setAnimate = () => {
        this.setState({
            animate: true,
            animateType: "click",
        });
    };

    render() {
        const {
            useShadow,
            useAlarm,
            useMinimalVersion,
            farmData: {
                background,
                levels,
                data,
                type,
                width: widthRaw,
                height: heightRaw,
            }
        } = this.props;
        const width = widthRaw * PRE_PROCESS_SCALING;
        const height = heightRaw * PRE_PROCESS_SCALING;
        const {
            currentLevel,
            mapType,
            selectedId,
            selectedType,
            animate,
            camera,
            animateType,
            __selectionTime__,
        } = this.state;
        const items = this.getItems(data, currentLevel);
        const {
            onElementClick,
            sizeOffset,
            t,
            portalHeight: hasFixedHeight,
        } = this.props;
        const showSmalls = this.showSmalls();
        const portalHeight = hasFixedHeight
            ? hasFixedHeight
            : this.portal.current
                ? window.innerHeight -
                this.portal.current.getBoundingClientRect().top -
                sizeOffset
                : 400;
        // ustawiamy cache dla wysokosci
        this.cache["height"] = portalHeight;
        const showControls = this.showControls();
        const showMessage = ["error", "empty"].includes(type);
        const classNames = ["farm-map-component"];
        if (animate) classNames.push("animate");
        if (this.move) classNames.push("moving");
        if (this.mobile) classNames.push("mobile");
        if (this.isChrome) classNames.push("chrome");
        const matrix = this.getMatrix();
        return (
            <div className={classNames.join(" ")}>
                <canvas id="area-canvas" />
                {!showMessage && !this.mobile && (
                    <Col xs={12} className={"px-sm-0"}>
                        <Row>
                            {showControls && (
                                <>
                                    <Col
                                        className={
                                            "col-xl-auto col-lg-auto flex-fill"
                                        }
                                        xs={12}
                                        md={12}
                                        lg={4}>
                                        <SearchAnimal
                                            onSearch={this.setSelectedId}
                                        />
                                    </Col>
                                    <Col
                                        className={"col-xl-auto col-lg-auto"}
                                        xs={12}
                                        md={6}
                                        lg={4}>
                                        <TypeSelect
                                            mapType={mapType}
                                            onSelect={this.setMapType}
                                        />
                                    </Col>
                                </>
                            )}
                            {levels.length > 1 && (
                                <Col
                                    className={"col-md-auto flex-fill"}
                                    xs={12}
                                    md={6}
                                    lg={4}>
                                    <LevelSelect
                                        level={currentLevel}
                                        levels={levels}
                                        onSelect={this.setLevel}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Col>
                )}
                {showControls && (
                    <>
                        <GlobalControlPanel
                            mobile={this.mobile}
                            changeOptionsCallback={this.onResize}
                            mapType={mapType}
                        />
                        {mapType === "animals" && <FilterAnimalControl />}
                    </>
                )}
                <div className={"portal-container"}>
                    {!showMessage && this.mobile && (
                        <div className={"ctrl-container"}>
                            {showControls && (
                                <>
                                    <div className={"s-box"}>
                                        {mapType !== "animals" && (
                                            <SearchAnimal
                                                mobile={this.mobile}
                                                onSearch={this.setSelectedId}
                                            />
                                        )}
                                    </div>
                                    <div className={"t-box"}>
                                        <TypeSelect
                                            mobile={this.mobile}
                                            mapType={mapType}
                                            onSelect={this.setMapType}
                                        />
                                    </div>
                                </>
                            )}
                            {levels.length > 1 && (
                                <div className={"l-box"}>
                                    <LevelSelect
                                        mobile={this.mobile}
                                        level={currentLevel}
                                        levels={levels}
                                        onSelect={this.setLevel}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    <div
                        ref={this.portal}
                        style={portalHeight ? {height: portalHeight} : null}
                        className={"view-portal"}>
                        {!useMinimalVersion && (
                            <>
                                <FarmMapControlContainer mobile={this.mobile}>
                                    <Rotate
                                        mobile={this.mobile}
                                        rotate={camera.rotate}
                                        onRotate={this.setRotate}
                                    />
                                    <Zoom
                                        mobile={this.mobile}
                                        zoom={camera.zoom}
                                        scaleOptions={this.scaleOptions}
                                        onZoom={this.setZoom}
                                    />
                                </FarmMapControlContainer>
                            </>
                        )}
                        {showMessage && (
                            <div className={"map-message"}>
                                <i className={"fas me-2 fa-exclamation"} />
                                {type === "error"
                                    ? t("farms.tabs.mapTab.farmMapError")
                                    : t("farms.tabs.mapTab.farmMapNotFound")}
                            </div>
                        )}
                        <div
                            className={"svg-container"}
                            style={{
                                width,
                                height,
                                transform: `${MatrixUtils.toCSS(matrix)}`,
                            }}>
                            <svg
                                width={width}
                                height={height}
                                viewBox={`0 0 ${width} ${height}`}>
                                <defs>
                                    <Patterns
                                        scale={camera.zoom || 1}
                                        enableShadow
                                        level={currentLevel}
                                    />
                                    <Gradients showSmalls={showSmalls} />
                                </defs>
                                <BackgroundSVG
                                    background={background}
                                    opacity={1}
                                    blur={0}
                                />
                                <ElementList
                                    key={mapType}
                                    omitNames={this.getOmitNames()}
                                    viewBox={this.getRealViewBox()}
                                    enableDataFetcher={
                                        !!useAlarm || !!useShadow
                                    }
                                    items={items}
                                    onClick={
                                        onElementClick || this.setSelectedId
                                    }
                                    onDoubleClick={this.setAnimate}
                                    mobile={this.mobile}
                                    rotate={camera.rotate}
                                    showSmalls={showSmalls}
                                    onBeforeRender={this.getOnBeforeRender()}
                                />
                                <SelectedItem
                                    mobile={this.mobile}
                                    animate={animate}
                                    setLevel={this.setLevel}
                                    setPositionAt={this.setViewportAt}
                                    itemId={selectedId}
                                    modTime={__selectionTime__}
                                    currentLevel={items}
                                    allLevel={data}
                                />
                                {/*uncomment this to show initial zoom point of interest*/}
                                {/*{!!levelBounds &&*/}
                                {/*    <rect opacity={0.5} pointerEvents={"none"} x={levelBounds[0].x1}*/}
                                {/*          y={levelBounds[0].y1} width={levelBounds[0].x2 - levelBounds[0].x1}*/}
                                {/*          height={levelBounds[0].y2 - levelBounds[0].y1}></rect>*/}
                                {/*}*/}
                            </svg>
                        </div>
                    </div>
                    {showControls && (
                        <LocationDrawer
                            mapType={mapType}
                            type={selectedType}
                            mobile={this.mobile}
                            animateType={animateType}
                            selectedId={selectedId}
                        />
                    )}
                </div>
            </div>
        );
    }
}

FarmMapComponent.defaultProps = {
    useShadow: true,
    useAlarm: true,
    sizeOffset: getRemToPx(8),
    useMinimalVersion: false,
};

FarmMapComponent.propTypes = {
    useShadow: PropTypes.bool,
    useAlarm: PropTypes.bool,
    onBeforeRender: PropTypes.func,
    onElementClick: PropTypes.func,
    sizeOffset: PropTypes.number,
    selectedId: PropTypes.string, // default value for selected it
    portalWidth: PropTypes.number,
    portalHeight: PropTypes.number,
    useMinimalVersion: PropTypes.bool,
};

export default compose(
    withRoles({
        roles: [RoleTypes.FARM_MAP_READ],
        showInfo: true,
        clientPackage: { [LicPackageKeys.FARM_MAP]: LicPackageLevels.BASIC }
    }),
    withTranslation(),
    connect(
        makeMapStateToProps,
    )
)(FarmMapComponent);