import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import HttpBackend from "i18next-http-backend";
import Backends from "i18next-chained-backend";
import config from "./conf/config";
import LanguageDetector from "i18next-browser-languagedetector";

const Backend = new HttpBackend();

const constructBackends = () => {
    return {
        backends: [Backend],
        backendOptions: [
///////////////////////////
/////////////
/////////////////////////////////////////////////////////
//////////////
/////////////////////
            {
                loadPath: `${config.apiGateway.URL}translations/locale?language={{lng}}&namespace={{ns}}`, // xhr load path for my own fallback
            },
//////////////////////
        ],
    };
};

export const ns = ["login"];

console.log(initReactI18next);

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backends)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        compatibilityJSON: "v3", // ToDo przekonwertować tłumaczenia do v4 albo wyzej
        // lng: 'cimode', // overrides detected
        backend: constructBackends(),
        mustacheConfig: {
            tags: ["{{", "}}"],
            disable: false,
        },
        // nsSeparator: false,
        objectNotation: true,
        fallbackLng: false,
        debug: false, // !["production", "test"].includes(process.env.REACT_APP_STAGE),
        ns,
        interpolation: {
            format: function (value, format) {
                if (format === "uppercase") return value.toUpperCase();
                if (format === "lowercase") return value.toLowerCase();
                if (format === "bold") return value.bold();
                return value;
            },
            escapeValue: false, // not needed for react as it escapes by default
            skipOnVariables: false, // in order to behave as v19
        },
    });
// console.log("translationsdetect", process.env.NODE_ENV);
if (process.env.NODE_ENV === "test") {
    i18n.changeLanguage("pl");
} else {
    i18n.use(LanguageDetector);
}
// console.log("i18n", process.env)

export default i18n;
