/********CLIMATE*******/
import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import {
    cloneDeep,
    flatten,
    get,
    groupBy,
    isArray,
    isEmpty,
    isEqual,
    isNil,
    isObject,
    isString,
    memoize,
} from "lodash";
import memoizeOne from "memoize-one";
import moment from "moment";
import {notify} from "reapop";
import devicesDB from "../../src/database/devicesDB";
import {setShadowLoading} from "../actions/shadowsActions";
import {TreeColors, TreeTypes} from "../constans/deviceTree";
import {
    DevType,
    DispenserTypes,
    FrameTypes,
    Interfaces,
    NRFTypes,
} from "../constans/devices";
import {DispenserErrorType} from "../constans/dispenserViewLocalErrors";
import {FilterTypes} from "../constans/filter";
import {Level} from "../constans/levelTypes";
import i18n from "../i18n";
import store from "../store/store";
import {isFiniteNumber} from "./MathUtils";
import {baseLengthToVolume} from "./UnitUtils";
import {formatLocationName} from "./global-formatters/formatLocationName";
import {getPlacementArray} from "./DeviceLocationUtils";

export function calculateVent(ventilation) {
    let calculated = 0;
    if (ventilation <= 50) {
        calculated = ventilation * 2;
    }
    if (ventilation > 50) {
        calculated = ventilation * 3 - 50;
    }
    if (ventilation > 80) {
        calculated = ventilation * 4 - 130;
    }
    if (ventilation > 105) {
        calculated = ventilation * 6 - 340;
    }
    if (ventilation > 150) {
        calculated = ventilation * 10 - 940;
    }
    if (ventilation > 180) {
        calculated = ventilation * 15 - 1840;
    }
    return calculated;
}

/**
 * Metoda obliczająca procent wentylacji
 * @param ventilation - wartość zwracana ze sterownika
 * @returns {number} - procent wentylacji zgodnie z ustawieniami na sterowniku
 */
export function calculateVentilation(ventilation) {
    let calculated = calculateVent(ventilation);
    if (calculated > 1000) {
        calculated = 1000;
    }
    return calculated / 10;
}

/**
 * Metoda obliczająca ilość masterów
 * @param ventilation - wartość zwracana ze sterownika
 * @returns {number} - ilość masterów zgodnie z ustawieniami na sterowniku
 */
export function calculateMasters(ventilation) {
    let calculated = calculateVent(ventilation);
    return calculated > 1000 ? Math.floor((calculated - 1000) / 200) : 0;
}

/*******DISPENSER**********/

export function filterID(dev) {
    if (dev && dev.DevType === "DI" && dev.Protocol === FrameTypes.NRF) {
        try {
            if (dev.DevID.split("_")[2].length !== 6) {
                return false;
            } else {
                if (dev.DevID.split("_")[2].endsWith("0")) {
                    return false;
                }
            }
        } catch (e) {
            return false;
        }
    }

    if (dev && dev.DevType === "BR") {
        return !dev.DevID.includes("CONF") && !dev.DevID.includes("WORK");
    }
    return true;
}

export function generateDeviceTree(devices, gateway, gwDevices = []) {
    let devicesFiltered = devices.filter(
        (dev) => dev.FarmID === gateway.FarmID
    );
    let gatewayDevices = gwDevices.filter(
        (gwDev) =>
            gwDev.FarmID === gateway.FarmID &&
            !devicesFiltered.filter(
                (dev) =>
                    dev.DevID === gwDev.DevID && gwDev.FarmID === dev.FarmID
            )[0] &&
            [
                DevType.DISPENSER,
                DevType.SCALE,
                DevType.CLIMATE,
                DevType.CAGE,
                DevType.BRIDGE,
            ].includes(gwDev.DevType) &&
            filterID(gwDev)
    );
    //merge both devices
    let allDevices = [...devicesFiltered, ...gatewayDevices];
    if (gateway && gateway.DevID) {
        let tmp = {
            name: gateway.Name,
            nodeID: gateway.DevID,
            device: gateway,
            type: TreeTypes.RASPBERRY,
            children: getChildrenForDevice(allDevices, gateway),
            nodeSvgShape: {
                shapeProps: {
                    fill: TreeColors.GATEWAY,
                    r: 20,
                },
            },
        };
        return tmp;
    }
}

export function getChildrenForBridge(devices, parent, iface) {
    let tmpDevs = [];
    if (devices) {
        devices
            .filter(
                (dev) =>
                    parent.DevID === dev.ParentID && dev.Interface === iface
            )
            .map((dev) => {
                let tmp = {
                    name: !dev._id ? dev.Name : `${dev.DevType} ${dev.DevAdr}`,
                    nodeID: dev.DevID,
                    type: dev._id
                        ? dev.DevType === DevType.BRIDGE
                            ? TreeTypes.BRIDGE_GW
                            : TreeTypes.DEVICE_GW
                        : dev.DevType === DevType.BRIDGE
                            ? TreeTypes.BRIDGE
                            : TreeTypes.DEVICE,
                    children: getChildrenForDevice(devices, dev),
                    device: dev,
                    nodeSvgShape: {
                        shapeProps: {
                            fill: getTreeType(dev),
                            r: 10,
                        },
                    },
                };
                tmpDevs.push(tmp);
            });
    }
    return tmpDevs;
}

export function getTreeType(device) {
    if (device._id) {
        return TreeColors.NOT_IMPORTED;
    } else {
        let devType = device.DevType;
        switch (devType) {
            case DevType.GATEWAY:
                return TreeColors.GATEWAY;
            case DevType.BRIDGE:
                return TreeColors.BRIDGE;
            default: {
                if (!device.Address || device.Interface < 0) {
                    return TreeColors.ERROR;
                }
                switch (devType) {
                    case DevType.DISPENSER_NRF:
                        return TreeColors.DISPENSER_NRF;
                    case DevType.DISPENSER:
                        return TreeColors.DISPENSER;
                    case DevType.CLIMATE:
                        return TreeColors.CLIMATE;
                    case DevType.CAGE:
                        return TreeColors.CAGE;
                    case DevType.SCALE:
                        return TreeColors.SCALE;
                    default:
                        return TreeColors.ERROR;
                }
            }
        }
    }
}

export function getChildrenForDevice(devices, parent) {
    let tmpDevs = [];
    if (devices) {
        if (parent.DevType === DevType.BRIDGE) {
            Object.keys(Interfaces)
                .filter(
                    (key) =>
                        key.startsWith("RS485") ||
                        key.startsWith("NRF_") ||
                        !!getChildrenForBridge(devices, parent, Interfaces[key])
                            .length
                )
                .map((key) => {
                    let tmp = {
                        name: key,
                        nodeID: `${parent.DevID}_${key}`,
                        type: TreeTypes.INTERFACE,
                        nodeSvgShape: {
                            shapeProps: {
                                fill: parent.Interfaces[Interfaces[key]]
                                    ? TreeColors.INTERFACE
                                    : TreeColors.ERROR,
                                r: 10,
                            },
                        },
                        iface: Interfaces[key],
                        bridge: parent,
                        children: getChildrenForBridge(
                            devices,
                            parent,
                            Interfaces[key]
                        ),
                    };
                    return tmpDevs.push(tmp);
                });
        } else {
            devices
                .filter((dev) => parent.DevID === dev.ParentID)
                .map((dev) => {
                    let tmp = {
                        name: !dev._id
                            ? dev.Name
                            : `${dev.DevType} ${dev.DevAdr}`,
                        nodeID: dev.DevID,
                        nodeSvgShape: {
                            shapeProps: {
                                fill: getTreeType(dev),
                                r: 10,
                            },
                        },
                        type: dev._id
                            ? dev.DevType === DevType.BRIDGE
                                ? TreeTypes.BRIDGE_GW
                                : TreeTypes.DEVICE_GW
                            : dev.DevType === DevType.BRIDGE
                                ? TreeTypes.BRIDGE
                                : TreeTypes.DEVICE,
                        children: getChildrenForDevice(devices, dev),
                        device: dev,
                    };
                    tmpDevs.push(tmp);
                });
        }
    }
    return tmpDevs;
}

export function prettifyDeviceName(device) {
    if (device) {
        return `${Object.entries(DevType).filter(
            (entry) => entry[1] === device.DevType
        )[0][1]
        } ${device.Name} ${device.Address
            ? "0x" + device.Address.toString(16).toUpperCase()
            : ""
        } ${!!device.ParentID ? "✔" : "✖"}`;
    }
    return "Unknown";
}

export function getDispenserTypeForNode(node) {
    if (!node) return;
    const {
        device: {device},
    } = node;
    if (device) {
        switch (device.DevType) {
            case DevType.DISPENSER:
                return DispenserTypes.DISPENSER_DTM;
            case DevType.DISPENSER_NRF:
                return DispenserTypes.DISPENSER_NRF;
            default:
                break;
        }
    }
}

export function getDispenserNodeType(nodes = []) {
    if (nodes) {
        let type = getDispenserTypeForNode(nodes[0]);
        nodes.map((node) => {
            if (type !== DispenserTypes.ALL) {
                if (type !== getDispenserTypeForNode(node)) {
                    type = DispenserTypes.ALL;
                }
            }
        });
        return type;
    }
}

export function checkIfBroadcast(DevID) {
    try {
        return DevID.split("_")[2].endsWith("0");
    } catch (e) {
        return true;
    }
}

export function getDispenserWSTBeforeDays(
    offsetBefore,
    dispenserIndex,
    data = {}
) {
    const result = {got: 0, forageAmount: 0, percentage: 0};
    try {
        let historyDay = data[Object.keys(data)[offsetBefore - 1]];
        if (historyDay) {
            result.got =
                +get(historyDay, `Food0Consumption[${dispenserIndex}]`, 0) +
                +get(historyDay, `Food1Consumption[${dispenserIndex}]`, 0);
            result.forageAmount = +get(
                historyDay,
                `PlannedConsumption[${dispenserIndex}]`,
                0
            );
            result.percentage = result.forageAmount
                ? (result.got * 100) / result.forageAmount
                : 0;
            return result;
        }
    } catch (e) {
        return result;
    }
    return result;
}

export function getDispenserNRFBeforeDays(offsetBefore, history = []) {
    let result = {got: 0, forageAmount: 0, percentage: 0};
    let day = moment().startOf("day").subtract(offsetBefore, "days");
    let historyDay = history.filter(
        (item) => moment(item.date).format("DD.MM") === day.format("DD.MM")
    )[0];
    if (historyDay) {
        result.got = historyDay.consumption.reduce((a, b) => a + b, 0); // / 1000;
        result.forageAmount = get(historyDay, "plannedConsumption"); // / 1000;
        result.percentage = result.forageAmount
            ? (result.got * 100) / result.forageAmount
            : 0;
    }
    return result;
}

export function onGetFullDevStateSuccess(message) {
    if (message) {
        let failed = message.CData.DeviceIDs;
        for (let key in message.CAnsw) {
            failed = failed.filter((DevID) => DevID !== key);
        }
        generateFullDevNotif(failed);
        // store.dispatch(setShadowLoading({DevID: message.CData.DeviceIDs, status: false}));
    }
}

/**
 * Grupuje urządzenia po gatewayID oraz po ich typie
 * @param elements
 * @returns {*}
 */
export function groupDevicesByGatewayID(elements) {
    if (!elements || !isArray(elements)) {
        return undefined;
    } else {
        try {
            let map = new Map();
            for (const item of elements) {
                let device;
                if (isObject(item)) {
                    device = cloneDeep(item);
                } else {
                    device = devicesDB.getDeviceByID(item);
                }
                if (device && device.GatewayID) {
                    let devices =
                        map.get(device.GatewayID) ||
                        Object.fromEntries(
                            Object.values(DevType).map((_DevType) => {
                                return [_DevType, []];
                            })
                        );
                    if (
                        devices[device.DevType] &&
                        !devices[device.DevType].filter(
                            (dev) => dev.DevID === device.DevID
                        )[0]
                    ) {
                        devices[device.DevType].push(device);
                    }
                    map.set(device.GatewayID, devices);
                }
            }
            return !isEmpty(map) ? map : undefined;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}

export function generateFullDevNotif(failedIDs = []) {
    if (failedIDs && failedIDs.length) {
        let devicesFailed = failedIDs.map((DevID) => {
            let device = devicesDB.getDeviceByID(DevID);
            return device ? `${device.Name}-${device.Address}` : DevID;
        });
        let notifi = {
            title: i18n.t("error"),
            message: i18n.t("popNotifications.connectDeviceFailure", {
                error:
                    devicesFailed.length > 3
                        ? devicesFailed[0] + "...+" + (devicesFailed.length - 1)
                        : devicesFailed.join(", "),
            }),
            status: "error",
            dismissible: true,
            dismissAfter: 5000,
        };
        store.dispatch(notify(notifi));
    }
}

export function onGetFullDevStateFailure(error, message) {
    if (message && message.CData) {
        let sendTo = message.CData.DeviceIDs;
        generateFullDevNotif(sendTo);
        store.dispatch(setShadowLoading({DevID: sendTo, status: false}));
    }
}

export function getInsertion(cage) {
    try {
        return cage.Settings.Insertions.filter((item) => !item.Stop).sort(
            (a, b) => b.Start - a.Start
        )[0];
    } catch (e) {
        console.error(e);
    }
}

/**
 * Function search for device passed as object of a PLACEMENT.Devices list
 * @param DevID
 * @param Adr
 * @returns structure {
 *     device: device Object from lokiJS
 *     type: device type DTM/NRF
 *     dispenser: dispenser object only from DTM device
 *     dispenserIndex: index only for DTM device
 * }
 */
export function getDispenserByPlcmntDevice({DevID, Adr}) {
    let dev = devicesDB.getDeviceByID(DevID);
    if (dev) {
        if (dev.DevType === DevType.DISPENSER) {
            let dispenser =
                dev.Dispensers &&
                dev.Dispensers.filter((disp, index) => index === Adr);
            return {
                device: dev,
                dispenser: dispenser,
                dispenserIndex: Adr,
                type: DevType.DISPENSER,
            };
        }
        if (dev.DevType === DevType.DISPENSER_NRF) {
            return {
                device: dev,
                type: DevType.DISPENSER_NRF,
            };
        }
    }
}

export function addDispenserError(
    errors,
    code,
    type = DispenserErrorType.VIEW
) {
    let tmp = errors || [];
    if (code === undefined) {
        return tmp;
    }
    !tmp.includes(type + code) && tmp.push(type + code);
    return tmp;
}

export function getDevicesClass(devicesArr) {
    const devsWithClass = [];
    for (let device of devicesArr) {
        devsWithClass.push(device);
    }
    return devsWithClass;
}

export function cutUselessPrefixes(
    values,
    trashPrefixes = ["$loki", "meta", "location", "GatewayID"]
) {
    let vals = cloneDeep(values);
    if (values) {
        if (Array.isArray(vals)) {
            vals.map((value) => {
                for (let prefix of trashPrefixes) {
                    delete value[prefix];
                }
                return value;
            });
        } else {
            for (let prefix of trashPrefixes) {
                delete vals[prefix];
            }
        }
    }
    return vals;
}

export function getFlatDevices(devices = [], showBasicDevices = true) {
    let devs = [];
    devices.forEach((device) => {
        let tmp = {device: device};
        if (DevType.SCALE === device.DevType) {
            let siloses = get(device, "Siloses", []);
            siloses.forEach((silo) => {
                if (silo.Active) {
                    devs.push({...tmp, index: +silo.Adr});
                }
            });
        } else if (DevType.DISPENSER === device.DevType) {
            let dispensers = get(device, "Dispensers", []);
            dispensers.forEach((dispenser) => {
                if (dispenser.Connected) {
                    devs.push({...tmp, index: +dispenser.Adr});
                }
            });
        } else if (DevType.MODBUS_RELAY === device.DevType) {
            const outputs = get(device, "Outputs", []);
            outputs.forEach(({Active, Adr}) => {
                if (Active) {
                    devs.push({...tmp, index: +Adr});
                }
            });
        } else {
            if (
                showBasicDevices &&
                [
                    DevType.CLIMATE_SK3,
                    DevType.CLIMATE_SK4,
                    DevType.CAGE_2WAY,
                    DevType.CAGE,
                    DevType.CLIMATE,
                    DevType.DISPENSER_NRF,
                    DevType.ANTENNA_RFID,
                    DevType.WATER_FLOW_METER,
                    DevType.WIRELESS_WATER_FLOW_METER,
                    DevType.ELECTRICITY_FLOW_METER,
                    DevType.ELECTRICITY_FLOW_METER_MODBUS,
                    DevType.SMALL_CAGE,
                    DevType.CHAIN_FEEDING,
                    DevType.SILO_RADAR,
                    DevType.DISPENSER,
                    DevType.VEHICLE_WEIGHT_R320
                ].includes(device.DevType)
            ) {
                devs.push(tmp);
            } else if (!showBasicDevices) {
                devs.push(tmp);
            }
        }
    });
    console.log(devs, "getFlatDevices");
    return devs;
}

/**
 * usunieto duzo informacji pobieranych ze stora aby dzialo z tymi naklejkami na dozownikach
 * @param device
 * @param index
 * @param devices
 * @return {string}
 */
export function formatDeviceForPairingSelectOptimized(
    {device, index} = {},
    devices = []
) {
    const bridge = devices.find((d) => d.DevID === device.ParentID);
    const isNRF = device.DevType === DevTypes.DISPENSER_NRF;
    return `[${getAddressString(device, index, {
        showHEX: isNRF,
        showDEC: !isNRF,
        emptyValue: "empty",
    })}] ${getInterfaceName(device.Interface)} "${device.Name}"${getAlias(device, index)
        ? ` (${i18n.t(
            "newSettings.devices.form.alias"
        )}: "${getAlias(device, index)}")`
        : ""
    }${bridge?.DevType === DevTypes.BRIDGE ? `  (BR_ADR: ${bridge.Address})` : ""}`;
}

export function getNumberOfPairsInLocation(devices, placement) {
    let keys = ["Sectors", "Chambers", "Boxes"];
    let amount = 0;
    for (let dev of devices) {
        if (dev.PlcmntID) {
            let id =
                placement.BgID ||
                placement.SID ||
                placement.CID ||
                placement.BID;
            amount += dev.PlcmntID.filter(
                (item) => item.PlcmntID === id
            ).length;
        }
        for (let key of keys) {
            if (placement[key]) {
                for (let plcmnt of placement[key]) {
                    amount += getNumberOfPairsInLocation(devices, plcmnt);
                }
            }
        }
    }
    return amount;
}

export function getInterfaceName(infc) {
    switch (infc) {
        case 5:
            return "L";
        case 6:
            return "R";
        case 7:
            return "C";
        default:
            return (
                get(
                    Object.entries(Interfaces).filter(([k, v]) => v === infc),
                    "[0][0]"
                ) || "?"
            );
    }
}

export function prettierRSInterface(text) {
    const types = text.split("_");
    return `${types[0]}-${+types[1] + 1}`;
}

export function formatAsHex(decimal, {padStartZeros = 4} = {}) {
    return `0x${(+decimal)
        .toString(16)
        .toUpperCase()
        .padStart(padStartZeros, "0")}`;
}

export function getDeviceNameByID(devieId) {
    return get(devicesDB.getDeviceByID(devieId), "Name", "?");
}

export function deviceIdToName(deviceId, index = null) {
    const device = devicesDB.getDeviceByID(deviceId);
    if (device) {
        const placements = getPlacementArray(device, index);
        if (placements.length) {
            const placementsName = formatLocationName(placements);
            return `${get(device, "Name", "?")} (${placementsName})`;
        }
    }
    return get(device, "Name", "?");
}

export const getDeviceLocationNameByBuildingsMap = (device, buildingsMap) => {
    const placementsIds = getPlacementArray(device);
    const placementNames = [];
    placementsIds.forEach((id) => {
        const location = buildingsMap.get(id);
        if (location) {
            if (location.level === Level.BOX) {
                placementNames.push(location.name.slice(-2).join(" - "));
            } else {
                placementNames.push(location.name[location.name.length - 1]);
            }
        }
    });
    return placementNames.join(", ");
};

export const manageDeviceHeaders = memoizeOne((t, buildingsMap = new Map()) => {
    return [
        {
            name: t("location"),
            filterColumn: "location",
            valueFormatter: (device) =>
                getDeviceLocationNameByBuildingsMap(device, buildingsMap),
            filterType: FilterTypes.STRING,
            rtl: true,
        },
        {
            name: t("designation"),
            field: "Name",
            filterType: FilterTypes.STRING,
            _mobileHeader: true,
        },
        {
            name: t("softwareVersion"),
            field: "VerSoft",
            filterType: FilterTypes.STRING,
        },
        {
            name: t("adres"),
            field: "Address",
            filterType: FilterTypes.NUMBER,
            valueFormatter: (value) => formatAsHex(value),
            disableValueFormatterSort: true,
        },
        {
            name: t("newSettings.cage.manage.interface"),
            field: "Interface",
            filterType: FilterTypes.NUMBER,
            valueFormatter: (value) => `${getInterfaceName(value)} (${value})`,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
        {
            name: t("newSettings.cage.manage.bridge"),
            field: "ParentID",
            filterType: FilterTypes.STRING,
            valueFormatter: (value) => getDeviceNameByID(value),
        },
        {
            name: t("newSettings.cage.manage.gateway"),
            field: "GatewayID",
            filterType: FilterTypes.STRING,
            valueFormatter: (value) => getDeviceNameByID(value),
        },
    ];
});

export function placementDevicesMap(optionalFarmID) {
    let farmId = optionalFarmID || store.getState().location.farm;
    let farmDevices = devicesDB.getDevices(farmId);
    let devicesMap = new Map();
    for (let device of farmDevices) {
        if (Array.isArray(device.PlcmntID)) {
            for (let plcmntObj of device.PlcmntID) {
                let devices = devicesMap.get(plcmntObj.PlcmntID) || [];
                if (devices.findIndex((o) => o.DevID === device.DevID) === -1) {
                    devices.push(device);
                    devicesMap.set(plcmntObj.PlcmntID, devices);
                }
            }
        }
    }
    return devicesMap;
}

export function createDeviceAddressList(adrString, devType) {
    if (typeof adrString !== "string") adrString = adrString + "";
    adrString = adrString.replace(/ /g, "");
    let address = [];
    for (let adr of adrString.split(",").filter((o) => o)) {
        console.log(adr);
        if (adr.includes("-")) {
            let numbers = [
                Number(adr.split("-")[0]),
                Number(adr.split("-")[1]),
            ];
            // zabezpiecznie przed podaniem zbyt dużej liczby adresów
            if (numbers.every((num) => num <= parseInt(0xffff, 16))) {
                address = [
                    ...address,
                    ...generateAdrByMinMax(
                        Math.min(...numbers),
                        Math.max(...numbers),
                        devType
                    ),
                ];
            }
        } else {
            address = [...address, Number(adr)];
        }
    }
    return [...new Set(address)];
}

export function createAssignedChannelsList(devices) {
    let list = [];
    for (let device of devices) {
        let temp = [
            get(device, "Settings.Channels.Left"),
            get(device, "Settings.Channels.Right"),
            get(device, "Settings.Channels.Center"),
        ];
        list.push(...temp);
    }
    return new Set(list);
}

export function minimumDifferenceInChannels(value, channelList, difference = 5) // returns false when difference is not preserved
{
    value = parseInt(value);
    for (let channel of channelList) {
        if (Math.abs(channel - value) < difference) {
            return false;
        }
    }
    return true;
}

export function generateNewChannel(channelList = [], {difference = 5, channelsToGenerate = 1} = {}) // returns generated channels
{
    let maximumValue = 126;
    let newChannels = [];
    if (channelList instanceof Set) channelList = Array.from(channelList);
    channelList.sort((a, b) => a - b);
    let firstChannel = channelList.length ? (channelList[0] === 255 ? 10 : channelList[0]) : 10;
    let right = firstChannel;
    let left = firstChannel;
    let leftSearch = true;
    let rightSearch = true;
    while (newChannels.length < channelsToGenerate && (leftSearch || rightSearch)) {
        if (leftSearch) {
            left -= 5;
            if (minimumDifferenceInChannels(left, channelList)) {
                if (left > 0 && left < maximumValue) newChannels.push(left);
                else leftSearch = false;
            }
        }
        if (rightSearch) {
            right += 5;
            if (minimumDifferenceInChannels(right, channelList)) {
                if (right < maximumValue) newChannels.push(right);
                else rightSearch = false;
            }
        }
    }
    newChannels.sort((a, b) => a - b);
    return channelsToGenerate > 1 ? newChannels : newChannels[0];
}

export function generateAdrByMinMax(min = 0, max = 128, devType = "") {
    let adr = [];
    switch (devType) {
        case DevType.DISPENSER_NRF: {
            let currentAdr = min;
            let group = currentAdr >> 8;
            let sub = currentAdr & 0x00ff;
            do {
                if (sub > 15) {
                    sub = 1;
                    group += 2;
                }
                currentAdr = (group << 8) | sub;
                adr.push(currentAdr);
                sub++;
            } while (currentAdr < max && group <= 255);
            break;
        }
        case DevType.CHAIN_FEEDING:
        case DevType.DISPENSER_NRF_MULTI: {
            let minAddress = +min.toString(16).substring(0, 2);
            let maxAddress = +max.toString(16).substring(0, 2);
            for (let i = minAddress; i <= maxAddress; i += 2) {
                if (i % 7 === 0) {
                    i += 2;
                    if (i > maxAddress) break;
                }
                let address = `0x${i}00`;
                adr.push(+address);
            }
            break;
        }
        default: {
            for (let i = min; i <= max; i++) {
                adr.push(i);
            }
        }
    }
    return adr;
}

export function getMultiForDispenser(deviceId) {
    try {
        if (!deviceId) {
            throw new Error("Device ID not provided");
        }
        const dispenserNRF = devicesDB.getDeviceByID(deviceId);
        if (!dispenserNRF || dispenserNRF.DevType !== DevType.DISPENSER_NRF) {
            throw new Error("Device not found or device is not Dispenser NRF");
        }
        const addr = dispenserNRF.Address & 0xff00;
        const farm = store.getState().location.farm;
        const attr = {
            Address: addr,
            DevType: DevType.DISPENSER_NRF_MULTI,
            ParentID: dispenserNRF.ParentID,
            FarmID: farm,
        };
        let devicesFound = devicesDB.getDevicesByAttributes(attr);
        if (devicesFound.length === 1) {
            return devicesFound[0];
        } else {
            throw new Error("Too many devices found for given attributes");
        }
    } catch (e) {
        console.error(e);
        return null;
    }
}

/**
 * Zmemoizowana funkcja do sprawdzania czy lista urzadzen posiada dany tyb urzadzenia
 * @param.devices - lista urzadzen
 * @param.deviceType - wyszukiwany rodzaj zwierzęcia
 * @type {function}
 * @returns {boolean}
 */
export const hasDeviceType = memoize(
    (devices, deviceType) => {
        return !!devices.find((device) => device.DevType === deviceType);
    },
    (...args) => JSON.stringify(args)
);

export const getLatestMetadata = (metadata) => {
    if (isObject(metadata)) {
        const max = Math.max(...Object.values(metadata));
        return isFinite(max) ? max : undefined;
    } else if (isFinite(metadata)) {
        return metadata;
    }
    return undefined;
};

export const aggregatedDataID = ({DevID, index = null, dataType} = {}) => {
    let aggregatedId = DevID;
    if (!isNil(index)) {
        aggregatedId += `_${index}`;
    }
    if (!isNil(dataType)) {
        aggregatedId = `${dataType}#${aggregatedId}`;
    }
    return aggregatedId;
}

export function getDevices(locationID) {
    const devices = {};
    if (devices[locationID]) return devices[locationID];
    let devs = devicesDB
        .getDevicesInPlcmntID(locationID, {showDevicesInChildren: false})
        .map((device) => ({
            DevID: device.DevID,
            GwID: device.GatewayID,
        }));
    devices[locationID] = devs;
    return devs;
}

/**
 * Funkcja formatuje lokalizacje dosatronu
 * @param minimizedLocations {object}
 */
export function formatDosatronLocation(minimizedLocations) {
    let names = [];
    for (let id in minimizedLocations) {
        let row = minimizedLocations[id];
        let name = formatLocationName(row.id);
        if (row.max !== row.boxes.length) {
            let boxesNames = row.boxes
                .map((box) => {
                    if (typeof box.name === "string")
                        return +box.name.match(/\d+/)[0];
                    return box.name;
                })
                .sort((a, b) => a - b);
            let flows = [{start: boxesNames[0]}];
            for (let i = 0; i < boxesNames.length - 1; i++) {
                if (boxesNames[i] + 1 !== boxesNames[i + 1]) {
                    flows[flows.length - 1].end = boxesNames[i];
                    flows.push({start: boxesNames[i + 1]});
                }
            }
            flows[flows.length - 1].end = boxesNames[boxesNames.length - 1];
            name += ` (${flows
                .map((flow) => {
                    if (flow.start === flow.end) return flow.start;
                    return `${flow.start} - ${flow.end}`;
                })
                .join(", ")})`;
        }
        names.push(name);
    }
    return names.join(", ");
}

export function statusValueFormatter(value) {
    switch (value) {
        case "IN_PROGRESS":
            return i18n.t("inProgress");
        case "CANCELED":
            return i18n.t("cancelled");
        case "SUCCEEDED":
        case "COMPLETED":
            return i18n.t("done");
        case "DELETION_IN_PROGRESS":
            return i18n.t("deleteInProgress");
        case "QUEUED":
            return i18n.t("queued");
        case "FAILED":
            return i18n.t("failed");
        case "TIMED_OUT":
            return i18n.t("timedOut");
        case "REJECTED":
            return i18n.t("rejected");
        case "REMOVED":
            return i18n.t("deleted");
        default:
            return value;
    }
}

const mapDevTypeToKey = (dev) => {
    const devType = get(dev, "DevType", "");
    const placements = getPlacementArray(dev);
    if (placements.length === 0) return "not_recognized";
    switch (devType) {
        case DevType.DISPENSER:
        case DevType.DISPENSER_NRF:
        case DevType.DISPENSER_NRF_MULTI: {
            const type = get(dev, "Settings.Type", NRFTypes.F3_F5);
            switch (type) {
                case NRFTypes.NUTRI_PRO:
                case NRFTypes.NUTRI_PRO_V2:
                    return "nutriPRO";
                case NRFTypes.IPSUM:
                    return "ipsum";
                default:
                    return "feeding";
            }
        }
        case DevType.CLIMATE:
        case DevType.CLIMATE_SK3:
        case DevType.CLIMATE_SK4:
            return "climate";
        case DevType.CAGE_2WAY:
        case DevType.CAGE:
            return "cage";
        case DevType.SMALL_CAGE:
            return "weights";
        case DevType.SILO_RADAR:
        case DevType.SCALE:
            return "silo";
        case DevType.WATER_FLOW_METER:
        case DevType.WIRELESS_WATER_FLOW_METER:
            return "water";
        case DevType.ELECTRICITY_FLOW_METER:
        case DevType.ELECTRICITY_FLOW_METER_MODBUS:
            return "electricity";
        case DevType.CHAIN_FEEDING:
            return "chainFeeding";
        case DevType.VEHICLE_WEIGHT_R320:
            return "vehicleWeight";
        default:
            return "not_recognized";
    }
};

const customDeviceGroup = (dev) => mapDevTypeToKey(dev);

export function getDevicesMapType(devices = []) {
    console.log("getDevicesMapType");
    let _devices = devices.slice();
    const devicesMapType = new Map();
    const groupedByType = groupBy(_devices, (dev) => customDeviceGroup(dev));
    for (let deviceTypeKey in groupedByType) {
        if (groupedByType.hasOwnProperty(deviceTypeKey)) {
            devicesMapType.set(
                deviceTypeKey,
                get(groupedByType, `${deviceTypeKey}`, []).length > 0
            );
        }
    }
    return devicesMapType;
}

export function translateDevTypes(type) {
    switch (type) {
        case DevTypes.GATEWAY:
            return i18n.t("newSettings.cage.manage.gateway");
        case DevTypes.BRIDGE:
            return i18n.t("controller");
        case DevTypes.SCALE:
            return i18n.t("newSettings.devices.addDevicesView.weightWST");
        case DevTypes.CAGE:
            return i18n.t(
                "newSettings.devices.addDevicesView.threewaySelectionStation"
            );
        case DevTypes.CAGE_2WAY:
            return i18n.t(
                "newSettings.devices.addDevicesView.twowaySelectionStation"
            );
        case DevTypes.CLIMATE:
            return (
                i18n.t("newReports.headers.Q52_devices_temperatures_stats") +
                " SK-2"
            );
        case DevTypes.CLIMATE_SK3:
            return (
                i18n.t("newReports.headers.Q52_devices_temperatures_stats") +
                " SK-3"
            );
        case DevTypes.CLIMATE_SK4:
            return (
                i18n.t("newReports.headers.Q52_devices_temperatures_stats") +
                " SK-4"
            );
        case DevTypes.DISPENSER:
            return i18n.t("newSettings.devices.addDevicesView.dispenserWST");
        case DevTypes.DISPENSER_NRF_MULTI:
            return i18n.t("connector");
        case DevTypes.THERMOEYE:
            return i18n.t("newSettings.devices.form.thermoEye");
        case DevTypes.WATER_FLOW_METER:
            return i18n.t("newSettings.devices.addDevicesView.waterFlowMeter");
        case DevTypes.WIRELESS_WATER_FLOW_METER:
            return i18n.t("newSettings.devices.addDevicesView.wmbusWaterFlowMeter");
        case DevTypes.ELECTRICITY_FLOW_METER:
            return (
                i18n.t("newSettings.devices.addDevicesView.electricityMeter") +
                " MBUS"
            );
        case DevTypes.ELECTRICITY_FLOW_METER_MODBUS:
            return (
                i18n.t("newSettings.devices.addDevicesView.electricityMeter") +
                " MODBUS"
            );
        case DevTypes.CHAIN_FEEDING:
            return i18n.t("chainFeeding");
        case DevTypes.SMALL_CAGE:
            return i18n.t("newSettings.devices.addDevicesView.smallCage");
        case DevTypes.DISPENSER_NRF:
            return `${i18n.t(
                "newSettings.devices.addDevicesView.dispenserNRF"
            )} / ${i18n.t(
                "newSettings.devices.addDevicesView.dispenserNRFG5"
            )} / ${i18n.t(
                "newSettings.devices.addDevicesView.dispenserNRFIpsum"
            )}`;
        default:
            return type;
    }
}

export function getDevicesWithScheduledTask(devices, commands) {
    let devicesWithScheduledTask = [];
    for (let device of devices) {
        for (let task of device.Settings.ScheduledTask || []) {
            if (
                (Array.isArray(task.Command) &&
                    !!task.Command.find((command) =>
                        commands.includes(command)
                    )) ||
                commands.includes(task.Command)
            ) {
                devicesWithScheduledTask.push({device, task});
            }
        }
    }
    devicesWithScheduledTask.sort(
        (a, b) => a.task.CData.ExecTime - b.task.CData.ExecTime
    );
    return devicesWithScheduledTask;
}

const CageWeightArraySize = {
    // są zapisywane rożne długości arraya bo na przestrzeni lat były błedne długości zapisywane
    // przez to dostajemy rożne zakresy 10-179.5, 10-180, 10-180.5
    MAX_180KG_HALF: [340, 341, 342],
    MAX_180KG_FULL: [170, 171, 172],
    MAX_230KG_HALF: [440, 441, 442],
    MAX_230KG_FULL: [220, 221, 222],
};

export const checkIfCageArrayContainsHalfWeights = ({length = 0} = {}) => {
    for (let [name, allowedLengths] of Object.entries(CageWeightArraySize)) {
        if (allowedLengths.includes(length)) return name.endsWith("HALF");
    }
    return false;
};

function getGatewaySelectName(device, isService = false) {
    if (!isService) {
        return device.Name;
    }
    return `${device.Name} S/N: ${device.DevID}`;
}

export function getSelectName(device, index = null, isService = false) {
    if ([DevType.GATEWAY, DevType.RTE485].includes(device.DevType))
        return getGatewaySelectName(device, isService);
    const locationName = formatLocationName(getPlacementArray(device, index), {
        nameDeep: 2,
        notFoundText: "?",
        maxItems: 20,
    });
    if (!isService) {
        return `${device.Name}${index !== null ? ` {${index}}` : ""}${locationName ? ` (${locationName})` : ""
        }`;
    }
    try {
        const parent = devicesDB.getDeviceByID(device.ParentID);

        return `${device.Name}${index !== null ? ` {${index}}` : ""
        } [ ${getAddressString(device)} |  ${parent?.Name} | ${getInterfaceName(
            device.Interface
        )} ]${locationName ? ` (${locationName})` : ""} S/N: ${device.DevID}`;
    } catch (e) {
        console.error(e);
        return getGatewaySelectName(device, isService);
    }
}

export function calculateSiloVolume(
    diameter,
    cylinderH,
    taperH,
    taperDiameter
) {
    const bigRadius = diameter / 2;
    const tapperRadius = taperDiameter / 2;
    const cylinderVolume = cylinderH * Math.PI * Math.pow(bigRadius, 2);
    const tapperVolume =
        (Math.PI *
            taperH *
            (Math.pow(tapperRadius, 2) +
                tapperRadius * bigRadius +
                Math.pow(bigRadius, 2))) /
        3;
    return baseLengthToVolume(cylinderVolume + tapperVolume);
}

export function getNetworkAddress(ipAddress, netmask) {
    if (!ipAddress || !netmask) return null;
    const regex = new RegExp("^(\\d+)\\.(\\d+)\\.(\\d+)\\.(\\d+)$");
    const ipAddressSplit = ipAddress.match(regex);
    const netmaskSplit = netmask.match(regex);
    if (!ipAddressSplit || !netmaskSplit) return null;
    const networkAddress = [];
    for (let i = 1; i < 5; i++) {
        const partOfIpAddress = +ipAddressSplit[i];
        const partOfNetmask = +netmaskSplit[i];
        networkAddress.push(partOfIpAddress & partOfNetmask);
    }
    return networkAddress.join(".");
}

export function createFormatterFromOptions(
    options,
    {equalFn = isEqual, fallbackValue = null} = {}
) {
    return (value) => {
        const index = options.findIndex((o) => equalFn(o.value, value));
        return index === -1 ? fallbackValue : options[index].name;
    };
}

export function getDeviceTranslationKey(device) {
    switch (device.DevType) {
        case DevTypes.ANTENNA_RFID:
            return "newSettings.devices.addDevicesView.antennaRFID";
        case DevTypes.BRIDGE:
            return "newSettings.devices.addDevicesView.bridge";
        case DevTypes.CAGE:
            return "newSettings.devices.addDevicesView.threewaySelectionStation";
        case DevTypes.CHAIN_FEEDING:
            return "chainFeeding";
        case DevTypes.CLIMATE:
            return "newSettings.devices.addDevicesView.climateWST";
        case DevTypes.CLIMATE_SK3:
            return "newSettings.devices.addDevicesView.climateSK3";
        case DevTypes.CLIMATE_SK4:
            return "newSettings.devices.addDevicesView.climateSK4";
        case DevTypes.DISPENSER_NRF: {
            switch (device.Settings?.Type) {
                case NRFTypes.G5:
                    return "newSettings.devices.addDevicesView.dispenserNRFG5";
                case NRFTypes.IPSUM:
                    return "ipsum";
                case NRFTypes.NUTRI_PRO:
                case NRFTypes.NUTRI_PRO_V2:
                    return "newSettings.devices.addDevicesView.dispenserNRFIpsum";
                default:
                    return "newSettings.devices.addDevicesView.dispenserNRF";
            }
        }
        case DevTypes.DISPENSER:
            return "newSettings.devices.addDevicesView.dispenserWST";
        case DevType.DOSATRON:
            return "dosatron";
        case DevTypes.ELECTRICITY_FLOW_METER_MODBUS:
        case DevTypes.ELECTRICITY_FLOW_METER:
            return "newSettings.devices.addDevicesView.electricityMeter";
        case DevTypes.GATEWAY:
            return "gateway";
        case DevTypes.SCALE:
            return "newSettings.devices.addDevicesView.weightWST";
        case DevTypes.SILO_RADAR:
            return "siloRadar";
        case DevTypes.CAGE_2WAY:
            return "newSettings.devices.addDevicesView.twowaySelectionStation";
        case DevTypes.WATER_FLOW_METER:
            return "newSettings.devices.addDevicesView.waterFlowMeter"; 
        case DevTypes.WIRELESS_WATER_FLOW_METER:
            return "newSettings.devices.addDevicesView.wmbusWaterFlowMeter";
        case DevTypes.VEHICLE_WEIGHT_R320:
            return "vehicleWeight";
        default:
            return "device";
    }
}

export function isForcedToHaveSingleLocation(device) {
    switch (device.DevType) {
        case DevTypes.CAGE:
        case DevTypes.MODBUS_RELAY:
        case DevTypes.SILO_RADAR:
            return true;
        default:
            return false;
    }
}

export function getRoleName(device) {
    switch (device.DevType) {
        case DevTypes.CAGE_2WAY:
        case DevTypes.CAGE:
            return RoleTypes.DEVICE_CAGE_READ;
        case DevTypes.CHAIN_FEEDING:
            return RoleTypes.DEVICE_CHAIN_READ;
        case DevTypes.CLIMATE:
        case DevTypes.CLIMATE_SK3:
            return RoleTypes.DEVICE_CLIMATE_READ;
        case DevTypes.DISPENSER_NRF: {
            switch (device.Settings?.Type) {
                case NRFTypes.NUTRI_PRO:
                    return RoleTypes.DEVICE_NUTRI_PRO_READ;
                case NRFTypes.IPSUM:
                    return RoleTypes.DEVICE_IPSUM_READ;
                default:
                    return RoleTypes.DEVICE_FEEDING_READ;
            }
        }
        case DevTypes.DISPENSER_NRF_MULTI:
            return RoleTypes.DEVICE_FEEDING_READ; //?
        case DevTypes.DISPENSER:
            return RoleTypes.DEVICE_FEEDING_READ;
        case DevTypes.WATER_FLOW_METER:
        case DevTypes.WIRELESS_WATER_FLOW_METER:
        case DevTypes.ELECTRICITY_FLOW_METER_MODBUS:
        case DevTypes.ELECTRICITY_FLOW_METER:
            return RoleTypes.DEVICE_COUNTER_READ;
        case DevTypes.VEHICLE_WEIGHT_R320:
        case DevTypes.SILO_RADAR:
        case DevTypes.SCALE:
            return RoleTypes.DEVICE_SILO_READ;
        default:
            return null;
    }
}

export function getSoftwareVersion(device) {
    const versionAsFloat = parseFloat(get(device, "VerSoft", "0"));
    return isFiniteNumber(versionAsFloat) ? versionAsFloat : 0;
}

export function getDeviceGroup(device) {
    switch (device?.DevType) {
        case DevTypes.CAGE_2WAY:
        case DevTypes.CAGE:
            return "cages";
        case DevTypes.CLIMATE_SK3:
        case DevTypes.CLIMATE_SK4:
        case DevTypes.CLIMATE:
            return "climates";
        case DevTypes.DISPENSER_NRF: {
            switch (device.Settings?.Type) {
                case NRFTypes.F3_F5:
                    return "dispensers";
                case NRFTypes.G5:
                    return "groupDispensers";
                case NRFTypes.IPSUM:
                    return "ipsums";
                case NRFTypes.NUTRI_PRO:
                case NRFTypes.NUTRI_PRO_V2:
                    return "nutripros";
                default:
                    return "device";
            }
        }
        case DevTypes.DISPENSER:
            return "dispensers";
        case DevTypes.ELECTRICITY_FLOW_METER_MODBUS:
        case DevTypes.ELECTRICITY_FLOW_METER:
            return "electricMeters";
        case DevTypes.MODBUS_RELAY:
            return "lights";
        case DevTypes.WATER_FLOW_METER:
        case DevTypes.WIRELESS_WATER_FLOW_METER:
            return "waterMeters";
        case DevTypes.VEHICLE_WEIGHT_R320:
            return "vehicleWeights";
        default:
            return "device";
    }
}

function _getAddressString(device, {showHEX = true, showDEC = true, emptyValue = ""} = {}) {
    if (isNil(device.Address)) return emptyValue;
    const result = [];
    if (showDEC) {
        result.push(device.Address);
    }
    if (showHEX) {
        result.push(device.Address.toString(16).toUpperCase());
    }
    return result.join("/");
}

export function getAddressString(device, index = null, options = undefined) {
    switch (device.DevType) {
        case DevTypes.SCALE:
        case DevTypes.DISPENSER: {
            let result = _getAddressString(device, options);
            if (!isNil(index)) {
                result += ` {${index}}`;
            }
            return result;
        }
        case DevTypes.MODBUS_RELAY: {
            let result = isNil(device.Address) ? "" : `${device.Address}/0x${device.Address.toString(16).toUpperCase()}`;
            if (!isNil(index)) {
                result += ` {${index}}`;
            }
            return result;
        }
        default:
            return _getAddressString(device, options);
    }
}

export function getAddressForLocation(device, locationID) {
    if (!device.PlcmntID) throw new Error("Brak tablicy PlcmntID");
    let loc = device.PlcmntID.find(item => item.PlcmntID === locationID);
    if (!loc || loc.Adr === undefined) return null;
    return loc.Adr;
}

export function getDebugName(device, index) {
    let name = device.Name;
    if (!isNil(index)) name += ` {${index}}`;
    if (!isNil(device.Address)) name += ` [${device.Address}/0x${device.Address.toString(16).toUpperCase()}]`;
    return name;
}

// to powinno robic tylko API, nie wiem dlaczego jest to na froncie
export function removeAnmIDInLocation(device, locationID, AnmID) {
    if (!device.PlcmntID) device.PlcmntID = [];
    let loc = device.PlcmntID.find(item => item.PlcmntID === locationID);
    if (loc) {
        if (!loc.AnmIDs) loc.AnmIDs = [];
        loc.AnmIDs = loc.AnmIDs.filter(item => item !== AnmID);
    }
}

function _getOutputByIndex(device, index) {
    return device.Outputs.find(({Adr}) => Adr === index);
}

function _getSiloByIndex(device, index) {
    return device.Siloses.find(({Adr}) => Adr === index);
}

export function getAlias(device, index) {
    if (device.DevType === DevTypes.MODBUS_RELAY) {
        return _getOutputByIndex(device, index)?.Alias || "";
    } else if (device.DevType === DevTypes.SCALE) {
        const temp = _getSiloByIndex(device, index);
        return temp?.Alias || temp?.Name || "";
    }
    return device?.Settings?.Alias || device?.Alias || "";
}

export function hasPlcmntID(device, _PlcmntID, index) {
    const PlcmntIDs = isArray(device.PlcmntID) ? device.PlcmntID : [];
    return PlcmntIDs.some(o => o.PlcmntID === _PlcmntID && (isNil(index) || index === o.Adr));
}

export function getPlacementIDs(device, _PlcmntID) {
    const PlcmntIDs = isArray(device.PlcmntID) ? device.PlcmntID : [];
    return PlcmntIDs.filter(o => o.PlcmntID === _PlcmntID);
}

export function getDebugText(device, index) {
    return `${device.DevID} ${getAddressString(device)} ${isNil(index) ? "" : `{${index}}`}`;
}

export function getInterfaceAsText(device) {
    switch (device.Interface) {
        case 5:
            return "L";
        case 6:
            return "R";
        case 7:
            return "C";
        default:
            return (Object.entries(Interfaces).find(([_name, value]) => value === device.Interface) || [""])[0];
    }
}

export function getShortInterfaceWithAddress(device) {
    return [getInterfaceAsText(device), getAddressString(device)].filter(o => !!o).join(" ");
}

export function getForageId(device) {
    const forageId = get(device, "Settings.Forage", null);
    return (isArray(forageId) ? forageId[0] : forageId) || null;
}

export function getForageIds(device) {
    const forageId = get(device, "Settings.Forage", null);
    return (isArray(forageId) ? forageId : isString(forageId) ? [forageId] : []) || [];
}

export function hasRoutine(device, routineIndex, outputIndex = null) {
    if (!isFiniteNumber(routineIndex)) {
        throw new Error("[hasRoutine] routineIndex is not a number!");
    }
    if (isArray(device.Settings.Routines)) {
        if (isFiniteNumber(outputIndex)) {
            const value = device.Settings.Routines[outputIndex];
            if (isArray(value)) {
                return value.includes(routineIndex);
            }
        } else {
            return flatten(device.Settings.Routines).includes(routineIndex);
        }
    }
    return false;
}

export function hasEnableAnalyze(device) {
    return (device.Settings?.EnableAnalyze) !== false;
}

/**
 * Sprawdza, czy w danym urządzeniu istnieje aktywny element o określonym indeksie.
 *
 * @param {object} device - Obiekt reprezentujący urządzenie.
 * @param {number} index - Indeks do sprawdzenia w urządzeniu.
 * @returns {boolean} - Zwraca true, jeśli istnieje aktywny element o podanym indeksie, w przeciwnym razie false.
 */
export function hasActiveIndex(device, index) {
    /**
     * Funkcja pomocnicza do sprawdzania aktywności w tablicy obiektów.
     *
     * @param {string} parentKey - Klucz do tablicy rodzica w urządzeniu.
     * @param {string} indexKey - Klucz do pola indeksu w obiektach tablicy.
     * @param {string} statusKey - Klucz do pola statusu w obiektach tablicy.
     * @returns {boolean} - Zwraca true, jeśli istnieje aktywny element w tablicy, w przeciwnym razie false.
     */
    const _isActive = (parentKey, indexKey, statusKey) => {
        if (!isArray(device[parentKey])) return false;
        return get(device, parentKey, []).some((o) => get(o, indexKey) === index && get(o, statusKey));
    };

    switch (device?.DevType) {
        case DevTypes.SCALE:
            return _isActive("Siloses", "Adr", "Active");
        case DevTypes.DISPENSER:
            return _isActive("Dispensers", "Adr", "Connected");
        case DevTypes.MODBUS_RELAY:
            return _isActive("Outputs", "Adr", "Active");
        default:
            return false;
    }
}

export function interfaceValueFormatter(value) {
    let name = getInterfaceName(value);
    if (name) return `${name} (${value})`;
};

export function addressFormatter(value) {
    if (typeof value === "object" || !value) return '';
    return !isNil(value) ? `${value} / 0x${value.toString(16).toUpperCase()}` : '';
};
/**
 * Zwraca informację o urządzeniu pogrupowane na urządzenia oraz posortowane
 *
 * @param {object} data - Obiekt reprezentujący urządzenie.
 * @returns {object} zwraca informację o urządzeniu pogrupowane na urządzenia oraz posortowane
 */
export const groupDeviceData = memoizeOne((data) => {
    let grouped = groupBy(data, ({placements}) => placements[0]?.name?.[0] ?? "");
    const sortedKeys = Object.keys(grouped).sort();
    let ret = [];
    sortedKeys.forEach((key) => {
        ret.push({header: key ? key : "", device: {DevType: "header"}});
        const value = grouped[key];
        for (const item of value) ret.push(item);
    });
    return ret;
});

export function HeaderComponent({header}) {
    return <h4>{header}</h4>;
}

/**
 * zwróć true jeśli
 * @param mqttMessage
 * @return {boolean}
 */
export const enableMQTTAggregatedData = (mqttMessage) => {
    try {
        const blacklist = [DevTypes.VEHICLE_WEIGHT_R320];
        if (blacklist.includes(mqttMessage?.DeTy)) return false;
    } catch (e) {
        // no need for garbage
    }
    return true;

}