import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import { cloneDeep, get, isArray, isEqual, isFinite, isNil, isString, memoize } from "lodash";
import moment from "moment";
import { DevType } from "../constans/devices";
import { EventTypes } from "../constans/eventTypes";
import { CurveDayShowingType, CurveType, FeedingCurveType, FeedingType } from "../constans/feedingTypes";
import { Level } from "../constans/levelTypes";
import { DispenserDriverCommandTypes, DispenserNRFCommandTypes, DispenserNRFEventTypes } from "../constans/mqttMessages";
import { SectorType } from "../constans/sectorTypes";
import animalsDB from "../database/animalsDB";
import buildingsDB from "../database/buildingsDB";
import devicesDB from "../database/devicesDB";
import { preEvents } from "./AnimalDocumentsUtils";
import { getLinkedAnimalIDs, isNutriPro, isRFID } from "./DispenserNRFUtils";
import {
    getDefaultCurvesForSectors,
    getTimeFromInseminationToPartuition,
    getTimeOnBirthRoom,
    getTimeOnSowsRoom
} from "./SettingsUtils";
import { enhancedComparer } from "./TextUtils";
import { removeAnmIDInLocation } from "./DevicesUtils";
/*
 do wyslania NRF RFID -> GW ID -> NUMERY RFID
 do wyslania DTM IND -> GW ID -> DEV ID -> NUMERY DOZOW
 do wyslania NRF IND -> GW ID-> ID URZADZEN
 */

const defaultMapValue = {
    groupNRF: [],
    individualDTM: [],
    individualNRF: { devices: [], animals: [] }
};

export function getSelectedDispensers(chamberId, dispensersSelected = []) {
    console.log("getSelectedDispensers");
    const result = {
        feedingType: !!get(buildingsDB.getLocationByID(chamberId), "Boxes", []).length ? FeedingType.INDIVIDUAL : FeedingType.GROUP,
        selectedNodes: dispensersSelected,
        receivers: new Map(),
        size: dispensersSelected.length,
        chamberID: chamberId,
        sectorType: buildingsDB.getSectorTypeByChamberID(chamberId)
    };
    const receivers = new Map();
    if (result.feedingType === FeedingType.INDIVIDUAL) {
        dispensersSelected.forEach(sel => {
            let dev = sel.receiver;
            const extraParams = sel.animal ? {
                AnmNo1: sel.animal.AnmNo1,
                AnmID: sel.animal.AnmID,
                RFID: sel.animal.RFID
            } : {};
            extraParams.PlcmntID = sel.id;
            if (dev) {
                const tmp = receivers.has(dev.gatewayId) ? receivers.get(dev.gatewayId) : cloneDeep(defaultMapValue);
                if (isNil(dev.index)) {
                    //nrf
                    tmp.individualNRF.devices = [...new Set([...tmp.individualNRF.devices, dev.deviceId])];
                    if (Object.keys(extraParams).length) {
                        tmp.individualNRF.animals.push(extraParams);
                    }
                } else {
                    //dtm
                    let idx = tmp.individualDTM.findIndex(o => o.DevID === dev.deviceId);
                    if (~idx) {
                        if (!~tmp.individualDTM[idx].outputs.findIndex(o => o.number === (dev.index + 1))) {
                            tmp.individualDTM[idx].outputs.push({
                                number: dev.index + 1,
                                ...extraParams
                            });
                        }
                    } else {
                        tmp.individualDTM.push({
                            DevID: dev.deviceId,
                            outputs: [{
                                number: dev.index + 1,
                                ...extraParams
                            }]
                        });
                    }
                }
                receivers.set(dev.gatewayId, tmp);
            }
        });
    } else {
        const dispensers = devicesDB.getDevicesInPlcmntID(chamberId, { showDevicesInChildren: false }).filter(o => o.DevType === DevType.DISPENSER_NRF);
        if (dispensers.length) {
            const animals = dispensersSelected.map(o => {
                const t = { RFID: o.id };
                if (o.animal) {
                    t.AnmNo1 = o.animal.AnmNo1;
                    t.AnmID = o.animal.AnmID;
                }
                return t;
            });
            const tmp = cloneDeep(defaultMapValue);
            tmp.groupNRF = [{
                LocID: chamberId,
                animals: animals
            }];
            dispensers.forEach(d => {
                if (!receivers.has(d.GatewayID)) {
                    receivers.set(d.GatewayID, cloneDeep(tmp));
                }
            });
        }
    }
    result.receivers = receivers;
    return result;
}

export function getStagesFromDays(days = [], stages = []) {
    console.log(days, stages);
    days = days.map(day => ({
        ...day,
        ForageAmount: day.ForageAmount < 99 ? day.ForageAmount * 1000 : day.ForageAmount,
    }));
    if (days.length === 0) return [];
    let choppedByPlansAndForages = [];
    let start = 0;
    days.forEach((day, index) => {
        if (days[start].DailyPlan !== day.DailyPlan || days[start].ForageType !== day.ForageType || stages.find((stage) => stage.StartDay === (index + 1))) {
            choppedByPlansAndForages.push({ start, end: index - 1 });
            start = index;
        }
    });
    if ((choppedByPlansAndForages.length === 0) || choppedByPlansAndForages.find((o) => (o.end !== start))) {
        choppedByPlansAndForages.push({
            start: start,
            end: days.length - 1
        });
    }
    console.log("stages chopped by plan & forage type", choppedByPlansAndForages);
    let newStages = [];
    choppedByPlansAndForages.forEach((stage, index) => {
        let start = stage.start;
        while (start !== (stage.end + 1)) {
            let endDay = getEndDay(start, stage.end, days);
            let stageName = stages.find((o) => o.StartDay === (start + 1));
            newStages.push({
                name: get(stageName, "Name", `Etap ${newStages.length + 1}`),
                startingAmount: days[start].ForageAmount,
                endingAmount: days[endDay].ForageAmount,
                days: endDay - start + 1,
                forage: days[start].ForageType,
                plan: days[start].DailyPlan,
            });
            start = endDay + 1;
        }
    });
    return newStages;
}

export function getEndDay(start, end, days) {
    for (let i = end; i >= start; i--) {
        if (check(start, i, days)) {
            console.log(start, i);
            return i;
        }
    }
}

export function check(from, to, days) {
    if (from === to) return true;
    let expected = [];
    let actual = [];
    for (let i = from; i <= to; i++) {
        let amount = days[from].ForageAmount + ((i - from) * (days[to].ForageAmount - days[from].ForageAmount) / ((to - from) || 1));
        expected.push(amount + (amount % 100 > 49 ? 100 - (amount % 100) : -(amount % 100)));
        actual.push(days[i].ForageAmount);
    }
    return isEqual(expected, actual);
}

/**
 * karmienie wyglada teraz tak "samo" dla nrf i wst
 * @param values
 * @param values.curve
 * @param values.curveStart
 * @param values.doseCorrection
 * @param values.punishment
 * @param values.repetitionDate
 * @returns {{percentCorrection: number, punishment: number, offset: number, stage: *, startTime: number, curveNr: *}|{curveNumber: *, doseCorrection: number, curveStart: number, curveShift: number}}
 */
export function prepareFeeding(values) {
    let data;
    //jesli jest krzywa normalna to curveDay ma priorytet przed curveStart
    //jesli krzywa jest nienormalna tzn porodowa itp to repetitionDate musi byc
    const { curveDay, curve, curveStart, doseCorrection, punishment, repetitionDate } = values; //curveShift
    const showingType = getCurveDayShowingType(curve);
    let _timestamp = showingType === CurveDayShowingType.NORMAL ? (isFinite(curveDay) ? +moment().startOf("day").subtract(curveDay - 1, "day") : curveStart || +new Date()) : repetitionDate;
    let startTime = moment(getCurveStartTime(curve, +moment(_timestamp))).startOf("day");
    let stage = DispenserNRFEventTypes.NO_EVENT;
    let workingDay = moment().startOf("day").diff(startTime, "days") + 1;
    let inseminationDay = get(curve, "SetData.InseminationJumpTo", 0);
    if (inseminationDay === 255) inseminationDay = 0;
    if (inseminationDay && workingDay >= inseminationDay) {
        stage = DispenserNRFEventTypes.INSEMINATION;
        startTime = startTime.add((inseminationDay - 1), "days");
    }
    data = {
        curveNr: curve ? curve.SetData.Index + 1 : 0,
        offset: 0,//+curveShift,
        percentCorrection: +doseCorrection,
        startTime: +startTime,
        punishment: +punishment,
        stage: stage
    };
    return data;

}

/**
 *
 * @param curve krzywa
 * @param timestamp w przypadku gdy w krzywej jest inseminacja/porod przyjmujemy ze timestamp to czas wysapienia tego zdarzenia w innym to start krzywej
 * @return {number}
 */
export function getCurveStartTime(curve, timestamp) {
    const showingType = getCurveDayShowingType(curve);
    switch (showingType) {
        case CurveDayShowingType.BEFORE: {
            return +moment(timestamp).startOf("day").subtract(get(curve, "SetData.Days", []).length, "days");
        }
        case CurveDayShowingType.BOTH: {
            return +moment(timestamp).startOf("day").subtract(get(curve, "SetData.InseminationJumpTo", 1) - 1, "days");
        }
        default: {
            return +moment(timestamp).startOf("day");
        }
    }
}

/**
 * Wrzucamy zwykly dzien krzywej z danymi z krzywej a otrzymujemy formatowany dzien krzywej
 * @deprecated
 * @param allCurveDays {number} ilosc wszystkich dni krzywej
 * @param day {number} dzien krzywej
 * @param curveType {string} typ krzywej
 * @param parturitionDay {number} dzien w ktorym bedzie porod
 * @param inseminationDay {number} dzien w ktorym bedzie inseminacja
 * @returns {number}
 */
export function formatCurveDayByCurveType(allCurveDays, day, curveType, {
    parturitionDay = 0,
    inseminationDay = 0
} = {}) {
    switch (curveType) {
        case FeedingCurveType.BEFORE_PARTURITION:
            return (day - 1) - allCurveDays;
        case FeedingCurveType.AFTER_PARTURITION:
            return (day - 1);
        case FeedingCurveType.MIXED_PARTURITION:
            return (day - parturitionDay);
        default:
            return day;
    }
}

export function formatCurveDay(day = 0, curveObj) {
    const curveLen = get(curveObj, "SetData.Days.length", 0);
    const repetitionDay = get(curveObj, "SetData.InseminationJumpTo", 0);
    const type = getCurveDayShowingType(curveObj);
    switch (type) {
        case CurveDayShowingType.BEFORE:
            return (day - 1) - curveLen;
        case CurveDayShowingType.AFTER:
            return (day - 1);
        case CurveDayShowingType.BOTH:
            return (day - repetitionDay);
        default:
            return day;
    }
}

/**
 * @param receivers {Map}
 * @param receivers.groupNRF
 * @param receivers.individualDTM
 * @param receivers.individualNRF
 * @return 0 - no wst, no nrf, 1 - only wst, 2 - only nrf, 3 - wst and nrf
 */
export function getReceiversType(receivers = new Map()) {
    let result = 0b00;
    for (let [gateway, devices] of receivers.entries()) {
        if (gateway) {
            if (devices.groupNRF.length || devices.individualNRF.devices.length) {
                result |= 0b10;
            }
            if (devices.individualDTM.length) {
                result |= 0b01;
            }
        }
    }
    return result;
}

/**
 * @deprecated //todo: usunac wraz z wejsciem widokow od nowego api
 * @param animal
 * @param devices
 * @param devicesDTM
 * @param devicesNRF
 * @return {{devices: Map<any, any>, devicesNRF: Map<any, any>, devicesDTM: Map<any, any>}}
 */
export function clearAnimalFromDeviceHelper(animal, devices = new Map(), devicesDTM = new Map(), devicesNRF = new Map()) {
    const standings = [];
    if (animal) {
        console.log(animal, "has animal");
        let tmpPlacement;
        //plcmnt moze byc stringiem
        if (isString(animal.PlcmntID)) {
            tmpPlacement = get(buildingsDB.getLocationByID(animal.PlcmntID), "BID");
            if (tmpPlacement) {
                standings.push(tmpPlacement);
            }
        } else if (isArray(animal.PlcmntID)) {
            animal.PlcmntID.forEach(o => {
                tmpPlacement = get(buildingsDB.getLocationByID(o.PlcmntID), "BID");
                if (tmpPlacement) {
                    standings.push(tmpPlacement);
                }
            });
        }
        let feedingConfigZero = {
            curve: null,
            curveStart: +moment().startOf("day"),
            parturitionDate: +moment().startOf("day"),
            punishment: 0,
            doseCorrection: 0
        };
        standings.forEach(standId => {
            let device = devicesDB.getDevicesInPlcmntID(standId, { showDevicesInChildren: false }).filter(dev => [DevType.DISPENSER_NRF, DevType.DISPENSER].includes(dev.DevType))[0];
            if (device) {
                device = devices.get(device.DevID) || device.clone();
                removeAnmIDInLocation(device, standId, animal.AnmID);
                let tmp = JSON.parse(JSON.stringify(device));
                delete tmp.location;
                delete tmp.GatewayID;
                delete tmp.$loki;
                tmp.DtaModTime = +new Date();
                devices.set(device.DevID, tmp);
                if (device.DevType === DevType.DISPENSER) {
                    let devIndex = get(get(device, "PlcmntID", []).filter(plcmnt => plcmnt.PlcmntID === standId), "[0].Adr");
                    if (!isNil(devIndex)) {
                        let paramsOut = devicesDTM.get(device.DevID) || [];
                        devicesDTM.set(device.DevID, [...paramsOut, {
                            number: devIndex + 1,
                            ...prepareFeeding(feedingConfigZero, DevType.DISPENSER)
                        }]);
                    }
                } else if (device.DevType === DevType.DISPENSER_NRF) {
                    devicesNRF.set(device.DevID, prepareFeeding(feedingConfigZero, DevType.DISPENSER_NRF));
                }
            }
        });
    }
    return {
        devices,
        devicesNRF,
        devicesDTM
    };

}

/**
 *
 * @param chamber
 * @param options.animals - lista zwierzat z propsa
 * @param options
 */
export function createAnimalDict(chamber, options = {}) {
    const { IndividualFeeding } = chamber;
    const animals = options.animals ? options.animals : animalsDB.getAllAnimalsForLocation(chamber, Level.CHAMBER, { joinEvents: false });
    const animalDict = new Map();
    console.log("createAnimalDict", options, chamber, animals);
    if (IndividualFeeding) {
        animals.forEach(o => {
            //kiedys wywalic ten pomiot szatana zwany plcmnt id arrayem xd
            if (isArray(o.PlcmntID)) {
                o.PlcmntID.forEach(p => {
                    animalDict.set(p.PlcmntID, o);
                });
            } else if (isString(o.PlcmntID)) {
                animalDict.set(o.PlcmntID, o);
            }
        });
    } else {
        animals.forEach(o => {
            //kiedys wywalic ten pomiot szatana zwany plcmnt id arrayem xd
            if (isString(o.RFID)) {
                animalDict.set(o.RFID, o);
            }
        });
    }
    return animalDict;
}

export function isFeedingEligibleForSelection(feedItem = {}) {
    return !!feedItem.feed;
}

export function getCurveDayShowingType(curve) {
    const repetitionDay = !get(curve, "SetData.InseminationJumpTo") ? CurveDayShowingType.BEFORE : get(curve, "SetData.InseminationJumpTo") === 255 ? CurveDayShowingType.AFTER : CurveDayShowingType.BOTH;
    switch (get(curve, 'SetData.Type')) {
        case CurveType.INDIVIDUAL:
            return CurveDayShowingType.NORMAL;
        case CurveType.MATING:
        case CurveType.PARTURITION:
            return repetitionDay;
        default:
            return CurveDayShowingType.NORMAL;
    }
}

/**
 * Funkcja do sprawdzania czy krzywa jest wybieralna dla danego typu urzadzenia
 * @param devType - typ urzadzenia
 * @param curve - opcja krzywa || ( index && showingType )
 * @param index - opcja
 * @param showingType - opcja
 * @return {boolean}
 */
export const isValidCurveForDevType = memoize(({ devType, curve, index, showingType }) => {
    console.log("isValidCurveForDevType", devType, curve, index, showingType);
    const _index = isNil(index) ? get(curve, "SetData.Index", 999) : index;
    const days = get(curve, "SetData.Days", []);
    const _showingType = isNil(showingType) ? getCurveDayShowingType(curve) : showingType;
    switch (devType) {
        case DevType.DISPENSER_NRF:
            return true;
        case DevType.DISPENSER:
            return (_index < 5) && (_showingType !== CurveDayShowingType.BOTH) && !days.filter(day => (day.DailyPlan > 4) || (day.ForageType > 1)).length;
        default:
            return false;
    }
}, (...args) => JSON.stringify(args));


export function changeStartTimeToRepetitionDate(node, feedingCurves = [], startTime = +new Date()) {
    const { curve: currentConfig = {} } = node || {};
    const config = {};
    config.curveNr = currentConfig.number || 0;
    config.startTime = currentConfig.start || 0;
    config.offset = currentConfig.offset || 0;
    config.stage = currentConfig.eventStage || 0;
    config.punishment = currentConfig.punishment || 0;
    config.percentCorrection = currentConfig.punishment || 0;
    const curve = feedingCurves.find(o => o.SetID === currentConfig.id);
    if (curve && (getCurveDayShowingType(curve) === CurveDayShowingType.BOTH)) {
        config.startTime = startTime;
        config.stage = 1;
    }
    return config;
}

/**
 *
 * @param node - obiekt karmienia
 * @param curve - krzywa na ktora chcemy zmienic
 * @param day - dzien krzywej na ktora chcemy zmienic
 * @param feedingCurves - lista dostepnych krzywych
 */
export function canChangeCurve(node, curve, day = 1, feedingCurves = []) {
    console.log(node, curve, day, feedingCurves, "canChangeCurve");

    if (!curve || !node) return false;
    const { curve: { number, day: _day } = {} } = node;
    //jesli jest nieskonfigurowany moze ustawic
    if (number === 0) {
        return true;
    }
    const currentCurve = feedingCurves.find(c => c.SetData.Index === (number - 1));
    //nie wiemy co to za krzywa albo ma jakis smietnik wiec yolo zmieniamy
    if (!currentCurve) {
        return true;
    }
    const currentDay = isFinite(_day) ? _day : 1;
    if (currentCurve.SetData.Index === curve.SetData.Index) {
        //jesli ma to samo ustawione to sprawdzamy czy dzien nie jest wyzszy juz
        return currentDay < day;
    }
    return true;
}

export function canSetRepetitionDay(node, feedingCurves = []) {
    if (!node) return false;
    const { curve: { number, day } = {} } = node;
    if (!number) return false;
    const currentCurve = feedingCurves.find(c => c.SetData.Index === (number - 1));
    return !!currentCurve && [CurveType.PARTURITION, CurveType.MATING].includes(currentCurve.SetData.Type) && (getCurveDayShowingType(currentCurve) === CurveDayShowingType.BOTH) && (day < currentCurve.SetData.InseminationJumpTo);
}

/**
 * funkcja zwraca nody (obiekt karmienia) pogrupowane
 * @param selectedNodes
 * @param type
 * @param WST
 * @param NRF
 * @param feedingCurves
 * @return {{nodesEligible: [], nodesAlreadyReported: [], nodesMissingSettings: []}}
 */
export function canSetEvent(selectedNodes, type, WST, NRF, feedingCurves) {
    console.log("setCan", arguments);
    const nodesMissingSettings = [];
    const nodesAlreadyReported = [];
    const nodesEligible = [];
    const wstCopy = cloneDeep(WST);
    const nrfCopy = cloneDeep(NRF);
    console.log(nrfCopy);
    selectedNodes.forEach(node => {
        //wst
        if (isFinite(get(node, "receiver.index")) && type === FeedingType.INDIVIDUAL) {
            if (!WST.curve) {
                nodesMissingSettings.push(node);
            } else if (canChangeCurve(node, wstCopy.curve, wstCopy.day, feedingCurves)) {
                nodesEligible.push(node);
            } else {
                nodesAlreadyReported.push(node);
            }

        } else {
            console.log("checky checky");
            if (!nrfCopy.simulateButton && !NRF.curve) {
                nodesMissingSettings.push(node);
            } else if (nrfCopy.simulateButton ? canSetRepetitionDay(node, feedingCurves) : canChangeCurve(node, nrfCopy.curve, nrfCopy.day, feedingCurves)) {
                console.log("yyeess");
                nodesEligible.push(node);
            } else {
                console.log("nooo");
                nodesAlreadyReported.push(node);
            }
        }
    });
    return {
        nodesEligible,
        nodesAlreadyReported,
        nodesMissingSettings
    };
}

export const getAvailableCurveTypes = ({ sectorType } = {}) => {
    const curveTypes = Object.values(CurveType);
    if (sectorType === "override") return curveTypes;
    switch (sectorType) {
        case SectorType.DELIVERY:
            return [CurveType.INDIVIDUAL, CurveType.PARTURITION];
        case SectorType.MATING:
        case SectorType.SOWS:
            return [CurveType.INDIVIDUAL, CurveType.MATING];
        default:
            return [CurveType.INDIVIDUAL];
    }
};

function getDefaultCurveForSectorType(sectorType) {
    const { Mating, Sows, Delivery, Other } = getDefaultCurvesForSectors() || {};
    switch (sectorType) {
        case SectorType.MATING:
            return Mating;
        case SectorType.SOWS:
            return Sows;
        case SectorType.DELIVERY:
            return Delivery;
        default:
            return Other;
    }
}

export function initializeFeeding({ animal, curves, sectorType, initializeIndex = false }) {
    console.log(arguments, "initializeFeeding");
    let data = {
        stage: get(animal, "feedParam.stage") || 0,
        percentCorrection: get(animal, "feedParam.percentCorrection") || 0,
        punishment: get(animal, "feedParam.punishment") || 0,
        curve: initializeIndex ? 0 : null,
        curveStart: moment(get(animal, "feedParam.startTime") || +new Date()),
        curveDay: 1,
        repetitionDate: moment(get(animal, "feedParam.startTime") || +new Date())
    };
    const curveNr = get(animal, "feedParam.curveNr");
    console.log("curveNr", curveNr);
    let curve;
    if (curveNr) {
        curve = curves.find(c => c.SetData.Index === (curveNr - 1) && getAvailableCurveTypes({ sectorType }).includes(c.SetData.Type));
    } else {
        const defaultCurve = getDefaultCurveForSectorType(sectorType);
        console.log("default", defaultCurve);
        if (defaultCurve) {
            curve = curves.find(item => item.SetID === defaultCurve);
        }
    }
    console.log(curve);
    if (curve) {
        console.log("curve", curve);
        data.curve = initializeIndex ? curves.indexOf(curve) : curve;
        const isEventReported = !!get(animal, "feedParam.stage", 0);
        const repetitionDay = get(curve, "SetData.InseminationJumpTo", 0);
        const curveSize = get(curve, "SetData.Days.length", 0);
        const showingType = getCurveDayShowingType(curve);
        switch (showingType) {
            case CurveDayShowingType.BEFORE: {
                data.repetitionDate = data.curveStart.clone().add(curveSize, "days");
                break;
            }
            case CurveDayShowingType.AFTER: {
                data.repetitionDate = data.curveStart;
                break;
            }
            case CurveDayShowingType.BOTH: {
                data.repetitionDate = data.curveStart.clone().add((isEventReported ? 0 : Math.max(repetitionDay - 1, 0)), "day");
                break;
            }
            case CurveDayShowingType.NORMAL:
            default:
                data.curveDay = Math.min(curveSize, Math.max(1, moment().startOf("day").diff(data.curveStart.clone().startOf("day"), "days") + 1));
                break;
        }
    }
    data.repetitionDate = data.repetitionDate.format("YYYY-MM-DD");
    data.curveStart = data.curveStart.format("YYYY-MM-DD");
    return data;
}


export const setAnimalFeedingData = (dispensers, feedingData = {}) => {
    const data = [];
    if (dispensers.groupNRF.length) {
        dispensers.groupNRF.forEach((loc) => {
            loc.animals.forEach(animal => {
                data.push({
                    PlcmntID: loc.LocID,
                    ...animal,
                    feeding: feedingData
                });
            });
        });
    }
    if (dispensers.individualDTM.length) {
        dispensers.individualDTM.forEach(dev => {
            dev.outputs.forEach(out => {
                data.push({
                    ...out,
                    feeding: {
                        ...feedingData,
                        number: out.number
                    }
                }
                );
            });
        });
    }
    if (dispensers.individualNRF.animals.length) {
        dispensers.individualNRF.animals.forEach(animal => {
            data.push({
                ...animal,
                feeding: feedingData
            });
        });
    }
    return data;
};

export function sortStands(stands, standsInRow, standsOrder) {
    const sortByStandNumber = (o1, o2, ascending = true) =>
        enhancedComparer(get(ascending ? o1 : o2, "name"), get(ascending ? o2 : o1, "name"), { numeric: true });
    let dataSorted = stands.slice();
    const globalSortAscending = !((standsOrder >> 1) & 0b01);
    const nextRowSwapSort = !!((standsOrder >> 2) & 0b01);
    const localRowAscending = !!((standsOrder) & 0b01);
    dataSorted.sort((o1, o2) => sortByStandNumber(o1, o2, globalSortAscending));
    let lastIndex = 0;
    const data = [];
    const maxStandsInRow = Math.min(standsInRow, dataSorted.length);
    while (lastIndex < dataSorted.length) {
        const startIndex = lastIndex;
        const endIndex = Math.min(lastIndex + maxStandsInRow, dataSorted.length);
        lastIndex = endIndex;
        const tmp = dataSorted.slice(startIndex, endIndex);
        tmp.sort(
            (o1, o2) => sortByStandNumber(o1, o2, (nextRowSwapSort && (data.length % 2 === 1)) ? !localRowAscending : localRowAscending)
        );
        data.push(tmp);
    }
    return data;
}

/**
 *
 * @param sectorType
 * @param pigEvents
 * @param curves
 * @param isIndividualFeeding
 * @param isWST
 * @return {{repetitionDate}|{repetitionDate}|{repetitionDate}|{curveDay: number}|null}
 */
export const getCurveAndDayForAPig = (sectorType, pigEvents = [], curves = [], isIndividualFeeding = true, isWST = false) => {
    console.log("getCurveAndDayForAPig", sectorType, pigEvents, curves, isIndividualFeeding, isWST);
    let plannedCurve;
    let curveId = getDefaultCurveForSectorType(sectorType);
    console.log("default curve id", curveId);
    // jesli nie ma krzywej domyslnej to elko
    if (!curveId) return null;
    plannedCurve = curves.find(curve => curve.SetID === curveId);
    console.log("plannedCurve", plannedCurve);
    // jesli ktos usunal krzywa domyslna a jakis smiec w id jest to elko
    if (!plannedCurve) return null;
    // jesli ktos chce wrzucic krzywa na urzadzenie na ktore nie moze wrzucic to elko
    if (!isValidCurveForDevType({
        curve: plannedCurve,
        devType: isWST ? DevType.DISPENSER : DevType.DISPENSER_NRF
    })) return null;
    return {
        curve: plannedCurve,
        ...changeCurveInitialize(plannedCurve, pigEvents)
    };
};


export const changeCurveInitialize = (curve, pigEvents = [], { initializeWithDay, curveStart } = {}) => {
    const showingType = getCurveDayShowingType(curve);
    const curveLen = get(curve, "SetData.Days.length", 0);
    const repetitionDay = get(curve, "SetData.InseminationJumpTo", 1);
    const timeOnDelivery = getTimeOnBirthRoom();
    const timeOnSowsRoom = getTimeOnSowsRoom();
    const timeFromInseminationToBirth = getTimeFromInseminationToPartuition();
    const getLatestEventByTypeAndTime = (type, time) => pigEvents.find((item) => item.EvCode === type && item.EvTime >= time && item.EvTime < +new Date());
    let repetitionDate;
    let cycles = preEvents(pigEvents, null);
    let lastCycle = cycles.cycleTable[cycles.cycleTable.length - 1];
    // jesli mamy eventy to liczymy z eventów
    if (pigEvents.length) {
        switch (get(curve, "SetData.Type")) {
            case CurveType.PARTURITION: {
                const parturition = lastCycle?.[EventTypes.PARTURITION][0] || getLatestEventByTypeAndTime(EventTypes.PARTURITION, +moment().subtract(timeOnDelivery, "days"));
                if (parturition) {
                    repetitionDate = moment(parturition.EvTime).startOf("day").format("YYYY-MM-DD");
                } else {
                    const startParturition = getLatestEventByTypeAndTime(EventTypes.PARTURITION_START, +moment().subtract(timeOnDelivery, "days"));
                    if (startParturition) {
                        repetitionDate = moment(startParturition.EvTime).startOf("day").format("YYYY-MM-DD");
                    } else {
                        const insemination = lastCycle?.[EventTypes.INSEMINATION][0] || getLatestEventByTypeAndTime(EventTypes.INSEMINATION, +moment.utc().startOf("day").subtract(timeFromInseminationToBirth, "days"));
                        if (insemination) {
                            repetitionDate = moment.utc(insemination.EvTime).startOf("day").add(timeFromInseminationToBirth, "day").format("YYYY-MM-DD");
                        }
                    }

                }
            }
                break;
            case CurveType.MATING: {
                const insemination = lastCycle?.[EventTypes.INSEMINATION][0] || getLatestEventByTypeAndTime(EventTypes.INSEMINATION, +moment.utc().startOf("day").subtract(timeOnSowsRoom, "days"));
                if (insemination) {
                    repetitionDate = moment.utc(insemination.EvTime).format("YYYY-MM-DD");
                }
            }
                break;
            case CurveType.INDIVIDUAL:
            default:
                break;
        }
    }
    console.log("feedingUtils", curveStart, initializeWithDay);
    switch (showingType) {
        case CurveDayShowingType.BOTH:
            return { repetitionDate: repetitionDate || moment().startOf("day").add(repetitionDay - 1, "day").format("YYYY-MM-DD") };
        case CurveDayShowingType.AFTER:
            return { repetitionDate: repetitionDate || moment().startOf("day").format("YYYY-MM-DD") };
        case CurveDayShowingType.BEFORE:
            return { repetitionDate: repetitionDate || moment().startOf("day").add(curveLen, "day").format("YYYY-MM-DD") };
        default:
            let day = 1;
            if (initializeWithDay === true && isFinite(curveStart)) {
                const curveSize = get(curve, "SetData.Days.length", 1);
                day = Math.min(curveSize, Math.max(day, moment().startOf("day").diff(moment(curveStart).startOf("day"), "days") + 1));
            }
            return { curveDay: day };
    }
};


export function checkIfCurveIsUsed(curveNumber = 0, devices = [], { stopOnFirstHit = true } = {}) {
    console.log(`[checkIfCurveIsUsed] curveNumber=${curveNumber}`);
    const animalsFound = [];
    const devicesFound = [];
    let outputCounter = 0;
    let animalsList;
    const getAnimals = (device) => {
        if (!animalsList) animalsList = animalsDB.getAllAnimals(device.FarmID, undefined, false, false);
        return animalsList;
    };
    devices.forEach((device) => {
        if (isNil(device.DtaDltTime)) {
            if (device.DevType === DevTypes.DISPENSER_NRF) {
                if (isRFID(device)) {
                    let isSet = false;
                    const linkedAnmIDs = getLinkedAnimalIDs(device);
                    getAnimals(device);
                    for (let AnmID of linkedAnmIDs) {
                        const animal = animalsList.find(anm => anm.AnmID === AnmID);
                        if (get(animal, "feedParam.curveNr") === curveNumber) {
                            console.log(`FOUND A MATCH AnmID ${AnmID} in Device ${device.DevID}`);
                            isSet = true;
                            if (!animalsFound.find(o => o.AnmID === AnmID)) animalsFound.push(animal);
                            if (stopOnFirstHit) {
                                return {
                                    outputCounter,
                                    animalsFound,
                                    animalCounter: animalsFound.length,
                                    devicesFound,
                                    isUsed: true
                                };
                            }
                        }
                    }
                    outputCounter += +isSet;
                } else if (isNutriPro(device)) {
                    // na nutriPRO chyba typ krzywaj nie ma znaczenia
                } else {
                    // normalny nrf
                    if (get(device, `Settings.Configuration.${DispenserNRFCommandTypes.SET_CONFIG_STANDARD}.curveNr`) === curveNumber) {
                        console.log(`FOUND A MATCH in Device ${device.DevID}`);
                        outputCounter++;
                        if (!devicesFound.find(d => d.DevID === device.DevID)) devicesFound.push(device);
                        if (stopOnFirstHit) {
                            return {
                                outputCounter,
                                animalsFound,
                                animalCounter: animalsFound.length,
                                devicesFound,
                                isUsed: true
                            };
                        }
                    }
                }
            } else if (device.DevType === DevTypes.DISPENSER) {
                const paramOuts = get(device, `Settings.Configuration.${DispenserDriverCommandTypes.SET_PARAM_OUTS}`, []);
                if (isArray(paramOuts)) {
                    for (let out of paramOuts) {
                        if (out && out.curveNumber === curveNumber) {
                            console.log(`FOUND A MATCH in Device ${device.DevID} output number ${out.number}`);
                            outputCounter++;
                            if (!devicesFound.find(d => d.DevID === device.DevID)) devicesFound.push(device);
                            if (stopOnFirstHit) {
                                return {
                                    outputCounter,
                                    animalCounter: animalsFound.length,
                                    animalsFound,
                                    devicesFound,
                                    isUsed: true
                                };
                            }
                        }
                    }
                }

            }
        }

    });
    return {
        outputCounter,
        animalCounter: animalsFound.length,
        animalsFound,
        devicesFound,
        isUsed: !!(devicesFound.length + animalsFound.length)
    };
}