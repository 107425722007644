import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "./_button-group.scss";
import {CSSTransition} from "react-transition-group";

export default class ButtonGroup extends React.PureComponent {

    createButtonGroup() {
        const {style, children, vertical, fixed, className, show} = this.props;
        const component = <div style={style}
                               className={`fetura-button-group ${vertical ? 'vertical' : ''} ${fixed ? 'fixed' : ''} ${className}`}>
            {children}
        </div>;
        if (fixed && show === true || show === false) {
            return (
                <CSSTransition
                    in={show}
                    timeout={300}
                    classNames="show"
                    mountOnEnter
                    unmountOnExit>
                    {component}
                </CSSTransition>
            )

        }
        return component;
    }

    render() {
        const {fixed, renderInPortal} = this.props;
        if (fixed && renderInPortal) {
            return ReactDOM.createPortal(this.createButtonGroup(), document.getElementById("root") || document.createElement('div'));
        }
        return this.createButtonGroup();
    }

}

ButtonGroup.propTypes = {
    vertical: PropTypes.bool,
    fixed: PropTypes.bool,
    style: PropTypes.object,
    show: PropTypes.bool,
    // trzeba przerobic tak aby sprawdzalo react fragmenty
    // children: (props, propName, componentName) => {
    //     const prop = props[propName];
    //     let error = null;
    //     React.Children.forEach(prop, (child) => {
    //         console.log(child.type);
    //         if (child && child.type !== Button && child.type !== InfoBox && child.type !== "Symbol(react.fragment)") {
    //             error = new Error('`' + componentName + '` children should be of type `Button` or `InfoBox`');
    //         }
    //     });
    //     return error
    // },
    className: PropTypes.string,
    renderInPortal: PropTypes.bool
};

ButtonGroup.defaultProps = {
    vertical: false,
    fixed: false,
    className: "",
    renderInPortal: true
};
