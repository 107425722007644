import React, { useMemo } from 'react';
import { getGroupFatteningTimes } from '../../../utils/GroupUtils';
import { getTimeOnPigletHouse, getTimeOnPorkHouse } from '../../../utils/SettingsUtils';
import './_fattening-progress.scss';
import Tooltip from '../../basics/tooltip/Tooltip';
import { getColorByName } from '../../../utils/ColorUtils';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import NotFound from '../../NotFound';

function FatteningProgressItem({ value, max, color, width, type, tooltipContent, addBorder, showDayText = true }) {

    const { t } = useTranslation();

    const colorFillWidth = useMemo(() => {
        if (value > max) {
            return `${max * 100 / value}%`;
        }
        return `${value * 100 / max}%`;
    }, [value, max]);

    const errorFill = useMemo(() => {
        if (value <= max) return null;
        return `${(value - max) * 100 / max}%`;
    }, [value, max]);

    return (
        <div style={{ width }}>
            <div>{type}</div>
            <Tooltip tooltipContent={tooltipContent}>
                <div className={`fattening-progress-container${addBorder ? " bordered" : ""}`}>
                    <div style={{ backgroundColor: color, width: colorFillWidth }} className="fattening-progress-item" />
                    {
                        errorFill !== null &&
                        <div style={{ backgroundColor: getColorByName("error"), width: errorFill }} className="fattening-progress-item" />
                    }
                    {
                        showDayText &&
                        <div className="fattening-progress-text">{t("Xdays", { count: value })}</div>
                    }
                </div>
            </Tooltip>
        </div>
    );
}

function FatteningProgressTooltipContent({ max, value }) {

    const { t } = useTranslation();

    return (
        <div>{t("fatteningDaysExceeded", { count: value - max })}</div>
    );
}

export default function FatteningProgress({ queryCaller, group }) {

    const { t } = useTranslation();

    const fatteningAge = useMemo(() => getGroupFatteningTimes(queryCaller, group.AnmGrp), [group]); // eslint-disable-line react-hooks/exhaustive-deps

    const { weanersMax, finisherMax } = useMemo(() => {
        return { weanersMax: getTimeOnPigletHouse(), finisherMax: getTimeOnPorkHouse() };
    }, []);

    const { maxWeaners, maxFinisher } = useMemo(() => {
        const maxWeaners = fatteningAge.finisherAge === null ? Math.max(weanersMax, fatteningAge.weanersAge) : fatteningAge.weanersAge;
        const maxFinisher = Math.max(finisherMax, fatteningAge.finisherAge);
        return { maxWeaners, maxFinisher };
    }, [fatteningAge, finisherMax, weanersMax]);

    const { weanersWidth, finisherWidth } = useMemo(() => {
        // przeliczanie procentowego udzialu warchlaka i tucznika
        if (fatteningAge.weanersAge === null) return { weanersWidth: 0, finisherWidth: "100%" };
        const weanersWidth = maxWeaners * 100 / (maxWeaners + maxFinisher);
        const finisherWidth = 100 - weanersWidth;
        return { weanersWidth: `${weanersWidth}%`, finisherWidth: `${finisherWidth}%` };
    }, [maxWeaners, maxFinisher, fatteningAge]);

    const activeDay = useMemo(() => {
        return fatteningAge.weanersAge + fatteningAge.finisherAge;
    }, [fatteningAge]);

    const activeDayStyles = useMemo(() => {
        let max = fatteningAge.weanersAge === null ? maxFinisher : maxWeaners + maxFinisher;
        const percent = activeDay * 100 / max;
        const translate = percent > 70 ? -100 : percent < 5 ? 0 : -50;
        return { left: percent + "%", transform: `translateX(${translate}%)` };
    }, [activeDay, fatteningAge, maxFinisher, maxWeaners]);

    if (isNil(fatteningAge.weanersAge) && isNil(fatteningAge.finisherAge)) return <NotFound />;
    return (
        <div className="fattening-progress">
            {
                fatteningAge.weanersAge !== null &&
                <FatteningProgressItem
                    value={fatteningAge.weanersAge}
                    max={weanersMax}
                    color={getColorByName("piglets")}
                    width={weanersWidth}
                    type={t("animalTypes.2")}
                    activeDay={activeDay.weaners}
                    addBorder={fatteningAge.finisherAge === null}
                    tooltipContent={fatteningAge.weanersAge > weanersMax ? <FatteningProgressTooltipContent max={weanersMax} value={fatteningAge.weanersAge} /> : null}
                />
            }
            <FatteningProgressItem
                value={fatteningAge.finisherAge}
                max={finisherMax}
                color={getColorByName("porkers")}
                width={finisherWidth}
                type={t("animalTypes.3")}
                activeDay={activeDay.finisher}
                addBorder={fatteningAge.weanersAge === null}
                showDayText={fatteningAge.weanersAge !== null && fatteningAge.finisherAge !== null}
                tooltipContent={fatteningAge.finisherAge > finisherMax ? <FatteningProgressTooltipContent max={finisherMax} value={fatteningAge.finisherAge} /> : null}
            />
            <div className="fattening-progress-active-day-text" style={activeDayStyles}>{t("dayFromInsertion", { day: activeDay })}</div>
        </div>
    );
}
