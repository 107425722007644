import { throttle } from "lodash";
import { getBuildingsMap } from "../selectors/buildingsSelector";
import store from "../store/store";
import { calculateAnimalParametersForAllAnimals2 } from "../utils/AnimalParamsWorkerUtils";
import { checkIfUserIsService } from "../utils/NewRolesUtils";
import { getFarmZoneType } from "../utils/TimeUtils";
import { getUtilResults } from "../utils/results/GeneralResultsUtils";
import { myID } from "../libs/generateID";

let animalParametersWorkerInstance = null;

export let parameters = [];

export function createWorkerForAnimalParameters(time = 1000 * 10) {
    const state = store.getState();
    const args = {
        user: state.user.user,
        sub: state.user.attributes.sub,
        isService: checkIfUserIsService(),
        FarmID: state.location.farm,
        utilResults: getUtilResults(),
        farmTimeZone: getFarmZoneType(state.location.farm),
        buildingsMap: getBuildingsMap(state, { showDeleted: true })
    };
    console.log(args);
    return new Promise((resolve, reject) => {
        // when user crates events continuously the worker gets terminated a lot
        // unfortunately before the worker gets terminated there are many LokiJS calls increasing memory fingerprint massively
        // delaying the start of the worker should do the trick
        const delayedStartId = setTimeout(() => {
            animalParametersWorkerInstance = new Worker(new URL("../workers/prepareAnimalParameters.worker", import.meta.url));
            animalParametersWorkerInstance.postMessage(args);
            animalParametersWorkerInstance.onmessage = (event) => {
                // console.error(event.data);
                animalParametersWorkerInstance = null;
                if (event.data.error) reject(new Error(event.data.error));
                else {
                    // const tmp = JSON.parse(JSON.stringify(event.data.result));
                    resolve(event.data.result);
                }
            };
        }, time);
        animalParametersWorkerInstance = {
            // don't even spawn the worker before 15 seconds - `terminate()` is used later on so we add one
            terminate: () => clearTimeout(delayedStartId)
        };
    });
}

function createWorkerForAnimalParameters2(time) {
    return new Promise((resolve, reject) => {

        const state = store.getState();
        setTimeout(async () => {
            try {
                const res = await calculateAnimalParametersForAllAnimals2(state.location.farm, getUtilResults(), getBuildingsMap(state, { showDeleted: true }));
                if (res === null) reject();
                resolve(res);
            } catch (err) {
                reject(err);
            }
        }, time);

    });
}
const throttledAnimalParametersTime = window.localStorage.getItem("isCypress") ? 1000 * 10 : 1000 * 60 * 2;
// const throttledAnimalParameters = throttle(createWorkerForAnimalParameters2, throttledAnimalParametersTime);

const dispatchAnimalParameters = throttle((dispatch) => {
    dispatch({
        type: "CALCULATE_ANIMAL_PARAMETERS",
        payload: createWorkerForAnimalParameters2().then(res => {
            parameters = res;
            return myID();
        })
    });
}, throttledAnimalParametersTime);

export function prepareAnimalParameters() {
    return dispatchAnimalParameters;
}

export function setMapSQL(sql, ...params) {
    return function (dispatch) {
        dispatch({
            type: "SET_MAP_SQL",
            payload: { sql, params }
        });
    };
}

export function clearParameters() {
    parameters = [];
    dispatchAnimalParameters.cancel();
}