import {findIndex, maxBy} from "lodash";

const initialState = {
    data: {},
    fetching: {},
    lastFetched: {},
    deviceData: {}
};

const insertIntoData = (state, action) => {
    console.log("insertIntoData", action)
    const data = {...state.data};
    const devID = action.payload.DevID || action.meta.DevID;
    console.log(devID);
    const dataArray = Array.isArray(action.payload) ? action.payload : action.payload?.items ? action.payload.items : [action.payload];
    const array = (data[devID] || []).slice(0);
    for (let row of dataArray) {
        let index = findIndex(array, {AggTi: row.AggTi});
        if (index !== -1) {
            array[index] = maxBy([row, array[index]], (o) => o.DaMoTi);
        } else {
            array.push(row);
        }
    }
    array.sort((a, b) => b.AggTi - a.AggTi);
    data[devID] = array;
    return data;
}

export default function aggregatedReducer(state = initialState, action) {
    switch (action.type) {
        case "USER_LOGOUT_FULFILLED":
        case "CHANGE_FARM":
            return initialState
        case "FETCH_AGGREGATED_DATA_PENDING": {
            if (action.meta.partial) return state;
            let fetching = {...state.fetching};
            fetching[action.meta.DevID] = true;
            console.log(action.meta.DevID, "FETCHINNNGGG")
            return {
                ...state,
                fetching
            };
        }
        case "FETCH_AGGREGATED_DATA_FULFILLED":
            try {
                let fetching = {...state.fetching};
                let lastFetched = {...state.lastFetched};
                if (!action.payload.LastEvaluatedKey) {
                    fetching[action.meta.DevID] = false;
                    lastFetched[action.meta.DevID] = Date.now();
                    console.log(action.meta.DevID, "FETCHINNNGGG STOP")
                }
                try {
                    let data = insertIntoData(state, action);
                    return {
                        ...state,
                        fetching,
                        lastFetched,
                        data
                    };
                } catch (e) {
                    console.error(e);
                    return {
                        ...state,
                        fetching
                    };
                }
            } catch (e) {
                console.error(e);
                return state;
            }
        case "FETCH_AGGREGATED_DATA_REJECTED": {
            let fetching = {...state.fetching};
            fetching[action.meta.DevID] = false;
            return {
                ...state,
                fetching
            };
        }
        case "AGGREGATED_DATA_MQTT": {
            let data = insertIntoData(state, action);
            let lastFetched = {...state.lastFetched};
            try {
                if (action.payload.DevID) {
                    lastFetched[action.payload.DevID] = Date.now();
                }
            } catch (err) {
                console.error(err);
            }
            return {
                ...state,
                lastFetched,
                data
            }
        }
        case "FETCH_DEVICE_DATA_PENDING": {
            const key = `${action.meta.Start}-${action.meta.End}-${action.meta.Placements.join("-")}`;
            return {
                ...state,
                deviceData: {
                    ...state.deviceData,
                    [action.meta.Type]: {
                        ...(state.deviceData[action.meta.Type] || {}),
                        [key]: {
                            ...(state.deviceData[action.meta.Type]?.[key] || {}),
                            loading: true,
                            error: undefined
                        }
                    }
                }
            };
        }
        case "FETCH_DEVICE_DATA_FULFILLED": {
            const key = `${action.meta.Start}-${action.meta.End}-${action.meta.Placements.join("-")}`;
            return {
                ...state,
                deviceData: {
                    ...state.deviceData,
                    [action.meta.Type]: {
                        ...(state.deviceData[action.meta.Type] || {}),
                        [key]: {
                            ...(state.deviceData[action.meta.Type]?.[key] || {}),
                            loading: false,
                            data: action.payload.result
                        }
                    }
                }
            };
        }
        case "FETCH_DEVICE_DATA_REJECTED": {
            const key = `${action.meta.Start}-${action.meta.End}-${action.meta.Placements.join("-")}`;
            return {
                ...state,
                deviceData: {
                    ...state.deviceData,
                    [action.meta.Type]: {
                        ...(state.deviceData[action.meta.Type] || {}),
                        [key]: {
                            ...(state.deviceData[action.meta.Type]?.[key] || {}),
                            loading: false,
                            error: action.payload.error || "Unknown Error"
                        }
                    }
                }
            };
        }
        default:
            return state;
    }
}

