import UserTypes from "@wesstron/utils/Api/constants/userTypes";
import { Auth } from "aws-amplify";
import i18next from "i18next";
import { isFunction } from "lodash";
import { notify } from "reapop";
import NewIOT from "../IOT/NewIOT";
import Paths from "../api/paths";
import { getAllUsers } from "../api/user/allUsers";
import {
    getServiceAccounts,
    getServiceClientsMap,
    getSupportAccounts,
} from "../api/user/serviceAccounts";
import { getTranslators } from "../api/user/translators";
import { history } from "../components/router/CustomBrowserRouter";
import db from "../database/lokiDB";
import { getUserIdToken, invokeApig, signOutUser } from "../libs/awsLib";
import { myID } from "../libs/generateID";
import store from "../store/store";
import { getDictionaries } from "../utils/DataLoaderUtils";
import { changeLanguage } from "../utils/LangUtils";
import { checkIfUserIsService } from "../utils/NewRolesUtils";
import { ReactLocalStorage } from "../utils/ReactLocalStorage";
import { getAttributesFromCognitoUser } from "../utils/UserUtils";
import { getAllDictionaries } from "./dictionaryActions";
import { setGlobalLoadingText } from "./viewActions";
////////////////
import { askForPermisstionToReceiveNotifications } from "../utils/FirebaseUtils";
import { checkIfUserHasToSubscribe } from "../utils/NewRolesUtils";
import { pushMessageToServiceWorker } from "../utils/ServiceWorkerUtils";
import { getProjects } from "./projectActions";
/////////
///////////////////////////////////////////////////////
//////////

export async function getUserSuccess(
    res,
    dispatch,
    sub,
    onSuccess,
    offlineData
) {
    let key = checkIfUserIsService() ? `${sub}_${res.value.ClientID}` : sub;
    // persistent storage
    if (navigator.storage && navigator.storage.persist) {
        await navigator.storage.persist();
    }
    if (/electron/i.test(navigator.userAgent)) {
        await checkUserData(res.value, sub, res.value.FarmData[0], offlineData);
        console.log("eqwwweqweqweqweqwe");
    } else {
        await db.loadDatabase(key);
    }
    dispatch({
        type: "GET_FARMS",
        payload: res.value.FarmData,
    });
////////////////////
    if (!checkIfUserHasToSubscribe(res.value)) {
        // wykomentowane bo pobiera dla ClientID dla starego klienta
        // if (res.value.UserType !== UserTypes.USER && res.value.UserType !== UserTypes.TRANSLATOR) {
        //     dispatch(getEmployees(res.value.ClientID));
        // }
        dispatch(setGlobalLoadingText(i18next.t("connectingToDevices")));
        pushMessageToServiceWorker(
            null,
            null,
            null,
            checkIfUserIsService(res.value.Roles),
            res.value
        );
        if (!NewIOT.client?.connected) {
            setTimeout(() => {
                NewIOT.startConnection();
            });
        }
        // noinspection ES6MissingAwait - w określonych przypadkach chrome nie ogarniał i front się wieszał na loadingu
        askForPermisstionToReceiveNotifications(res.value);
    }
    try {
        await getDictionaries(res.value.ClientID);
    } catch (e) {
        console.error("Cant get dictionaries on login");
        console.error(e);
    }
//////////////
    dispatch(getAllDictionaries());
    dispatch({
        type: "USER_IS_AUTHENTICATED",
    });
////////////////////
    dispatch(getProjects());
//////////////
    let savedLanguage = ReactLocalStorage.getPrivate("language");
    if (savedLanguage && process.env.NODE_ENV !== "test") {
        await changeLanguage(savedLanguage);
    }
    if (res?.value?.UserType === UserTypes.TRANSLATOR) {
        history.replace("/translate");
    }
}

export function getUser(
    sub,
    runSuccess = true,
    onSuccess,
    shouldDispatch = true,
    forceFetch = false
) {
    const sessionToken = myID();
    if (process.env.NODE_ENV !== "test") {
        localStorage.setItem("sessionToken", sessionToken);
    }
    return async function (dispatch) {
        dispatch({
            type: "GET_USER",
            payload: invokeApig({
                ...Paths.getUser(sub),
                queryParams: { sessionToken },
                headers: {
                    IdToken: await getUserIdToken(),
                },
                forceFetch,
                shouldIdToken: true,
            }),
        })
            .then(async (res) => {
                if (runSuccess) {
                    await getUserSuccess(res, dispatch, sub);
                }
                if (isFunction(onSuccess)) {
                    if (shouldDispatch) {
                        dispatch(onSuccess());
                    } else {
                        onSuccess();
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                dispatch(logoutUser());
            });
    };
}

export function fetchAllUsers(signal) {
    return function (dispatch) {
        dispatch({
            type: "FETCH_ALL_USERS",
            payload: getAllUsers(undefined, signal),
        }).catch(err => {
            console.error(err);
        });
    };
}

export function fetchServiceAccounts() {
    return function (dispatch) {
        dispatch({
            type: "FETCH_SERVICE_ACCOUNTS",
            payload: getServiceAccounts(),
        });
    };
}

export function fetchSupportAccounts() {
    return function (dispatch) {
        dispatch({
            type: "FETCH_SUPPORT_ACCOUNTS",
            payload: getSupportAccounts(),
        });
    };
}

export function fetchTranslators() {
    return function (dispatch) {
        dispatch({
            type: "FETCH_TRANSLATORS",
            payload: getTranslators(),
        });
    };
}

export function fetchServiceClientsMap(serviceLocalUserID) {
    return function (dispatch) {
        dispatch({
            type: "FETCH_SERVICE_CLIENT_MAP",
            payload: getServiceClientsMap({
                ServiceLocalUserID: serviceLocalUserID,
            }),
        });
    };
}

export function resetServiceClientMap() {
    return function (dispatch) {
        dispatch({
            type: "RESET_SERVICE_CLIENT_MAP",
        });
    };
}

export function logoutUser() {
    return function (dispatch) {
        dispatch({
            type: "USER_LOGOUT",
            payload: signOutUser(),
        }).then((res) => {
            history.push("/");
        });
    };
}

export function getUserAttributes(user, runGetUser = true, onSuccess) {
    return async function (dispatch) {
        dispatch({ type: "IS_AUTHENTICATING" });
        console.log("[getUserAttributes] user", user);
        let atr = user.attributes.sub;
        if (user) {
            if (runGetUser) {
                await dispatch(getUser(atr, true));
            }
            const attributes = getAttributesFromCognitoUser(user, atr);
            dispatch({
                type: "USER_GET_ATTRIBUTES_FULFILLED",
                payload: attributes,
            });
        }
        if (isFunction(onSuccess)) onSuccess();
    };
}

/**
 * akcja potwierdzająca mail użytkownika na podstawie jego username'a
 * @param username - nazwa użytkownika
 * @param onSuccess - funkcja wołana, gdy weryfikacja przebiegnie poprawnie
 * @returns {Function}
 */
export function confirmUserMail(username, onSuccess) {
    return async function (dispatch) {
        await dispatch({
            type: "USER_MAIL_VERIFY",
            payload: invokeApig({
                ...Paths.verifyUserEmail(),
                body: { username: username },
            }),
        })
            .then(() => {
                if (onSuccess) onSuccess();
            })
            .catch((e) => {
                console.error(e);
            });
    };
}

export function confirmUserAccount(username, code, action) {
    return async function (dispatch) {
        await dispatch({
            type: "USER_CONFIRMATION_ACCOUNT",
            payload: new Promise((resolve, reject) => {
                Auth.confirmSignUp(username, code)
                    .then(() => {
                        resolve();
                    })
                    .catch((e) => {
                        reject(e);
                    });
            }),
        })
            .then(() => {
                if (action) action();
            })
            .catch((err) => {
                // jesli user drugi raz kliknie w link na mailu, a wcześniej udało się potwierdzić wyświetlamy tę samą zwrotkę
                if (
                    err.message ===
                    "User cannot be confirmed. Current status is CONFIRMED" &&
                    action
                )
                    action();
                console.error("confirm error: ", err);
            });
    };
}

/**
 * Funkcja do pobierania użytkowników do których mamy dostęp
 * @store user.fetchingEmployees {boolean} flaga która mówi o pobieraniu userów
 * @store user.employees {object[]} tablica userów które się wypełni po pomyślnym wykonaniu funkcji
 * @param ClientID
 * @param forceFetch
 * @returns {Function}
 */
export function getEmployees(ClientID, forceFetch = false) {
    return function (dispatch) {
        dispatch({
            type: "GET_USER_EMPLOYEES",
            payload: ClientID
                ? invokeApig({
                    ...Paths.listUser({ clientID: ClientID }),
                    forceFetch,
                })
                : Promise.resolve({ res: [] }),
        }).catch((e) => {
            console.error("User employees GET error: ", e);
        });
    };
}

/**
 * Funkcja do aktualizowania ról (słowa kluczowe: role, autoryzacja, przywileje)
 * @store user.updatingAuthorizations {boolean} flaga czy role są w trakcie zapisu na dynamoDB
 * @param values
 * @param ClientID {string} id klienta
 * @param LocalUserID {string} id usera któremu się edytuje role
 * @param [onSuccess] {Function} funkcja która się wykona po pomyślnej edcyji użytkownika
 * @returns {Function}
 */
export function updateRoles(values, ClientID, LocalUserID, onSuccess) {
    return function (dispatch) {
        dispatch({
            type: "UPDATE_ROLES",
            payload: invokeApig({
                ...Paths.updateRoles({
                    clientID: ClientID,
                    localUserID: LocalUserID,
                }),
                body: values,
            }),
        })
            .then(() => {
                let notifi = {
                    title: i18next.t("popNotifications.editUser"),
                    message: i18next.t("popNotifications.successful"),
                    status: "success",
                    dismissible: true,
                    dismissAfter: 3000,
                };
                store.dispatch(notify(notifi));
                if (onSuccess) onSuccess();
            })
            .catch(() => {
                let notifi = {
                    title: i18next.t("popNotifications.editUser"),
                    message: i18next.t("popNotifications.failure"),
                    status: "error",
                    dismissible: true,
                    dismissAfter: 3000,
                };
                store.dispatch(notify(notifi));
            });
    };
}

/**
 * Metoda zmieniajaca lokalne hasło usera
 * @param password      nowe haslo
 * @param ClientID
 * @param LocalUserID
 * @param FarmID
 * @param showNotify
 * @param sub
 * @param create
 * @param license
 * @param login
 * @param onSuccess
 */
export function changeMQTTPassword(
    password,
    {
        ClientID = "",
        LocalUserID = "",
        FarmID = "",
        showNotify = true,
        sub = "",
        create = false,
        license = "",
        login = "",
    } = {},
    onSuccess
) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_MQTT_PASSWORD",
            payload: invokeApig({
                ...Paths.changeMQTTPassword(),
                body: {
                    LocalMqttSecret: password,
                    sub,
                    create,
                    ClientID,
                    LocalUserID,
                    license,
                    login,
                },
            }),
        })
            .then(() => {
                dispatch({
                    type: "USER_CHANGE_MQTT_PASSWORD",
                    payload: password,
                });
                if (showNotify) {
                    let notifi = {
                        title: i18next.t("popNotifications.changePassword"),
                        message: i18next.t(
                            "popNotifications.changePasswordSuccess"
                        ),
                        status: "success",
                        dismissible: true,
                        dismissAfter: 3000,
                    };
                    store.dispatch(notify(notifi));
                    if (isFunction(onSuccess)) onSuccess();
                }
            })
            .catch(() => {
                if (showNotify) {
                    let notifi = {
                        title: i18next.t(
                            "popNotifications.localMqttPassword.localPasswordAuthorization"
                        ),
                        message: i18next.t(
                            "popNotifications.localMqttPassword.failureMessage"
                        ),
                        status: "error",
                        dismissible: true,
                        dismissAfter: 3000,
                    };
                    store.dispatch(notify(notifi));
                }
            })
            .finally(() => {
                if (FarmID) {
                    history.push(`/farm/${FarmID}/settings`);
                }
            });
    };
}

export function userSortBy(userSort) {
    return function (dispatch) {
        dispatch({
            type: "USER_SORT",
            payload: { userSort },
        });
    };
}

export function userFarmFilter(farmID) {
    return function (dispatch) {
        dispatch({
            type: "USER_FARM_FILTER",
            payload: { farmID },
        });
    };
}

export function userTextFilter(text) {
    return function (dispatch) {
        dispatch({
            type: "USER_TEXT_FILTER",
            payload: { text },
        });
    };
}

export function userTypeFilter(userType) {
    return function (dispatch) {
        dispatch({
            type: "USER_TYPE_FILTER",
            payload: { userType },
        });
    };
}

export function userLanguageFilter(languages) {
    return function (dispatch) {
        dispatch({
            type: "USER_LANGUAGE_FILTER",
            payload: { languages },
        });
    };
}

export function setSelectedUserInfo(employee) {
    return function (dispatch) {
        dispatch({
            type: "SET_SELECTED_USER_INFO",
            payload: employee,
        });
    };
}

export function setSelectedTranslatorAccount(translator) {
    return (dispatch) => {
        dispatch({
            type: "SET_SELECTED_TRANSLATOR_ACCOUNT",
            payload: translator,
        });
    };
}

export function setSelectedServiceAccount(serviceAccount) {
    return function (dispatch) {
        dispatch({
            type: "SET_SELECTED_SERVICE_ACCOUNT",
            payload: serviceAccount,
        });
    };
}

export function setSelectedSupportAccount(supportAccount) {
    return function (dispatch) {
        dispatch({
            type: "SET_SELECTED_SUPPORT_ACCOUNT",
            payload: supportAccount,
        });
    };
}

export function setSupportUserToAssign(supportUserData) {
    return function (dispatch) {
        dispatch({
            type: "SET_SUPPORT_USER_TO_ASSIGN",
            payload: supportUserData,
        });
    };
}
