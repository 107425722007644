import i18next from "i18next";
import {DevType} from "../../../../../constans/devices";


const DeviceTypes = Object.values(DevType);

export default class Alert {
    constructor(code) {
        this.code = code;
        this.type = [];
        this.counter = 0;
        this.typeCounter = {};
        this.item2Type = {};
    }


    static isOtherType(type) {
        return !!DeviceTypes.includes(type);
    }

    static getTypeAlias(type) {
        switch (type) {
            case "SILORADAR": {
                return "SILO";
            }
            default:
                return type;
        }
    }

    add(itemType, itemId) {
        this.counter++;
        const type = Alert.isOtherType(itemType) ? "OTHER" : Alert.getTypeAlias(itemType);
        this.typeCounter[type] = this.typeCounter[type] ? this.typeCounter[type] + 1 : 1;
        this.item2Type[itemId] = type;
        this.type = [...new Set([...this.type, type])];
    }

    getTranslated() {
        return i18next.t([`notificationsCenter.errorCodes.S${this.code}`, `notificationsCenter.errorCodes.${this.code}`, `${this.code}`]);
    }

    getCounter() {
        return this.counter;
    }

    getCounterByType(type) {
        return this.typeCounter[type] || 0;
    }

    getTypes() {
        return this.type;
    }
}
