import alasql from "alasql";
import i18n from "i18next";
import { filter, find, findLast, get, has, isArray, isNil, memoize } from "lodash";
import moment from "moment/moment";
import { createSelector } from "reselect";
import { parameters } from "../actions/animalParametersActions";
import { USG_STATE } from "../constans/eventTypes";
import { UnitTypes } from "../constans/unitTypes";
import { myID } from "../libs/generateID";
import { isFiniteNumber } from "../utils/MathUtils";
import { getFirstCycleIndex, getTimeFromInseminationToPartuition } from "../utils/SettingsUtils";
import { convertWeightUnitTo } from "../utils/UnitUtils";
import { removeReasonFormatter } from "../views/new-farm-view/tabs/results-renderers/ResultsReportFormatters";
import { getAllAnimals } from "./animalSelector";
import { getBuildingsMap } from "./buildingsSelector";
import { getAllEventsForFarm } from "./eventsSelectors";
import { getKeyValueLogDict } from "./logSelector";
import { localMomentToUTC } from "../utils/DateTimeUtils";

export const getAnimalParametersLoading = state => state.animalParameters.fetching;

const _getAnimalParameters = state => state.animalParameters.data;

export const getAnimalParameters = createSelector(_getAnimalParameters, () => {
    return parameters;
});

export const getMapSQL = state => state.animalParameters.map.sql;

export const getMapSQLData = state => state.animalParameters.map;

export const getAnimalParametersFirstFetching = state => state.animalParameters.firstFetching;

const getAlaSQLTables = createSelector([getAnimalParameters, getAllEventsForFarm, getAllAnimals], (paramsTable, eventsTable, animalsTable) => {
    return {
        ParamsTable: {
            data: paramsTable,
            index: ["group_nr", "AnmID", ["AnmID", "cycle"], ["AnmID", "group_nr", "cycle"]]
        },
        EventsTable: { data: eventsTable },
        AnimalsTable: { data: animalsTable }
    };
});

const createTables = (tables) => {
    for (const tableName in tables) {
        alasql(`CREATE TABLE IF NOT EXISTS ${tableName}`);
        alasql.tables[tableName].data = tables[tableName].data;
        if (tables[tableName].index) {
            for (let index of tables[tableName].index) {
                const indexName = `${isArray(index) ? index.join("_") : index}_index`;
                const indexColumns = isArray(index) ? index.join(", ") : index;
                alasql(`CREATE INDEX ${indexName} ON ${tableName} (${indexColumns})`);
            }
        }
    }
};

const createAggregators = () => {
    alasql.aggr.LAST_NOT_NULL = (value, accumulator, stage) => {
        switch (stage) {
            case 1: {
                if (isNil(value)) return null;
                return value;
            }
            case 2: {
                if (isNil(value)) return accumulator;
                return value;
            }
            default: {
                return accumulator ?? null;
            }
        }
    };
    alasql.aggr.FIRST_NOT_NULL = (value, accumulator, stage) => {
        switch (stage) {
            case 1: {
                if (isNil(value)) return null;
                return value;
            }
            case 2: {
                if (isNil(value)) return accumulator;
                return isNil(accumulator) ? value : accumulator;
            }
            default: {
                return accumulator ?? null;
            }
        }
    };
    alasql.aggr.ARRAY_NOT_NULL = (value, accumulator, stage) => {
        switch (stage) {
            case 1: {
                if (isNil(value)) return [];
                return [value];
            }
            case 2: {
                if (isNil(value)) return accumulator;
                return accumulator.concat([value]);
            }
            default: {
                return accumulator ?? [];
            }
        }
    };
};

const createCustomFunctions = ({ dictionaries, buildingsMap }) => {
    const hasValue = (o) => "any" !== o && "*" !== o;
    // indices are made and stored inside alasql but I cant get alasql to use them, so we must extract them manually
    // (this could be due to "cheat-y" way of inserting tables - couldn't be bothered to investigate more)
    const getIndexedData = (tableName, indexName, ...args) => {
        // find unique index name (these are numeric as of 10.01.2024)
        const index = alasql.tables[tableName].inddefs[indexName].hh ?? "unknown";
        // internal alasql keys are made this way
        const key = args.filter((o) => hasValue(o)).join("`");
        return alasql.tables[tableName].indices[index][key] ?? [];
    };
    const t = i18n.t;

    const getIndexKey = memoize((hasAnmID, hasGroup, hasCycle) => {
        const key = [];
        if (hasAnmID) key.push("AnmID");
        if (hasGroup) key.push("group_nr");
        if (hasCycle) key.push("cycle");
        key.push("index");
        const index = key.join("_");
        console.log("possible index %s", index);
        return index;
    }, (...args) => args.join("_"));
    const makeCheckCondition = ((condition, timestamp) => {
        switch (condition.replace("last ", "")) {
            case "<=":
                return (ts) => ts <= timestamp;
            case "<":
                return (ts) => ts < timestamp;
            case "=":
                return (ts) => ts === timestamp;
            case ">=":
                return (ts) => ts >= timestamp;
            case ">":
                return (ts) => ts > timestamp;
            case "*":
            case "any":
            default:
                return () => true;
        }
    });
    const getBuildingsHierarchy = memoize((PlcmntID) => {
        const hierarchy = {};
        let level = buildingsMap.get(PlcmntID);
        if (!level) return {};
        let parent = level.id !== level.parentId ? level.parentId : null;
        hierarchy[level.level] = PlcmntID;
        while (parent) {
            const tmp = buildingsMap.get(parent);
            hierarchy[tmp.level] = parent;
            parent = tmp.id !== tmp.parentId ? tmp.parentId : null;
        }
        return hierarchy;
    });
    /**
     * couldn't get alasql indices to work with a simple query
     * this finds a parameter with given name inside current animal cycle and its quite fast
     * @deprecated
     * @param key
     * @param condition {'last <='|'last <'|'last ='|'last >='|'last >'|'last *'|'last any'|'<='|'<'|'='|'>='|'>'|'*'|'any'}
     * @param timestamp
     * @param animalId {string|"any"|"*"} any można użyć tylko tak jak pozwala na to lokalny index tzn. ["cycle"], ["group_nr", "AnmID"], ["group_nr", "AnmID", "cycle"]
     * @param cycle {number|"any"|"*"}
     * @param group_nr {number|"any"|"*"}
     * @return {*}
     * @constructor
     */
    alasql.fn.FIND_ANIMAL_PARAM = (key, condition, timestamp, animalId, cycle, group_nr) => {
        // todo: zoptymalizować przeszukiwanie danych
        const checkCondition = makeCheckCondition(condition, timestamp);
        const findFn = condition.startsWith("last") ? findLast : find;
        const idxKey = getIndexKey(hasValue(animalId), hasValue(group_nr), hasValue(cycle));
        const data = getIndexedData("ParamsTable", idxKey, animalId, group_nr, cycle);
        return findFn(data, (r) => (key === "any" || r.key === key) && checkCondition(r.dataEv));
    };
    alasql.fn.FILTER_ANIMAL_PARAM = (key, condition, timestamp, animalId, cycle, group_nr) => {
        const checkCondition = makeCheckCondition(condition, timestamp);
        const idxKey = getIndexKey(hasValue(animalId), hasValue(group_nr), hasValue(cycle));
        const data = getIndexedData("ParamsTable", idxKey, animalId, group_nr, cycle);
        return filter(data, (r) => (key === "any" || r.key === key) && checkCondition(r.dataEv));
    };
    /**
     * zwraca stała wartość
     * @param key
     * @returns {null|*}
     * @constructor
     */
    alasql.fn.CONST = (key) => {
        switch (key) {
            case "FIRST_CYCLE_INDEX":
                return getFirstCycleIndex();
            case "TIME_FROM_INSEMINATION_TO_PARTURITION":
                return getTimeFromInseminationToPartuition();
            default:
                return null;
        }
    };
    alasql.fn.TRANS = memoize((key) => {
        return t(key);
    });
    /**
     * zwraca wartość domyślną
     * przydatne, gdy chcemy wyzerować nulle
     * albo zrobić alias dla kolumny
     * @param value
     * @param defaultValue
     * @returns {any|null}
     * @constructor
     */
    alasql.fn.DEF = (value, defaultValue) => {
        if (isNil(value)) return defaultValue ?? null;
        return value;
    };
    alasql.fn.GET = (value, key, defaultValue) => {
        return get(value, key, defaultValue);
    };
    /**
     * odpowiednik toFixed, zwraca null jesli value nie jest liczbą
     * @param value
     * @param fractionDigits
     * @returns {null|number}
     * @constructor
     */
    alasql.fn.FIXED = (value, fractionDigits = 2) => {
        if (!isFiniteNumber(value)) return null;
        return +(value.toFixed(fractionDigits));
    };
    const fmtDateUtils = {
        momentInstance: moment()
    };
    fmtDateUtils.gradation = {
        month: (monthNumber) => fmtDateUtils.momentInstance.month(monthNumber).format("MMMM"),
        weekDay: (dayNumber) => fmtDateUtils.momentInstance.isoWeekday(dayNumber).format("dddd")
    };
    /**
     * formatowanie daty
     * todo: dodac utc
     * @param value
     * @param gradation {string|null}
     * @returns {string|null}
     * @constructor
     */
    alasql.fn.FMT_DATE = (value, gradation) => {
        if (isNil(value)) return null;
        return gradation ? fmtDateUtils.gradation[gradation](value) : moment(value).format("L");
    };
    /**
     * pierwsze 3 argumenty kompatybilne z 'DATEDIFF' z T-SQL
     * @param datePart
     * @param startDate
     * @param endDate
     * @param startOf {"day"|null}
     * @returns {number|null}
     * @constructor
     */
    alasql.fn.DATE_DIFF = (datePart, startDate, endDate, startOf = null) => {
        if (isNil(startDate) || isNil(endDate)) return null;
        const start = startOf ? moment(startDate).startOf(startOf) : moment(startDate);
        const end = startOf ? moment(endDate).startOf(startOf) : moment(endDate);
        return end.diff(start, datePart);
    };
    alasql.fn.DATE_DIFF_UTC = (datePart, startDate, endDate, startOf = null) => {
        if (isNil(startDate) || isNil(endDate)) return null;
        const start = startOf ? moment.utc(startDate).startOf(startOf) : moment.utc(startDate);
        const end = startOf ? moment.utc(endDate).startOf(startOf) : moment.utc(endDate);
        return end.diff(start, datePart);
    };
    /**
     * returns a pretty name for given format and value
     * @param format {string|"technologyGroupRemoval"|"weight"|"usgResult"|"devices"|"placements"|"animals"|"employees"|"dictionaries"|"groups"|"settings"|"sales"|"medicines"} nazwa formatowania zgodna z getKeyValueLogDict
     * @param value {string|number}
     * @returns {string|number|string|*}
     * @constructor
     */
    alasql.fn.FMT = (format, value) => {
        switch (format) {
            case "technologyGroupRemoval": {
                return removeReasonFormatter({ reason: value }, false);
            }
            case "usgResult": {
                switch (value) {
                    case USG_STATE.NEGATIVE:
                        return t("events.usgEvent.negative");
                    case USG_STATE.POSITIVE:
                        return t("events.usgEvent.positive");
                    case USG_STATE.REPEAT:
                        return t("events.usgEvent.toRepeat");
                    case USG_STATE.MISSING:
                        return t("events.usgEvent.missing");
                    default:
                        return null;
                }
            }
            case "weight": {
                return convertWeightUnitTo(value, { rawValue: true, acceptNil: true, fixed: 4, unit: UnitTypes.MEDIUM });
            }
            case "retentionReason": {
                switch (value) {
                    case 0: return t("noNextInsemination");
                    case 1: return t("sale");
                    case 2: return t("fall");
                    case 3: return t("animalDocuments.removeReason.repetition");
                    default: return value;
                }
            }
            default: {
                if (has(dictionaries, format)) {
                    return get(dictionaries, format)?.[value] ?? value;
                }
                break;
            }
        }
        return value;
    };
    alasql.fn.GENERATE_DATES = (startDate, endDate) => {
        const dates = [];
        let date = localMomentToUTC(moment(startDate));
        const end = localMomentToUTC(moment(endDate === null ? undefined : endDate).endOf("day"));
        while (date <= end) {
            dates.push({ date: +date });
            date = date.add(1, "d");
        }
        return dates;
    };
    alasql.fn.GET_LEVEL = (PlcmntID, level) => {
        const hierarchy = getBuildingsHierarchy(PlcmntID);
        return hierarchy[level] || null;
    };
};

const callQuery = (query, params) => {
    const queryKey = `alasql.query (runId: ${myID()})`;
    console.log("***calling query***");
    console.log(query);
    console.log("***end of query***");
    console.time(queryKey);
    const data = alasql(query, params);
    console.timeEnd(queryKey);
    console.log(data);
    return data;
};
callQuery.async = async (query, params) => {
    const queryKey = `alasql.query (runId: ${myID()}, async)`;
    console.log("***calling query async***");
    console.log(query);
    console.log("***end of query async***");
    console.time(queryKey);
    return alasql.promise(query, params).then((data) => {
        console.log(data);
        return data;
    }).finally(() => {
        console.timeEnd(queryKey);
    });
};

function setOptions() {
    alasql.options.cache = false;
}

export const getQueryCaller = createSelector([getAlaSQLTables, getKeyValueLogDict, getBuildingsMap], (tables, dictionaries, getBuildingsMap) => {
    // set custom options
    setOptions();
    // create custom functions
    createCustomFunctions({ dictionaries, getBuildingsMap });
    // create custom aggregators
    createAggregators({ dictionaries });
    // set js objects as tables
    createTables(tables);
    // add query caller to global window
    window.__SQL__ = callQuery;
    return callQuery;
});