import i18next from "i18next";
import { get, memoize } from 'lodash';
import moment from "moment";
import "moment-timezone";
import { checkIfUserIsService } from "../utils/NewRolesUtils";
import store from "../store/store";
import { reportStatus, reportType } from "../constans/reports";
import { getDaysForRepeatedInsemination, getUnitSystem } from "../utils/SettingsUtils";
import { invokeApig } from "../libs/awsLib";
import { notify } from "reapop";
import Paths from "../api/paths";
import localReportDataDB from "../database/localReportDataDB";
import reportsDB from "../database/reportsDB";
import { processCSV } from "../utils/FileUtils";
import { createLocalReport } from '../utils/RaportsUtils';
import { getUtilResults } from "../utils/results/GeneralResultsUtils";

export function createReport(dispatch, w, postMessageData, type, { start = 0, stop = 0, forDay = 0 } = {}, farmID) {
    let report = createLocalReport(farmID, type, { startDate: start, endDate: stop, day: forDay });
    reportsDB.insertNewReport(report);
    dispatch(getAllReports(farmID));
    w.onmessage = async event => {
        const { data: { status, data } } = event;
        if (status === "error") {
            reportsDB.updateReportStatus(report.FeturaQuery.FeturaQTime, reportStatus.FAILED);
            dispatch(getAllReports(farmID));
        } else {
            reportsDB.updateReportStatus(report.FeturaQuery.FeturaQTime);
            localReportDataDB.saveLocalData(report.FeturaQuery.FeturaQTime, data);
            dispatch(getAllReports(farmID));
        }
    };
    setTimeout(() => {
        const oneDayReports = [reportType.STRUCTURE, reportType.IDLE, reportType.RANKING,reportType.GILTS];
        const isOneDayReport = oneDayReports.includes(type);
        const workerData = {
            ...postMessageData,
            FarmID: farmID,
            isService: checkIfUserIsService(),
            sub: store.getState().user.attributes.sub,
            user: store.getState().user.user,
            unitSystem: getUnitSystem(),
            cycleSettings: get(store.getState(), "settings.general.SetData.Settings.Cycle", {}),
            utilResults: getUtilResults(),
            herdSettings: get(store.getState(), "settings.herdSettings.SetData", {})
        }
        if (!isOneDayReport) {
            workerData.start = start;
            workerData.stop = stop;
        } else {
            workerData.start = +moment(forDay).startOf("day");
            workerData.stop = +moment(forDay).endOf("day");
        }
        w.postMessage(workerData);
    }, 1000 * 12) // timeout na 12s, aby miec pewnosc, ze loki sie zapisze
}

/**
 * Funkcja wyszykuje ostatnie inseminacje i zwraca planowane porody dla swin dla danej farm
 * @param farmID
 * @param start
 * @param stop
 * @returns {Function}
 */
export function getHistoryBirthData(farmID, start, stop) {
    return (dispatch) => {
        createReport(dispatch, new Worker(new URL("../workers/raports/birthHistory.worker.js", import.meta.url)), {}, reportType.BIRTH, {
            start: start,
            stop: stop
        }, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.birthReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(notify(notifi));
    }
}

export function getHistoryOfEvents(farmID, start, stop) {
    return (dispatch) => {
        createReport(dispatch, new Worker(new URL("../workers/raports/historyOfEvents.worker.js", import.meta.url)), {}, reportType.HISTORY_OF_EVENTS, { start, stop }, farmID);
        dispatch(notify({
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.historyOfEvents"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        }));
    }
}

export function getCommentsReportData(farmID, start, stop) {
    return (dispatch) => {
        createReport(dispatch, new Worker(new URL("../workers/raports/comments.worker.js", import.meta.url)), {}, reportType.COMMENTS, { start, stop }, farmID);
        dispatch(notify({
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.comments"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        }));
    }
}

/**
 * Funkcja wylicza na podstawie eventów w danym czasie zużycie konretnych leków
 * @param farmID
 * @param startDate
 * @param endDate
 * @returns {Function}
 */

export function getMedicineConsumptionData(farmID, startDate, endDate) {
    return (dispatch) => {
        createReport(dispatch, new Worker(new URL("../workers/raports/medicineConsumption.worker.js", import.meta.url)), {}, reportType.MEDICINE_CONSUMPTION, {
            start: startDate,
            stop: endDate
        }, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.medicineConsumptionReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(notify(notifi));
    }
}

/**
 *
 * @param farmID
 * @param day
 * @returns {Function}
 */
export function getIdleDaysData(farmID, day) {
    return (dispatch) => {
        createReport(dispatch, new Worker(new URL("../workers/raports/idleDays.worker.js", import.meta.url)), {}, reportType.IDLE, { forDay: day }, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.idleDaysReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(notify(notifi));
    }
}


export function getSalesData(farmID, startDate, endDate) {
    return (dispatch) => {
        createReport(dispatch, new Worker(new URL("../workers/raports/sales.worker.js", import.meta.url)), {}, reportType.SALES, {
            start: startDate,
            stop: endDate
        }, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.salesReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(notify(notifi));
    }
}

export function getTreatmentData(farmID, startDate, endDate) {
    return function (dispatch) {
        createReport(dispatch, new Worker(new URL("../workers/raports/treatment.worker.js", import.meta.url)), {}, reportType.TREATMENT, {
            start: startDate,
            stop: endDate
        }, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.treatmentReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(notify(notifi));
    }
}

export function getSeparationData(farmID, startDate, endDate) {
    return function (dispatch) {
        createReport(dispatch, new Worker(new URL("../workers/raports/separations.worker.js", import.meta.url)), {}, reportType.SEPARATION, {
            start: startDate,
            stop: endDate
        }, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.separationReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(notify(notifi));
    }
}
export function getSeparationGiltsData(farmID, startDate, endDate) {
    return function (dispatch) {
        createReport(dispatch, new Worker(new URL("../workers/raports/separationGilts.worker.js", import.meta.url)), {}, reportType.GILTS_SEPARATION, {
            start: startDate,
            stop: endDate
        }, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.separationReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(notify(notifi));
    }
}
//todo: pozmieniac aby dzialalo na employee a nie na insID
export function getInseminationsData(farmID, start, stop) {
    return (dispatch) => {
        let daysBetweenInseminations = getDaysForRepeatedInsemination();
        createReport(dispatch, new Worker(new URL("../workers/raports/inseminations.worker.js", import.meta.url)), { daysBetweenInseminations }, reportType.INSEMINATION, {
            start: start,
            stop: stop
        }, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.inseminationsReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(notify(notifi));
    }
}

/**
 *
 * @param farmID
 * @param day - stan na podany dzień
 * @returns {Function}
 */
export function getHerdStructureData(farmID, day) {
    return (dispatch) => {
        createReport(dispatch, new Worker(new URL("../workers/raports/herdStructure.worker.js", import.meta.url)), {}, reportType.STRUCTURE, { forDay: day }, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.herdStructureReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(notify(notifi));
    }
}

export function getRankingData(farmID, day) {
    return (dispatch) => {
        createReport(dispatch, new Worker(new URL("../workers/raports/ranking.worker.js", import.meta.url)), {}, reportType.RANKING, { forDay: day }, farmID);
        dispatch(notify({
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.animalRanking"),
            status: "success",
            dismissible: true,
            dismissAfter: 5000
        }))
    };
}

export function getSelectionData(farmID, start, stop) {
    return function (dispatch) {
        createReport(dispatch, new Worker(new URL("../workers/raports/selection.worker.js", import.meta.url)), {}, reportType.SELECTION, {
            start: start,
            stop: stop
        }, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.selectionReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(notify(notifi));
    }
}

export function getDeadData(farmID, start, stop) {
    return function (dispatch) {
        createReport(dispatch, new Worker(new URL("../workers/raports/deaths.worker.js", import.meta.url)), {}, reportType.DEAD, {
            start: start,
            stop: stop
        }, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.deadReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(notify(notifi));
    }
}
export function getDeadGiltsData(farmID, start, stop) {
    return function (dispatch) {
        createReport(dispatch, new Worker(new URL("../workers/raports/deathGilts.worker.js", import.meta.url)), {}, reportType.GILTS_DEAD, {
            start: start,
            stop: stop
        }, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.deadGiltsReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(notify(notifi));
    }
}
export function getGiltsData(farmID, day) {
    return function (dispatch) {
        createReport(dispatch, new Worker(new URL("../workers/raports/gilts.worker.js", import.meta.url)), {}, reportType.GILTS, { forDay: day }, farmID);
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.giltsReport"),
            status: 'success',
            dismissible: true,
            dismissAfter: 5000
        };
        dispatch(notify(notifi));
    }
}
export function getInseminationListData(farmID, start, stop) {
    return function (dispatch) {
        createReport(
            dispatch,
            new Worker(new URL("../workers/raports/listOfInseminations.worker.js", import.meta.url)),
            {},
            reportType.LIST_OF_INSEMINATION,
            {
                start: start,
                stop: stop,
            },
            farmID
        );
        const notifi = {
            title: i18next.t("reportNotifications.title"),
            message: i18next.t("reportNotifications.inseminationsListReport"),
            status: "success",
            dismissible: true,
            dismissAfter: 5000,
        };
        dispatch(notify(notifi));
    };
}

export function getAllReports(farmID) {
    return function (dispatch) {
        dispatch({
            type: "GET_ALL_REPORTS",
            payload: reportsDB.getAllReports(farmID)
        })
    }
}

export function listAthenaReports(FarmID, ClientID, LocalUserID, forceFetch = false) {
    return function (dispatch) {
        dispatch({
            type: "LIST_ATHENA_REPORTS",
            payload: invokeApig({
                ...Paths.getReports({ clientID: ClientID, localUserID: LocalUserID }),
                queryParams: { DtaModTime: reportsDB.getModificationTime().DtaModTime },
                forceFetch
            })
        }).then((res) => {
            if (res.value.items.length > 0) {
                reportsDB.insertAthenaReports(res.value.items);
                dispatch(getAllReports(FarmID));
            }
        })
    }
}

async function _getReportData(LocalQTime, CreatorID, ClientID, LocalUserID) {
    let resultGetReport = await invokeApig({
        ...Paths.getReportData({ clientID: ClientID, localUserID: LocalUserID }),
        body: {
            LocQTime: LocalQTime,
            CreatorID
        }
    });
    let path = resultGetReport.ResourceUrl;
    let fileRes = await fetch(path);
    let text = await fileRes.text();
    return await processCSV(text);
}

export const getReportData = memoize(_getReportData);

export async function downloadReportFile(LocalQTime, CreatorID, fileName) {
    let reportData = await invokeApig({
        ...Paths.getReportData(),
        body: {
            LocQTime: LocalQTime,
            CreatorID,
            fileName
        }
    });
    const link = document.createElement("a");
    link.href = reportData.ResourceUrl;
    link.click();
}