import { isArray, isFunction } from "lodash";
import Paths from "../api/paths";
import { getCommissioningProtocol as getCommissioningProtocolAPI } from "../api/service/getCommissioningProtocol";
import { SettingTypes } from "../constans/settingTypes";
import settingsDB from "../database/settingsDB";
import i18n from "../i18n";
import { invokeApig } from "../libs/awsLib";
import { myID } from "../libs/generateID";
import { getClimateCurvesSK3, getFeedingCurves, getFeedingSchedules } from "../selectors/settingsSelector";
import store from "../store/store";
import { cutUselessPrefixes } from "../utils/DevicesUtils";
import { loadingNotification, onErrorNotification, onSuccessNotification } from "../utils/ReapopNotificationUtils";
import { prepareAnimalParameters } from "./animalParametersActions";

const t = (val) => i18n.t(val);

export function getSettings(ClientID, farmID, forceFetch = false, { fetchResults = false } = {}) {
    return function (dispatch) {
        let user = store.getState().user.user;
        farmID = farmID || store.getState().location.farm;
        console.log(farmID);
        dispatch({
            type: "GET_SETTINGS_DATA",
            payload: invokeApig({
                ...Paths.listSettings({ clientID: ClientID }),
                queryParams: { DtaModTime: settingsDB.getModificationTime(farmID).DtaModTime },
                forceFetch
            }).then((res) => {
                let l = {};
                Object.assign(l, res);
                //console.log("res", l);
                if (res.length > 0) {
                    //console.log("wchodze do ifa");
                    let tmp = [];
                    Object.assign(tmp, res);
                    // console.log("tmp", tmp);
                    settingsDB.insertIntoSettings(tmp);
                }
                if (farmID) {
                    dispatch({
                        type: "LIST_ALL_SETTINGS",
                        payload: settingsDB.getAllSettings(farmID),
                        meta: {
                            user
                        }
                    });
                    if (fetchResults) {
                        // dispatch(prepareSowsResults());
                        dispatch(prepareAnimalParameters());
                    }
                }
            }).catch(error => {
                if (farmID) {
                    dispatch({
                        type: "LIST_ALL_SETTINGS",
                        payload: settingsDB.getAllSettings(farmID),
                        meta: {
                            user
                        }
                    });
                }
            })
        });
    };
}

/**
 *
 * @param values - pojedynczy setting lub tablica (?)
 * @param onSuccess
 * @param onFailure
 * @returns {Function}
 */
export function createSettingDynamoDB(values, onSuccess, onFailure) {
    console.log("createSettingDynamoDB", values, onSuccess, onFailure);
    let setting = cutUselessPrefixes(values, ["$loki", "meta"]);
    return function (dispatch) {
        let notification = getNotification(setting);
        if (notification) {
            notification = loadingNotification("Dodawanie", notification);
        }
        dispatch({
            type: "CREATE_SETTING", payload: invokeApig({
                ...Paths.createSetting(),
                body: setting
            })
        }).then(res => {
            notification && onSuccessNotification("Dodano pomyślnie", notification);
            if (isFunction(onSuccess)) {
                try {
                    onSuccess(res);
                } catch (e) {
                    console.error(e);
                }
            }
        }).catch(err => {
            console.error(err);
            notification && onErrorNotification("Błąd dodawania", notification);
            if (isFunction(onFailure)) {
                try {
                    onFailure(err);
                } catch (e) {
                    console.error(e);
                }
            }
        });
        // dispatch({type: "CREATE_SETTING", payload: setting})
    };
}

/**
 * Funkcja updatująca ustawienia - notyfikacje są dodawane automatycznie
 * @param values - ustawienie pojedyncze / lub (?) array
 * @param onSuccess - funkcja wykonujaca się przy pomyslnej aktualizacji
 * @param onFailure - funkcja wykonujaca się przy bledzie aktualizacji
 * @returns {Function}
 */
export function updateSettingDynamoDB(values, onSuccess, onFailure) {
    console.log("updateSettingDynamoDB", values, onSuccess, onFailure);
    let setting = cutUselessPrefixes(values, ["$loki", "meta"]);
    let notification = getNotification(setting);
    if (notification) {
        notification = loadingNotification(isDelete(setting) ? "Usuwanie" : "Aktualizacja", notification);
    }
    return function (dispatch) {
        dispatch({
            type: "UPDATE_SETTING", payload: invokeApig({
                ...Paths.updateSetting(),
                body: setting
            })
        }).then(res => {
            notification && onSuccessNotification(isDelete(setting) ? "Usunięto pomyślnie" : "Zaktualizowano pomyślnie", notification);
            if (isFunction(onSuccess)) {
                try {
                    onSuccess(res);
                } catch (e) {
                    console.error(e);
                }
            }

        }).catch(err => {
            console.error(err);
            notification && onErrorNotification(isDelete(setting) ? "Błąd usuwania" : "Błąd dodawania", notification);
            if (isFunction(onFailure)) {
                try {
                    onFailure(err);
                } catch (e) {
                    console.error(e);
                }
            }
        });
    };
}

function getNotification(setting) {
    if (isArray(setting) && setting.length > 1) {
        return t('settings.settings');
    } else {
        let type = isArray(setting) ? setting[0].SetType : setting.SetType;
        switch (type) {
            case SettingTypes.FEEDING_CURVE:
                return t("feedingCurve");
            case SettingTypes.FEEDING_SCHEDULE:
                return t("notificationsSettings.feedingSchedule");
            case SettingTypes.FEEDING_FORAGE:
                return t("forage");
            case SettingTypes.FARM_MAP:
                return t("map");
            case SettingTypes.GENERAL:
                return t("notificationsSettings.mainSettings");
            case SettingTypes.NOTIFICATIONS:
                return t("notifications.notifications");
            case SettingTypes.LAYOUT:
                return t("notificationsSettings.dashBoard");
            case SettingTypes.YEARLY_PLANS:
                return t("notificationsSettings.annualPlan");
            case SettingTypes.ECONOMY:
                return t("notificationsSettings.sectorWithForage");
            default:
                return null;
        }
    }

}

function isDelete(setting) {
    if (isArray(setting)) {
        return !!setting[0].DtaDelTime;
    } else {
        return !!setting.DtaDelTime;
    }

}

export function getCommissioningProtocol() {
    return function (dispatch) {
        dispatch({
            type: "GET_COMMISSIONING_PROTOCOL",
            payload: getCommissioningProtocolAPI
        });
    };
}

export function restoreFeedingCurveFromGateway(gatewayCurve) {
    return function (dispatch, getState) {
        const state = getState();
        const { user: { user: { ClientID } }, location: { farm } } = state;
        const feedingCurves = getFeedingCurves(state) || [];
        let curve = feedingCurves.find(item => item.SetData.Index === gatewayCurve.number - 1);
        if (curve) {
            let clone = JSON.parse(JSON.stringify(curve));
            clone.DtaModTime = +new Date();
            clone.SetData.InseminationJumpTo = gatewayCurve.insemination;
            clone.SetData.Name = gatewayCurve.name || curve.SetData.Name || i18n.t("feedingCurve") + " " + gatewayCurve.number;
            clone.SetData.ParturitionJumpTo = gatewayCurve.parturition;
            clone.SetData.Type = gatewayCurve.type;
            clone.SetData.Days = gatewayCurve.days.map((d, index) => ({
                ForageAmount: d.amount,
                DailyPlan: d.schedule - 1,
                ForageType: d.forage - 1,
                Notify: d.notify
            }));
            dispatch({
                type: "UPDATE_CURVE_OFFLINE",
                payload: clone
            });
        } else {
            const newCurve = {
                ClientID,
                DtaModTime: +new Date(),
                FarmID: farm,
                SetData: {
                    Days: gatewayCurve.days.map(d => ({
                        ForageAmount: d.amount,
                        DailyPlan: d.schedule - 1,
                        ForageType: d.forage - 1,
                        Notify: d.notify
                    })),
                    Index: gatewayCurve.number - 1,
                    InseminationJumpTo: gatewayCurve.insemination,
                    Name: gatewayCurve.name || i18n.t("newSettings.chainFeeding.scheduleAnalog.title") + " " + gatewayCurve.number,
                    ParturitionJumpTo: gatewayCurve.parturition,
                    Type: gatewayCurve.type
                },
                SetID: myID(),
                SetType: "C"
            };
            dispatch({
                type: "ADD_NEW_CURVE_OFFLINE",
                payload: newCurve
            });
        }
    };
}

export function restoreFeedingScheduleFromGateway(gatewaySchedule) {
    return function (dispatch, getState) {
        const state = getState();
        const { user: { user: { ClientID } }, location: { farm } } = state;
        const schedules = getFeedingSchedules(state) || [];
        let schedule = schedules.find(item => item.SetData.Index === gatewaySchedule.number - 1);
        if (schedule) {
            const clone = JSON.parse(JSON.stringify(schedule));
            clone.DtaModTime = +new Date();
            clone.SetData.Name = gatewaySchedule.name || schedule.SetData.Name || i18n.t("newSettings.chainFeeding.scheduleAnalog.title") + " " + gatewaySchedule.number;
            clone.SetData.Doses = gatewaySchedule.doses.map(d => ({
                Percent: d.percent,
                Start: d.start,
                Stop: d.stop,
            }));
            dispatch({
                type: "UPDATE_SCHEDULE_OFFLINE",
                payload: clone
            });
        } else {
            const newSchedule = {
                ClientID,
                DtaModTime: +new Date(),
                FarmID: farm,
                SetData: {
                    Doses: gatewaySchedule.doses.map(d => ({
                        Percent: d.percent,
                        Start: d.start,
                        Stop: d.stop,
                    })),
                    Index: gatewaySchedule.number - 1,
                    Name: gatewaySchedule.name || i18n.t("feedingSchedule") + " " + gatewaySchedule.number
                },
                SetID: myID(),
                SetType: "S"
            };
            dispatch({
                type: "ADD_NEW_SCHEDULE_OFFLINE",
                payload: newSchedule
            });
        }
    };
}

export function restoreClimateCurveFromGateway(gatewayCurve) {
    return function (dispatch, getState) {
        const state = getState();
        let climateCurves = getClimateCurvesSK3(state) || [];
        let curve = climateCurves.find(item => item.SetData.Index === gatewayCurve.number - 1);
        console.log(curve);
        function convertStages() {
            return gatewayCurve.stages.map(stage => ({
                Days: stage.days,
                RequestedTemperatureEnd: stage.requestedTemperatureEnd,
                RequestedTemperatureStart: stage.requestedTemperatureStart,
                WaterMatTemperature: stage.waterMatTemperature,
                MinimumVentilation: stage.minimumVentilation,
                ElectricMatLevel: stage.electricMatLevel
            }));
        }
        if (curve) {
            let clone = JSON.parse(JSON.stringify(curve));
            clone.DtaModTime = +new Date();
            curve.SetData.Name = gatewayCurve.name || curve.SetData.Name || i18n.t("climateCurve") + " " + gatewayCurve.number;
            curve.SetData.Stages = convertStages();
            curve.SetData.Type = gatewayCurve.matType === 1 ? "WAT" : "ELE";
            dispatch({
                type: "UPDATE_CLIMATE_CURVE_OFFLINE",
                payload: curve
            });
        } else {
            const { user: { user: { ClientID } }, location: { farm } } = state;
            let newCurve = {
                ClientID,
                DtaModTime: +new Date(),
                FarmID: farm,
                SetData: {
                    Index: gatewayCurve.number - 1,
                    Name: gatewayCurve.name || i18n.t("climateCurve") + " " + gatewayCurve.number,
                    Stages: convertStages(),
                    Type: gatewayCurve.matType === 1 ? "WAT" : "ELE"
                },
                SetID: myID(),
                SetType: "CC3"
            };
            dispatch({
                type: "ADD_NEW_CLIMATE_CURVE_OFFLINE",
                payload: newCurve
            });
        }
    };
}