import React from "react";
import "./_alink.scss"
import PropTypes from "prop-types"
import {Link} from "react-router-dom";

export default class ALink extends React.Component {

    getClassName() {
        const {color, bolded, uppercase, disabled, additionalClass} = this.props;
        let className = "fetura-link";
        className += ` ${color}`;
        if (bolded) className += " fw-bold";
        if (uppercase) className += " text-uppercase";
        if (disabled) className += " disabled";
        if (additionalClass) className += ` ${additionalClass}`;
        return className;
    }

    render() {
        const {children, onClick, href} = this.props;
        if (href) {
            return (
                <Link to={href} className={this.getClassName()}>{children}</Link>
            )
        }
        return (
            <a className={this.getClassName()} onClick={onClick}>{children}</a>
        );
    }

}

ALink.propTypes = {
    href: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.oneOf(["primary", "secondary", "success", "info", "warning", "error"]),
    bolded: PropTypes.bool,
    uppercase: PropTypes.bool,
    disabled: PropTypes.bool,
};

ALink.defaultProps = {
    color: "success",
    bolded: false,
    uppercase: true
};
