export const ParamTypes = {
    AMOUNT: "AMOUNT",
    WEIGHT: "WEIGHT",
    TIME: "TIME",
    DAYS: "DAYS",
    ANIMAL: "ANIMAL",
    REASON: "REASON",
    AGE: "AGE",
    RESULT: "RESULT",
    BOOLEAN: "BOOLEAN",
    PARTURITION_LEVEL: "PARTURITION_LEVEL",
    INDEX: "INDEX",
    PERCENT: "PERCENT",
    PRICE: "PRICE",
    TECHNOLOGY_GROUP_REMOVAL: "TECHNOLOGY_GROUP_REMOVAL",
    SECTOR_TYPE: "SECTOR_TYPE",
    MEDICINE: "MEDICINE"
};