import React, { useCallback, useMemo, useRef, useState } from "react";
import { onBeforeRenderShowLocation } from "../farm-map/utils";
import FarmMapComponent from "../farm-map/FarmMapComponent";
import Button from "../basics/button/Button";
import ReactDOM from "react-dom";
import "./_find-animal.scss"
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { roleSelector } from "../../selectors/roleSelector";
import { useSelector } from "react-redux";
import { getDevices } from "../../selectors/deviceSelector";
import diodeControl from "../farm-map/components/device-controls/control-panel/DiodeControl";
import { useTranslation } from "react-i18next";
import useFarmMapInfo from "../../hooks/useFarmMapInfo";
import { useInterval } from "../../hooks/useInterval";
import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import useShadowState from "../../hooks/useShadowState";
import {normalizePlacement} from "../../utils/LocationUtils";
import { hasPlcmntID } from "../../utils/DevicesUtils";

/*
* DATA HOOKS
*/

// get info if enabling a diode is a thing in current placement
const useDiodeData = (PlcmntID, show) => {

    const _emptyArray_ = useRef([]).current;

    const _emptyObj_ = useRef({}).current;

    const [time, setTime] = useState(Date.now());

    const intervalFunc = useCallback(() => {
        setTime(Date.now());
    }, [setTime])

    const roleUtils = useSelector(roleSelector);

    useInterval(intervalFunc, show ? 5000 : 0);

    const allDevices = useSelector(getDevices);

    const devices = useMemo(() => allDevices.filter((d) => hasPlcmntID(d, PlcmntID) && d.DevType === DevTypes.DISPENSER_NRF), [allDevices, PlcmntID]);

    useShadowState(show ? devices : _emptyArray_);

    const shadowSelector = useMemo(() => (state) => {
        if (!show) return _emptyObj_;
        const transformData = (shadow) => {
            if (!shadow || !shadow?.diode?.colour || !shadow?.diode?.time) {
                return {
                    LED: null
                }
            }
            const { diode } = shadow;
            const now = Date.now();
            const show = now < (diode.setTime + diode.time);
            return {
                LED: show ? {
                    Colour: diode.colour,
                    Blink: diode.blink,
                    Time: diode.time
                } : null
            }
        }
        const data = {};
        devices.forEach(({ DevID }) => {
            data[DevID] = transformData(state.shadows.shadows.get(DevID));
        })
        return data;
    }, [devices, show, _emptyObj_]);

    const data = useSelector(shadowSelector);

    const { t } = useTranslation();

    return useMemo(() => diodeControl({
        t,
        devices: devices.map((device) => ({ device })),
        roleUtils,
        placementId: PlcmntID,
        data,
        time
    }, "device"), [t, devices, roleUtils, PlcmntID, time, data]);
}

// modal bloat: show, open, hide
const useModalControls = () => {
    const [show, setShow] = useState(false);
    const openModal = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setShow(true)
    }, []);

    const hideModal = useCallback((e) => {
        e?.preventDefault?.();
        e?.stopPropagation?.();
        setShow(false)
    }, []);

    return [show, openModal, hideModal];
}

// gets variables needed for farm map view
const useFarmData = () => {
    const onBeforeRender = useCallback((params) => {
        return onBeforeRenderShowLocation(params);
    }, []);

    const onElementClick = useCallback(() => {
        // empty handler to make sure user cannot change selected item
    }, []);

    const { hasFarmMap } = useFarmMapInfo();

    return [onBeforeRender, onElementClick, hasFarmMap];
}

/*
* COMPONENTS
*/
// popover container for a map, rendered inside view-container-modal so we can use relative top/left positioning
const Popover = React.memo(({ children, position, hide }) => {

    const ref = useRef(null);

    const style = useMemo(() => ({
        top: position.y,
        left: position.x
    }), [position]);

    useOnClickOutside(ref, hide);
    let index = document.getElementsByClassName("view-container-modal").length - 1 //chose closest element
    return (
        ReactDOM.createPortal(
            <div ref={ref} style={style} className={"fetura--find-animal__popover"}>
                {children}
            </div>
            ,
            document.getElementsByClassName("view-container-modal")[index] || document.createElement("div"))
    )
});

const FindAnimal = ({ animal: { PlcmntID } }) => {

    const buttonRef = useRef(null);
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [show, openModal, hideModal] = useModalControls();
    const [onBeforeRender, onElementClick, hasFarmMap] = useFarmData();

    const realPlcmntID = useMemo(() => {
        return normalizePlacement(PlcmntID);
    }, [PlcmntID])

    const diode = useDiodeData(realPlcmntID, show);

    // button position needed to position popover correctly
    const buttonPosition = useMemo(() => {
        if (!show || !buttonRef.current) return { x: 0, y: 0 };
        const bcr = buttonRef.current.getBoundingClientRect();
        return { x: bcr.x, y: bcr.y };
    }, [buttonRef, show]);

    // make button not clickable if dont have a map or a diode (mobile view has no map btw)
    const disableButton = !hasFarmMap && !diode.show;

    // turn diode on, make button loading, hide modal etc.
    const handleToggleLED = useCallback((e) => {
        setLoading(true);
        diode.submit()
            .then(() => {
                diode?.__alreadyFound__ && hideModal();
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setLoading(false);
            })
    }, [diode, hideModal, setLoading]);

    if (disableButton) return null;

    return (
        <>
            <Button disabled={disableButton} buttonRef={buttonRef}
                buttonStyle={"text"} className={"round"} type={"button"}
                icon={<i className={"fas fa-location-dot"} />}
                onClick={openModal} />
            {
                show &&
                <Popover hide={hideModal} position={buttonPosition} diode={diode}>
                    <div className={"fetura--find-animal__exit-container"}>
                        <Button buttonStyle={"round"} className={"text"} type={"button"} onClick={hideModal}
                            icon={<i className={"fas fa-times"} />}
                            title={t("cancel")}
                        />
                    </div>
                    <div>
                        <FarmMapComponent useMinimalVersion portalHeight={300} portalWidth={300}
                            onElementClick={onElementClick}
                            selectedId={realPlcmntID} useShadow={false}
                            useAlarm={false}
                            onBeforeRender={onBeforeRender} />
                    </div>
                    {
                        diode.show &&
                        <Button isLoading={loading} className={"m-0 w-100"} buttonColor={"success"} type={"button"}
                            onClick={handleToggleLED}
                            title={diode.icon.description}
                            text={diode.icon.name}
                            icon={<i className={diode.icon.iconName} />} />
                    }
                </Popover>
            }
        </>
    )
}

export default React.memo(FindAnimal);
