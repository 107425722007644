import * as EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import { cloneDeep, findIndex, get, isFunction, isNil, last, set } from "lodash";
import i18next from "i18next";
import { USG_STATE } from "../../../../constans/eventTypes";
import { getPigBalance } from "../../../../utils/EventUtils";
import { getEventsFromRow } from "../../../../utils/AnimalDocumentsUtils";
import { checkIfUserIsService } from "../../../../utils/NewRolesUtils";
import moment from "moment";
import animalsDB from "../../../../database/animalsDB";
import {
    ValidationDefaultValues
} from "../../../../views/new-settings-view/validations/validation-values/ValidationDefaultValues";
import { getMaxDelayForBirth, getTimeFromInseminationToPartuition } from "../../../../utils/SettingsUtils";

const eventFunctionHandler = {
    [EventTypes.INSEMINATION]: checkInsemination,
    [EventTypes.USG]: checkUSG,
    [EventTypes.PARTURITION]: checkParturition,
    [EventTypes.FALL_PIGLETS]: checkFallPiglets,
    [EventTypes.SEPARATION_TO_MOMMY]: checkSeparationToMommy,
    [EventTypes.MOMMY]: checkMommy,
    [EventTypes.SEPARATION]: checkSeparation,
    [EventTypes.ACTIVE_NIPPLES]: checkActiveNipples,
    [EventTypes.CASTRATION]: checkWeighting,
    [EventTypes.NO_PREGNANCY]: checkNoPregnancy,
};

export const findRowFromCycle = (cycleTable, event) => {
    return cycleTable.find((row) => row[event.EvCode].find((e) => e.EvID === event.EvID));
};

/**
 * funkcja walidująca brak ciąży
 * @param path
 * @param event
 * @param value
 */
function checkNoPregnancy(path, event, value) {
    const errors = {};
    if (isNil(value)) {
        errors.result = false;
        errors.message = i18next.t("required");
    }
    return errors;
}

/**
 * funkcja walidująca ważenie
 * @param path
 * @param event
 * @param value
 */
function checkWeighting(path, event, value) {
    const errors = {};
    const field = last(path.split("."));
    if (isNil(value)) {
        errors.result = false;
        errors.message = i18next.t("required");
    }
    if (field === "Weight" && value < 1) {
        errors.result = false;
        errors.message = i18next.t("errors.lessVal", { count: 1 });
    }
    return errors;
}

/**
 * funkcja walidująca aktywne sutki
 * @param path
 * @param event
 * @param value
 */
function checkActiveNipples(path, event, value) {
    const errors = {};
    const field = last(path.split("."));
    if (isNil(value)) {
        errors.result = false;
        errors.message = i18next.t("required");
    }
    if (field === "Nipples" && value < 1) {
        errors.result = false;
        errors.message = i18next.t("errors.lessVal", { count: 1 });
    }
    return errors;
}

/**
 * funkcja walidująca odsad
 * @param path
 * @param event
 * @param value
 * @param cycleTable
 * @param validations
 */
function checkSeparation(path, event, value, cycleTable, validations) {
    const errors = {};
    const field = last(path.split("."));
    if (isNil(value)) {
        errors.result = false;
        errors.message = i18next.t("required");
    }
    if (field === "PiCnt" && value < 1) {
        errors.result = false;
        errors.message = i18next.t("errors.noLess", { number: 1 });
    }
    if (field === "PiWeight") {
        if (value < 1) {
            errors.result = false;
            errors.message = i18next.t("errors.lessVal", { count: 1 });
        }
    } else {
        let cloned = cloneDeep(event);
        set(cloned, path, value);
        const balance = getPigletBalanceAfterUpdate(cycleTable, cloned);
        if (get(validations, "separationWithMorePigletsThanAnimalHas", ValidationDefaultValues.separationWithMorePigletsThanAnimalHas) && balance < 0) {
            errors.result = false;
            errors.message = i18next.t("errors.negativePigletBalance", { balance });
        }
    }
    // if (balance > 0) {
    //     const indexOfSeparation = findIndex(cycleTable, (row) => row[EventTypes.SEPARATION].some((ev) => ev.EvID === event.EvID));
    //     if (indexOfSeparation < cycleTable.length - 1) {
    //         const hasInsemination = cycleTable[indexOfSeparation + 1][EventTypes.INSEMINATION].length > 0;
    //         if (hasInsemination) {
    //             errors.result = false;
    //             errors.message = i18next.t("errors.hasInseminationAndPositiveBalanceAfterSeparations");
    //         }
    //     }
    // }
    return errors;
}

/**
 * funkcja walidująca upadek prosiąt
 * @param path
 * @param event
 * @param value
 * @param cycleTable
 */
function checkFallPiglets(path, event, value, cycleTable) {
    const errors = {};
    const field = last(path.split("."));
    if (isNil(value)) {
        errors.result = false;
        errors.message = i18next.t("required");
    }
    if (field === "Weight" && value < 1) {
        errors.result = false;
        errors.message = i18next.t("errors.lessVal", { count: 1 });
    }
    if (field === "Piglets" && value <= 0) {
        errors.result = false;
        errors.message = i18next.t("errors.noLessOrEq0");
    }
    let cloned = cloneDeep(event);
    set(cloned, path, value);
    const balance = getPigletBalanceAfterUpdate(cycleTable, cloned);
    if (balance < 0) {
        errors.result = false;
        errors.message = i18next.t("errors.negativePigletBalance", { balance });
    }
    return errors;
}

/**
 * funkcja walidująca odsadzenie do mamki
 * @param path
 * @param event
 * @param value
 * @param cycleTable
 */
function checkSeparationToMommy(path, event, value, cycleTable) {
    const errors = {};
    const field = last(path.split("."));
    if (isNil(value)) {
        errors.result = false;
        errors.message = i18next.t("required");
    }
    if (field === "PiCnt" && value <= 0) {
        errors.result = false;
        errors.message = i18next.t("errors.lessVal", { count: 0 });
    }
    if (field === "PiWeight" && value < 1) {
        errors.result = false;
        errors.message = i18next.t("errors.lessVal", { count: 1 });
    }
    let cloned = cloneDeep(event);
    set(cloned, path, value);
    const balance = getPigletBalanceAfterUpdate(cycleTable, cloned);
    if (balance < 0) {
        errors.result = false;
        errors.message = i18next.t("errors.negativePigletBalance", { balance });
    }
    return errors;
}

/**
 * funkcja walidująca mamkę
 * @param path
 * @param event
 * @param value
 * @param cycleTable
 */
function checkMommy(path, event, value, cycleTable) {
    const errors = {};
    const field = last(path.split("."));
    if (isNil(value)) {
        errors.result = false;
        errors.message = i18next.t("required");
    }
    if (field === "PiCnt" && value <= 0) {
        errors.result = false;
        errors.message = i18next.t("errors.lessVal", { count: 0 });
    }
    let cloned = cloneDeep(event);
    set(cloned, path, value);
    const balance = getPigletBalanceAfterUpdate(cycleTable, cloned);
    if (balance < 0) {
        errors.result = false;
        errors.message = i18next.t("errors.negativePigletBalance", { balance });
    }
    return errors;
}

function checkIfInseminationHasNoErrors(boarID, evTime) {
    const errors = {};
    const animal = animalsDB.getAnimalById(boarID);
    if (animal && animal.DtaDthTime) {
        if (moment(animal.DtaDthTime).isBefore(evTime, "day")) {
            errors.result = false;
            errors.message = i18next.t("errors.deathTimeBeforeInsemination");
        }
    }
    return errors;
}

function checkWeightingTime(evTime, cycle) {
    const errors = {};
    if (cycle && cycle[EventTypes.PARTURITION].length) {
        const parturitionTime = cycle[EventTypes.PARTURITION][0].EvTime;
        const diff = moment(evTime).endOf("day").diff(moment(parturitionTime), "days");
        // według https://redmine.wesstron.local/issues/7216 maks 5 dni po porodzie
        if (diff < 0 || diff > 5) {
            errors.result = false;
            errors.message = i18next.t("errors.parturitionWeightingWrongTime");
        }
    }
    return errors;
}

function checkInsemination(path, event, value) {
    let errors = {};
    if (isNil(value)) {
        errors.result = false;
        errors.message = i18next.t("required");
    }
    if (path === "EvData.BoarID") {
        errors = checkIfInseminationHasNoErrors(value, event.EvTime);
    }
    return errors;
}

/**
 * funkcja walidująca usg
 * @param path
 * @param event
 * @param value
 * @param cycleTable
 */
function checkUSG(path, event, value, cycleTable) {
    const errors = {};
    const field = last(path.split("."));
    if (isNil(value)) {
        errors.result = false;
        errors.message = i18next.t("required");
    }
    if (field === "Pregnant" && value === USG_STATE.NEGATIVE) {
        const cycle = findRowFromCycle(cycleTable, event);
        const sensitiveEvents = [cycle[EventTypes.PARTURITION], cycle[EventTypes.FALL_PIGLETS], cycle[EventTypes.SEPARATION_TO_MOMMY], cycle[EventTypes.SEPARATION]]
            .filter(item => item).flat();
        if (sensitiveEvents.length > 0) {
            errors.result = false;
            errors.message = i18next.t("errors.usg.cannotBeNegativeInCycle");
        }
    }
    return errors;
}

/**
 * funkcja walidująca poród
 * @param path
 * @param event
 * @param value
 * @param cycleTable
 */
function checkParturition(path, event, value, cycleTable) {
    const errors = {};
    const field = last(path.split("."));
    if (isNil(value)) {
        errors.result = false;
        errors.message = i18next.t("required");
    }
    if (field === "Weight" && value < 1) {
        errors.result = false;
        errors.message = i18next.t("errors.lessVal", { count: 1 });
    }
    if (field === "Weight" && value > 20) {
        errors.result = false;
        errors.message = i18next.t("errors.greaterThan", { number: 20 })
    }
    if (["HealthyCnt", "WeakCnt", "DeadCnt", "MummyCnt"].includes(field) && value < 0) {
        errors.result = false;
        errors.message = i18next.t("errors.lessVal", { count: 0 });
    }
    if (["DeadCnt", "MummyCnt"].includes(field) && value > 25) {
        errors.result = false;
        errors.message = i18next.t("errors.greaterThan", { number: 25 });
    }
    if ("HealthyCnt" === field && value > 50) {
        errors.result = false;
        errors.message = i18next.t("errors.greaterThan", { number: 50 });
    }
    if ("WeakCnt" === field && value > event.EvData.HealthyCnt) {
        errors.result = false;
        errors.message = i18next.t("errors.greaterThan", { number: event.EvData.HealthyCnt });
    }
    if ("GiltsCnt" === field && value > event.EvData.HealthyCnt) {
        errors.result = false;
        errors.message = i18next.t("errors.greaterThan", { number: event.EvData.HealthyCnt });
    }
    let cloned = cloneDeep(event);
    set(cloned, path, value);
    if (["HealthyCnt", "WeakCnt", "DeadCnt", "MummyCnt", "GiltsCnt"].includes(field) && value === 0) {
        const { EvData: { HealthyCnt, MummyCnt, DeadCnt, WeakCnt, GiltsCnt } } = cloned;
        errors.result = !!(+HealthyCnt + +MummyCnt + +DeadCnt + +WeakCnt + +GiltsCnt);
        errors.message = i18next.t("errors.atLeastMusBeGreaterThan0");
    }
    const balance = getPigletBalanceAfterUpdate(cycleTable, cloned);
    if (balance < 0) {
        errors.result = false;
        errors.message = i18next.t("errors.negativePigletBalance", { balance });
    }
    return errors;
}

/**
 * funkcja zwracajaca wynik walidacji w postaci obiektu {result, message} użyta w walidacji na redux formie - komponent EditEventPopup.js
 * @param path
 * @param event
 * @param value
 * @param cycleTable
 * @param animal
 * @param validations
 * @returns {{}}
 */
export function validateEvent(path, event, value, cycleTable, animal, validations) {
    if (isNil(value) || value === "") {
        return { message: i18next.t("required"), result: false };
    }
    if (path === "EvTime") {
        let errors = {};
        const time = moment(value);
        const cycle = cycleTable.find((row) => row[event.EvCode].some((e) => e.EvID === event.EvID));
        if (!checkIfUserIsService() && time.isAfter(new Date(), "days")) {
            errors.message = i18next.t("errors.doNotInsertEventInFuture");
        } else if (event.EvCode !== EventTypes.SOW_CYCLES && animal?.DtaInTime && moment(animal.DtaInTime).isAfter(time, "day")) {
            errors.message = i18next.t("errors.eventBeforeInsertion");
        } else if (animal?.DtaBrthTime && moment(animal.DtaBrthTime).isAfter(time, "day")) {
            errors.message = i18next.t("errors.eventBeforeBirth");
        } else if (event.EvCode === EventTypes.INSEMINATION) {
            errors = checkIfInseminationHasNoErrors(event.EvData.BoarID, value);
        } else if (event.EvCode === EventTypes.CASTRATION) {
            errors = checkWeightingTime(value, cycle);
        } else if (event.EvCode === EventTypes.PARTURITION && get(validations, "daysFromInseminationToParturition", ValidationDefaultValues.daysFromInseminationToParturition)) {
            const insemination = cycle?.[EventTypes.INSEMINATION]?.[0];
            if (insemination) {
                const timeFromInseminationToParturition = getTimeFromInseminationToPartuition();
                const parturitionDelay = getMaxDelayForBirth();
                const start = moment.utc(insemination.EvTime).startOf("day").add(timeFromInseminationToParturition - parturitionDelay, "days");
                const end = moment.utc(insemination.EvTime).startOf("day").add(timeFromInseminationToParturition + parturitionDelay, "days");
                if (!time.isBetween(start, end, "day", "[]")) {
                    errors.message = i18next.t("validationErrors.daysFromInseminationToParturition");
                }
            }
        }
        if (errors.message) errors.result = false;
        return errors;
    } else {
        const { EvCode } = event;
        const handledFunction = eventFunctionHandler[EvCode];
        if (isFunction(handledFunction)) return handledFunction(path, event, value, cycleTable, validations);
    }
    return true;
}


function getPigletBalanceAfterUpdate(cycleTable, event) {
    const cycleEvents = getEventsFromRow(findRowFromCycle(cycleTable, event));
    const indexOfEvent = findIndex(cycleEvents, (el) => el.EvID === event.EvID);
    if (indexOfEvent < 0) return 0;
    cycleEvents[indexOfEvent] = event;
    return getPigBalance(cycleEvents);
}
