import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { bindActionCreators, compose } from "redux";
import {
    getPigletTreatmentArrayForSelectedAnimal,
    getSelectedAnimalForDocuments
} from "../../../selectors/animalDocumentsSelectors";
import TableGrid from "../../basics/table-grid/TableGrid";
import moment from "moment";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import { checkIfUserHasPrivilegedAccess } from "../../../utils/NewRolesUtils";
import PropTypes from "prop-types";
import { GRAFTING_RESULT } from "../../../constans/graftingResults";
import DateHeaderComponent from "../grafting/DateHeaderComponent";
import DoneHeaderComponent from "../grafting/DoneHeaderComponent";
import { GraftingProgramApplicationTypes } from "../../../constans/graftingProgramApplicationTypes";
import { ModalName as EditGraftingModalName } from "../../modals-new/edit-grafting-modal/EditGraftingModal";
import { show } from "redux-modal";
import { ModalName as ConfirmModalName } from "../../modals-new/confirm-modal/ConfirmModal";
import {
    editSowCycle,
    editSowCycleNotificationFailure,
    editSowCycleNotificationSuccess
} from "../../../api/cycles/editSowCycle";
import { isMobile } from "../../../utils/MobileUtils";
import { getEmployeeList, getServiceList } from "../../../selectors/userSelector";
import { employeesValueFormatter } from "../../../utils/RaportsUtils";
import CollapsableContainer from "../containers/CollapsableContainer";

function mapStateToProps(state, props) {
    return {
        graftingReasons: state.dictionary.graftingReason.WData,
        animal: getSelectedAnimalForDocuments(state),
        treatments: getPigletTreatmentArrayForSelectedAnimal(state, { cycleNumber: props.cycle }),
        employees: getEmployeeList(state),
        service: getServiceList(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({ show }, dispatch)
    }
}

class PigletsTreatmentCard extends Component {

    constructor(props) {
        super(props);
        this.mobile = isMobile();
    }

    dateFormatter = value => {
        const { t } = this.props;
        if (value.Date) {
            return `${moment(value.Date).format("DD.MM.YYYY")} (${value.Delay < 0 ? t("cycleReasons.parturitionBeforeDays", { count: Math.abs(value.Delay) }) : t("cycleReasons.parturitionAfterDays", { count: value.Delay })})`
        }
        if (value.Start && !moment(value.Start).isSame(value.End)) {
            if (value.End) return moment(value.Start).format("DD.MM.YY") + " - " + moment(value.End).format("DD.MM.YY");
            return moment(value.Start).format("DD.MM.YY") + " - ...";
        }
        return moment(value).format("DD.MM.YY");
    };

    medicineFormatter = value => {
        if (value) {
            return value.WData.Name
        }
    };

    userFormatter = value => {
        const { employees, service } = this.props;
        return employeesValueFormatter(value, employees, service);
    };

    reasonValueFormatter = value => {
        const { graftingReasons } = this.props;
        let reason = graftingReasons.find((reason) => reason.ID === value);
        if (reason) return reason.Value;
    };

    getIconValue = value => {
        switch (value) {
            case GRAFTING_RESULT.NOT_DONE:
                return "fas fa-fw fa-times error";
            case GRAFTING_RESULT.DONE:
                return "fas fa-fw fa-check success";
            case GRAFTING_RESULT.PENDING:
                return "fas fa-fw fa-clock info";
            default:
                return "";
        }
    };

    dayValueFormatter = value => {
        const { t } = this.props;
        let additionalText = "";
        switch (value.graftingProgramReason) {
            case GraftingProgramApplicationTypes.PARTURITION: {
                additionalText = t('newSettings.breeding.graftingProgram.afterParturition').toLowerCase();
                break;
            }
            case GraftingProgramApplicationTypes.INSERTION: {
                additionalText = t('newSettings.breeding.graftingProgram.afterInsertion').toLowerCase();
                break;
            }
            case GraftingProgramApplicationTypes.AGE: {
                additionalText = t('newSettings.breeding.graftingProgram.afterBirth').toLowerCase();
                break;
            }
            case GraftingProgramApplicationTypes.SEPARATION: {
                additionalText = t('newSettings.breeding.graftingProgram.afterSeparation').toLowerCase();
                break;
            }
            case GraftingProgramApplicationTypes.INSEMINATION: {
                additionalText = t('newSettings.breeding.graftingProgram.afterInsemination').toLowerCase();
                break;
            }
            default: {
                break;
            }
        }
        let day = value.day;
        if (day) return `${day} ${additionalText}`;
        return "";
    };

    onEditClick = object => {
        this.props.show(EditGraftingModalName, { event: object });
    }

    onRemoveClick = object => {
        const { t, animal } = this.props;
        this.props.show(ConfirmModalName, {
            title: t("removeEventForAnimalTitle", { animal: animal.AnmNo1 }),
            text: t("removeEventForAnimalText", { animal: animal.AnmNo1 }),
            confirmText: t("yes"),
            size: "lg",
            onConfirmed: props => {
                object.DtaDelTime = +new Date();
                object.DtaModTime = +new Date();
                return editSowCycle({ AnmID: animal.AnmID, eventsToUpdate: [], eventsToDelete: [object] }, { FarmID: animal.FarmID }).then((res) => {
                    props.handleHide();
                    editSowCycleNotificationSuccess(res);
                }).catch((e) => {
                    editSowCycleNotificationFailure(e);
                });
            }
        })
    }

    render() {
        const { t, animal, treatments = [] } = this.props;
        const headers = [
            {
                name: t("date"),
                field: "date",
                valueFormatter: this.dateFormatter
            },
            {
                name: t("day"),
                headerComponent: <DateHeaderComponent animal={animal} showFromInsemination={false} />,
                valueFormatter: this.dayValueFormatter,
            },
            {
                name: t("pigletsAmount"),
                field: "pigletsAmount"
            },
            {
                name: t("medicine"),
                field: "medicine",
                valueFormatter: this.medicineFormatter,
                colWidth: 2,
            },
            {
                name: t("givenDose"),
                field: "dose"
            },
            {
                name: t("reason"),
                field: "reason",
                valueFormatter: this.reasonValueFormatter
            },
            {
                name: t("done"),
                field: "done",
                headerComponent: <DoneHeaderComponent showDoneTooltip={true} />,
                component: props => <i className={this.getIconValue(props.value)} />,
                notSortable:true,
            },
            {
                name: t("eventGrid.operator"),
                field: "operator",
                valueFormatter: this.userFormatter,
                shouldShow: () => checkIfUserHasPrivilegedAccess(),
                colWidth: 2,
            },
            {
                name: t("comment"),
                field: "comment"
            },
            {
                name: "",
                headerClassName: "index",
                itemClassName: "index",
                component: props => props.object.event ?
                    <i className="fas fa-edit pointer" onClick={() => this.onEditClick(props.object.event)} /> : null,
                notSortable:true,
            },
            {
                name: "",
                headerClassName: "index",
                itemClassName: "index",
                component: props => props.object.event ?
                    <i className="fas fa-trash pointer" onClick={() => this.onRemoveClick(props.object.event)} /> : null,
                notSortable:true,
            }
        ];
        return (
            <>
                <CollapsableContainer.Fieldset
                    id="cy-piglets-treatments-card"
                    header={t("logsView.shortNames.1022")}
                    defaultExpanded={treatments.length > 0}
                >
                    <TableGrid data={treatments} headers={headers} mobileRow={<DefaultMobileRow />}
                        showPagination={!this.mobile} paginationItems={10} scrollOnPageChange={false} />
                </CollapsableContainer.Fieldset>
            </>
        );
    }
}

PigletsTreatmentCard.propTypes = {
    cycle: PropTypes.number.isRequired
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(PigletsTreatmentCard);
