import {invokeApig} from '../libs/awsLib';
import db from "../database/animalsDB"
import Paths from "../api/paths";

export function listAnimalDynamoDB(FarmID, stopLoadingToProps = false, LastEvaluatedKey = undefined, forceFetch = false) {
    return function (dispatch) {
        let querryParams = {FarmID: FarmID, DtaModTime: db.getModificationTime(FarmID).DtaModTime, partial: true};
        if (LastEvaluatedKey) {
            querryParams.DtaModTime = 0;
            querryParams.ESKDtaModTime = LastEvaluatedKey.DtaModTime;
            querryParams.ESKAnmID = LastEvaluatedKey.AnmID;
        }
        dispatch({
            type: "LIST_ANIMAL",
            payload: invokeApig({
                ...Paths.listAnimal({farmID: FarmID}),
                queryParams: querryParams,
                forceFetch
            })
        }).then((res) => {
            let l = {};
            Object.assign(l, res);
            if (res.value.items.length > 0) {
                let tmp = [];
                Object.assign(tmp, res.value.items);
                db.insertIntoAnimals(tmp);
            }
            if (res.value.LastEvaluatedKey) {
                // musimy wyslac za kazdym razem forceFetch bo nie wiemy w jakiej partii zostanie zwrocony nowy obiekt
                dispatch(listAnimalDynamoDB(FarmID, stopLoadingToProps, res.value.LastEvaluatedKey, forceFetch));
            }

            const changedIDs = res.value.items.map(({AnmID}) => AnmID)
            !stopLoadingToProps && dispatch(getAnimalModificationTime(FarmID,changedIDs))
        }).catch(error => {
            !stopLoadingToProps && dispatch(getAnimalModificationTime(FarmID))
        })

    }
}

/**
 * Funkcja zastepuje getAllAnimal ktora wrzucala wszystkie swinie do stora - nikt nie był do nich podpięty a pobieranie samych swin trwało dla 6000 tyś 20 ms dla każdej z nich dając długie czasy ładowania
 * @param farmId
 * @return {function(...[*]=)}
 */
export function getAnimalModificationTime(farmId, anmIDs = null) {
    return function (dispatch) {
        dispatch({
            type: "GET_ANIMAL_MODIFICATION_TIME", payload: {...db.getModificationTime(farmId), anmIDs}
        });
    }
}
