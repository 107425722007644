import {customAlphabet} from "nanoid"

export function myID() {
    let nanoID = customAlphabet('1234567890AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz-', 20);
    return nanoID();
}

export function cypressID() {
    let nanoID = customAlphabet('0123456789', 15);
    return nanoID();
}

export function cypressStringID() {
    let nanoID = customAlphabet('AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz', 20);
    return nanoID();
}