import { get, isNil } from "lodash";
import React from "react";
import Tooltip from "../../../../components/basics/tooltip/Tooltip";
import { SelectedItemStatus, SelectedItemStatusExplanation } from "../../../../constans/animalDocuments";
import { USG_STATE } from "../../../../constans/eventTypes";
import { UnitTypes } from "../../../../constans/unitTypes";
import buildingsDB from "../../../../database/buildingsDB";
import i18n from "../../../../i18n";
import store from "../../../../store/store";
import { getLocationName } from "../../../../utils/BuildingUtils";
import { getAnimalUnit } from "../../../../utils/SettingsUtils";
import { REMOVE_TECHNOLOGY_GROUP_REASONS } from "../../../../utils/TechnologyGroupUtils";
import { convertWeightUnitTo, getUnit } from "../../../../utils/UnitUtils";

export const usgResultFormatter = (value, { toExcel = false } = {}) => {
    if (value === "-") return value;
    else if (value === USG_STATE.REPEAT) return !toExcel ?
        <i className="fas fa-fw fa-redo-alt warning" /> : i18n.t("events.usgEvent.toRepeat");
    else if (value === true || value === USG_STATE.POSITIVE) return !toExcel ?
        <i className="fas fa-fw fa-check success" /> : i18n.t('events.usgEvent.positive');
    else if (value === false || value === USG_STATE.NEGATIVE) return !toExcel ?
        <i className="fas fa-fw fa-times error" /> : i18n.t("events.usgEvent.negative");
    else return "";
};

export const percentFormatter = (value) => {
    if (!value || typeof value === "object") {
        return "-";
    }
    return `${+parseFloat(`${value}`).toFixed(2)}%`;
};

export const booleanFormatter = (value, { toExcel = false, negation = false } = {}) => {
    let toCheck = value;
    if (negation) toCheck = !value;
    if (toCheck) return !toExcel ? <i className="fas fa-fw fa-check success" /> : i18n.t("yes").toUpperCase();
    return !toExcel ? <i className="fas fa-fw fa-times error" /> : i18n.t("no").toUpperCase();
};

export const weightFormatter = (value, { convert = false, rawValue = false, showUnit = true } = {}) => {
    if (isNil(value)) return null;
    if (convert) {
        return convertWeightUnitTo(value, {
            unit: getAnimalUnit(),
            fixed: 2,
            showUnit: showUnit,
            rawValue,
        });
    }
    return `${value}${showUnit ? getUnit("weight", UnitTypes.MEDIUM) : ''}`;
};
export const excelWeightFormatter = (value, rawValue = true, showUnit = false) => {
    return convertWeightUnitTo(value, {
        fixed: 2,
        showUnit: showUnit,
        rawValue,
    });
};
export const daysFormatter = (value) => {
    if (typeof value === "object" || !value) return "";
    return i18n.t("Xdays", { count: value });
};

export const chamberFormatter = (value) => {
    const location = buildingsDB.getLocationByID(value);
    if (location) return getLocationName(location);
    return "";
};

export const lastValueFormatter = value => get(value, "lastColumnValue", value);

export const raceFormatter = (value) => {
    const state = store.getState();
    const races = state?.dictionary?.race?.WData;
    if (value) {
        const race = (races || []).find((item) => item.ID === value);
        return race?.Value;
    }
    return "";
};

export const medicineFormatter = value => {
    const state = store.getState();
    const medicines = state?.dictionary?.medicines;
    if (value) {
        const medicine = (medicines || []).find((item) => item.WordID === value);
        return medicine?.WData.Name;
    }
    return "";
};

export const fallReasonFormatter = (value) => {
    const { dictionary: { fallReasons } } = store.getState();
    return get(get(fallReasons, `WData`, [])
        .find((emp) => emp.ID === value), "Value", "?");
};

export const animalAgeFormatter = (value) => {
    if (typeof value !== "number") return "";
    return dayFormatter(value);
};

export const dayFormatter = (value) => {
    if (typeof value === "number") return `${value} ${value === 1 ? i18n.t("day") : i18n.t("days")}`;
    return "";
};

export const explanationFormatter = (value) => {
    switch (value) {
        case SelectedItemStatusExplanation.OUT_DECREASED_EFFECTIVENESS:
            return i18n.t("explanationFormatter.outDecreasedEffectiveness");
        default:
            return "";
    }
};

export function getStatusData(value) {
    const { Status } = value;
    switch (Status) {
        case SelectedItemStatus.PREGNANCY_PENDING:
            return {
                icon: <i className="fas fa-fw fa-clock info" />,
                content: i18n.t("inseminationEffectivenes.pregnancyPending")
            };
        case SelectedItemStatus.PARTURITION_TOO_EARLY: {
            const date = get(value, "AdditionalInfo.date", 0);
            return {
                icon: <i className="fas fa-fw fa-times error" />,
                content: i18n.t("inseminationEffectivenes.parturitionTooEarly", { date })
            };
        }
        case SelectedItemStatus.PARTURITION_TOO_LATE: {
            const date = get(value, "AdditionalInfo.date", 0);
            return {
                icon: <i className="fas fa-fw fa-times error" />,
                content: i18n.t("inseminationEffectivenes.parturitionTooLate", { date })
            };
        }
        case SelectedItemStatus.PARTURITIONED: {
            const { AdditionalInfo: { parturitionDate } } = value;
            return {
                icon: <i className="fas fa-fw fa-check success" />,
                content: i18n.t("inseminationEffectivenes.parturitioned", { parturitionDate })
            };
        }
        case SelectedItemStatus.NEGATIVE_USG: {
            const date = get(value, "AdditionalInfo.date", 0);
            return {
                icon: <i className="fas fa-fw fa-search error" />,
                content: i18n.t("inseminationEffectivenes.negativeUsg", { date })
            };
        }
        case SelectedItemStatus.NO_PREGNANCY: {
            const date = get(value, "AdditionalInfo.date", 0);
            return {
                icon: <i className="fas fa-fw fa-times error" />,
                content: i18n.t("inseminationEffectivenes.noPregnancy", { date })
            };
        }
        case SelectedItemStatus.REPETITION: {
            const date = get(value, "AdditionalInfo.date", 0);
            return {
                icon: <i className="fas fa-fw fa-redo-alt error" />,
                content: i18n.t("inseminationEffectivenes.repetition", { date })
            };
        }
        case SelectedItemStatus.SELL: {
            const date = get(value, "AdditionalInfo.date", 0);
            return {
                icon: <i className="fas fa-fw fa-dollar-sign error" />,
                content: i18n.t("inseminationEffectivenes.sell", { date })
            };
        }
        case SelectedItemStatus.SELL_WITHOUT_OR_NEGATIVE_USG: {
            const date = get(value, "AdditionalInfo.date", 0);
            return {
                icon: <i className="fas fa-fw fa-dollar-sign error" />,
                content: i18n.t("inseminationEffectivenes.sellWithoutOrNegativeUsg", { date })
            };
        }
        case SelectedItemStatus.FALL: {
            const date = get(value, "AdditionalInfo.date", 0);
            return {
                icon: <i className="fas fa-fw fa-coffin-cross error" />,
                content: i18n.t("inseminationEffectivenes.fall", { date })
            };
        }
        case SelectedItemStatus.FALL_WITHOUT_OR_NEGATIVE_USG: {
            const date = get(value, "AdditionalInfo.date", 0);
            return {
                icon: <i className="fas fa-fw fa-coffin-cross error" />,
                content: i18n.t("inseminationEffectivenes.fallWithoutOrNegativeUsg", { date })
            };
        }
        case SelectedItemStatus.NO_PARTURITION:
            return {
                icon: <i className="fas fa-fw fa-times error" />,
                content: i18n.t("cycleReasons.noParturitionAfterEv")
            };
        case SelectedItemStatus.SELL_OR_FALL_AFTER_POSITIVE_USG: {
            const date = get(value, "AdditionalInfo.date", 0);
            return {
                icon: <i className="fas fa-fw fa-check success" />,
                content: i18n.t("inseminationEffectivenes.sellOrFallAfterPositiveUsg", { date })
            };
        }
        case SelectedItemStatus.NO_INSEMINATION:
            return {
                icon: <i className="fas fa-fw fa-exclamation-triangle warning" />,
                content: i18n.t("inseminationEffectivenes.noInsemination")
            };
        case SelectedItemStatus.INSEMINATION_NOT_IN_CORRECT_TIME: {
            const date = get(value, "AdditionalInfo.date", 0);
            return {
                icon: <i className="fas fa-fw fa-calendar-times error" />,
                content: i18n.t("inseminationEffectivenes.inseminationInWrongTime", { date })
            };
        }
        case SelectedItemStatus.OK:
            return { icon: <i className="fas fa-fw fa-check success" />, content: "OK" };
        case SelectedItemStatus.PARTURITION_IN_WRONG_TIME: {
            const { AdditionalInfo: { expectedParturitionDates, parturitionDate } } = value;
            return {
                icon: <i className="fas fa-fw fa-calendar-times error" />,
                content: i18n.t("resultStatusFormatter.parturitionInWrongTime", {
                    expectedStartDate: expectedParturitionDates[0],
                    expectedEndDate: expectedParturitionDates[1],
                    parturitionDate
                })
            };
        }
        case SelectedItemStatus.NO_INSEMINATION_AFTER_7_DAYS:
            return {
                icon: <i className="fas fa-fw fa-exclamation-triangle warning" />,
                content: i18n.t("resultStatusFormatter.noInseminationWithinSevenDays")
            };
        case SelectedItemStatus.INSEMINATION_AFTER_7_DAYS:
            return {
                icon: <i className="fas fa-fw fa-exclamation-triangle warning" />,
                content: i18n.t("resultStatusFormatter.noInseminationAfterSevenDays")
            };
        case SelectedItemStatus.SEPARATION: {
            const { AdditionalInfo: { separationDate } } = value;
            return {
                icon: <i className="fas fa-fw fa-check success" />,
                content: i18n.t("resultStatusFormatter.separation", { separationDate })
            };
        }
        case SelectedItemStatus.NO_SEPARATION: {
            return {
                icon: <i className="fas fa-fw fa-exclamation-triangle warning" />,
                content: i18n.t("resultStatusFormatter.noSeparation")
            };
        }
        case SelectedItemStatus.PARTIAL_SEPARATION: {
            return {
                icon: <i className="fas fa-fw fa-circle-info info" />,
                content: i18n.t("resultStatusFormatter.partialSeparation")
            };
        }
        default:
            return { icon: null, content: null };
    }
}

export function statusFormatter(value, { excel = false } = {}) {
    const { Status } = value;
    const { icon, content } = getStatusData(value);
    if (!isNil(icon)) {
        if (excel) return content;
        return (
            <Tooltip tooltipContent={content}>
                {icon}
            </Tooltip>
        );
    }
    return Status;
}

export const removeReasonFormatter = (value, showText = true) => {
    if (!value) return null;
    switch (value.reason) {
        case REMOVE_TECHNOLOGY_GROUP_REASONS.INSEMINATION_OTHER_WEEK:
            return i18n.t("inseminationInNextWeek");
        case REMOVE_TECHNOLOGY_GROUP_REASONS.NEGATIVE_USG:
            return i18n.t("animalDocuments.removeReason.negativeUSG");
        case REMOVE_TECHNOLOGY_GROUP_REASONS.NO_PREGNANCY:
            return i18n.t("events.parturitionEvent.noPregnancy");
        case REMOVE_TECHNOLOGY_GROUP_REASONS.FALL:
            return i18n.t("eventTypes.F");
        case REMOVE_TECHNOLOGY_GROUP_REASONS.SELL:
            return i18n.t("eventTypes.L");
        case REMOVE_TECHNOLOGY_GROUP_REASONS.SEPARATION:
            return i18n.t("eventTypes.S");
        case REMOVE_TECHNOLOGY_GROUP_REASONS.NEXT_CYCLE:
            return i18n.t("foundNextCycle");
        case REMOVE_TECHNOLOGY_GROUP_REASONS.NO_PARTURITION_EVENT:
            return i18n.t("noParturitionEvent");
        case REMOVE_TECHNOLOGY_GROUP_REASONS.IS_MOMMY:
            return "Mamkowanie";
        default:
            return showText ? i18n.t("notFound.dataNotFound") : null;
    }
};
