import React, {Component} from 'react';
import Card from "../../basics/card/Card";
import InfoBox from "../../basics/info-box/InfoBox";
import Select from "../../basics/select/Select";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {connect} from "react-redux";
import animalsDB from "../../../database/animalsDB";
import moment from "moment";
import {isNil, isEmpty, isEqual} from "lodash";
import {convertRowsToCycles} from "../../../utils/AnimalDocumentsUtils";
import {withTranslation} from "react-i18next";
import SwipeableViews from 'react-swipeable-views';
import {getAnimalUnit} from "../../../utils/SettingsUtils";

class SowCardGridMobile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...SowCardGridMobile.getValues(props),
            ...SowCardGridMobile.getInitialIndex(props)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.cycleTable, prevProps.cycleTable)) {
            this.setState({
                ...SowCardGridMobile.getValues(this.props),
                ...SowCardGridMobile.getInitialIndex(this.props)
            })
        }
    }

    static getValues = (props) => {
        const {cycleTable} = props;
        const cycles = !isEmpty(cycleTable) ? convertRowsToCycles(cycleTable) : [];
        return {cycles};
    };

    static getInitialIndex(props) {
        return {index: Array.isArray(props.cycleTable) && props.cycleTable.length !== 0 ? props.cycleTable.length - 1 : 0}
    };

    getBoarName = (insemination) => {
        let id = insemination.EvData.BoarID;
        let boar = animalsDB.getAnimalById(id);
        if (boar) {
            let name = boar.AnmNo1;
            if (boar.DtaDthTime) name += ` ✝ (${moment(boar.DtaDthTime).format("DD.MM.YYYY")})`;
            return name;
        }
        return "-";
    };

    getFallReason(reason) {
        const {fallReasons: {WData}} = this.props;
        let rsn = WData.find(item => item.ID === reason);
        if (rsn) return rsn.Value;
    }

    getNoPregnancyReason(reason) {
        const {noPregnancyReasons: {WData}} = this.props;
        let rsn = WData.find(item => item.ID === reason);
        if (rsn) return rsn.Value;
    }

    onSelectChange = (index) => {
        this.setState({
            index
        })
    };

    getDate = (event, {utc = false, format = "DD.MM.YYYY"} = {}) => {
        if (utc) return moment.utc(event.EvTime).format(format);
        return moment(event.EvTime).format("DD.MM.YYYY");
    };

    getFallenPigletsAge = (fall, parturitions) => {
        const {t} = this.props;
        let pigletsAge = "-";
        if (parturitions[0]) {
            pigletsAge = t("Xdays", {count: !isNil(fall.Age) ? fall.Age : moment(fall.EvTime).startOf("day").diff(moment(parturitions[0].EvTime).startOf("day"), "days") + 1})
        }
        return `${t("age")}: ${pigletsAge}`
    };

    cycleRenderer = ({key, cycle}) => {
        const {t} = this.props;
        return (
            <div key={key} className={"mh-5rem"}>
                {
                    cycle &&
                    <>
                        {
                            cycle.I.length > 0 &&
                            <div>
                                <h5>{t("animalDocuments.insemination")}</h5>
                                {
                                    cycle.I.map((ins, index) => {
                                        return (
                                            <div key={index}>
                                                {this.getDate(ins, {utc: true})}: {this.getBoarName(ins)}
                                            </div>
                                        )
                                    })
                                }
                                <hr/>
                            </div>
                        }
                        {
                            cycle.U.length > 0 &&
                            <>
                                <h5>{t("usg")}</h5>
                                {
                                    cycle.U.map((usg, index) => {
                                        return (
                                            <div key={index} className="position-relative">
                                                {this.getDate(usg)}: <i
                                                className={`fas ${usg.EvData.Pregnant ? 'fa-check' : 'fa-times'}`}/>
                                            </div>
                                        )
                                    })
                                }
                                <hr/>
                            </>
                        }
                        {
                            cycle.P.length > 0 &&
                            <>
                                <h5>{t("animalDocuments.birth")}</h5>
                                {
                                    cycle.P.map((parturition, index) => {
                                        return (
                                            <div key={index}>
                                                {this.getDate(parturition)}
                                                <div>
                                                    {t("alive")}: {parturition.EvData.HealthyCnt || 0}
                                                </div>
                                                <div>
                                                    {t("weakPiglets")}: {parturition.EvData.WeakCnt || 0}
                                                </div>
                                                <div>
                                                    {t("dead")}: {parturition.EvData.DeadCnt || 0}
                                                </div>
                                                <div>
                                                    {t("mummies")}: {parturition.EvData.MummyCnt || 0}
                                                </div>
                                                <div>
                                                    {t("gilts")}: {parturition.EvData.GiltsCnt || 0}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <hr/>
                            </>
                        }
                        {
                            cycle.AN.length > 0 &&
                            <>
                                <h5>{t("animalDocuments.activeNipples")}</h5>
                                {
                                    cycle.AN.map((nipples, index) => {
                                        return (
                                            <div key={index}>
                                                {t("amount")}: {nipples.EvData.Nipples}
                                            </div>
                                        )
                                    })
                                }
                                <hr/>
                            </>
                        }
                        {
                            cycle.S.length > 0 &&
                            <>
                                <h5>{t("separation")}</h5>
                                {
                                    cycle.S.map((separation, index) =>
                                        <div key={index}>
                                            {this.getDate(separation)} - {separation.EvData.PiCnt} / {convertWeightUnitTo(separation.EvData.PiWeight, {
                                            unit: getAnimalUnit(),
                                            showUnit: true
                                        })}
                                        </div>
                                    )
                                }
                                <hr/>
                            </>
                        }
                        {
                            cycle.V.length > 0 &&
                            <>
                                <h5>{t("fallPiglets")}</h5>
                                {
                                    cycle.V.map((fallPiglets, index) =>
                                        <div key={index}>
                                            {this.getDate(fallPiglets)} - {fallPiglets.EvData.Piglets} / {this.getFallenPigletsAge(fallPiglets, cycle.P)}
                                        </div>
                                    )
                                }
                                <hr/>
                            </>
                        }
                        {
                            cycle.W.length > 0 &&
                            <>
                                <h5>{t("animalDocuments.mommySeparationAdded")}</h5>
                                {
                                    cycle.W.map((mommy, index) =>
                                        <div key={index}>
                                            {this.getDate(mommy)} - {mommy.EvData.PiCnt}
                                        </div>
                                    )
                                }
                                <hr/>
                            </>
                        }
                        {
                            cycle.M.length > 0 &&
                            <>
                                <h5>{t("animalDocuments.mommySeparationSubstracted")}</h5>
                                {
                                    cycle.M.map((separation, index) =>
                                        <div key={index}>
                                            {this.getDate(separation)} - {separation.EvData.PiCnt}
                                        </div>
                                    )
                                }
                                <hr/>
                            </>
                        }
                        {
                            cycle.N.length > 0 &&
                            <>
                                <h5>{t("noPregnancy")}</h5>
                                {
                                    cycle.N.map((noPregnancy, index) => {
                                        return (
                                            <div key={index}>
                                                {this.getDate(noPregnancy)} - {this.getNoPregnancyReason(noPregnancy.EvData.Reason)}
                                            </div>
                                        )
                                    })
                                }
                                <hr/>
                            </>
                        }
                    </>
                }
            </div>)
    }

    render() {
        const {cycles = [], index} = this.state;
        const {t} = this.props;
        return (
            <Card>
                <h5>{t("animalDocuments.cyclesHistory")}&nbsp;</h5>
                {
                    cycles.length === 0 &&
                    <InfoBox boxColor={"warning"}>{t("animalDocuments.noDataForAnimal")}</InfoBox>
                }
                {
                    cycles.length !== 0 &&
                    <Select value={index} options={cycles.map((row, i) => ({
                        name: `${t("animalDocuments.cycleNumber")} ${row.cycle}`,
                        value: i
                    }))} clearButton={false} onChange={this.onSelectChange}/>
                }
                <SwipeableViews
                    animateHeight
                    index={index}
                    onChangeIndex={this.onSelectChange}
                >
                    {
                        cycles.map((cycle, key) => this.cycleRenderer({key, cycle}))
                    }
                </SwipeableViews>
            </Card>
        );
    }
}

SowCardGridMobile = connect(state => ({
    rows: state.animalDocuments.rows,
    fallReasons: state.dictionary.fallReasons,
    noPregnancyReasons: state.dictionary.noPreganancy,
    lang: state.language.lang.lang,
    cycleTable: state.animalDocuments.cycleTable,
}))(SowCardGridMobile);

export default withTranslation()(SowCardGridMobile);
