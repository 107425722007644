import React from "react";
import PropTypes from "prop-types";
import CycleRenderer from "./CycleRenderer";
import {get, isEqual} from "lodash";
import animalsDB from "../../../database/animalsDB";
import {getEventDate} from "./utils";
import moment from "moment";
import { formatTechnoGroupName, getTechnologyGroupByTime } from "../../../utils/TechnologyGroupUtils";

class InseminationRenderer extends React.Component {
    state = {
        hoveredID: "",
    };

    getBoar = (data) => {
        const boarID = get(data, "event.EvData.BoarID");
        if (boarID) {
            const animal = animalsDB.getAnimalById(boarID, {joinEvents: false, findDeleted: true});
            const deathTime = animal ? animal.DtaDthTime || animal.DtaDelTime : undefined;
            let name = get(animal, "AnmNo1", "-");
            if (deathTime) name += ` ✝ (${moment(deathTime).format("DD.MM.YYYY")})`;
            return name;
        }
        return "";
    };
    onMouseEnter = (event) => {
        const cell = get(event, "currentTarget");
        if (cell) {
            const EvID = cell.id.split(" ")[0];
            this.setState({
                hoveredID: EvID,
            });
        }
    };

    onMouseLeave = () => {
        this.setState({
            hoveredID: "",
        });
    };

    render() {
        const {
            cycle: {inseminations, key},
            expanded: {rows},
            isAdvanced,
            tooltipContent,
            cycleRenderClass,
            onDoubleEventClick,
            deletionMode,
            onDeleteClick,
            printing,
            isInterrupted,
        } = this.props;
        const {hoveredID} = this.state;
        let rowValue = rows.find((o) => o === key);
        let firstInsemination = inseminations[0];
        if (!firstInsemination) {
            return (
                <>
                    <CycleRenderer
                        comment={cycleRenderClass && tooltipContent}
                        deletionMode={deletionMode}
                        isInterrupted={isInterrupted}
                    />
                    <CycleRenderer isInterrupted={isInterrupted} />
                    <CycleRenderer isInterrupted={isInterrupted} />
                </>
            );
        }
        const operators = firstInsemination.operators;
        const EvTime = getEventDate(firstInsemination, {UTC: true});
        const _DateFormat = "DD.MM.YY";
        const technologyGroup = getTechnologyGroupByTime(+moment.utc(EvTime,_DateFormat));
        const {start, end} = technologyGroup;
        const techName = formatTechnoGroupName(start, end);
        return (
            <>
                <CycleRenderer
                    comment={tooltipContent}
                    isAdvanced={isAdvanced}
                    operators={operators}
                    deletionMode={deletionMode}>
                    {isAdvanced && (isEqual(key, rowValue) || printing) ? (
                        inseminations.map((insemination, index) => {
                            const {
                                event: {EvID},
                            } = insemination;
                            return (
                                <div
                                    className={`cell-wrapper ${hoveredID === EvID ? "error" : "success"}`}
                                    id={`${EvID} EvTime`}
                                    key={index}
                                    onDoubleClick={onDoubleEventClick}
                                    onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                                    onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                                    onClick={deletionMode ? onDeleteClick : undefined}>
                                    {getEventDate(insemination, {UTC: true})}
                                </div>
                            );
                        })
                    ) : (
                        <div
                            className={`cell-wrapper ${
                                hoveredID === firstInsemination.event.EvID ? "error" : "success"
                            }`}
                            id={`${firstInsemination.event.EvID} EvTime`}
                            onDoubleClick={onDoubleEventClick}
                            onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                            onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                            onClick={deletionMode ? onDeleteClick : undefined}>
                            {getEventDate(firstInsemination, {UTC: true})}
                        </div>
                    )}
                </CycleRenderer>
                <CycleRenderer>
                    {isAdvanced && (isEqual(key, rowValue) || printing) ? (
                        inseminations.map((insemination, index) => {
                            const {
                                event: {EvID},
                            } = insemination;
                            return (
                                <div
                                    className={`cell-wrapper ${hoveredID === EvID ? "error" : "success"}`}
                                    id={`${EvID} EvData.BoarID`}
                                    key={index}
                                    onDoubleClick={onDoubleEventClick}
                                    onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                                    onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                                    onClick={deletionMode ? onDeleteClick : undefined}>
                                    {this.getBoar(insemination)}
                                </div>
                            );
                        })
                    ) : (
                        <div
                            className={`cell-wrapper ${
                                hoveredID === firstInsemination.event.EvID ? "error" : "success"
                            }`}
                            id={`${firstInsemination.event.EvID} EvData.BoarID`}
                            onDoubleClick={onDoubleEventClick}
                            onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                            onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                            onClick={deletionMode ? onDeleteClick : undefined}>
                            {this.getBoar(firstInsemination)}
                        </div>
                    )}
                </CycleRenderer>
                <CycleRenderer>
                <div className={`cell-wrapper ${hoveredID === firstInsemination.event.EvID ? "error" : "success"}`}
                         id={`${firstInsemination.event.EvID} EvData.TechGroup`}
                         onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                         onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                         onClick={deletionMode ? onDeleteClick : undefined}
                    >
                        {techName}
                    </div>
                </CycleRenderer>
            </>
        );
    }
}

InseminationRenderer.propTypes = {
    cycle: PropTypes.shape({
        inseminations: PropTypes.array.isRequired
    }).isRequired,
    showTitle: PropTypes.bool
};

export default InseminationRenderer;
