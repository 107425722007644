// widok do pokazywania parametrow na karcie zwierzecia, nie jest pokazywana, mozna go odkomentowac do testu
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FilterTypes } from '../../../constans/filter';
import { getSelectedAnimalForDocuments } from '../../../selectors/animalDocumentsSelectors';
import { makeGetEmployeeList } from '../../../selectors/userSelector';
import Card from "../../basics/card/Card";
import TableGrid from "../../basics/table-grid/TableGrid";
import { getAnimalParameters } from '../../../selectors/animalParametersSelector';
import alasql from 'alasql';
import { uniq } from 'lodash';

export default function AnimalParameters() {

    const getEmployeeList = useMemo(makeGetEmployeeList, []);

    const animal = useSelector(getSelectedAnimalForDocuments);
    const employees = useSelector(getEmployeeList);
    const animalParameters = useSelector(getAnimalParameters);

    const params = useMemo(() => {
        const anmIDs = animal.AnmGrp ? uniq([...animal.AnmIDs, ...animal.Rmvd]) : [animal.AnmID];
        return alasql("SELECT * FROM ? WHERE AnmID IN @(?)", [animalParameters, anmIDs]);
    }, [animal, animalParameters]);

    const dateFormatter = useCallback(value => {
        if (!value) return null;
        return moment(value).format("L HH:mm");
    }, []);

    const booleanFormatter = useCallback(value => {
        return value ? "Tak" : "Nie";
    }, []);

    const valueFormatter = useCallback(value => JSON.stringify(value), []);

    const userFormatter = useCallback(value => {
        const user = employees.find(item => item.LocalUserID === value);
        if (user) return `${user.firstname || ""} ${user.surname || ""}`;
        return value;
    }, [employees]);

    const headers = useMemo(() => {
        const tmp = [
            { name: "Nazwa", field: "key", filterType: FilterTypes.STRING },
            { name: "Grupa", field: "group_nr", filterType: FilterTypes.STRING },
            // { name: "Genetyka", field: "genetics" },
            { name: "Data", field: "dataEv", valueFormatter: dateFormatter, disableValueFormatterSort: true, filterType: FilterTypes.DATE },
            { name: "Typ", field: "type", filterType: FilterTypes.STRING },
            { name: "Wartość", field: "value", valueFormatter, filterType: FilterTypes.STRING },
            { name: "Użytkownik", field: "user", valueFormatter: userFormatter, filterType: FilterTypes.STRING },
        ];
        if (animal.AnmGrp) {
            tmp.push(
                { name: "Lokalizacja", field: "location", filterType: FilterTypes.STRING },
                { name: "Data wprowadzenia", field: "date_introduction", valueFormatter: dateFormatter, filterType: FilterTypes.DATE },
                { name: "Data zakończenia", field: "date_end", valueFormatter: dateFormatter, filterType: FilterTypes.DATE }
            );
        } else {
            tmp.push(
                { name: "Cykl", field: "cycle", filterType: FilterTypes.NUMBER },
                { name: "Aktualny cykl", field: "actCycle", valueFormatter: booleanFormatter, filterType: FilterTypes.STRING },
            );
        }
        return tmp;
    }, [dateFormatter, booleanFormatter, valueFormatter, userFormatter, animal.AnmGrp]);

    return (
        <Card>
            <TableGrid headers={headers} data={params} showFilter />
        </Card>
    );
}
