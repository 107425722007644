import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getFallHistogram } from '../../../../selectors/animalDocumentsSelectors';
import Chart from '../../../basics/chart/Chart';
import CollapsableContainer from '../../containers/CollapsableContainer';
import { LabelList } from 'recharts';
export default function FallHistogram() {

    const { t } = useTranslation();

    const data = useSelector(getFallHistogram);

    const customLabel = (props) => {
        return props.data.map((item) => {
            return <LabelList position={'top'}/>
           
        })
    }
    const dataDef = useMemo(() => [
        {
            dataKey: "porkhouse",
            color: "green",
            name: t('SType.5'),
            stack: 'fall'
        },
        {
            dataKey: "piglethouse",
            color: "blue",
            name: t('SType.6'),
            stack:'fall'
        },
        {
            dataKey: "other",
            color: "yellow",
            name: t("farms.tabs.mapTab.types.OTHER"),
            custom: customLabel,
            stack:'fall'
        },
    ], [t]);

    return (
        <CollapsableContainer.Card
            header={t("fallHistogram")}
            defaultExpanded={true}>
            <div className={"h-0 mh-15rem"}>
                <Chart
                    Yaxis={{
                        name: t("objectDescription.ANIMAL.AnmCnt._title")
                    }}
                    Xaxis={{
                        dataKey: "reason",
                        name: t("reason")
                    }}
                    data={data}
                    type={"Bar"}
                    isAnimationActive={false}
                    dataDef={dataDef}
                    saveAsExcell={t("fallHistogram")}
                />
            </div>
        </CollapsableContainer.Card>
    )
}
