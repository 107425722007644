import AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
// import { getCalculateResultsAfterSowCycles, getDaysForRepeatedInsemination, getFirstCycleIndex, getFirstTechnologyGroupStart, getMaximumIntervalBetweenSeparations, getTechnologyGroupWeeks, getTimeFromInseminationToPartuition, getTimeFromInseminationToPregnancy, getTimeOnBirthRoomMommy, getValidationSettings } from "./SettingsUtils";
import animalsDB from "../database/animalsDB";
import saveAs from "file-saver";
import { first, get } from "lodash";
import { GeneratorTableColumns, GeneratorTranslator } from "./SowCardGeneratorUtils";
import store from "../store/store";
import { getValidationSettings } from "./SettingsUtils";

export async function printAnimalWorker(props, animalType) {
    const template = props.template;
    let technologyGroupPrintCardWorkerInstance = null;
    return new Promise((resolve, reject) => {
        const { races, buildingsMap, chosenAnimals: _chosenAnimals, medicines, t, FarmID, commentDictionary, events = [], animals = [] } = props;
        let utils;
        let groupName;
        const chosenAnimals = _chosenAnimals?.length ? _chosenAnimals : [props.utils.animal || props.utils.group]
        if (!chosenAnimals[0].events?.length) {
            chosenAnimals[0].events = events || [];
        }
        const cycleSettings = get(store.getState(), "settings.general.SetData.Settings.Cycle", {});
        cycleSettings.validations = getValidationSettings();



        if (animalType === AnimalTypes.SOW) {

            groupName = props.groupName || props.utils.AnmNo1;

            const boars = animalsDB.getAllAnimals(
                FarmID,
                AnimalTypes.BOAR,
                true,
                false,
                true
            );
            utils = {
                commentDictionary,
                boars,
                races,
                buildingsMap,
                medicines,
                template,
            };
        } else {
            const giltsCnt = animals.filter(({ AnimalKind }) => AnimalKind === AnimalTypes.RENOVATION_SOW).reduce((acc, animal) => acc + animal.AnmCnt, 0);
            const firstEvent = first(events);
            utils = {
                buildingsMap,
                medicines,
                template,
                ...props.utils,
                firstEvent,
                giltsCnt,
                animals,
            };
            groupName = props.utils.group?.GrNo1 || props.utils.animal?.AnmNo1;
        }
        technologyGroupPrintCardWorkerInstance = new Worker(new URL("../workers/prepareTechnologyGroupPrintCardFromGenerator.worker", import.meta.url));
        const TableTranslations = {};
        for (let key in GeneratorTableColumns) {
            const name = GeneratorTableColumns[key];
            TableTranslations[name] = GeneratorTranslator(name);
        }

        technologyGroupPrintCardWorkerInstance.postMessage({
            chosenAnimals,
            groupName,
            t: {
                day: t('day'),
                days: t('days'),
                //Table
                ...TableTranslations,
            },
            settings: cycleSettings,
            utils
        });
        technologyGroupPrintCardWorkerInstance.onmessage = event => {
            if (event.data.error) reject(new Error(event.data.error));
            else {
                const {
                    result: { blob },
                } = event.data;
                saveAs(blob, `${groupName.replace(/[ :]+/g, '_')}.pdf`);
                resolve();
                props.handleHide();
            }
        };
    });
}