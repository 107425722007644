const animalIndex = "SELECT VALUE [value] FROM ParamsTable WHERE AnmID = ? AND key = 'index'";
const averageLiveBorn = "SELECT VALUE AVG([value]) FROM ParamsTable WHERE AnmID = ? AND key = 'liveBornPiglet'";
const averageBornWeight = "SELECT VALUE SUM(piglets * weight) / SUM(piglets) FROM (SELECT LAST_NOT_NULL(CASE WHEN key = 'bornPiglet' THEN [value] END) as piglets, LAST_NOT_NULL(CASE WHEN key = 'bornPigletWeight' THEN [value] END) as weight FROM ParamsTable WHERE (key = 'bornPiglet' OR key = 'bornPigletWeight') AND AnmID = ? GROUP BY cycle)";
const averageWeanedPiglets = "SELECT VALUE AVG([value]) FROM ParamsTable WHERE AnmID = ? AND key = 'weanedPiglet'";
const averageWeanedWeight = "SELECT VALUE SUM(s1.piglets * s2.weight) / SUM(s1.piglets) FROM (SELECT EvID, [value] as piglets FROM ParamsTable WHERE AnmID = ? AND key = 'weanedPiglet') as s1 INNER JOIN (SELECT EvID, [value] as weight FROM ParamsTable WHERE AnmID = ? AND key = 'weanedPigletWeight') as s2 ON s1.EvID = s2.EvID";
const averageIdleDays = "SELECT VALUE AVG([value]) FROM ParamsTable WHERE AnmID = ? AND key = 'idleDays'";
const averageLactationDays = "SELECT VALUE AVG([value]) FROM ParamsTable WHERE AnmID = ? AND key = 'lactationDays'";
const pigletMortality = "SELECT VALUE AVG(mortality) FROM (SELECT SUM(CASE WHEN key = 'deadPiglet' THEN [value] END) / LAST_NOT_NULL(CASE WHEN key = 'liveBornPiglet' THEN [value] END) * 100 as mortality FROM ParamsTable WHERE AnmID = ? AND (key = 'deadPiglet' OR key = 'liveBornPiglet') GROUP BY cycle)";

const animalIndexMax = "SELECT VALUE MAX([value]) FROM ParamsTable WHERE key = 'index'";
const averageLiveBornMax = "SELECT VALUE MAX(averageBorn) FROM (SELECT AVG([value]) as averageBorn FROM ParamsTable WHERE key = 'liveBornPiglet' GROUP BY AnmID)";
const averageBornWeightMax = "SELECT VALUE MAX(average) FROM (SELECT SUM(piglets * weight) / SUM(piglets) as average FROM (SELECT AnmID, LAST_NOT_NULL(CASE WHEN key = 'bornPiglet' THEN [value] END) as piglets, LAST_NOT_NULL(CASE WHEN key = 'bornPigletWeight' THEN [value] END) as weight FROM ParamsTable WHERE key = 'bornPiglet' OR key = 'bornPigletWeight' GROUP BY AnmID, cycle) GROUP BY AnmID)";
const averageWeanedPigletsMax = "SELECT VALUE MAX(average) FROM (SELECT AVG([value]) as average FROM ParamsTable WHERE key = 'weanedPiglet' GROUP BY AnmID)";
const averageWeanedWeightMax = "SELECT VALUE MAX(weight) FROM (SELECT SUM(s1.piglets * s2.weight) / SUM(s1.piglets) as weight FROM (SELECT AnmID, EvID, [value] as piglets FROM ParamsTable WHERE key = 'weanedPiglet') as s1 INNER JOIN (SELECT AnmID, EvID, [value] as weight FROM ParamsTable WHERE key = 'weanedPigletWeight') as s2 ON s1.EvID = s2.EvID GROUP BY AnmID)";
const averageIdleDaysMax = "SELECT VALUE MAX(average) FROM (SELECT AVG([value]) as average FROM ParamsTable WHERE key = 'idleDays' GROUP BY AnmID)";
const averageLactationDaysMax = "SELECT VALUE MAX(average) FROM (SELECT AVG([value]) as average FROM ParamsTable WHERE key = 'lactationDays' GROUP BY AnmID)";

const liveBornInfo = "SELECT cycle, LAST(group_nr) as [group], SUM(CASE WHEN key = 'liveBornPiglet' THEN [value] END) as born, SUM(CASE WHEN key = 'deadBornPiglet' THEN [value] END) as dead, SUM(CASE WHEN key = 'mummyBornPiglet' THEN [value] END) as mummy, SUM(CASE WHEN key = 'giltsBornPiglet' THEN [value] END) as gilts, SUM(CASE WHEN key = 'weakBornPiglet' THEN [value] END) as weak FROM ParamsTable WHERE AnmID = ? AND key in ('liveBornPiglet', 'deadBornPiglet', 'mummyBornPiglet', 'weakBornPiglet', 'giltsBornPiglet') GROUP BY cycle ORDER BY cycle";
const bornWeightInfo = "SELECT cycle, LAST(group_nr) as [group], SUM(CASE WHEN key = 'liveBornPiglet' THEN [value] END) as born, SUM(CASE WHEN key = 'bornPigletWeight' THEN [value] END) as weight FROM ParamsTable WHERE AnmID = ? AND key IN ('liveBornPiglet', 'bornPigletWeight') GROUP BY cycle ORDER BY cycle";
const weanedPigletsInfo = "SELECT cycle, LAST(group_nr) as [group], SUM(CASE WHEN key = 'weanedPiglet' THEN [value] END) as weaned, SUM(CASE WHEN key = 'liveBornPiglet' THEN [value] END) as born, SUM(CASE WHEN key = 'deadPiglet' THEN [value] END) as fall, SUM(CASE WHEN key = 'fostering' THEN [value] END) as fostering FROM ParamsTable WHERE AnmID = ? AND key IN ('weanedPiglet', 'liveBornPiglet', 'deadPiglet', 'fostering') GROUP BY cycle ORDER BY cycle";
const weanedWeightInfo = "SELECT cycle, LAST(group_nr) as [group], SUM(CASE WHEN key = 'weanedPigletWeight' THEN [value] END) as weight, SUM(CASE WHEN key = 'weanedPiglet' THEN [value] END) as weaned, LAST_NOT_NULL(CASE WHEN key = 'activeNipples' THEN [value] END) as active FROM ParamsTable WHERE AnmID = ? AND key in ('weanedPigletWeight', 'weanedPiglet', 'activeNipples') GROUP BY cycle ORDER BY cycle";
const idleDaysInfo = "SELECT cycle, LAST(group_nr) as [group], SUM(CASE WHEN key = 'idleDays' THEN [value] END) as idle FROM ParamsTable WHERE AnmID = ? AND key = 'idleDays' GROUP BY cycle ORDER BY cycle";
const lactationDaysInfo = "SELECT cycle, LAST(group_nr) as [group], SUM(CASE WHEN key = 'lactationDays' THEN [value] END) as lactation, SUM(CASE WHEN key = 'lactationMommyDays' THEN [value] END) as mommy FROM ParamsTable WHERE AnmID = ? AND key IN ('lactationDays', 'lactationMommyDays') GROUP BY cycle ORDER BY cycle";
const pigletMortalityInfo = "SELECT cycle, LAST(group_nr) as [group], SUM(CASE WHEN key = 'liveBornPiglet' THEN [value] END) as born, SUM(CASE WHEN key = 'deadPiglet' THEN [value] END) as dead FROM ParamsTable WHERE AnmID = ? AND key IN ('liveBornPiglet', 'deadPiglet') GROUP BY cycle ORDER BY cycle";
const indexInfo = "SELECT cycle, LAST(group_nr) as [group], SUM(CASE WHEN key = 'idleDays' THEN [value] END) as idle, SUM(CASE WHEN key = 'gestationDays' THEN [value] END) as gestation, SUM(CASE WHEN key = 'lactationDays' THEN [value] END) as lactation, SUM(CASE WHEN key = 'gestationDays' OR key = 'lactationDays' THEN [value] END) as productive FROM ParamsTable WHERE AnmID = ? AND key IN ('idleDays', 'gestationDays', 'lactationDays') GROUP BY cycle ORDER BY cycle";

export const sowResultSelects = {
    animalIndex,
    averageLiveBorn,
    averageBornWeight,
    averageWeanedPiglets,
    averageWeanedWeight,
    averageIdleDays,
    averageLactationDays,
    pigletMortality
};

export const sowResultsAutoRange = {
    animalIndexMax,
    averageLiveBornMax,
    averageBornWeightMax,
    averageWeanedPigletsMax,
    averageWeanedWeightMax,
    averageIdleDaysMax,
    averageLactationDaysMax
};

export const sowResultsInfo = {
    liveBornInfo,
    bornWeightInfo,
    weanedPigletsInfo,
    weanedWeightInfo,
    idleDaysInfo,
    lactationDaysInfo,
    pigletMortalityInfo,
    indexInfo
};