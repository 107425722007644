/**
 * shared between multiple selectors
 */
export default {
    // gets current farm id
    getFarmID: (state) => state.location.farm,
    // gets mod time of groups
    getGroupDtaModTime: (state) => state.groups?.groupTime,
    // gets info if managing subgroups (porkers/piglets) is enabled
    getManageSubgroups: () => false,
    // getManageSubgroups: (state) => get(state, "settings.general.SetData.Settings.Cycle.ManageSubGroups", false)
    // get mod time of animals
    getAnimalDtaModTime: (state) => state.animals?.time,
    // get client id
    getClientID: (state) => state.user.user.ClientID,
    // get devices
    getDevices: (state) => state.farmDevices.devices
};
