import { aggregatedDataID } from "../utils/DevicesUtils";
import { createSelector } from "reselect";
import { maxBy } from "lodash";
import { getAggregatedData, getAggregatedDataLoading } from "./aggregatedDataSelector";

const defaultEmptyArray = [];

export const getAggData = (state, { DevID, index = null, dataType }) =>
    state.aggregatedData.data[aggregatedDataID({ DevID, index, dataType })] || defaultEmptyArray;

export const getFetching = (state, { DevID, index = null, dataType }) =>
    state.aggregatedData.fetching[aggregatedDataID({ DevID, index, dataType })]

export const getLastFetched = (state, { DevID, index = null, dataType }) =>
    state.aggregatedData.lastFetched[aggregatedDataID({ DevID, index, dataType })]

export const makeGetAggregatedData = () => {
    return createSelector(
        [getAggData, getFetching, getLastFetched],
        (_data, _fetching, _lastFetched) => {
            return {
                data: _data,
                fetching: !!_fetching,
                lastFetched: _lastFetched || 0,
                lastModTime: maxBy(_data, (o) => o.DaMoTi)?.DaMoTi || 0
            }
        });
}

const getDevIDsFromProps = (state, props) => props.DevIDs;

const getTimeForAggData = (state, props) => props.time;

export const getAggregatedDataReportCompare = createSelector(getAggregatedData, getAggregatedDataLoading, getDevIDsFromProps, getTimeForAggData, (data, fetching, DevIDs, time) => {
    let loading = false;
    const d = {};
    for (let DevID of DevIDs) {
        if (fetching[DevID]) loading = true;
        d[DevID] = data[DevID]?.filter(item => {
            if (typeof time === "number") return item.AggTi === time;
            return item.AggTi >= time.start && item.AggTi <= time.end;
        }) || [];
    }
    return {
        data: d,
        loading: loading
    }
})

const getDevices = (_, devices) => devices;

const getFrom = (_, __, from) => from;

const getTo = (_, __, ___, to) => to;

export const makeGetAggregatedDataForDevices = () => createSelector(getAggregatedData, getAggregatedDataLoading, getDevices, getFrom, getTo, (aggData, fetching, devices, from, to) => {
    const obj = { loading: false };
    for (let device of devices) {
        if (fetching[device.DevID]) obj.loading = true;
        let dataForDevice = aggData[device.DevID] || defaultEmptyArray;
        if (from) dataForDevice = dataForDevice.filter(item => item.AggTi >= from);
        if (to) dataForDevice = dataForDevice.filter(item => item.AggTi <= to);
        obj[device.DevID] = dataForDevice.sort((a, b) => a.AggTi - b.AggTi);
    }
    return obj;
})