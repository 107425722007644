import {invokeApig} from "../libs/awsLib";
import moment from "moment"
import Paths from "../api/paths";
import {cloneDeep, isArray, isFinite, isNil, isObject} from "lodash";
import {getDeviceData as getDeviceDataAPI} from "../api/deviceData/getDeviceData";

/**
 *
 * @param device
 * @param params
 * @param params.AggDataTime {number} czas dla pojedyneczego rekodru
 * @param params.AggDataTimeFrom {number} zakres od
 * @param params.AggDataTimeTo {number} zakres do
 * @param params.index {number|null|undefined} index dla urzadzenia
 * @param onSuccess
 * @param onFailure
 * @return {function(...[*]=)}
 */
export function getAggregatedData(device, params = {}, onSuccess = () => {
}, onFailure = () => {
}) {
    return function (dispatch) {
        let id = device.DevID;
        if (!Object.keys(params).filter(o => o.startsWith("AggDataTime")).length) {
            params.AggDataTime = moment.utc().startOf("day").toDate().getTime();
        }
        if (isFinite(params.index)) {
            id += `_${params.index}`;
            delete params.index;
        }
        if (!isNil(params.dataType)) {
            id = `${params.dataType}#${id}`;
            delete params.dataType;
        }
        dispatch({
            type: "FETCH_AGGREGATED_DATA",
            payload: invokeApig({
                ...Paths.getAggregatedData(device.DevID, {farmID: device.FarmID}),
                queryParams: {
                    table: "AggData",
                    ...params,
                    DevIDAggData: id
                }
            }),
            meta: {
                DevID: id,
                partial: !!Object.keys(params).filter(o => o.startsWith("ESK")).length
            }
        }).then(res => {
            console.log(res);
            //stary sposob
            if (isArray(res?.value)) {
                onSuccess(res);
            } else if (isObject(res?.value)) {
                //nowy sposob
                const LastEvalKey = cloneDeep(res?.value?.LastEvaluatedKey);
                if (LastEvalKey) {
                    const lastEvalKeyParams = {};
                    for (let [key, value] of Object.entries(LastEvalKey)) {
                        lastEvalKeyParams[`ESK${key}`] = value;
                    }
                    dispatch(getAggregatedData(device, {
                        ...params,
                        ...lastEvalKeyParams,
                    }, onSuccess, onFailure));
                } else {
                    onSuccess();
                }
            }
        }).catch(e => {
            console.error(e);
            onFailure(e);
        });
        dispatch({
            type: "FETCH_AGGREGATED_DATA",
            payload: id
        })
    }
}

export function getDeviceData({Start, End, Placements, Type}) {
    return function (dispatch) {
        const plcmnt = Placements.slice().sort();
        dispatch({
            type: "FETCH_DEVICE_DATA",
            payload: getDeviceDataAPI({Start, End, Placements: plcmnt, Type}),
            meta: {Start, End, Placements: plcmnt, Type}
        })
    }
}