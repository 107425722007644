import {cloneDeep, isArray, isNil, isObject} from "lodash";
import {detailedDiff} from "deep-object-diff";
import parser from "../components/svg-editor/utils/parser";

export const initialValue = {
    levels: [[], [], [], [], [], [], [], [], [], [], [], [], []],
    selectedLevel: 0,
    focusedId: null,
    history: [],
    size: {width: 5000, height: 5000},
    modificationTime: 0
};

export default function editFarmMapReducer(state = initialValue, action) {
    switch (action.type) {
        case "SET_FOCUS_FARM_MAP": {
            return {
                ...state,
                focusedId: action.payload
            }
        }
        case "SET_SELECTED_LEVEL_FARM_MAP": {
            return {
                ...state,
                selectedLevel: action.payload
            }
        }
        case "INITIALIZE_FARM_MAP": {
            const newLevels = [...state.levels];
            for (let i = 0; i < action.payload.levels.length; i++) {
                if (action.payload.levels[i]) {
                    newLevels[i] = action.payload.levels[i];
                } else {
                    newLevels[i] = [];
                }
            }
            return {
                ...initialValue,
                levels: newLevels,
                size: action.payload.size
            }
        }
        case "RESIZE_CANVAS_FARM_MAP": {
            const {width, height, align} = action.payload;
            let newLevels = cloneDeep(state.levels);
            const oldLevels = cloneDeep(state.levels);
            newLevels = newLevels.map(level => ([
                ...level.map((item) => {
                    let tmp = item;
                    const parsed = parser(item.type, item);
                    if (parsed) {
                        tmp = {
                            ...item,
                            ...parsed.transformCanvasResize(state.size.width, state.size.height, width, height, align).getParams()
                        }
                    }
                    return tmp;
                })
            ]))
            const diff = {
                ...detailedDiff(newLevels, oldLevels),
                focusedId: state.focusedId,
                selectedLevel: state.selectedLevel,
                size: cloneDeep(state.size)
            };
            const history = [...state.history, diff];
            return {
                ...state,
                levels: newLevels,
                history,
                size: {width, height},
                modificationTime: +new Date()
            };
        }
        // case "ADD_LEVEL_FARM_MAP": {
        //     const newLevels = [...state.levels];
        //     const oldLevels = cloneDeep(state.levels);
        //     newLevels[action.payload] = [];
        //     const diff = {
        //         ...detailedDiff(newLevels, oldLevels),
        //         focusedId: state.focusedId,
        //         selectedLevel: state.selectedLevel,
        //         size: cloneDeep(state.size)
        //     };
        //     const history = [...state.history, diff];
        //     return {
        //         ...state,
        //         history,
        //         levels: newLevels,
        //         selectedLevel: action.payload,
        //         modificationTime: +new Date()
        //     }
        // }
        // case "REMOVE_LEVEL_FARM_MAP": {
        //     const newLevels = [...state.levels];
        //     const oldLevels = cloneDeep(state.levels);
        //     newLevels[action.payload] = [];
        //     if (newLevels.length === 0) newLevels.push([]);
        //     const diff = {
        //         ...detailedDiff(newLevels, oldLevels),
        //         focusedId: state.focusedId,
        //         selectedLevel: state.selectedLevel,
        //         size: cloneDeep(state.size)
        //     };
        //     const history = [...state.history, diff];
        //     return {
        //         ...state,
        //         levels: newLevels,
        //         history,
        //         selectedLevel: Math.max(action.payload - 1, 0),
        //         modificationTime: +new Date()
        //     }
        // }
        case "CHANGE_BULK_FARM_MAP_ITEM": {
            const newLevels = [...state.levels];
            const oldLevels = cloneDeep(state.levels);
            if (!newLevels[state.selectedLevel]) newLevels[state.selectedLevel] = [];
            newLevels[state.selectedLevel] = [...newLevels[state.selectedLevel]];
            for (let newItem of action.payload) {
                const item = {
                    ...parser(newItem.type, newItem).getParams(),
                    id: newItem.id,
                    type: newItem.type
                }
                const index = newLevels[state.selectedLevel].findIndex(o => o.id === item.id);
                if (index !== -1) {
                    newLevels[state.selectedLevel][index] = item;
                } else {
                    newLevels[state.selectedLevel].push(item);
                }
            }
            const diff = {
                ...detailedDiff(newLevels, oldLevels),
                focusedId: state.focusedId,
                selectedLevel: state.selectedLevel,
                size: cloneDeep(state.size)
            };
            const history = [...state.history, diff];
            return {
                ...state,
                levels: newLevels,
                history,
                modificationTime: +new Date()
            }
        }
        case "CHANGE_FARM_MAP_ITEM":
        case "ADD_FARM_MAP_ITEM": {
            const newLevels = [...state.levels];
            const oldLevels = cloneDeep(state.levels);
            if (!newLevels[state.selectedLevel]) newLevels[state.selectedLevel] = [];
            newLevels[state.selectedLevel] = [...newLevels[state.selectedLevel]];
            const item = {
                ...parser(action.payload.type, action.payload).getParams(),
                id: action.payload.id,
                type: action.payload.type,
                layer: action.payload.layer
            }
            console.log(item);
            const index = newLevels[state.selectedLevel].findIndex(o => o.id === item.id);
            if (index !== -1) {
                const layer = newLevels[state.selectedLevel][index].layer;
                newLevels[state.selectedLevel][index] = item;
                newLevels[state.selectedLevel][index].layer = layer;
            } else {
                newLevels[state.selectedLevel].push(item);
            }
            const diff = {
                ...detailedDiff(newLevels, oldLevels),
                focusedId: state.focusedId,
                selectedLevel: state.selectedLevel,
                size: cloneDeep(state.size)
            };
            const history = [...state.history, diff];
            return {
                ...state,
                levels: newLevels,
                history,
                modificationTime: +new Date()
            }
        }
        case "REMOVE_FARM_MAP_ITEM": {
            const newLevels = [...state.levels];
            const oldLevels = cloneDeep(state.levels);
            const newFocusedId = state.focusedId === action.payload ? null : state.focusedId;
            newLevels[state.selectedLevel] = [...(state.levels[state.selectedLevel] || [])].filter(o => o.id !== action.payload);
            const diff = {
                ...detailedDiff(newLevels, oldLevels),
                focusedId: state.focusedId,
                selectedLevel: state.selectedLevel,
                size: cloneDeep(state.size)
            };
            const history = [...state.history, diff];
            return {
                ...state,
                levels: newLevels,
                history,
                focusedId: newFocusedId,
                modificationTime: +new Date()
            }
        }
        case "HISTORY_GO_BACK": {
            const newLevels = [...state.levels];
            const oldLevels = cloneDeep(state.levels);
            const newHistory = [...state.history];
            const diff = newHistory.pop();
            const keysToOverride = ["focusedId", "selectedLevel", "size"];
            const overrideParams = {};
            for (let key of keysToOverride) {
                overrideParams[key] = cloneDeep(state[key]);
            }
            if (diff) {
                for (let key of keysToOverride) {
                    overrideParams[key] = cloneDeep(diff[key]);
                }
                ["added", "deleted", "updated"].forEach(type => {
                    for (let levelString in diff[type]) {
                        const level = +levelString;
                        const obj = diff[type][level];
                        if (isArray(obj) && type === "added") {
                            newLevels[level] = obj;
                        } else if (isNil(obj) && type === "deleted") {
                            newLevels[level] = [];
                        } else if (isObject(obj)) {
                            newLevels[level] = [...(newLevels[level] || [])];
                            for (let indexString in obj) {
                                const index = +indexString;
                                const params = diff[type][level][index];
                                switch (type) {
                                    case "added": {
                                        newLevels[level][index] = params;
                                        break;
                                    }
                                    case "updated": {
                                        newLevels[level][index] = {...newLevels[level][index], ...params}
                                        for (let key in params) {
                                            if (params[key] === undefined) {
                                                delete newLevels[level][index][key];
                                            }
                                        }
                                        break;
                                    }
                                    case "deleted": {
                                        if (params) {
                                            for (let key in params) {
                                                delete newLevels[level][index][key];
                                            }
                                        } else {
                                            newLevels[level].splice(index, 1);
                                        }
                                        break;
                                    }
                                    default:
                                        break;
                                }
                            }

                        }
                    }
                })
            }
            console.log(diff, oldLevels);
            return {
                ...state,
                levels: newLevels,
                history: newHistory,
                ...overrideParams,
                modificationTime: +new Date()
            }
        }
        case "DESTROY_FARM_MAP":
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
