import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "./_menu.scss";
import {isEqual, isString} from "lodash";
import {bodyLockScroll, bodyUnlockScroll, getRemToPx, isElementFixed, ScrollLock} from "../../../utils/DOMUtils";
import {CSSTransition} from "react-transition-group";
import {BreakPoint} from "../../../constans/breakpointTypes";
import Button from "../button/Button";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {cloneFast} from "../../../utils/Utils";


const isDEBUG = false;

const debug = (...args) => isDEBUG ? console.log(isString(args[0]) ? "[MENU DEBUG] " + args[0] : "[MENU DEBUG] ", ...isString(args[0]) ? args.slice(1) : args) : null;

class Menu extends React.Component {

    state = {
        isOpen: !!this.props.event
    }
    rootNode = document.getElementById('root');
    menu = React.createRef();

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.isOpen !== this.state.isOpen && !this.state.isOpen) {
            document.removeEventListener("click", this.onClickOutside, {capture: true});
            bodyUnlockScroll(ScrollLock.MENU);
        }
        if (this.props.event && !isEqual(prevProps.event, this.props.event)) {
            this.setState({isOpen: true});
            document.addEventListener("click", this.onClickOutside, {capture: true});
            bodyLockScroll(ScrollLock.MENU);
        }
    }

    calculatePosition = () => {
        if (this.props.event && this.menu.current) {
            const isFixed = this.isFixed();
            const scrollY = this.getScroll();
            debug("is button %s", isFixed)
            const positions = [
                {
                    measureFromLeft: false,
                    measureFromTop: false,
                    fits: "no"
                },
                {
                    measureFromLeft: true,
                    measureFromTop: false,
                    fits: "no"
                },
                {
                    measureFromLeft: false,
                    measureFromTop: true,
                    fits: "no"
                },
                {
                    measureFromLeft: true,
                    measureFromTop: true,
                    fits: "no"
                }
            ]
            // stale
            const menuWidth = this.menu.current.clientWidth,
                menuHeight = this.menu.current.clientHeight;
            const windowHeight = window.innerHeight;
            const windowWidth = window.innerWidth;
            const {pageX, pageY} = this.props.event;
            // funkcje pomocnicze
            const setTransform = (x = null, y = null) => {
                const transform = [];
                if (x) {
                    transform.push(`translateX(${x}px)`);
                }
                if (y) {
                    transform.push(`translateY(${y}px)`);
                }
                this.menu.current.style.transform = transform.join(" ");
            }
            const setFullscreen = (isFullScreen) => {
                this.menu.current.classList[isFullScreen ? "add" : "remove"]("mobile");
            }
            const setFixed = (isFixed) => {
                this.menu.current.style.position = isFixed ? 'fixed' : "";
            }
            const setBorder = (measureFromLeft = null, measureFromTop = null) => {
                if (measureFromLeft === null) {
                    this.menu.current.style.borderRadius = "0px";
                    return;
                }
                const border = [];
                const value = 2 * +measureFromTop + +measureFromLeft;
                switch (value) {
                    case 0:
                        border.push(...[0, 0.5, 0.5, 0.5]);
                        break;
                    case 1:
                        border.push(...[0.5, 0, 0.5, 0.5]);
                        break;
                    case 2:
                        border.push(...[0.5, 0.5, 0.5, 0]);
                        break;
                    case 3:
                        border.push(...[0.5, 0.5, 0, 0.5]);
                        break;
                    default:
                        border.push(0.5);
                }
                this.menu.current.style.borderRadius = border.map(o => `${o}rem`).join(" ");
            };
            //
            const paddingRem = 1;
            const paddingPx = getRemToPx(paddingRem) + 2; // dodajemy dwa bo jest border po dwoch stronach
            const fitsInViewport = (((menuWidth + paddingPx) <= windowWidth) && ((menuHeight + paddingPx) <= windowHeight));
            debug("fits in viewport %s", fitsInViewport);
            if (fitsInViewport) {
                for (let i = 0; i < positions.length; i++) {
                    const offsetX = menuWidth;
                    const offsetY = menuHeight;
                    debug("index %s", i);
                    if (isFixed) {
                        const {event: {nativeEvent: {clientX: buttonX, clientY: buttonY} = {}} = {}} = this.props;
                        positions[i].x = positions[i].measureFromLeft ? buttonX - offsetX : buttonX
                        positions[i].y = positions[i].measureFromTop ? buttonY - offsetY : buttonY
                    } else {
                        // positions[i].x = positions[i].measureFromLeft ? pageX - offsetX : pageX;
                        // positions[i].y = positions[i].measureFromTop ? pageY - offsetY : pageY;
                        positions[i].x = pageX;
                        positions[i].y = pageY;
                    }
                    debug("x=%s y=%s", positions[i].x, positions[i].y);
                    positions[i].offsetX = windowWidth - (positions[i].x + offsetX) - paddingPx;
                    debug("offsetX=%s", positions[i].offsetX);
                    if (positions[i].offsetX > 0) positions[i].offsetX = 0;
                    positions[i].offsetY = (windowHeight + (isFixed ? 0 : scrollY)) - (positions[i].y + offsetY) - paddingPx;
                    debug("offsetY=%s", positions[i].offsetY);
                    if (positions[i].offsetY > 0) positions[i].offsetY = 0;
                    positions[i].fits = (positions[i].offsetY || positions[i].offsetX) ? "almost" : "fits";
                    positions[i].index = i;
                }
            }
            const isTooBig = menuHeight >= windowHeight || !fitsInViewport;

            // warunki
            if (isTooBig || windowWidth < BreakPoint.XS) {
                setFullscreen(true);
                setTransform(null);
                setBorder(null);
            } else {
                setFullscreen(false);
                // const p = positions.slice(0).sort((o1,o2)=> o1 - o2);
                for (let p of positions) {
                    if (p.fits === "fits") {
                        debug("fits", cloneFast(p));
                        setTransform(p.x, p.y);
                        setFixed(isFixed);
                        setBorder(p.measureFromLeft, p.measureFromTop);
                        return;
                    }
                }
                for (let p of positions) {
                    if (p.fits === "almost") {
                        debug("almost", cloneFast(p));
                        setTransform(p.x + p.offsetX, p.y + p.offsetY);
                        setFixed(isFixed);
                        setBorder(5);
                        return;
                    }
                }
            }
        }
    };

    componentWillUnmount() {
        bodyUnlockScroll(ScrollLock.MENU);
        document.removeEventListener('click', this.onClickOutside);
    }

    onClickOutside = (event) => {
        if (this.menu.current && !this.menu.current.contains(event.target)) {
            event.stopPropagation();
            event.preventDefault();
            event.stopImmediatePropagation();
        }
        bodyUnlockScroll(ScrollLock.MENU);
        this.setState({
            isOpen: false
        });
        this.props.closeCallback();
    };

    isFixed = () => {
        const {event} = this.props;
        debug(event?.target?.localName, event?.target?.className)
        if (!!event && isString(event?.target?.localName)) {
            return isElementFixed(event?.target, {includeSticky: false});
        }
    };

    getScroll = () => {
        return window.scrollY || 0;
    };

    render() {
        const {children, appear, t, className} = this.props;
        const {isOpen} = this.state;
        return ReactDOM.createPortal((
            <CSSTransition in={isOpen} unmountOnExit onEnter={this.calculatePosition} appear={appear}>
                <div className={`menu-container${className ? ` ${className}` : ""}`} ref={this.menu}>
                    <div className={"menu"}>
                        <ul>
                            {children}
                        </ul>
                        <div className={"cancel-button"}>
                            <Button buttonColor={"menu-color"} stopPropagation={false}
                                    icon={<i className={"fa-fw fas fa-times"}/>}
                                    type={"button"}>{t("addStandToDispenser.cancel")}</Button>
                        </div>
                    </div>
                </div>

            </CSSTransition>
        ), this.rootNode)
    }

}

Menu.propTypes = {
    event: PropTypes.object,
    cornerRadius: PropTypes.bool,
    appear: PropTypes.bool,
    closeCallback: PropTypes.func
};

Menu.defaultProps = {
    cornerRadius: true,
    appear: false,
    closeCallback: () => {
    }
};


export default compose(
    withTranslation()
)(Menu);
