import {get} from "lodash";
import moment from "moment";
import React from "react";
import {Modal} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {Field, FormSection, reduxForm} from "redux-form";
import {connectModal, show} from "redux-modal";
import {
    getDetailedLocationDays,
    getMergedInsertionEventsForSubgroupAnimal, makeGetAnimalsInGroup
} from "../../../selectors/animalDocumentsSelectors";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import ModalHeader from "../ModalHeader";
import {submit, validate} from "./EditInsertionsModalSubmit";
import {formatLocationName} from "../../../utils/global-formatters/formatLocationName";
import ConfirmModificationField from "../../custom-fields/ConfirmModificationField";

export const ModalName = "edit-insertions-modal-name";

function makeMapStateToProps() {
    const getAnimalsInGroup = makeGetAnimalsInGroup();
    const params = {
        getDeletedAnimals: true,
    }
    return (state) => ({
        animals: getAnimalsInGroup(state, params),
        mergedInsertions: getMergedInsertionEventsForSubgroupAnimal(state),
        locationDays: getDetailedLocationDays(state)
    });
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch),
    };
}

class EditInsertionsModal extends React.Component {

    constructor(props) {
        super(props);
        const {initialize, mergedInsertions: {insertions} = {}} = this.props;
        initialize({
            insertions
        });
    }

    parseAnmCnt = value => value !== "" ? +value : null;

    getAnimalNumberFormatter(anmID, insertionTime) {
        const {animals, canManageSubgroups, t} = this.props;
        const animal = animals.find((a) => a.AnmID === anmID);
        const time = t("insertionFrom", {date: moment.utc(+insertionTime).format(moment.HTML5_FMT.DATE)});
        if (!animal) return time;
        if ((get(animal, "AnmCnt", 1) === 1 && (get(animal, "RFID", "") || get(animal, "Tagged", false))) || canManageSubgroups) {
            return `${animal.AnmNo1}  ${time}`;
        }
        return `${formatLocationName(animal.PlcmntID)}  ${time}`;
    }

    getInsertionFields() {
        const {mergedInsertions: {insertions} = {}} = this.props;
        const fields = [];
        for (const name in insertions) {
            const insertionDetails = name.split("_");
            const insertionTime = insertionDetails[1];
            const anmID = insertionDetails[2];
            fields.push(
                <Field
                    name={name}
                    component={ReduxLabeledInput}
                    label={this.getAnimalNumberFormatter(anmID, insertionTime)}
                    type={"number"}
                    parse={this.parseAnmCnt}
                />
            )
        }
        return fields;
    }

    render() {
        const {t, show, handleHide, handleSubmit, dirty, submitting, group, invalid} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader title={t("insertionsEdition")} onCloseClick={handleHide}/>
                    <ModalBody>
                        <FormSection name={"insertions"}>
                            {this.getInsertionFields()}
                        </FormSection>
                        <hr/>
                        <ConfirmModificationField name={group?.GrNo1} group={!!group}/>
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton
                        confirmText={t("save")}
                        hasButtonDisabled={invalid || !dirty}
                        formName={ModalName}
                        submitting={submitting}
                        onCloseClick={handleHide}
                    />
                </form>
            </Modal>
        );
    }
}

export default compose(
    connectModal({name: ModalName}),
    withTranslation(),
    connect(makeMapStateToProps, mapDispatchToProps),
    reduxForm({
        form: ModalName,
        onSubmit: submit,
        validate,
    })
)(EditInsertionsModal);