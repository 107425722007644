
import AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import moment from "moment";
import { ParamTypes } from "../constans/paramTypes";
import { SectorType } from "../constans/sectorTypes";
import animalsDB from "../database/animalsDB";
import buildingsDB from "../database/buildingsDB";
import { createParamObject, getGroupDay } from "./AnimalParamUtils";
import { formatTechnoGroupName, getTechnologyGroupByTime } from "./TechnologyGroupUtils";

function createGroupParamObject(key, value, sharedParameters, event, type, location) {
    return createParamObject(key, value, { ...sharedParameters, location: location || "" }, event, type);
}

function createGroupedStatsObject() {
    return {
        inserted: 0,
        transferredTo: 0,
        transferredFrom: 0,
        fallen: 0,
        reclassified: 0,
        sellData: [], // obiekty z iloscia i waga aby liczyc srednia wazona
        start: Infinity,
        end: 0
    };
}

function createGroupedAnimalsObject(event, AnimalKind) {
    const animal = animalsDB.getAnimalById(event.AnmID, { joinEvents: false, findDeleted: true }); // tymczasowo z lokiego
    return {
        AnmID: event.EvData.TransferTo || event.AnmID,
        AnimalKind: AnimalKind ?? animal?.AnimalKind,
        currentAmount: 0,
        weaners: createGroupedStatsObject(),
        porkers: createGroupedStatsObject(),
        gilts: createGroupedStatsObject(),
        animal,
        buyCost: []
    };
}

function manageInsertion(event, groupedAnimals, sharedParameters) {
    const params = [];
    // tworzenie obiektu historii zwierzecia, dwie ilosci aby zliczac aktualna i maksymalna
    if (!groupedAnimals[event.AnmID])
        groupedAnimals[event.AnmID] = createGroupedAnimalsObject(event, event.EvData.AnimalKind);
    groupedAnimals[event.AnmID].currentAmount += event.AnmCnt;
    if (!event.EvData.OldAnmID) {
        const addAmount = (key) => {
            params.push(createGroupParamObject(key, event.AnmCnt, sharedParameters, event, ParamTypes.AMOUNT, event.EvData.PlcmntID));
        };
        if (event.EvData.AnimalKind === AnimalTypes.PORKER) {
            groupedAnimals[event.AnmID].porkers.inserted += event.AnmCnt;
            groupedAnimals[event.AnmID].porkers.start = Math.min(groupedAnimals[event.AnmID].porkers.start, event.EvTime);
            addAmount("finisherInsertedCnt");
        } else if (event.EvData.AnimalKind === AnimalTypes.RENOVATION_SOW) {
            groupedAnimals[event.AnmID].gilts.inserted += event.AnmCnt;
            groupedAnimals[event.AnmID].gilts.start = Math.min(groupedAnimals[event.AnmID].gilts.start, event.EvTime);
            addAmount("giltInsertedCnt");
        } else {
            groupedAnimals[event.AnmID].weaners.inserted += event.AnmCnt;
            groupedAnimals[event.AnmID].weaners.start = Math.min(groupedAnimals[event.AnmID].weaners.start, event.EvTime);
            addAmount("weanedInsertedCnt");
        }
        if (groupedAnimals[event.AnmID].animal?.Price) {
            groupedAnimals[event.AnmID].buyCost.push({ amount: event.AnmCnt, price: groupedAnimals[event.AnmID].animal?.Price });
        }
    }
    return params;
}

function calcAge(birthTime, eventTime) {
    return moment(eventTime).startOf("day").diff(moment(birthTime).startOf("day"), "days");
}

function manageFall(event, groupedAnimals, sharedParameters) {
    if (!groupedAnimals[event.AnmID]) {
        console.warn("Nie ma zwierzecia o takim ID", event, groupedAnimals);
        return [];
    }
    groupedAnimals[event.AnmID].currentAmount -= event.EvData.AnmCnt;
    const params = [];
    const addAmount = key => {
        params.push(createGroupParamObject(key, event.EvData.AnmCnt, sharedParameters, event, ParamTypes.AMOUNT, event.EvData.PlcmntID));
    };
    const addWeight = key => {
        if (event.EvData.Weight) {
            params.push(createGroupParamObject(key, event.EvData.Weight, sharedParameters, event, ParamTypes.WEIGHT, event.EvData.PlcmntID));
        }
    };
    const age = calcAge(groupedAnimals[event.AnmID].animal.DtaBrthTime, event.EvTime);
    const addAge = key => {
        if (groupedAnimals[event.AnmID].animal.DtaBrthTime) {
            params.push(createGroupParamObject(key, age, sharedParameters, event, ParamTypes.AGE, event.EvData.PlcmntID));
        }
    };
    const addReason = key => {
        params.push(createParamObject(key, event.EvData.Reasn, sharedParameters, event, ParamTypes.REASON));
    };
    // TODO dodanie wieku prosiaka
    if (groupedAnimals[event.AnmID].AnimalKind === AnimalTypes.PORKER) {
        groupedAnimals[event.AnmID].porkers.fallen += event.EvData.AnmCnt;
        groupedAnimals[event.AnmID].porkers.end = Math.max(groupedAnimals[event.AnmID].porkers.end, event.EvTime);
        addWeight("finisherPigDeathWeight");
        addAge("finisherPigDeathAge");
        addAmount("finisherPigDeathCnt");
        addReason("finisherPigDeathReason");
    } else if (groupedAnimals[event.AnmID].AnimalKind === AnimalTypes.RENOVATION_SOW) {
        groupedAnimals[event.AnmID].gilts.fallen += event.EvData.AnmCnt;
        groupedAnimals[event.AnmID].gilts.end = Math.max(groupedAnimals[event.AnmID].gilts.end, event.EvTime);
        addWeight("giltPigDeathWeight");
        addAge("giltPigDeathAge");
        addAmount("giltPigDeathCnt");
        addReason("giltPigDeathReason");
    } else {
        groupedAnimals[event.AnmID].weaners.fallen += event.EvData.AnmCnt;
        groupedAnimals[event.AnmID].weaners.end = Math.max(groupedAnimals[event.AnmID].weaners.end, event.EvTime);
        addWeight("weanedPigDeathWeight");
        addAge("weanedPigDeathAge");
        addAmount("weanedPigDeathCnt");
        addReason("weanedPigDeathReason");
    }
    return params;
}

function manageSell(event, groupedAnimals, sharedParameters) {
    if (!groupedAnimals[event.AnmID]) {
        console.warn("Nie ma zwierzecia o takim ID", event, groupedAnimals);
        return [];
    }
    const params = [];
    const addAmount = key => {
        params.push(createGroupParamObject(key, event.AnmCnt, sharedParameters, event, ParamTypes.AMOUNT, event.EvData.PlcmntID));
    };
    const addWeight = key => {
        if (event.EvData.Weight) {
            params.push(createGroupParamObject(key, event.EvData.Weight, sharedParameters, event, ParamTypes.WEIGHT, event.EvData.PlcmntID));
        }
    };
    const age = calcAge(groupedAnimals[event.AnmID]?.animal.DtaBrthTime, event.EvTime);
    const addAge = key => {
        if (groupedAnimals[event.AnmID].animal.DtaBrthTime) {
            params.push(createGroupParamObject(key, age, sharedParameters, event, ParamTypes.AGE, event.EvData.PlcmntID));
        }
    };
    groupedAnimals[event.AnmID].currentAmount -= event.AnmCnt;
    if (groupedAnimals[event.AnmID].AnimalKind === AnimalTypes.PORKER) {
        addAmount("finisherPigSellCnt");
        addWeight("finisherPigSellWeight");
        addAge("finisherPigSellAge");
        groupedAnimals[event.AnmID].porkers.sellData.push({ amount: event.AnmCnt, weight: event.EvData.Weight });
        groupedAnimals[event.AnmID].porkers.end = Math.max(groupedAnimals[event.AnmID].porkers.end, event.EvTime);
    } else if (groupedAnimals[event.AnmID].AnimalKind === AnimalTypes.RENOVATION_SOW) {
        addAmount("giltPigSellCnt");
        addWeight("giltPigSellWeight");
        addAge("giltPigSellAge");
        groupedAnimals[event.AnmID].gilts.sellData.push({ amount: event.AnmCnt, weight: event.EvData.Weight });
        groupedAnimals[event.AnmID].gilts.end = Math.max(groupedAnimals[event.AnmID].gilts.end, event.EvTime);
    } else {
        addAmount("weanedPigSellCnt");
        addWeight("weanedPigSellWeight");
        addAge("weanedPigSellAge");
        groupedAnimals[event.AnmID].weaners.sellData.push({ amount: event.AnmCnt, weight: event.EvData.Weight });
        groupedAnimals[event.AnmID].weaners.end = Math.max(groupedAnimals[event.AnmID].weaners.end, event.EvTime);
    }
    return params;
}

function managePigletReclassify(event, groupedAnimals, sharedParameters) {
    if (!groupedAnimals[event.AnmID]) {
        console.warn("Nie ma zwierzecia o takim ID", event, groupedAnimals);
        return [];
    }
    const params = [];
    groupedAnimals[event.AnmID].weaners.end = Math.max(groupedAnimals[event.AnmID].weaners.end, event.EvTime);
    if (event.EvData.NewAnmID === event.AnmID) {
        groupedAnimals[event.AnmID].AnimalKind = AnimalTypes.PORKER;
    }
    if (!groupedAnimals[event.EvData.NewAnmID]) {
        groupedAnimals[event.EvData.NewAnmID] = createGroupedAnimalsObject(event, AnimalTypes.PORKER);
    }
    groupedAnimals[event.EvData.NewAnmID].porkers.inserted += event.AnmCnt;
    groupedAnimals[event.EvData.NewAnmID].porkers.start = groupedAnimals[event.AnmID].weaners.end;
    params.push(createParamObject("pigletReclassifyToPorker", event.AnmCnt, sharedParameters, event, ParamTypes.AMOUNT));
    return params;
}

function getNewAnimalType(transferTo, previousAnimal, AnmID) {
    const location = buildingsDB.getTreeByLocationID(transferTo); // TODO zamienic na selektor
    if (location.sector?.SType === SectorType.PORK_HOUSE) return AnimalTypes.PORKER;
    if (location.sector?.SType === SectorType.PIGLET_HOUSE) return AnimalTypes.PIGLET;
    if (previousAnimal) return previousAnimal.AnimalKind;
    const animal = animalsDB.getAnimalById(AnmID, { joinEvents: false, findDeleted: true });
    return animal.AnimalKind;
}

function manageTransfer(event, groupedAnimals, sharedParameters, buildingsMap, utilResults) {
    const selectedGroupID = sharedParameters.group_nr;
    const destinationGroupID = event.EvData?.NewGroupID ?? event.EvData?.GroupTransfer;
    const sourceGroupID = event.EvData?.OldGroupID;

    const newAnimalType = getNewAnimalType(event.EvData.TransferTo, groupedAnimals[event.AnmID], event.AnmID);

    let addedNewAnimal = false;
    if (!groupedAnimals[event.AnmID]) {
        if (selectedGroupID === destinationGroupID || selectedGroupID === sourceGroupID) {
            groupedAnimals[event.AnmID] = createGroupedAnimalsObject(event, newAnimalType);
            groupedAnimals[event.AnmID].currentAmount += event.AnmCnt;
            addedNewAnimal = true;
        } else {
            console.warn("Nie ma zwierzecia o takim ID", event, groupedAnimals);
            return [];
        }
    }
    const params = [];
    const addTransferred = key => {
        params.push(createGroupParamObject(key, event.AnmCnt, sharedParameters, event, ParamTypes.AMOUNT, event.EvData.DstID));
    };
    // if (event.EvData.TransferTo) {
    //     if (event.EvData.TransferTo !== event.AnmID) {
    //         groupedAnimals[event.AnmID].currentAmount -= event.AnmCnt;
    //         if (groupedAnimals[event.AnmID].AnimalKind === AnimalTypes.PORKER) {
    //             groupedAnimals[event.AnmID].porkers.inserted -= event.AnmCnt;
    //             groupedAnimals[event.AnmID].porkers.end = Math.max(groupedAnimals[event.AnmID].porkers.end, event.EvTime);
    //         } else if (groupedAnimals[event.AnmID].AnimalKind === AnimalTypes.RENOVATION_SOW) {
    //             groupedAnimals[event.AnmID].gilts.inserted -= event.AnmCnt;
    //             groupedAnimals[event.AnmID].gilts.end = Math.max(groupedAnimals[event.AnmID].gilts.end, event.EvTime);
    //         } else {
    //             groupedAnimals[event.AnmID].weaners.inserted -= event.AnmCnt;
    //             groupedAnimals[event.AnmID].weaners.end = Math.max(groupedAnimals[event.AnmID].weaners.end, event.EvTime);
    //         }
    //     }
    //     if (!groupedAnimals[event.EvData.TransferTo]) {
    //         groupedAnimals[event.EvData.TransferTo] = createGroupedAnimalsObject(event, newAnimalType);
    //     }
    //     groupedAnimals[event.EvData.TransferTo].currentAmount += event.AnmCnt;
    //     if (newAnimalType === AnimalTypes.PORKER) {
    //         groupedAnimals[event.EvData.TransferTo].porkers.transferredTo += event.AnmCnt;
    //         groupedAnimals[event.EvData.TransferTo].porkers.inserted += event.AnmCnt;
    //         groupedAnimals[event.EvData.TransferTo].porkers.start = Math.min(groupedAnimals[event.EvData.TransferTo].porkers.start, event.EvTime);
    //     } else if (newAnimalType === AnimalTypes.RENOVATION_SOW) {
    //         groupedAnimals[event.EvData.TransferTo].gilts.transferredTo += event.AnmCnt;
    //         groupedAnimals[event.EvData.TransferTo].gilts.inserted += event.AnmCnt;
    //         groupedAnimals[event.EvData.TransferTo].gilts.start = Math.min(groupedAnimals[event.EvData.TransferTo].gilts.start, event.EvTime);
    //     } else {
    //         groupedAnimals[event.EvData.TransferTo].weaners.transferredTo += event.AnmCnt;
    //         groupedAnimals[event.EvData.TransferTo].weaners.inserted += event.AnmCnt;
    //         groupedAnimals[event.EvData.TransferTo].weaners.start = Math.min(groupedAnimals[event.EvData.TransferTo].weaners.start, event.EvTime);
    //     }
    // }

    if (selectedGroupID !== destinationGroupID || destinationGroupID !== sourceGroupID || sourceGroupID !== selectedGroupID) {
        if (destinationGroupID === selectedGroupID) {
            // dodanie do grupy
            if (!groupedAnimals[event.EvData.TransferTo] && event.EvData.TransferTo) {
                groupedAnimals[event.EvData.TransferTo] = createGroupedAnimalsObject(event, newAnimalType);
                groupedAnimals[event.EvData.TransferTo].currentAmount += event.AnmCnt;
            }
            groupedAnimals[event.AnmID].currentAmount -= event.AnmCnt;
            if (newAnimalType === AnimalTypes.PORKER) {
                if (event.EvData.OldGroupID) {
                    addTransferred("finisherTransferredTo");
                }
                // usuniecie ze starego
                if (!addedNewAnimal) {
                    groupedAnimals[event.AnmID].porkers.inserted -= event.AnmCnt;
                    groupedAnimals[event.AnmID].porkers.end = Math.max(groupedAnimals[event.AnmID].porkers.end, event.EvTime);
                }
                if (event.EvData.TransferTo) {
                    // dodanie do nowego
                    groupedAnimals[event.EvData.TransferTo].porkers.inserted += event.AnmCnt;
                    groupedAnimals[event.EvData.TransferTo].porkers.start = Math.min(groupedAnimals[event.AnmID].porkers.start, event.EvTime);
                }
            } else if (newAnimalType === AnimalTypes.RENOVATION_SOW) {
                if (event.EvData.OldGroupID) {
                    addTransferred("giltTransferredTo");
                }
                if (!addedNewAnimal) {
                    groupedAnimals[event.AnmID].gilts.inserted -= event.AnmCnt;
                    groupedAnimals[event.AnmID].gilts.end = Math.max(groupedAnimals[event.AnmID].gilts.end, event.EvTime);
                }
                if (event.EvData.TransferTo) {
                    // dodanie do nowego
                    groupedAnimals[event.EvData.TransferTo].gilts.inserted += event.AnmCnt;
                    groupedAnimals[event.EvData.TransferTo].gilts.start = Math.min(groupedAnimals[event.AnmID].gilts.start, event.EvTime);
                }
            } else {
                if (event.EvData.OldGroupID) {
                    addTransferred("weanerTransferredTo");
                }
                if (!addedNewAnimal) {
                    groupedAnimals[event.AnmID].weaners.inserted -= event.AnmCnt;
                    groupedAnimals[event.AnmID].weaners.end = Math.max(groupedAnimals[event.AnmID].weaners.end, event.EvTime);
                }
                if (event.EvData.TransferTo) {
                    // dodanie do nowego
                    groupedAnimals[event.EvData.TransferTo].weaners.inserted += event.AnmCnt;
                    groupedAnimals[event.EvData.TransferTo].weaners.start = Math.min(groupedAnimals[event.AnmID].weaners.start, event.EvTime);
                }
            }
        } else if (sourceGroupID === selectedGroupID && destinationGroupID) {
            // odjecie z grupy
            groupedAnimals[event.AnmID].currentAmount -= event.AnmCnt;
            console.log("addedNewAnimal", addedNewAnimal, event.AnmCnt, sourceGroupID, sourceGroupID, selectedGroupID, destinationGroupID);
            if (newAnimalType === AnimalTypes.PORKER) {
                addTransferred("finisherTransferredFrom");
                groupedAnimals[event.AnmID].porkers.inserted -= event.AnmCnt;
                groupedAnimals[event.AnmID].porkers.end = Math.max(groupedAnimals[event.AnmID].porkers.end, event.EvTime);
            } else if (newAnimalType === AnimalTypes.RENOVATION_SOW) {
                addTransferred("giltTransferredFrom");
                groupedAnimals[event.AnmID].gilts.inserted -= event.AnmCnt;
                groupedAnimals[event.AnmID].gilts.end = Math.max(groupedAnimals[event.AnmID].gilts.end, event.EvTime);
            } else {
                addTransferred("weanerTransferredFrom");
                groupedAnimals[event.AnmID].weaners.inserted -= event.AnmCnt;
                groupedAnimals[event.AnmID].weaners.end = Math.max(groupedAnimals[event.AnmID].weaners.end, event.EvTime);
            }
        } else if (event.EvData.TransferTo && event.EvData.TransferTo !== event.AnmID) {
            groupedAnimals[event.AnmID].currentAmount -= event.AnmCnt;
            if (!groupedAnimals[event.EvData.TransferTo]) {
                groupedAnimals[event.EvData.TransferTo] = createGroupedAnimalsObject(event, newAnimalType);
                groupedAnimals[event.EvData.TransferTo].currentAmount += event.AnmCnt;
            }
        }
    }
    if (!event.EvData.OriginalEvent) {
        const source = buildingsMap.get(event.EvData.SrcID);
        const destination = buildingsMap.get(event.EvData.DstID);
        if (source?.SType === SectorType.BABY_ROOM && destination?.SType === SectorType.PIGLET_HOUSE) {
            const date = moment(event.EvTime).subtract(20, "weeks");
            const technologyGroup = getTechnologyGroupByTime(+date, utilResults.TechnologyGroupStart, utilResults.TechnologyGroupWeeks);
            params.push(createParamObject("pigletRoomSeparation", event.AnmCnt, {
                ...sharedParameters,
                group_nr: formatTechnoGroupName(technologyGroup.start, technologyGroup.end, true),
                groupStart: +technologyGroup.start,
                groupDay: getGroupDay(event, technologyGroup)
            }, event, ParamTypes.AMOUNT));
        }
    }
    return params;
}

function manageReclassify(event, groupedAnimals, sharedParameters, group) {
    if (!groupedAnimals[event.AnmID]) {
        // console.warn("Nie ma zwierzecia o takim ID", event, groupedAnimals);
        groupedAnimals[event.AnmID] = createGroupedAnimalsObject(event);
    }
    const params = [];
    const addReclassifiedAmount = key => {
        params.push(createParamObject(key, event.EvData.ReclassifiedAnimals.length, sharedParameters, event, ParamTypes.AMOUNT));
    };
    groupedAnimals[event.AnmID].currentAmount -= event.EvData.AnmCnt;
    if (groupedAnimals[event.AnmID].AnimalKind === AnimalTypes.PORKER) {
        addReclassifiedAmount("finisherReclassified");
        groupedAnimals[event.AnmID].porkers.reclassified += event.EvData.ReclassifiedAnimals.length;
        groupedAnimals[event.AnmID].porkers.end = Math.max(groupedAnimals[event.AnmID].porkers.end, event.EvTime);
    } else if (groupedAnimals[event.AnmID].AnimalKind === AnimalTypes.RENOVATION_SOW) {
        addReclassifiedAmount("giltReclassified");
        groupedAnimals[event.AnmID].gilts.reclassified += event.EvData.ReclassifiedAnimals.length;
        groupedAnimals[event.AnmID].gilts.end = Math.max(groupedAnimals[event.AnmID].gilts.end, event.EvTime);
    } else {
        addReclassifiedAmount("weanerReclassified");
        groupedAnimals[event.AnmID].weaners.reclassified += event.EvData.ReclassifiedAnimals.length;
        groupedAnimals[event.AnmID].weaners.end = Math.max(groupedAnimals[event.AnmID].weaners.end, event.EvTime);
    }
    for (let { AnmNo1 } of event.EvData.ReclassifiedAnimals) {
        const animals = animalsDB.getAllAnimalsByAnmNo1(AnmNo1, event.FarmID);
        const animalInGroup = animals.find(item => [...group.AnmIDs, ...group.Rmvd].includes(item.AnmID));
        if (animalInGroup) {
            if (!groupedAnimals[animalInGroup.AnmID]) {
                groupedAnimals[animalInGroup.AnmID] = createGroupedAnimalsObject(event, event.EvData.AnimalKind || animalInGroup.AnimalKind);
            }
        }
    }
    return params;
}

function calcMortality(stats) {
    return +(stats.fallen / stats.all * 100).toFixed(2);
}

function calcSellWeight(stats) {
    let sum = 0;
    let weight = 0;
    for (let row of stats.sellData) {
        sum += row.amount * row.weight;
        weight += row.amount;
    }
    return Math.round(sum / weight);
}

function calcSellWeightTotal(stats) {
    let sum = 0;
    for (let row of stats.sellData) {
        sum += row.amount * row.weight;
    }
    return sum;
}

function calcGain(sellWeight, age, group) {
    return Math.round((sellWeight - group.Weight) / age);
}

function calcBuyCost(buyCost) {
    let sum = 0;
    let weight = 0;
    for (let row of buyCost) {
        sum += row.amount * row.price;
        weight += row.amount;
    }
    return +(sum / weight).toFixed(2);
}

function addParametersForGroupedAnimals(group, groupedAnimals, sharedParameters) {
    // console.log(groupedAnimals);
    const params = [];
    const pigletStats = { all: 0, fallen: 0, sellData: [], start: Infinity, end: 0 };
    const porkerStats = { all: 0, fallen: 0, sellData: [], start: Infinity, end: 0 };
    const giltStats = { all: 0, fallen: 0, sellData: [], start: Infinity, end: 0 };
    const buyCost = [];
    for (let row of Object.values(groupedAnimals)) {
        if (group.AnmIDs.includes(row.AnmID) || group.Rmvd.includes(row.AnmID)) {
            // warchlaki
            pigletStats.all += row.weaners.inserted;
            pigletStats.fallen += row.weaners.fallen;
            pigletStats.sellData.push(...row.weaners.sellData);
            if (row.weaners.start !== Infinity && row.weaners.end !== 0) {
                pigletStats.start = Math.min(pigletStats.start, row.weaners.start);
                pigletStats.end = Math.max(pigletStats.end, row.weaners.end);
            }
            // tuczniki
            porkerStats.all += row.porkers.inserted;
            porkerStats.fallen += row.porkers.fallen;
            porkerStats.sellData.push(...row.porkers.sellData);
            if (row.porkers.start !== Infinity && row.porkers.end !== 0) {
                porkerStats.start = Math.min(porkerStats.start, row.porkers.start);
                porkerStats.end = Math.max(porkerStats.end, row.porkers.end);
            }
            // loszki
            giltStats.all += row.gilts.inserted;
            giltStats.fallen += row.gilts.fallen;
            giltStats.sellData.push(...row.gilts.sellData);
            if (row.gilts.start !== Infinity && row.gilts.end !== 0) {
                giltStats.start = Math.min(giltStats.start, row.gilts.start);
                giltStats.end = Math.max(giltStats.end, row.gilts.end);
            }
            buyCost.push(...row.buyCost);
        }
    }
    // console.log("-----------GROUP", group.AnmGrp, group.GrNo1, pigletStats, porkerStats, giltStats);
    let pigletSellWeight = null, porkerSellWeight = null, giltSellWeight = null;
    // upadki
    if (pigletStats.fallen > 0) {
        params.push(createParamObject("weanedPigDeath", calcMortality(pigletStats), sharedParameters, null, ParamTypes.PERCENT));
    }
    if (porkerStats.fallen > 0) {
        params.push(createParamObject("finisherPigDeath", calcMortality(porkerStats), sharedParameters, null, ParamTypes.PERCENT));
    }
    if (giltStats.fallen > 0) {
        params.push(createParamObject("giltDeath", calcMortality(giltStats), sharedParameters, null, ParamTypes.PERCENT));
    }
    // sprzedaze
    if (pigletStats.sellData.length > 0) {
        pigletSellWeight = calcSellWeight(pigletStats);
        const totalWeight = calcSellWeightTotal(pigletStats);
        params.push(createParamObject("weanedPigSellWeightAverage", pigletSellWeight, sharedParameters, null, ParamTypes.WEIGHT));
        params.push(createParamObject("weanedPigSellWeightTotal", totalWeight, sharedParameters, null, ParamTypes.WEIGHT));
    }
    if (porkerStats.sellData.length > 0) {
        porkerSellWeight = calcSellWeight(porkerStats);
        const totalWeight = calcSellWeightTotal(porkerStats);
        params.push(createParamObject("finisherPigSellWeightAverage", porkerSellWeight, sharedParameters, null, ParamTypes.WEIGHT));
        params.push(createParamObject("finisherPigSellWeightTotal", totalWeight, sharedParameters, null, ParamTypes.WEIGHT));
    }
    if (giltStats.sellData.length > 0) {
        giltSellWeight = calcSellWeight(giltStats);
        const totalWeight = calcSellWeightTotal(giltStats);
        params.push(createParamObject("giltSellWeightAverage", giltSellWeight, sharedParameters, null, ParamTypes.WEIGHT));
        params.push(createParamObject("giltSellWeightTotal", totalWeight, sharedParameters, null, ParamTypes.WEIGHT));
    }
    // wiek
    if (pigletStats.start !== Infinity) {
        const isZeroValue = Object.values(groupedAnimals).filter(item => item.AnimalKind === AnimalTypes.PIGLET).every(item => item.currentAmount === 0);
        const age = calcAge(pigletStats.start, isZeroValue ? pigletStats.end : group.DtaDltTime || +moment().startOf("day"));
        params.push(createParamObject("weanedPigPigletHouseAge", age, sharedParameters, null, ParamTypes.AGE));
        if (pigletSellWeight) {
            params.push(createParamObject("weanerPigGain", calcGain(pigletSellWeight, age, group), sharedParameters, null, ParamTypes.WEIGHT));
        }
    }
    if (porkerStats.start !== Infinity) {
        const isZeroValue = Object.values(groupedAnimals).filter(item => item.AnimalKind === AnimalTypes.PORKER).every(item => item.currentAmount === 0);
        const age = calcAge(porkerStats.start, isZeroValue ? porkerStats.end : group.DtaDltTime || +moment().startOf("day"));
        params.push(createParamObject("finisherPigPorkHouseAge", age, sharedParameters, null, ParamTypes.AGE));
        if (porkerSellWeight) {
            params.push(createParamObject("finisherPigGain", calcGain(porkerSellWeight, age, group), sharedParameters, null, ParamTypes.WEIGHT));
        }
    }
    if (group.DtaDltTime) {
        params.push(createParamObject("groupCloseTime", group.DtaDltTime, sharedParameters, null, ParamTypes.TIME));
    }
    if (buyCost.length > 0) {
        params.push(createParamObject("weanerPigPrice", calcBuyCost(buyCost), sharedParameters, null, ParamTypes.PRICE));
    }
    return params;
}

function getDateOfIntroduction(group) {
    const insertionTimes = [];
    for (let AnmID of [...group.AnmIDs, ...group.Rmvd]) {
        const animal = animalsDB.getAnimalById(AnmID, { joinEvents: false, findDeleted: true });
        if (animal && animal.DtaInTime) {
            insertionTimes.push(animal.DtaInTime);
        }
    }
    if (insertionTimes.length === 0) return null;
    return Math.min(...insertionTimes);
}

export function calculateGroupParameters(group, events, buildingsMap, utilResults) {
    // console.log(`------------------------Grupa ${group.GrNo1} ${group.AnmGrp}----------------------`);
    // console.log(group);
    // console.log(events);
    const groupedAnimals = {};
    const params = [];
    const sharedParameters = {
        group_nr: group.AnmGrp,
        location: "", // TODO dodanie lokalizacji, co jak bedzie kilka? sumowany string?
        date_introduction: getDateOfIntroduction(group),
        date_end: group.DtaDelTime || group.DtaDltTime || null
    };
    for (let event of events) {
        switch (event.EvCode) {
            case EventTypes.INSERTION:
                params.push(...manageInsertion(event, groupedAnimals, sharedParameters));
                break;
            case EventTypes.FALL:
                params.push(...manageFall(event, groupedAnimals, sharedParameters));
                break;
            case EventTypes.SELL:
                params.push(...manageSell(event, groupedAnimals, sharedParameters));
                break;
            case EventTypes.PIGLET_RECLASSIFY:
                params.push(...managePigletReclassify(event, groupedAnimals, sharedParameters));
                break;
            case EventTypes.TRANSFER:
                params.push(...manageTransfer(event, groupedAnimals, sharedParameters, buildingsMap, utilResults));
                break;
            case EventTypes.RECLASSIFY:
                params.push(...manageReclassify(event, groupedAnimals, sharedParameters, group));
                break;
            default:
                // if (![EventTypes.WEIGHTING, EventTypes.TECHNOLOGY_GROUP_TRANSFER, EventTypes.INSEMINATION, EventTypes.USG, EventTypes.GRAFTING, EventTypes.TREATMENT].includes(event.EvCode))
                //     console.log("Nieznany event", event);
                break;
        }
    }
    // console.log(groupedAnimals);
    params.push(...addParametersForGroupedAnimals(group, groupedAnimals, sharedParameters));
    // console.log(group);
    // console.log(groupedAnimals);
    // console.log(params);
    return params;
}

export const ForTests = {
    createGroupedStatsObject,
    createGroupedAnimalsObject,
    manageInsertion,
    calcAge,
    manageFall,
    manageSell,
    managePigletReclassify,
    getNewAnimalType,
    manageTransfer,
    calcMortality,
    calcSellWeight,
    calcGain,
    calcBuyCost,
    addParametersForGroupedAnimals,
    getDateOfIntroduction,
    manageReclassify
};