// import LokiIndexedCryptoAdapter from "./adapter/LokiIndexedCryptoAdapter";
import animalsDB from "./animalsDB";
import buildingsDB from "./buildingsDB";
import devicesDB from "./devicesDB";
import eventsDB from "./eventsDB";
import IncrementalIndexedDBAdapter from "lokijs/src/incremental-indexeddb-adapter";
import Loki from "lokijs";

// let LokiIndexedAdapter;
// if (process.env.NODE_ENV === 'test') {
//     LokiIndexedAdapter = require('lokijs/src/loki-fs-structured-adapter');
// } else {
//     LokiIndexedAdapter = require('lokijs/src/loki-indexed-adapter');
// }

let collections = [];

export const DATABASE = {
    key: 'fetura_',//+getCurrentUser().pool.storage["aws.cognito.identity-id.eu-central-1:419e1198-ad2a-4ada-9921-895a83a624ca"],
    database: 'fetura.db'//+getCurrentUser().pool.storage["aws.cognito.identity-id.eu-central-1:419e1198-ad2a-4ada-9921-895a83a624ca"]+'.db'
};


export class Database {
    db;

    animals;
    buildings;
    cachedTablesParams;
    events;
    devices;
    dictionary;
    settings;
    commandsHistory;
    groups;
    test;
    reports;
    api;
    formData;
    results;
    sns;
    eventHistory;
    sales;
    filters;
    economy;
    backup;
    deviceScan;
    breedingCounters;
    localReportData;

    initDB(dbName) {
        return new Promise((resolve, reject) => {
            try {
                this.db = new Loki(dbName, {
                    // autoload: true,
                    // autoloadCallback: loadHandler,
                    autosave: true,
                    autosaveInterval: 10000, // 1 seconds
                    adapter: new IncrementalIndexedDBAdapter(),
                    verbose: true,
                    serializationMethod: "normal"
                });
                resolve(this.db);
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }

    getCollection = (collectionName, id = "", clone = false) => {
        try {
            let coll = this.db.getCollection(collectionName);
            if (!coll) {
                coll = this.db.addCollection(collectionName, {
                    unique: [id],
                    // indices: [id], find nie dziala bo w pewnym momencie ilosc binaryIndicies nie zgadza sie z iloscia danych, tl;dr nie ma indicies nie ma problemu
                    clone,
                    cloneMethod: "parse-stringify",
                    disableMeta: true
                });
            }
            collections.push(collectionName);
            return coll;
        } catch (error) {
            console.error(error);
        }
    };

    loadHandler() {
        // if database did not exist it will be empty so I will intitialize here
        this.cachedTablesParams = this.getCollection('cachedTablesParams');
        this.animals = this.getCollection('animals', "AnmID");
        this.buildings = this.getCollection('buildings', "BgID");
        this.events = this.getCollection('events', "EvID");
        this.devices = this.getCollection('devices', "DevID");
        this.dictionary = this.getCollection('dictionaryV2');
        this.commandsHistory = this.getCollection('commandsHistory');
        this.users = this.getCollection('users');
        this.groups = this.getCollection("groups");
        this.settings = this.getCollection('settings');
        if (this.settings.data.length === 0) {
            let item3 = {
                look: {
                    insertionType: 0,
                    showDead: false,
                    autoMerge: false,
                    borderTiles: false
                }
            };
            this.settings.insert(item3);
        }
        this.documents = this.getCollection('documents');
        this.aggregated = this.getCollection('aggregated');
        this.test = this.getCollection('test');
        this.reports = this.getCollection('reports', "", false);
        this.api = this.getCollection("api");
        this.formData = this.getCollection("formData");
        this.results = this.getCollection("results");
        this.sns = this.getCollection("sns");
        this.eventHistory = this.getCollection("eventHistory");
        this.sales = this.getCollection("sales");
        this.filters = this.getCollection("filters");
        this.economy = this.getCollection("economy");
        this.breedingCounters = this.getCollection("breedingCounters");
        this.backup = this.getCollection("backup");
        this.localReportData = this.getCollection("localReportData", "", false);
        this.deviceScan = this.getCollection("deviceScan", "serial");
        // mechanizm usuwania kolekcji, ktore nie sa uzywane
        const colls = this.db.listCollections();
        for (let coll of colls) {
            if (!collections.includes(coll.name)) {
                console.log(`%cRemoving collection ${coll.name} from database because it is obsolete`, 'color: red');
                this.db.removeCollection(coll.name);
            }
        }
        animalsDB.clearCache();
        buildingsDB.clearCache();
        devicesDB.clearCache();
        eventsDB.clearCache();
    }

    loadDB() {
        return new Promise((resolve, reject) => {
            this.db.loadDatabase({}, err => {
                if (err) {
                    console.error("LOAD DATABASE", err);
                    reject(err);
                }
                this.loadHandler();
                resolve();
            });
        });
    }


    closeDB() {
        return new Promise((resolve, reject) => {
            this.db.close((...args) => {
                console.log(`%cClosing old database ${DATABASE.database}`, 'color: red', args);
                resolve();
            });
        });
    }

    /**********************************
     ***         GENERAL            ***
     **********************************/
    async loadDatabase(login, force = false) {
        let newKey = 'fetura_' + login + '.db';
        if (process.env.PUBLIC_URL && process.env.PUBLIC_URL.length > 0) {
            newKey = `${process.env.PUBLIC_URL}-${newKey}`;
        }
        if (DATABASE.database !== newKey || force) {
            if (this.db) {
                await this.closeDB();
                this.db = null;
            }
            console.log(`%cCreating new database: ${newKey}`, 'color: red');
            DATABASE.key = 'fetura_' + login;
            DATABASE.database = newKey;
            // console.log(process.env.NODE_ENV);
            if (process.env.NODE_ENV === 'test') {
                // let adb = require("lokijs/src/loki-fs-structured-adapter");
                // const adapter = new adb(DATABASE.key);
                // await initDB(DATABASE.database, adapter);
            } else {
                await this.initDB(DATABASE.database);
            }
            await this.loadDB();
        }
        // Promise.resolve();
    }

    asyncSaveDB() {
        return new Promise((resolve, reject) => {
            this.db.saveDatabase(err => {
                if (err) reject(err);
                resolve();
            });
        });
    }

    saveDB() {
        this.db.saveDatabase(function (err) {
            if (process.env.NODE_ENV !== 'test') {
                console.log("SAVE");
            }
            if (err) {
                console.error("SAVE ERROR DB", err);
            }
            if (process.env.NODE_ENV !== 'test') {
                console.log("save database successfull");
            }
        });
    }

    deleteDatabase() {
        if (!this.db) return;
        return new Promise((resolve, reject) => {
            this.db.deleteDatabase((res) => {
                if (res.success) {
                    resolve();
                } else {
                    console.error(res.text())
                    reject(new Error('Could not delete loki'));
                }
            });
        });
    }
}

const lokiDB = new Database();
export default lokiDB;
