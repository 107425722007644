import {findIndex} from "lodash";

const initialValue = {
    devices: []
};

export default function deviceScanReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_SCANNED_DEVICES": {
            return {...state, devices: action.payload};
        }
        case "SCAN_DEVICE_PENDING": {
            const copy = state.devices.slice();
            if (action.meta.update) {
                console.log("update");
                const index = findIndex(copy, o => o.Serial === action.meta.row.Serial);
                copy[index] = {...action.meta.row, status: "loading"};
                return {...state, devices: copy};
            }
            copy.push({...action.meta.row, status: "loading"});
            return {...state, devices: copy};
        }
        case "SCAN_DEVICE_REJECTED": {
            const copy = state.devices.slice();
            const index = findIndex(copy, o => o.Serial === action.meta.row.Serial);
            switch (action.payload?.code) {
                case 1:
                    copy[index] = {...copy[index], status: "notFound"};
                    break;
                case 3:
                    copy[index] = {...copy[index], status: "alreadyAssigned"};
                    break;
                case 4:
                    copy[index] = {...copy[index], status: "validationError"};
                    break;
                default:
                    copy[index] = {...copy[index], status: "error"};
            }
            return {...state, devices: copy};
        }
        case "SCAN_DEVICE_FULFILLED": {
            const copy = state.devices.slice();
            const index = findIndex(copy, o => o.Serial === action.meta.row.Serial);
            copy[index] = {
                ...copy[index],
                status: "success",
                DevID: action.payload.DevID,
                DevType: action.payload.DevType
            };
            return {...state, devices: copy};
        }
        case "CLEAR_SCANNED_DEVICES":
            return {...state, devices: []};
        case "CHANGE_FARM":
            return initialValue;
        default:
            return state;
    }
}
