import { findIndex, get, isEqual, isFunction, isNil, memoize, isArray } from 'lodash';
import moment from 'moment';
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
    useTransition
} from 'react';
import { useTranslation } from 'react-i18next';
import sift, { createEqualsOperation } from 'sift';
import { Level } from '../../../constans/levelTypes';
import filtersDB from '../../../database/filtersDB';
import useDebounce from "../../../hooks/useDebounce";
import { myID } from '../../../libs/generateID';
import { isMobile } from '../../../utils/MobileUtils';
import { enhancedComparer } from '../../../utils/TextUtils';
import Button from '../button/Button';
import Checkbox from '../checkbox/Checkbox';
import Input from '../input/Input';
import MobileInfo from '../mobile-info/MobileInfo';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import Filter from './filter/Filter';
import TableMobileHeaders from './mobile/TableMobileHeaders';

function TableGridHeaderSelect({ data, sortedData, selectedRows = [], onSelectAll, _isRowSelectable }) {

    const checkBoxID = useRef(myID());

    const notSelectableRows = useMemo(() => data.filter((object) => !_isRowSelectable(object)), [data, _isRowSelectable]);

    return (
        <div className="header-item selected-header">
            <Checkbox label="" id={checkBoxID.current} onChange={onSelectAll}
                disabled={notSelectableRows.length === sortedData.length}
                checked={selectedRows.length === sortedData.length} />
        </div>
    );
}

function TableGridHeaders({
    forceMobileView, mobileRow, mobile, headersToRender, selectableRow, singleRowSelect,
    data, selectedRows = [], isSortable, name, _isRowSelectable, sortArray, setSortArray,
    setSelectedRows, shouldIndex, sortedData = []
}) {

    const showShowMobile = useMemo(() => {
        return !(forceMobileView || (mobile && mobileRow));
    }, [forceMobileView, mobile, mobileRow]);

    const canSelectRow = useMemo(() => {
        return selectableRow && !singleRowSelect;
    }, [selectableRow, singleRowSelect]);

    const onHeaderClick = useCallback((header) => {
        setSortArray(sortArray => {
            let newSortArray = sortArray.slice(0);
            let sortColumnIndex = findIndex(newSortArray, (s) => s.name === header.name);
            const getSortType = (_sortType, _name) => {
                let result = "asc";
                if (_name === header.name) {
                    switch (_sortType) {
                        case "off":
                            result = "asc";
                            break;
                        case "asc":
                            result = "desc";
                            break;
                        default:
                            result = "off";
                    }
                }
                return result;
            };
            let sortedTypeItem = "asc";
            if (sortColumnIndex !== -1) {
                const { sortType, name } = newSortArray[sortColumnIndex];
                sortedTypeItem = getSortType(sortType, name);
                if (sortedTypeItem === "off") newSortArray.splice(sortColumnIndex, 1);
                else newSortArray[sortColumnIndex] = { name: header.name, sortType: sortedTypeItem };
            } else {
                if (sortedTypeItem !== "off") newSortArray.push({ name: header.name, sortType: sortedTypeItem });
            }
            return newSortArray;
        });
    }, [setSortArray]);

    const onSelectAll = useCallback((value) => {
        let tmp = [];
        if (value) {
            for (let i = 0; i < sortedData.length; i++) {
                if (_isRowSelectable(sortedData[i])) {
                    const index = findIndex(data, sortedData[i]);
                    tmp.push(index);
                }
            }
        }
        setSelectedRows(tmp);
    }, [sortedData, data, _isRowSelectable, setSelectedRows]);

    const getKey = useCallback((header, index) => {
        if (header.key) return header.key;
        return header.field || index;
    }, []);

    if (!showShowMobile) {
        return (
            <TableMobileHeaders onSortChange={onHeaderClick} isSortable={isSortable}
                sortArray={sortArray} headers={headersToRender}
                selectedAll={selectedRows.length === sortedData.length}
                onSelectAll={selectableRow && !singleRowSelect ? onSelectAll : undefined} />
        );
    }
    return (
        <div className="header-container">
            {
                canSelectRow &&
                <TableGridHeaderSelect
                    data={data}
                    selectedRows={selectedRows}
                    _isRowSelectable={_isRowSelectable}
                    sortedData={sortedData}
                    onSelectAll={onSelectAll} />

            }
            {
                shouldIndex && <div className="header-item index">#</div>
            }
            {
                headersToRender.map((header, i) =>
                    <TableHeader
                        key={getKey(header, i)}
                        header={header}
                        isSortable={isSortable}
                        onSortChange={onHeaderClick}
                        sortArray={sortArray} />
                )
            }
        </div>
    );
}

function TableGridPinnedRowRenderer({ pinnedRowData, headers, selectableRow, shouldIndex }) {

    return pinnedRowData.map((row, i) => {
        if (React.isValidElement(row)) return (
            <div className="pinned-row">{row}</div>
        );
        return <TableRow object={row} headers={headers} key={i} isPinned
            isSelectable={selectableRow} shouldIndex={shouldIndex}
        />;
    });
}

function TableGridTopPinnedRows({
    topPinnedRows, headers, selectableRow, shouldIndex,
    paginatedData, paginationItems, data
}) {

    const pinnedRowData = useMemo(() => {
        if (!topPinnedRows) return null;
        if (isFunction(topPinnedRows)) return topPinnedRows(paginatedData, paginationItems, data);
        return topPinnedRows;
    }, [data, paginatedData, topPinnedRows, paginationItems]);

    if (!pinnedRowData) return null;
    return (
        <div className="top-pinned-container">
            <TableGridPinnedRowRenderer
                pinnedRowData={pinnedRowData}
                headers={headers}
                selectableRow={selectableRow}
                shouldIndex={shouldIndex}
            />
        </div>
    );
}

function TableGridBodyMobile({
    isFullWidthRow,
    paginatedData,
    fullWidthRow,
    headersToRender,
    paginationItems,
    page,
    onSelectMobileNode,
    onSelectedRowClick,
    checkIfSelected,
    rowClassName,
    singleRowSelect,
    selectableRow,
    rowID,
    mobileRow
}) {

    return paginatedData.map((object, i) => {
        let isFullWidth = isFullWidthRow && isFullWidthRow(object);
        if (isFullWidth) {
            return React.cloneElement(fullWidthRow, {
                object, //TODO dodac wiecej propsow
            }, fullWidthRow.props.children);
        }
        return React.cloneElement(mobileRow, {
            ...mobileRow.props,
            object,
            headers: headersToRender,
            paginationItems,
            page,
            key: (page * paginationItems) + i,
            index: (page * paginationItems) + i + 1,
            onClick: onSelectMobileNode, // TODO
            onSelect: onSelectedRowClick,
            selected: checkIfSelected(i),
            className: rowClassName,
            singleRowSelect: singleRowSelect,
            isSelectable: selectableRow,
            rowID: rowID
        });
    });
}

function TableGridBodyDesktop({
    isFullWidthRow, paginatedData, headersToRender, page, paginationItems,
    singleRowSelect, shouldIndex, selectableRow, mobile, checkIfSelected,
    fullWidthRow, onRowClick, rowClassName, onSelectedRowClick, isExpanded, component, rowID,
    _isRowSelectable, data, stripped
}) {

    const getBeforeFullWidthAmount = useCallback((index) => {
        if (isFullWidthRow) {
            let amount = 0;
            for (let i = 0; i < index; i++) {
                if (data[i] && isFullWidthRow(data[i])) {
                    amount++;
                }
            }
            return amount;
        }
        return 0;
    }, [isFullWidthRow, data]);

    return paginatedData.map((object, i) => {
        return (
            <TableRow
                headers={headersToRender}
                object={object}
                key={(page * paginationItems) + i}
                index={(page * paginationItems) + i + 1}
                singleRowSelect={singleRowSelect}
                shouldIndex={shouldIndex}
                isSelectable={selectableRow}
                selected={checkIfSelected(i)}
                mobile={mobile}
                onClick={onRowClick}
                onSelectedRowClick={onSelectedRowClick}
                page={page}
                paginationItems={paginationItems}
                isFullWidthRow={isFullWidthRow}
                fullWidthRow={fullWidthRow}
                isRowSelectable={_isRowSelectable}
                isClickable={selectableRow || !!onRowClick}
                className={rowClassName}
                fullWidthBefore={getBeforeFullWidthAmount((page * paginationItems) + i)}
                isExpanded={isExpanded}
                component={component}
                rowID={rowID}
                stripped={stripped}
            />
        );
    });
}

function TableGridBody({
    forceMobileView, mobile, mobileRow, isFullWidthRow, paginatedData, headersToRender,
    page, singleRowSelect, shouldIndex, selectableRow, fullWidthRow, data, selectedRows = [],
    onRowClick, onSelectedRowClick, rowClassName, isExpanded, component, rowID, paginationItems, _isRowSelectable, stripped
}) {
    const isMobile = useMemo(() => forceMobileView || (mobile && mobileRow), [mobile, mobileRow, forceMobileView]);

    const checkIfSelected = useCallback((i) => {
        let paginatedRow = paginatedData[i];
        let index = findIndex(data, paginatedRow);
        return selectedRows.includes(index);
    }, [data, paginatedData, selectedRows]);

    if (isMobile) return (
        <TableGridBodyMobile
            isFullWidthRow={isFullWidthRow}
            paginatedData={paginatedData}
            checkIfSelected={checkIfSelected}
            fullWidthRow={fullWidthRow}
            headersToRender={headersToRender}
            mobileRow={mobileRow}
            onRowClick={onRowClick}
            onSelectedRowClick={onSelectedRowClick}
            page={page}
            paginationItems={paginationItems}
            rowClassName={rowClassName}
            rowID={rowID}
            selectableRow={selectableRow}
            singleRowSelect={singleRowSelect}
        />
    );
    return (
        <TableGridBodyDesktop
            isFullWidthRow={isFullWidthRow}
            paginatedData={paginatedData}
            headersToRender={headersToRender}
            page={page}
            paginationItems={paginationItems}
            singleRowSelect={singleRowSelect}
            shouldIndex={shouldIndex}
            selectableRow={selectableRow}
            mobile={mobile}
            fullWidthRow={fullWidthRow}
            onRowClick={onRowClick}
            onSelectedRowClick={onSelectedRowClick}
            rowClassName={rowClassName}
            isExpanded={isExpanded}
            component={component}
            rowID={rowID}
            checkIfSelected={checkIfSelected}
            _isRowSelectable={_isRowSelectable}
            data={data}
            stripped={stripped}
        />
    );
}

function TableGridBottomPinnedRows({
    bottomPinnedRows,
    paginatedData,
    paginationItems,
    data,
    headers,
    selectableRow,
    shouldIndex,
    filteredData
}) {

    const pinnedRowData = useMemo(() => {
        if (!bottomPinnedRows) return null;
        if (isFunction(bottomPinnedRows)) return bottomPinnedRows(paginatedData, paginationItems, data, filteredData);
        return bottomPinnedRows;
    }, [data, paginatedData, bottomPinnedRows, paginationItems, filteredData]);

    if (!pinnedRowData) return null;

    return (
        <div className="bottom-pinned-container">
            <TableGridPinnedRowRenderer
                pinnedRowData={pinnedRowData}
                headers={headers}
                selectableRow={selectableRow}
                shouldIndex={shouldIndex}
            />
        </div>
    );
}

function TableGridPaginationSmall({ page, setPage, maxPage }) {

    const decrementPage = useCallback(() => {
        setPage(page => page - 1);
    }, [setPage]);

    const incrementPage = useCallback(() => {
        setPage(page => page + 1);
    }, [setPage]);

    return (
        <div className="pagination small-pagination">
            <Button buttonStyle={"round"} icon={<i className="fas fa-backward" />}
                onClick={decrementPage} disabled={page === 0} />
            <Button buttonStyle={"round"} icon={<i className="fas fa-forward" />}
                onClick={incrementPage} disabled={page === maxPage} />
        </div>
    );
}

function TableGridPaginationBig({
    page, setPage, maxPage, paginatedData, data, setPaginationItems, paginationItems
}) {

    const { t } = useTranslation();

    const resetPage = useCallback(() => {
        setPage(0);
    }, [setPage]);

    const decrementPage = useCallback(() => {
        setPage(page => page - 1);
    }, [setPage]);

    const incrementPage = useCallback(() => {
        setPage(page => page + 1);
    }, [setPage]);

    const setMaxPage = useCallback(() => {
        setPage(maxPage);
    }, [setPage, maxPage]);

    const onPaginationAmountChange = useCallback((value) => {
        setPaginationItems(+value);
    }, [setPaginationItems]);

    return (
        <div className="pagination">
            <div className="items-amount">
                {t("tableGrid.displaying", { type1: paginatedData.length, type2: data.length })}
            </div>
            <div className="pagination-buttons">
                <Button type={'button'} buttonStyle={"round"} icon={<i className="fas fa-step-backward" />}
                    onClick={resetPage} />
                <Button type={'button'} buttonStyle={"round"} icon={<i className="fas fa-backward" />}
                    onClick={decrementPage} disabled={page === 0} />
                <span>{page + 1}/{maxPage + 1}</span>
                <Button type={'button'} buttonStyle={"round"} icon={<i className="fas fa-forward" />}
                    onClick={incrementPage} disabled={page === maxPage} />
                <Button type={'button'} buttonStyle={"round"} icon={<i className="fas fa-step-forward" />}
                    onClick={setMaxPage} />
                <Input type="number" value={paginationItems}
                    onChange={onPaginationAmountChange} />
            </div>
        </div>
    );
}

function TableGridPagination({
    page,
    setPage,
    smallPagination,
    data,
    onPaginationAmountChange,
    paginatedData,
    paginationItems,
    maxPage,
    setPaginationItems
}) {

    if (smallPagination) return <TableGridPaginationSmall page={page} setPage={setPage} maxPage={maxPage} />;
    return (
        <TableGridPaginationBig
            page={page}
            setPage={setPage}
            data={data}
            maxPage={maxPage}
            onPaginationAmountChange={onPaginationAmountChange}
            paginatedData={paginatedData}
            paginationItems={paginationItems}
            setPaginationItems={setPaginationItems}
        />
    );
}

function TableGridMobileInfo({ show, mobileAdditionalInfoName, expandedMobileNode, setShow, mobileAdditionalInfo }) {

    const name = useMemo(() => {
        if (typeof mobileAdditionalInfoName === "function") return mobileAdditionalInfoName(expandedMobileNode);
        return mobileAdditionalInfoName;
    }, [mobileAdditionalInfoName, expandedMobileNode]);

    const onHide = useCallback(() => {
        setShow(false);
    }, [setShow]);

    return (
        <MobileInfo show={show} name={name} onHide={onHide}>
            {
                React.cloneElement(mobileAdditionalInfo, {
                    ...mobileAdditionalInfo.props,
                    object: expandedMobileNode
                })
            }
        </MobileInfo>
    );
}

const TableGridFilter = forwardRef(({
    forceMobileView,
    mobile,
    headers,
    headersToRender,
    data,
    setSortArray,
    name,
    showFilter,
    setFilteredData,
    saveToExcel,
    savedParameters,
    saveFilter,
    startTransition,
    ableToHideColumns,
    maxColumns,
    setHeadersToDisplay,
    excelFileName,
    sortedData
}, ref) => {

    const filterRef = useRef();

    const operations = useRef({
        //StringAsDevAddressEquals
        $devAdrEq(params, ownerQuery, options) {
            return createEqualsOperation(
                value => {
                    if (params && value) {
                        if (params === value) return true;
                        else {
                            const paramAddress = params.startsWith("0x") ? params : `0x${parseInt(params).toString(16)}`;
                            const hexValue = value.replace(/\s/g, '').split("/")[1];
                            return paramAddress === hexValue;
                        }
                    }
                    return !(params && !value);
                },
                ownerQuery,
                options
            );
        },
        // StringAsNumberGreaterThanEquals
        $sanGte(params, ownerQuery, options) {
            return createEqualsOperation(
                value => +((value + "").replace(/[^0-9.-]/g, '')) >= params,
                ownerQuery,
                options
            );
        },
        // StringAsNumberLessThanEquals
        $sanLte(params, ownerQuery, options) {
            return createEqualsOperation(
                value => +((value + "").replace(/[^0-9.-]/g, '')) <= params,
                ownerQuery,
                options
            );
        },
        // StringAsNumberEquals
        $sanEq(params, ownerQuery, options) {
            return createEqualsOperation(
                value => +((value + "").replace(/[^0-9.-]/g, '')) === params,
                ownerQuery,
                options
            );
        },
        // StringAsNumberNotEquals
        $sanNe(params, ownerQuery, options) {
            return createEqualsOperation(
                value => +((value + "").replace(/[^0-9.-]/g, '')) !== params,
                ownerQuery,
                options
            );
        },
        $includes(params, ownerQuery, options) {
            return createEqualsOperation(
                value => !isNil(value) && !isNil(params) ? value.toString().toUpperCase().includes(params.toString().toUpperCase()) : false,
                ownerQuery,
                options
            );
        },
        $startsWith(params, ownerQuery, options) {
            return createEqualsOperation(
                value => value ? value.toString().toUpperCase().startsWith(params.toString().toUpperCase()) : false,
                ownerQuery,
                options
            );
        },
        $endsWith(params, ownerQuery, options) {
            return createEqualsOperation(
                value => value ? value.toString().toUpperCase().endsWith(params.toString().toUpperCase()) : false,
                ownerQuery,
                options
            );
        },
        $dateGte(params, ownerQuery, options) {
            return createEqualsOperation(
                value => {
                    let val = moment(value);
                    val.startOf("day");
                    return val.toDate().getTime() >= moment(params).toDate().getTime();
                },
                ownerQuery,
                options
            );
        },
        $dateLte(params, ownerQuery, options) {
            return createEqualsOperation(
                value => {
                    let val = moment(value);
                    val.startOf("day");
                    return val.toDate().getTime() <= moment(params).toDate().getTime();
                },
                ownerQuery,
                options
            );
        },
        $dateEq(params, ownerQuery, options) {
            return createEqualsOperation(
                value => {
                    let val = moment(value);
                    val.startOf("day");
                    return val.format("DD.MM.YYYY") === moment(params).format("DD.MM.YYYY");
                },
                ownerQuery,
                options
            );
        },
        $locLevel(params, ownerQuery, options) {
            return createEqualsOperation(
                value => {
                    if (value && params) {
                        let val = Array.isArray(value) ? value : [value];
                        return val.includes(params.object[params.key]);
                    }
                    return false;
                },
                ownerQuery,
                options
            );
        },
        $locBelow(params, ownerQuery, options) {
            return createEqualsOperation(
                value => {
                    if (value && params) {
                        let val = Array.isArray(value) ? value : [value];
                        let level = params.key === "BgID" ? Level.BUILDING : params.key === "SID" ? Level.SECTOR : params.key === "CID" ? Level.CHAMBER : Level.BOX;
                        let ids = [];
                        // w storybooku nie mozna zaimportowac lokiego, więc nie można importować z niego rzeczy
                        if (process.env.STORYBOOK_RUNNING !== "true") {
                            let animalsDB = require("../../../database/animalsDB").default;
                            ids = animalsDB.scanLocalizationIds(params.object, level);
                        }
                        for (let id of ids) {
                            if (val.includes(id)) return true;
                        }
                    }
                    return false;
                },
                ownerQuery,
                options
            );
        }
    });

    const toFilter = useMemo(() => {
        try {
            return data.map(row => {
                let obj = { _row: row };
                let toSearch = {};
                for (let header of headers) {
                    let name = header.filterColumn || header.field;
                    if (header.valueFormatter) {
                        try {
                            toSearch[name] = header.valueFormatter(get(row, header.field || header.filterColumn, row));
                        } catch (e) {
                            toSearch[name] = get(row, header.field || header.filterColumn);
                        }
                        if (!header.disableValueFormatterFilter) {
                            obj[name] = toSearch[name];
                        } else {
                            obj[name] = get(row, name);
                        }
                    } else {
                        // todo - jest przypadek w ktorym jesli bedziemy chceli wyciaganac cos z obiektu to nie zadziala
                        obj[name] = get(row, header.field || header.filterColumn);
                        toSearch[name] = get(row, header.field || header.filterColumn);
                    }
                }
                obj._search = JSON.stringify(toSearch).toLowerCase();
                return obj;
            });
        } catch (e) {
            return [];
        }
    }, [data, headers]);

    const filterHeaders = useMemo(() => {
        return headers.map(header => header.filterType ? header : undefined).filter(item => item);
    }, [headers]);

    const onFilterChange = useCallback(filter => {
        startTransition(() => {
            if (isEqual(filter, filterRef.current?.defaultFilter)) {
                setFilteredData(data);
            } else {
                const filtered = toFilter.filter(sift(filter, { operations: operations.current }));
                setFilteredData(filtered.map(row => row._row));
            }
        });
        if (name) {
            saveFilter(name, { filters: filter });
        }
    }, [toFilter, setFilteredData, name, saveFilter, startTransition, data]);

    const onHeadersToDisplayChange = useCallback((_headers) => {
        setHeadersToDisplay(_headers);
        // if he hid the column and there was sorting on it, it should be removed
        setSortArray(sortArray => sortArray.filter((header) => _headers.some((item) => item.name === header.name)));
        if (name) {
            saveFilter(name, { headersToDisplay: _headers });
        }
    }, [setSortArray, setHeadersToDisplay, saveFilter, name]);

    const onQuickFilterChange = useCallback(value => {
        startTransition(() => {
            let filtered = toFilter.filter(item => item._search.includes(value.toLowerCase()));
            setFilteredData(filtered.map(row => row._row));
        });
    }, [toFilter, setFilteredData, startTransition]);

    const getFilter = useCallback(() => {
        return savedParameters;
    }, [savedParameters]);

    useImperativeHandle(ref, () => ({
        filterComponent: filterRef.current
    }), []);

    return (
        <Filter
            mobile={(forceMobileView || mobile)}
            filterHeaders={filterHeaders}
            onFilterChange={onFilterChange}
            onHeadersToDisplayChange={onHeadersToDisplayChange}
            onQuickFilterChange={onQuickFilterChange}
            name={name}
            getFilter={getFilter}
            headersToDisplay={headersToRender}
            ref={filterRef}
            showFilter={showFilter}
            headers={headers}
            data={data}
            saveToExcel={saveToExcel}
            ableToHideColumns={ableToHideColumns}
            maxColumns={maxColumns}
            excelFileName={excelFileName}
            sortedData={sortedData}
        />
    );
});

export default forwardRef(function TableGrid({
    title,
    shouldIndex,
    selectableRow,
    showPagination,
    className,
    bottomPinnedRows,
    topPinnedRows,
    isFullWidthRow,
    fullWidthRow,
    isSortable = true,
    onRowClick,
    singleRowSelect,
    name,
    mobileRow,
    mobileAdditionalInfo,
    rowClassName,
    isExpanded,
    component,
    smallPagination,
    forceMobileView,
    rowID,
    data,
    headers,
    ableToHideColumns,
    isRowSelectable,
    paginationItems: paginationItemsProp,
    mobileAdditionalInfoName,
    clearSelectedRowsOnQuickFilter = true,
    showFilter,
    fullWidthRowGroups,
    onSortChange,
    saveToExcel,
    initialSortColumn,
    initialSortType,
    clearSelectOnNewData = true,
    scrollOnPageChange = true,
    onSelectedRowsChanged,
    maxColumns,
    excelFileName,
    stripped = false, //Change row background color depends on index
    selectedRows: selectedRowsProp
}, ref) {

    const { t } = useTranslation();

    const tableGridFilter = useRef();
    const initialScrollDone = useRef(false);

    const [, startTransition] = useTransition();

    const filter = useRef();
    const mobile = useRef(isMobile());

    const prevData = useRef(data);
    const prevSelectedRowsProp = useRef([]);

    const savedParameters = useMemo(() => {
        const defaultData = {
            sortArray: [],
            page: 0,
            paginationItems: paginationItemsProp || data.length,
            filters: { $and: [{}] }
        };
        if (!name) return defaultData;
        let indexedDBData = filtersDB.getFilter(name);
        if (ableToHideColumns) {
            defaultData["headersToDisplay"] = headers.filter(({ show }) => show);
        }
        try {
            return {
                ...defaultData,
                ...indexedDBData,
                paginationItems: paginationItemsProp || data.length
            };
        } catch (e) {
            return defaultData;
        }
    }, []); // eslint-disable-line

    const [selectedRows, setSelectedRows] = useState([]);
    const [headersToDisplay, setHeadersToDisplay] = useState(() => {
        if (savedParameters.headersToDisplay) return savedParameters.headersToDisplay;
        return headers;
    });
    const [paginationItems, setPaginationItems] = useState(savedParameters.paginationItems || 100);
    const [page, setPage] = useState(savedParameters.page);
    const [show, setShow] = useState(false);
    const [expandedMobileNode] = useState(null);
    const [sortArray, setSortArray] = useState(() => {
        let initial = savedParameters.sortArray.slice();
        if (initialSortColumn) {
            if (isArray(initialSortColumn)) {
                initialSortColumn.forEach((column) => {
                    initial.push({ name: column, sortType: initialSortType || "asc" });
                });
            } else {
                initial.push({ name: initialSortColumn, sortType: initialSortType || "asc" });

            }
        }
        return initial;
    });
    const [filteredData, setFilteredData] = useState(data);
    const [sortedData, setSortedData] = useState(filteredData);

    useEffect(() => {
        setHeadersToDisplay(() => {
            if (savedParameters.headersToDisplay) return savedParameters.headersToDisplay;
            return headers;
        });
    }, [headers, savedParameters]);

    useEffect(() => {
        // trzeba przejrzec widoki i memoizowac dane, bo wtedy mozna dzialac tylko na referencji
        if (!isEqual(prevData.current, data)) {
            if (tableGridFilter.current.filterComponent) {
                tableGridFilter.current.filterComponent.clearQuickFilter();
                tableGridFilter.current.filterComponent.onApplyFilterClick();
            }
            if (clearSelectOnNewData) {
                setSelectedRows(prev => prev.length === 0 ? prev : []);
            }
        }
        prevData.current = data;
    }, [data, clearSelectOnNewData]);

    useEffect(() => {
        if (clearSelectedRowsOnQuickFilter) {
            setSelectedRows(prev => prev.length === 0 ? prev : []);
        }
    }, [filteredData, clearSelectedRowsOnQuickFilter]);

    useEffect(() => {
        if (onSelectedRowsChanged) {
            let array = selectedRows.map(value => data[value]);
            onSelectedRowsChanged(array);
        }
    }, [selectedRows, onSelectedRowsChanged]); // eslint-disable-line

    useEffect(() => {
        if (Array.isArray(selectedRowsProp) && !isEqual(prevSelectedRowsProp.current, selectedRowsProp)) {
            setSelectedRows(selectedRowsProp);
        }
        prevSelectedRowsProp.current = selectedRowsProp;
    }, [selectedRowsProp]);

    const saveFilter = useCallback((name, filters) => {
        const current = filtersDB.getFilter(name);
        filtersDB.saveFilter(name, { ...current, ...filters });
    }, []);

    const debouncedSaveFilter = useDebounce(saveFilter, 500);

    useEffect(() => {
        if (name) {
            debouncedSaveFilter(name, {
                page,
                paginationItems,
                sortArray
            });
        }
    }, [sortArray, name, page, paginationItems, debouncedSaveFilter]);

    const classNames = useMemo(() => {
        let base = "fetura-grid";
        if (className) base += ` ${className}`;
        if (mobile.current) base += " mobile";
        return base;
    }, [className]);

    const newHeaders = useMemo(() => {
        return headersToDisplay.map((header) => {
            let hasRole = true;
            const element = {
                ...header,
                valueFormatter: header.valueFormatter ? memoize(header.valueFormatter, (...args) => JSON.stringify(args)) : undefined
            };
            if (header.hasOwnProperty("shouldShow")) {
                hasRole = typeof shouldShow === "function" ? header.shouldShow() : !!header.shouldShow;
            }
            if (hasRole) {
                return element;
            }
        }).filter(header => header);
    }, [headersToDisplay]);

    useEffect(() => {
        return () => {
            for (let header of newHeaders) {
                header.valueFormatter?.cache?.clear?.();
            }
        };
    }, []); // eslint-disable-line

    const headersToRender = useMemo(() => {
        if (ableToHideColumns && headers.length) {
            return newHeaders.map((header) => headers.find(({ field }) => field === header.field)).filter((h) => h);
        }
        return newHeaders;
    }, [newHeaders, headers, ableToHideColumns]);

    const maxPage = useMemo(() => {
        const tmp = Math.ceil(filteredData.length / paginationItems - 1);
        return tmp < 0 ? 0 : tmp;
    }, [paginationItems, filteredData.length]);

    useEffect(() => {
        // obnizanie strony do ostatniej mozliwej, bo przekroczono zakres
        setPage(page => page > maxPage ? maxPage : page);
    }, [maxPage]);

    const sortValues = useCallback((a, b, header = {}, sortType) => {
        const getValue = (_val) => {
            let val = header.field ? get(_val, header.field) : _val;
            if (header.sortBy) val = get(val, header.sortBy);
            if (header.valueFormatter && !header.disableValueFormatterSort) {
                return header.valueFormatter(val) + "";
            }
            return val;
        };
        return enhancedComparer(getValue(a), getValue(b), {
            numeric: true,
            ascending: sortType !== "desc",
            nonValuesAtEnd: false
        });
    }, []);

    const groupedSort = useCallback((items, sortArray) => {
        let _items = items.slice(0);
        const getHeader = memoize((name) => {
            return newHeaders.find((item) => item.name === name);
        });
        if (sortArray.length) {
            return _items.sort((a, b) => {
                let sortArrayIndex = 0;
                let sortResult;
                do {
                    const { name, sortType } = sortArray[sortArrayIndex];
                    const header = getHeader(name);
                    if (header && header.customSort) {
                        // jeśli przekazał sortBy to jest ważniejszy
                        if (sortType === "asc") sortResult = header.customSort(a, b, header, sortType, get(a, header.sortBy || header.field), get(b, header.sortBy || header.field), header.dateFormat);
                        else if (sortType === "desc") sortResult = header.customSort(b, a, header, sortType, get(b, header.sortBy || header.field), get(a, header.sortBy || header.field), header.dateFormat);
                    } else {
                        sortResult = sortValues(a, b, header, sortType);
                    }
                    if (sortResult === 0) sortArrayIndex++;
                    else break;
                } while (sortArrayIndex <= sortArray.length - 1);
                return sortResult;
            });
        }
        return _items;
    }, [newHeaders, sortValues]);

    const grouped = useMemo(() => {
        if (fullWidthRowGroups) {
            let tmp = [];
            let array = [tmp];
            for (let d of filteredData) {
                let isFullWidth = isFullWidthRow(d);
                if (isFullWidth) {
                    tmp = [d];
                    array.push(tmp);
                } else {
                    tmp.push(d);
                }
            }
            return array.filter(item => item.length > 0);
        } else {
            return [filteredData];
        }
    }, [filteredData, fullWidthRowGroups, isFullWidthRow]);

    useEffect(() => {
        startTransition(() => {
            let sorted = [];
            for (let group of grouped) {
                let sortResult = groupedSort(group, sortArray);
                if (fullWidthRowGroups) {
                    const fullWidthRowItem = sortResult.find((item) => item.fullWidthRow);
                    if (fullWidthRowItem) {
                        sortResult = sortResult.filter((item) => !item.fullWidthRow);
                        sortResult.unshift(fullWidthRowItem);
                    }
                }
                sorted.push(...sortResult);
                if (onSortChange && isFunction(onSortChange)) onSortChange(sorted);
            }
            setSortedData(sorted);
        });
    }, [onSortChange, fullWidthRowGroups, grouped, groupedSort, sortArray]);

    const paginatedData = useMemo(() => {
        if (!showPagination) return sortedData;
        return sortedData.slice(page * paginationItems, page * paginationItems + paginationItems);
    }, [page, paginationItems, sortedData, showPagination]);

    const _isRowSelectable = useCallback((object) => {
        if (isFunction(isRowSelectable)) return isRowSelectable(object);
        return true;
    }, [isRowSelectable]);

    const _onRowClick = useMemo(() => {
        if (onRowClick) {
            return (object) => {
                let index = findIndex(data, object);
                onRowClick(object, index);
            };
        }
        return null;
    }, [onRowClick, data])

    const _onSelectedRowClick = useMemo(() => {
        if (selectableRow) {
            return (object) => {
                let index = findIndex(data, object);
                setSelectedRows(prev => {
                    if (singleRowSelect) return [index];
                    let indexOfValue = prev.indexOf(index);
                    if (indexOfValue === -1) return [...prev, index];
                    return prev.filter(item => item !== index);
                });
            };
        }
        return null;
    }, [singleRowSelect, data, selectableRow])


    useEffect(() => {
        if (scrollOnPageChange && initialScrollDone.current) {
            filter.current.scrollIntoView({
                behavior: "smooth"
            });
        }
        initialScrollDone.current = true;
    }, [page, scrollOnPageChange]);

    useImperativeHandle(ref, () => ({
        data: paginatedData,
        headers: headersToRender
    }), [paginatedData, headersToRender]);

    return (
        <>
            <TableGridFilter
                clearSelectedRowsOnQuickFilter={clearSelectedRowsOnQuickFilter}
                data={data}
                forceMobileView={forceMobileView}
                headers={headers}
                headersToRender={headersToRender}
                mobile={mobile.current}
                name={name}
                selectedRows={selectedRows}
                setSortArray={setSortArray}
                showFilter={showFilter}
                setFilteredData={setFilteredData}
                saveToExcel={saveToExcel}
                ref={tableGridFilter}
                savedParameters={savedParameters}
                saveFilter={saveFilter}
                startTransition={startTransition}
                ableToHideColumns={ableToHideColumns}
                maxColumns={maxColumns}
                setHeadersToDisplay={setHeadersToDisplay}
                excelFileName={excelFileName}
                sortedData={sortedData}
            />
            {title && <h4>{title}</h4>}
            <div className={classNames} ref={filter}>
                <TableGridHeaders
                    forceMobileView={forceMobileView}
                    mobileRow={mobileRow}
                    mobile={mobile.current}
                    data={data}
                    sortedData={sortedData}
                    isRowSelectable={isRowSelectable}
                    selectedRows={selectedRows}
                    headersToRender={headersToRender}
                    isSortable={isSortable}
                    name={name}
                    _isRowSelectable={_isRowSelectable}
                    selectableRow={selectableRow}
                    singleRowSelect={singleRowSelect}
                    sortArray={sortArray}
                    setSortArray={setSortArray}
                    setSelectedRows={setSelectedRows}
                    shouldIndex={shouldIndex}
                />
                <TableGridTopPinnedRows
                    topPinnedRows={topPinnedRows}
                    headers={headersToRender}
                    selectableRow={isRowSelectable}
                    shouldIndex={shouldIndex}
                    paginatedData={paginatedData}
                    paginationItems={paginationItems}
                    data={data}
                />
                <div className={`body-container ${!!bottomPinnedRows ? "bottom" : ""}`}>
                    {
                        paginatedData.length > 0 &&
                        <TableGridBody
                            forceMobileView={forceMobileView}
                            mobileRow={mobileRow}
                            mobile={mobile.current}
                            isFullWidthRow={isFullWidthRow}
                            paginatedData={paginatedData}
                            _isRowSelectable={_isRowSelectable}
                            component={component}
                            data={data}
                            fullWidthRow={fullWidthRow}
                            headersToRender={headersToRender}
                            isExpanded={isExpanded}
                            onRowClick={_onRowClick}
                            onSelectedRowClick={_onSelectedRowClick}
                            page={page}
                            paginationItems={paginationItems}
                            rowClassName={rowClassName}
                            rowID={rowID}
                            selectableRow={selectableRow}
                            selectedRows={selectedRows}
                            shouldIndex={shouldIndex}
                            singleRowSelect={singleRowSelect}
                            stripped={stripped}
                        />
                    }
                    {
                        paginatedData.length === 0 &&
                        <div className="empty-list">
                            <i>{t("tableGrid.noData")}</i>
                        </div>
                    }
                </div>
                <TableGridBottomPinnedRows
                    bottomPinnedRows={bottomPinnedRows}
                    paginatedData={paginatedData}
                    paginationItems={paginationItems}
                    data={data}
                    filteredData={filteredData}
                    headers={headersToRender}
                    selectableRow={selectableRow}
                    shouldIndex={shouldIndex}
                />
                {
                    showPagination &&
                    <TableGridPagination
                        page={page}
                        setPage={setPage}
                        smallPagination={smallPagination}
                        data={filteredData}
                        paginatedData={paginatedData}
                        paginationItems={paginationItems}
                        maxPage={maxPage}
                        setPaginationItems={setPaginationItems}
                    />
                }
                {
                    mobile && mobileAdditionalInfo &&
                    <TableGridMobileInfo
                        expandedMobileNode={expandedMobileNode}
                        mobileAdditionalInfo={mobileAdditionalInfo}
                        mobileAdditionalInfoName={mobileAdditionalInfoName}
                        setShow={setShow}
                        show={show}
                    />
                }
            </div>
        </>
    );
});