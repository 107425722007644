import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {Modal} from "react-bootstrap";
import {connectModal} from "redux-modal";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {Field, reduxForm} from "redux-form";
import {submit, validate} from "./ConfirmResurrectAnimalSubmit";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import InfoBox from "../../basics/info-box/InfoBox";
import {withRouter} from "react-router-dom";

export const ModalName = "confirm-resurrect-animal";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        selectedItem: state.animalDocuments.selectedItem,
    };
}

class ConfirmResurrectAnimal extends Component {
    render() {
        const {handleHide, show, t, animal, submitting, selectedItem} = this.props;
        const isGroup = selectedItem.hasOwnProperty("GrNo1");
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader title={t("confirmResurrectAnimal")} onCloseClick={handleHide}/>
                <ModalBody>
                    <InfoBox>
                        {
                            !isGroup && t("toResurrectAnimal", {AnmNo1: animal.AnmNo1})
                        }
                        {
                            isGroup && t("toRessurectAnimalToGroup", {GrNo1: selectedItem.GrNo1})
                        }
                    </InfoBox>
                    <Field
                        name={"animalNumber"}
                        component={ReduxLabeledInput}
                        type={"text"}
                        label={t("animalNumber")}
                    />
                    <Field label={t("enterReasonOfYourDecision")} component={ReduxLabeledInput} type={"textarea"}
                           id={"comment"}
                           required
                           name={"comment"}/>
                </ModalBody>
                <ModalFooter hasConfirmButton confirmText={t("save")} onCloseClick={handleHide}
                             formName={ModalName} submitting={submitting}/>
            </Modal>
        );
    }
}

export default compose(
    connectModal({name: ModalName}),
    withTranslation(),
    connect(mapStateToProps),
    withRouter,
    reduxForm({
        form: ModalName,
        onSubmit: submit,
        validate
    })
)(ConfirmResurrectAnimal);
