import {SubmissionError} from "redux-form";
import {isPlainObject, isString} from "lodash";
import {constantCase} from "change-case"
import i18next from "i18next";

const getTransKeyFromServerResponse = (res) => {
    if (isPlainObject(res)) {
        if (isString(res?.message)) {
            return `apiResponse.${constantCase(res.message)}`;
        }
    }
    return null;
}


export const handleResponseToSubmissionError = (res) => {
    const translation = getTransKeyFromServerResponse(res);
    if (!translation) return;
    throw new SubmissionError({
        _error: i18next.t(translation)
    })
}