import React from "react";
import {Modal} from "react-bootstrap";
import {FieldArray, reduxForm} from "redux-form";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import {submit, validate} from "./EditTreatmentModalSubmit";
import {connect} from "react-redux";
import "../../../views/event-chooser-view/events/treatment/_treatment.scss"
import ReduxTableGrid from "../../basics/table-grid/redux-table-grid/ReduxTableGrid";
import moment from "moment";
import {withTranslation} from "react-i18next";
import EditTreatmentModalDataForm from "./EditTreatmentModalDataForm";
import {compose} from "redux";
import {getMedicines} from "../../../selectors/dictionarySelectors";

export const ModalName = "edit-treatment-modal";

function mapStateToProps(state) {
    return {
        medicines: getMedicines(state),
        user: state.user.user,
    }
}

export class EditTreatmentModal extends React.Component {

    state = {
        minDate: null
    }

    constructor(props) {
        super(props);

        let minDate = props.treatment.EvData.Treatment.reduce((a, event) => {
            let min = Math.min(...event.Dates);
            return min < a ? min : a;
        }, moment().toDate().getTime());

        this.state.minDate = moment(minDate)
        this.props.initialize(props.treatment)
    }

    daysValueFormatter = value => {
        return value.map(date => moment(date).format("DD.MM.YYYY")).join(", ");
    };

    medicinesValueFormatter = value => {
        const {medicines} = this.props;
        let meds = value.map(medicineID => medicines.find(item => item.WordID === medicineID)).filter(item => item);
        return meds.map(med => med.WData.Name).join(", ");
    };

    onAddClick = (values) => {
        const {dates, medicines} = values;
        this.props.array.push("EvData.Treatment", {
            Medicine: medicines,
            Dates: dates.map(date => date.toDate().getTime())
        });
    };

    render() {
        const {show, handleHide, t, submitting} = this.props;
        const headers = [
            {
                name: t("medicine"),
                field: "Medicine",
                dontShowInput: true,
                valueFormatter: this.medicinesValueFormatter
            },
            {
                name: t("days"),
                field: "Dates",
                dontShowInput: true,
                valueFormatter: this.daysValueFormatter
            }
        ];
        return (
            <Modal show={show} size={"lg"}>
                <ModalHeader title={t("modals.editTreatmentModal.title")} onCloseClick={handleHide}/>
                <ModalBody className="treatment">
                    <EditTreatmentModalDataForm onSubmit={this.onAddClick}/>
                    <FieldArray component={ReduxTableGrid} name="EvData.Treatment" headers={headers} addRemoveButton
                                minLength={0}/>
                </ModalBody>
                <ModalFooter hasConfirmButton confirmText={t("save")} onCloseClick={handleHide}
                             formName={ModalName} submitting={submitting}/>
            </Modal>
        )
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    connectModal({name: ModalName}),
    reduxForm({
        form: ModalName,
        onSubmit: submit,
        validate
    })
)(EditTreatmentModal);
