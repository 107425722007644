import React, {useCallback, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {convertWeightUnitTo, getUnit} from "../../../../utils/UnitUtils";
import useAnimalGroupInfo from "../useAnimalGroupInfo";
import {interp, isBetween, isFiniteNumber} from "../../../../utils/MathUtils";
import moment from "moment";
import useDomain from "../useDomain";
import {localMomentToUTC, utcDateFormatter, utcMomentToLocal} from "../../../../utils/DateTimeUtils";
import {makeGetWeightingEvents} from "../../../../selectors/animalDocumentsSelectors";
import {useSelector} from "react-redux";
import {findLastIndex, isArray} from "lodash";
import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import {Cell} from "recharts";
import {getColorByName} from "../../../../utils/ColorUtils";
import EstimatedWeight from "../../../../beans/estimated-weight/EstimatedWeight";
import {getOtherWeightsSelector} from "../../../../selectors/settingsSelector";
import ChartTemplate from "./ChartTemplate";
import {getAnimalUnit} from "../../../../utils/SettingsUtils";

const CustomTooltip = ({label, payload}) => {

    return (
        <div className="compare-insertion-tooltip recharts-default-tooltip">
            <div>{utcDateFormatter(label)}</div>
            <ul>
                {
                    !!payload && payload.map((item, i) => {
                        const diff = isArray(item.value) ? item.value[1] - item.value[0] : 0;
                        return (
                            <li key={i}
                                style={{color: diff < 0 ? getColorByName("error") : item.fill}}>
                                <span>
                                    {
                                        diff > 0 &&
                                        <i className={"fas fa-arrow-up"}/>
                                    }
                                    {
                                        diff < 0 &&
                                        <i className={"fas fa-arrow-down"}/>
                                    }
                                    {item.name}{": "}
                                    {
                                        isArray(item.value) &&
                                        <>
                                            {+diff.toFixed(3)}
                                            {item.unit}
                                            <div>
                                                <small>{item.value[0]} <i
                                                    className={"fas fa-arrow-right"}/> {item.value[1]} {item.unit}
                                                </small>
                                            </div>
                                        </>
                                    }
                                    {
                                        !isArray(item.value) &&
                                        <>
                                            {item.value}{item.unit}
                                        </>
                                    }
                                </span>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

const GainChart = () => {

    const ref = useRef(null);

    const animalUnit = useRef(getAnimalUnit());

    const weightUnit = useRef(getUnit("weight", animalUnit.current));

    const groupInfo = useAnimalGroupInfo();

    const weightFormatter = useCallback((value) => {
        if (value === undefined || value === null || isNaN(value)) return null;
        return convertWeightUnitTo(value, {
            showUnit: false,
            unit: animalUnit.current,
            rawValue: true,
            fixed: 2
        })
    }, []);

    const chartDomain = useDomain();

    const getWeightingEvents = useMemo(() => makeGetWeightingEvents(), []);

    const weightingEvents = useSelector(getWeightingEvents);

    const weightDict = useMemo(() => {
        const dictionary = {};
        // group weight reads by a day (utc)
        for (const event of weightingEvents) {
            if (![EventTypes.WEIGHTING, EventTypes.INSERTION].includes(event?.EvCode)) continue;
            const utcTIME = +localMomentToUTC(event?.EvTime).startOf("day");
            if (!dictionary[utcTIME]) dictionary[utcTIME] = [];
            dictionary[utcTIME].push({
                Weight: event?.EvData?.Weight || 0,
                AnmCnt: event?.EvData?.AnmCnt || event?.AnmCnt || 1
            });
        }
        // transform many weights into a single one using weighted average
        for (const timestamp in dictionary) {
            if (dictionary.hasOwnProperty(timestamp)) {
                const {Weight, AnmCnt} = dictionary[timestamp].reduce((total, current) => {
                    return {
                        Weight: total.Weight + (current.Weight * current.AnmCnt),
                        AnmCnt: total.AnmCnt + current.AnmCnt
                    }
                }, {Weight: 0, AnmCnt: 0});
                dictionary[timestamp] = AnmCnt > 0 ? Math.round(Weight / AnmCnt) : 0;
            }
        }
        return dictionary;
    }, [weightingEvents]);

    const weightArray = useSelector(getOtherWeightsSelector);

    const dataSet = useMemo(() => {
        const fmt = weightFormatter;
        const ds = [];
        const [chartStart, chartEnd] = chartDomain;
        const diff = Math.abs(moment.utc(chartStart).diff(moment.utc(chartEnd), "day"));
        const estimate = new EstimatedWeight(0, []);
        estimate.setWeightTable(weightArray, 1200);
        estimate.setBirthDate(groupInfo.birthTime);
        for (let i = 0; i <= diff; i++) {
            const dayUTC = moment.utc(chartStart).add(i, "day");
            const dayLOCAL = utcMomentToLocal(dayUTC);
            const estimated = estimate.getWeightByDate(+dayLOCAL);
            const isGroupActive = isBetween(+dayUTC, groupInfo.start, groupInfo.end);
            const weight = weightDict[+dayUTC] ?? null;
            if (!isGroupActive) {
                ds.push({
                    time: +dayUTC,
                    value: null,
                    details: [],
                    error: null,
                    weight,
                    estimated: fmt(estimated)
                })
                continue;
            }
            const {value, details} = (() => {
                const details = [];
                let cnt = 0;
                const locations = Object.keys(groupInfo.animalNumberPerLocation);
                for (const locationId of locations) {
                    const animals = groupInfo.getNumberByTimeAndLocation(+dayLOCAL, locationId);
                    if (animals === 0) continue;
                    const locationData = {
                        locationId,
                        value: animals,
                        error: null,
                        count: animals
                    }
                    cnt += animals;
                    details.push(locationData);
                }
                return {value: cnt, details};
            })();
            const lastWeightIndex = findLastIndex(ds, ({weight}) => weight !== null);
            ds.push({
                time: +dayUTC,
                value,
                details,
                error: null,
                weight,
                estimated: fmt(estimated)
            });
            if (weight !== null && lastWeightIndex !== -1) {
                const currentIndex = ds.length - 1;
                for (let j = (lastWeightIndex + 1); j < currentIndex; j++) {
                    const newWeight = interp.linear({
                        x: j,
                        x1: lastWeightIndex,
                        y1: ds[lastWeightIndex].weight,
                        x2: currentIndex,
                        y2: weight
                    });
                    const item = ds[j];
                    item.weight = newWeight;
                }
            }
        }
        let hasGain = false;
        for (let i = 1; i < ds.length; i++) {
            const prev = ds[i - 1]?.weight;
            const current = ds[i]?.weight;
            if (!isFiniteNumber(prev)) continue;
            if (!isFiniteNumber(current)) continue;
            const item = ds[i];
            item.gain = [fmt(prev), fmt(current)];
            item.increasing = item.gain[1] >= item.gain[0];
            hasGain = true;

        }
        return hasGain ? ds : [];

    }, [groupInfo, weightFormatter, chartDomain, weightDict, weightArray]);

    const {t} = useTranslation();

    const dataDef = useMemo(() => {
        const COLOR = {
            RED: getColorByName("red"),
            GREEN: getColorByName("green"),
            NONE: "none"
        }
        const getColourByEntry = (entry) => {
            if (!entry.gain) return COLOR.NONE;
            return entry?.increasing ? COLOR.GREEN : COLOR.RED
        }
        return [
            {
                dataKey: "estimated",
                color: "purple",
                strokeDasharray: "5 5",
                type: "linear",
                chartType: "Line",
                unit: weightUnit.current,
                name: t("expectedWeight")
            },
            {
                dataKey: "gain",
                color: "green",
                chartType: "Bar",
                unit: weightUnit.current,
                name: t("averageGain"),
                minPointSize: 1,
                connectNulls: false,
                custom: ({data}) => {
                    return (
                        <>
                            {
                                data.map((entry, index) => (
                                    <Cell key={`cell-${index}`}
                                          strokeWidth={1}
                                          fillOpacity={1}
                                          stroke={getColourByEntry(entry)}
                                          fill={getColourByEntry(entry)}/>
                                ))
                            }
                        </>
                    )
                }
            }
        ];
    }, [t]);

    return (
        <ChartTemplate
            overrideDesktop={true}
            show={true}
            domain={["0", "dataMax+5"]}
            data={dataSet} error={false}
            YaxisName={t("averageWeightShort")}
            name={t("averageGain")} loading={false} id={"cy-gain-chart"}
            dataDef={dataDef}
            customTooltip={CustomTooltip}
            dataSet={dataSet}
            saveAsExcell={t("averageWeightShort")}
            defaultExpanded={true} forwardRef={ref}/>
    )
}
export default React.memo(GainChart);