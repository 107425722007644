import React, {useMemo} from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import SummaryTemplate from "./SummaryTemplate";
import {parseFeed} from "../parsers";
import {getMapData, makeGetAlertsData} from "../../../../selectors/farmMapSelector";
import ErrorList from "./ErrorList";
import WarningList from "./WarningList";
import {upperCase} from "../../../../utils/TextUtils";
import AnimalsSummary from "./AnimalsSummary";
import memoizeOne from "memoize-one";
import {DevType} from "../../../../constans/devices";
import {isRFID} from "../../../../utils/DispenserNRFUtils";
import {hasPlcmntID} from "../../../../utils/DevicesUtils";
import FilterAnimalDrawerContent from "../animal-controls/FilterAnimalDrawerContent";

function AnimalContent({
                           animal,
                           t,
                           location,
                           expanded,
                           toggleExpand,
                           data = {},
                           dispensers,
                           mapType,
                           alerts: {errors, isErrorInChildren, warnings, isWarningInChildren}
                       }) {
    const animals = useMemo(() => [animal], [animal]);
    if (!location || !animal) return null;
    const classNames = ["map-drawer"];
    if (expanded) classNames.push("expanded");
    const {text, isLive} = parseFeed(data);
    const prefix = upperCase(t(`animalKind.${animal.AnimalKind}`));
    return (
        <div className={classNames.join(" ")}>
            <div className={"toggle-expand"} aria-label={expanded ? t("collapse") : t("animalDocuments.expand")}
                 onClick={toggleExpand}/>
            <div className={"map-drawer-content"}>
                <div className={"map-drawer-header"}>
                    <div>{prefix}{" "}{animal.AnmNo1}</div>
                    <div>{t("farms.tabs.mapTab.inName", {name: location.name.slice(0).join(" - ")})}</div>
                </div>
                {
                    mapType === "animals" &&
                    <FilterAnimalDrawerContent animals={animals}/>
                }
                <ErrorList t={t} errors={errors} isErrorInChildren={isErrorInChildren}/>
                <WarningList t={t} warnings={warnings} isWarningInChildren={isWarningInChildren}/>
                {
                    !!dispensers.length &&
                    <div className={"map-drawer-params"}
                         aria-label={t("shadowDescription.CL_SK3.ambientConditions._title")}>
                        <SummaryTemplate icon={"fas fa-wheat"} header={t("forageDispensed")} alerts={data.Alerts}
                                         warnings={data.Warnings}
                                         content={text} isWarning={!isLive}/>
                    </div>
                }
                <AnimalsSummary animals={animals}/>
            </div>

        </div>
    );
}


const emptyObject = {};

const getDispensers = memoizeOne((devices, locationId) => devices.filter(device => [DevType.DISPENSER_NRF].includes(device.DevType) && hasPlcmntID(device, locationId) && isRFID(device)));

const makeMapStateToProps = () => {
    return (state, props) => {
        const getAlertsData = makeGetAlertsData();
        return {
            data: getMapData(state)[props.animal?.AnmID] || emptyObject,
            alerts: getAlertsData(state, props.animal?.AnmID, "animals"),
            dispensers: getDispensers(state.farmDevices.devices, props.animal?.PlcmntID),
        };
    };
}
export default compose(
    withTranslation(),
    connect(makeMapStateToProps)
)(AnimalContent)
