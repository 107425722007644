import { getFarmID } from "../../selectors/farmSelector";
import store from "../../store/store";

const errorsToDiscard = [
    "Uncaught NetworkError: Failed to execute 'importScripts' on 'WorkerGlobalScope'",
    "The user aborted a request.",
    "Fetch is aborted",
    "Unmount",
    "NetworkError when attempting to fetch resource.",
    "NetworkError: A network error occurred.",
    "Unmount of component",
    "Failed to fetch",
    "task_timeout"
];

const importScriptsRegex = /Uncaught NetworkError: Failed to execute 'importScripts' on 'WorkerGlobalScope'.*/;
const iosNoResponseRegex = /^FetchEvent\.respondWith received an error: no-response: no-response.*/;
const urlRegex = /^http:\/\/192\..*/;

export const discardBugsnagError = (event) => {
    if (window.location.hostname === 'localhost' || urlRegex.test(window.location.href)) return true;
    if (event.errors.length > 0) {
        for (let err of event.errors) {
            // const messageType = err.metaData?.feedback?.messageType;
            // const errorMessage = err.errorMessage;
            // const errorMetaMessage = err.metaData?.feedback?.error?.message;
            // const errorMeta = err.metaData?.feedback?.error;
            // if (
            //     errorsToDiscard.includes(errorMessage) ||
            //     errorsToDiscard.includes(messageType) ||
            //     errorsToDiscard.includes(errorMetaMessage) ||
            //     errorsToDiscard.includes(errorMeta) ||
            //     importScriptsRegex.test(errorMessage) ||
            //     iosNoResponseRegex.test(messageType)
            // ) return true;
            const errorMessage = err?.message;
            const bugsnagErrorMessage = err?.errors[0]?.errorMessage;
            if (
                errorsToDiscard.includes(errorMessage) ||
                errorsToDiscard.includes(bugsnagErrorMessage) ||
                importScriptsRegex.test(errorMessage) ||
                importScriptsRegex.test(bugsnagErrorMessage) ||
                iosNoResponseRegex.test(errorMessage) ||
                iosNoResponseRegex.test(bugsnagErrorMessage)
            ) return false
            return true
        }
    }
    return false;
};

export function routingResolver(url) {
    const state = store.getState();
    const farm = getFarmID(state);
    const user = state.user.user;
    const newUrl = url.pathname.replace(farm, ":FarmID").replace(user.ClientID, ":ClientID").replace(user.LocalUserID, ":LocalUserID");
    console.log("Bugsnag URL: ", url, newUrl);
    return newUrl;
}