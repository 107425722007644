import React from "react";
import PropTypes from "prop-types";
import "./_input.scss";
import { myID } from "../../../libs/generateID";
import Tooltip from "../tooltip/Tooltip";
import Button from "../button/Button";
import DataList from "./data-list/DataList";
import { isFunction, pick } from "lodash";

export default class Input extends React.PureComponent {

    constructor(props) {
        super(props);
        this.dataListId = myID();
    }


    findClosestsInXInput(array, rect) {
        let diff, closestInput;
        for (let input of array) {
            let inputRect = input.getBoundingClientRect();
            if (!closestInput) {
                diff = Math.abs(rect.x - inputRect.x);
                closestInput = input;
            } else {
                let diffToInput = Math.abs(rect.x - inputRect.x);
                if (diffToInput < diff) {
                    diff = diffToInput;
                    closestInput = input;
                }
            }
        }
        return closestInput;
    }

    keyDownEventListener = e => {
        let shouldProceed = true;
        const { focusOnClosestInput, onKeyDown } = this.props;
        if (onKeyDown) {
            shouldProceed = onKeyDown(e);
        }
        // jezeli metoda zwroci false to nie zostanie wykonane przeskakiwanie do innych inputow
        // uzyte glownie w selectach zeby mozna bylo przeskakiwac wartosci
        if (shouldProceed) {
            if ([38, 40].includes(e.which) && ["datetime-local", "number"].includes(this.props.type)) {
                e.preventDefault();
            }
            // wyszukiwanie elementow ponizej i ich focus po znalezieniu
            if (e.which === 40 && focusOnClosestInput) {
                let inputs = [...document.getElementsByTagName("input")];
                let rect = this.input.getBoundingClientRect();
                let lowerInputs = inputs.filter(item => {
                    let r1 = item.getBoundingClientRect();
                    return r1.y > rect.y;
                });
                let closestInput = this.findClosestsInXInput(lowerInputs, rect);
                if (closestInput) closestInput.select();
            }
            if (e.which === 38 && focusOnClosestInput) {
                let inputs = [...document.getElementsByTagName("input")];
                let rect = this.input.getBoundingClientRect();
                let higherInputs = inputs.filter(item => {
                    let r1 = item.getBoundingClientRect();
                    return r1.y < rect.y;
                });
                higherInputs.sort((a, b) => b.getBoundingClientRect().y - a.getBoundingClientRect().y);
                let closestInput = this.findClosestsInXInput(higherInputs, rect);
                if (closestInput) closestInput.select();
            }
        }
    };

    componentDidMount() {
        if (this.input) {
            //disable up, down, right, left arrow keys
            this.input.addEventListener("keydown", this.keyDownEventListener);
        }
    }

    componentWillUnmount() {
        if (this.input) {
            this.input.removeEventListener("keydown", this.keyDownEventListener);
        }
    }

    onChange = e => {
        try {
            const { type, onChangeWithEvent } = this.props;
            if (type === "file") {
                this.props.onChange(e);
            } else {
                let text = e.target.value;
                this.props.onChange && this.props.onChange(onChangeWithEvent ? e : text);
            }
        } catch (e) {
            console.error(e);
        }

    };

    onClick = e => {
        e.stopPropagation();
        this.props.onClick && this.props.onClick(e);
    };

    getClassname() {
        const { className, error, unit, warning, type } = this.props;
        let cName = "fetura-input";
        if (error) cName += " error";
        if (warning && !error) cName += " warning";
        if (unit) cName += " unit";
        if (className) {
            if (isFunction(className)) {
                cName += ` ${className(pick(this.props, ["id", "value", "name"]))}`;
            } else {
                cName += ` ${className}`;
            }
        }
        if (type === "file") cName += " file";
        return cName;
    }

    disableMouseWheel = event => {
        event.preventDefault();
    };

    onBlur = e => {
        const { type } = this.props;
        this.props.onBlur && this.props.onBlur(e);
        if (type === "number" && this.input) {
            this.input.removeEventListener("mousewheel", this.disableMouseWheel);
        }
    };

    onFocus = e => {
        const { onFocus, type } = this.props;
        if (onFocus) onFocus(e);
        if (type === "number") {
            if (this.input) {
                this.input.addEventListener("mousewheel", this.disableMouseWheel);
            }
        }
    };

    selectFile = () => {
        const { id } = this.props;
        document.getElementById(id).click();
    };

    // prosze nie ruszac tego raka, poniewaz na tym opiera sie autofocusowanie inputa w gridach i usuwanie wartosci na
    // klawiszu delete
    createRef = ref => {
        this.input = ref;
        this.props.inputRef && this.props.inputRef(ref);
    };

    onCreate = (type, id, disabled, readOnly, value, placeholder, min, max, optionalProps, name, lang,
        autofocus, style, autocomplete, hasDataList
    ) => {
        const { frName, title } = this.props;
        try {
            if (type === "file") {
                return (
                    <div>
                        <Button type={"button"} buttonColor={"success"} onClick={this.selectFile} text={frName} />
                        <input title={title} type={type} id={id} disabled={disabled} readOnly={readOnly} value={value}
                            style={{ display: "none" }} ref={this.createRef} min={min} max={max}
                            onChange={this.onChange} {...optionalProps} name={name} />
                    </div>
                );
            } else {
                return React.createElement(type === "textarea" ? "textarea" : "input", {
                    type: type,
                    id: id,
                    autoComplete: autocomplete,
                    autoFocus: autofocus,
                    disabled: disabled,
                    readOnly: readOnly,
                    value: value,
                    placeholder: placeholder,
                    ref: this.createRef,
                    onBlur: this.onBlur,
                    title,
                    min: min,
                    max: max,
                    ...optionalProps,
                    pattern: type === "number" ? "\\d*" : undefined,
                    inputMode: type === "number" ? "decimal" : undefined,
                    step: type === "number" ? "any" : undefined,
                    onFocus: this.onFocus,
                    onChange: this.onChange,
                    name: name,
                    lang,
                    style,
                    ...hasDataList && { list: this.dataListId }
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        const {
            optionalProps,
            type,
            id,
            error,
            disabled,
            readOnly,
            value,
            placeholder,
            unit,
            warning,
            showIconOnErrorOnWarning,
            min,
            max,
            name,
            lang,
            autofocus,
            style,
            autocomplete,
            dataList
        } = this.props;
        return (
            <div className={this.getClassname()} data-tip={!showIconOnErrorOnWarning ? (error || warning) : null}
                onClick={this.onClick}>
                {
                    this.onCreate(type, id, disabled, readOnly, value, placeholder, min, max, optionalProps,
                        name, lang, autofocus, style, autocomplete, !!dataList
                    )
                }
                {
                    !!dataList &&
                    <DataList dataList={dataList} id={this.dataListId} />
                }
                {
                    unit &&
                    <span>{unit}</span>
                }
                {
                    showIconOnErrorOnWarning && (error || warning) &&
                    <Tooltip tooltipContent={error || warning} type={error ? "error" : "warning"}>
                        <div className={`input-icon ${error ? "error" : "warning"}`} />
                    </Tooltip>
                }
            </div>
        );
    }

}

Input.propTypes = {
    type: PropTypes.oneOf(["date", "datetime-local", "email", "file", "hidden", "image", "month", "number", "password", "range", "reset", "search", "submit", "tel", "text", "time", "url", "week"]).isRequired,
    autocomplete: PropTypes.oneOf(["on", "off"]),
    className: PropTypes.string,
    id: PropTypes.string,
    error: PropTypes.string,
    warning: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.element,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    readOnly: PropTypes.bool,
    onClick: PropTypes.func,
    value: PropTypes.node,
    placeholder: PropTypes.node,
    autofocus: PropTypes.bool,
    unit: PropTypes.string,
    showIconOnErrorOnWarning: PropTypes.bool,
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    optionalProps: PropTypes.object,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    inputRef: PropTypes.func,
    frName: PropTypes.string,
    name: PropTypes.string,
    onChangeWithEvent: PropTypes.bool,
    lang: PropTypes.string,
    style: PropTypes.object,
    focusOnClosestInput: PropTypes.bool,
    dataList: PropTypes.arrayOf(PropTypes.string.isRequired)
};

PropTypes.defaultProps = {
    autofocus: false,
    className: "",
    id: myID(),
    onChange: (text) => {
    },
    readOnly: false,
    onClick: (e) => {
    },
    value: "",
    placeholder: "",
    onBlur: () => {
    },
    optionalProps: {},
    onFocus: () => {
    },
    onKeyDown: () => {
    },
    autocomplete: "on",
    focusOnClosestInput: true,
    dataList: null
};
