import {UnitTypes} from "../constans/unitTypes";
import convert from "convert-units";
import convertWithSlash from "./unit-converters/ConvertUnitsWithSlash";
import {get, isArray, isFinite, isNil, memoize} from "lodash";
import {getUnitSystem} from "./SettingsUtils";
import {isFiniteNumber} from "./MathUtils";


export const converter = (value, from, to) => {
    const converterType = getConverterType(to);
    switch (converterType) {
        case "convert-with-slash":
            return convertWithSlash(value).from(from).to(to);
        case "convert-units":
            return convert(value).from(from).to(to);
        default:
            throw new Error(`converter not found got "${converterType}"`);
    }
}

export const defaults = {
    weight: "g",
    temperature: "C",
    length: "cm",
    pressure: "bar",
    volume: "ml",
    energy: "Wh",
    power: "W",
    current: "mA",
    voltage: "mV",
    area: "cm2",
    density: "g/ml"
};

/**
 * returns value of default unit which should be used as base value when doing offset conversion
 * i.e: for temperature its 0 [0'C]
 * offset is then calculated toFahrenheit([x'C]) - toFahrenheit([0'C])
 * @type {object}
 */
const defaults_offset = {
    _default: 0
}

const getDefaultOffset = (unitType) => {
    return isNil(defaults_offset[unitType]) ? defaults_offset._default : defaults_offset[unitType];
}

const units = {
    metric: {
        weight: ["g", "kg", "mt"],
        temperature: ["C"],
        length: ["mm", "cm", "m"],
        pressure: ["bar"],
        volume: ["ml", "l", "m3"],
        energy: ["Wh", "kWh", "MWh"],
        power: ["W", "kW", "MW"],
        current: ["mA", "A", "kA"],
        voltage: ["mV", "V", "kV"],
        area: ["mm2", "cm2", "m2"],
        density: ["g/ml", "kg/l", "mt/m3"]
    },
    imperial: {
        weight: ["oz", "lb", "t"],
        temperature: ["F"],
        length: ["in", "ft", "yd"],
        pressure: ["psi"],
        volume: ["fl-oz", "gal", "yd3"],
        energy: ["Wh", "kWh", "MWh"],
        power: ["W", "kW", "MW"],
        current: ["mA", "A", "kA"],
        voltage: ["mV", "V", "kV"],
        area: ["in2", "ft2", "yd2"],
        density: ["oz/in3", "lb/ft3", "t/yd3"]
    }
};

const makeUnitPrettyAgain = memoize((unit) => {
    let newUnit = unit;
    if (!newUnit) return "";
    // metryczne tony => tony
    newUnit = newUnit.replace(/mt/g, "t");
    // m3 => m³ itd
    newUnit = newUnit.replace(/3/g, "³");
    // m2 => m²
    newUnit = newUnit.replace(/2/g, "²");
    return newUnit;
});

const getConverterType = memoize((unit) => {
    if (unit.includes("/")) {
        return "convert-with-slash";
    }
    return "convert-units";
});

export function getConverterUnitName(type, unit = UnitTypes.SMALL) {
    return  get(units, `[${getUnitSystem()}][${type}][${unit}]`);
}

export function getUnit(type, unit = UnitTypes.SMALL, {overrideUnitSystem} = {}) {
    let prefix = "";
    if (type === "temperature") {
        prefix = "°";
    }
    const value = get(units, `[${overrideUnitSystem || getUnitSystem()}][${type}][${unit}]`);
    return `${prefix}${makeUnitPrettyAgain(value)}`;
}

/**
 *
 * @param weight - przyjmuje wartosc w gramach?
 * @param unit
 * @param showUnit
 * @param fixed
 * @param defaultValue
 * @param rawValue
 * @param acceptNil
 * @param unitSystem
 * @returns {string/number}
 */
export function convertWeightUnitTo(weight, {
    unit = UnitTypes.SMALL,
    showUnit = false,
    fixed = 2,
    defaultValue = "-",
    rawValue = false,
    acceptNil = false,
    unitSystem = getUnitSystem()
} = {}) {
    return convertUnitTo("weight", weight, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil, unitSystem})
}

export function convertWeightToBaseUnit(weight, {fromUnit = UnitTypes.SMALL} = {}) {
    let convertFrom = units[getUnitSystem()].weight[fromUnit];
    if (isFinite(weight)) {
        return Math.round(converter(weight, convertFrom, defaults.weight));
    } else {
        throw new Error(`convertWeightToBaseUnit failed expected number got ${weight}`)
    }
}

export function convertTemperatureUnitTo(temperature, {
    showUnit = false,
    unit = UnitTypes.SMALL,
    fixed = 2,
    defaultValue = "-",
    rawValue = false,
    acceptNil = false,
    isOffset = false
} = {}) {
    return convertUnitTo("temperature", temperature, {
        showUnit,
        fixed,
        defaultValue,
        rawValue,
        unit,
        acceptNil,
        isOffset
    })
}


export function convertLengthUnitTo(length, {
    showUnit = false,
    unit = UnitTypes.MEDIUM,
    fixed = 2,
    defaultValue = "-",
    rawValue = false,
    acceptNil = false
} = {}) {
    return convertUnitTo("length", length, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}

export function convertLengthToBaseUnit(length, {fromUnit = UnitTypes.SMALL} = {}) {
    let convertFrom = units[getUnitSystem()].length[fromUnit];
    if (isFinite(length)) {
        return Math.round(converter(length, convertFrom, defaults.length));
    } else {
        throw new Error(`convertLengthToBaseUnit failed expected number got ${length}`)
    }
}

export function convertVolumeUnitTo(volume, {
    showUnit = false,
    unit = UnitTypes.MEDIUM,
    fixed = 2,
    defaultValue = "-",
    rawValue = false,
    acceptNil = false
} = {}) {
    return convertUnitTo("volume", volume, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}

export function convertEnergyUnitTo(energy, {
    showUnit = false,
    unit = UnitTypes.MEDIUM,
    fixed = 2,
    defaultValue = "-",
    rawValue = false,
    acceptNil = false
} = {}) {
    return convertUnitTo("energy", energy, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}


export function convertDensityUnitTo(density, {
    showUnit = false,
    unit = UnitTypes.MEDIUM,
    fixed = 2,
    defaultValue = "-",
    rawValue = false,
    acceptNil = false
} = {}) {
    return convertUnitTo("density", density, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}

export function convertPressureUnitTo(pressure, {
    showUnit = false,
    fixed = 2,
    defaultValue = "-",
    rawValue = false,
    acceptNil = false
} = {}) {
    return convertUnitTo("pressure", pressure, {
        showUnit,
        fixed,
        defaultValue,
        rawValue,
        unit: UnitTypes.SMALL,
        acceptNil
    })
}

export function convertPowerUnitTo(power, {
    showUnit = false,
    unit = UnitTypes.MEDIUM,
    fixed = 2,
    defaultValue = "-",
    rawValue = false,
    acceptNil = false
} = {}) {
    return convertUnitTo("power", power, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}

export function convertCurrentUnitTo(current, {
    showUnit = false,
    unit = UnitTypes.MEDIUM,
    fixed = 2,
    defaultValue = "-",
    rawValue = false,
    acceptNil = false
} = {}) {
    return convertUnitTo("current", current, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}

export function convertVoltageUnitTo(voltage, {
    showUnit = false,
    unit = UnitTypes.MEDIUM,
    fixed = 2,
    defaultValue = "-",
    rawValue = false,
    acceptNil = false
} = {}) {
    return convertUnitTo("voltage", voltage, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}

export function convertAreaUnitTo(area, {
    showUnit = false,
    unit = UnitTypes.MEDIUM,
    fixed = 2,
    defaultValue = "-",
    rawValue = false,
    acceptNil = false
} = {}) {
    return convertUnitTo("area", area, {showUnit, fixed, defaultValue, rawValue, unit, acceptNil})
}

export function convertUnitTo(unitType, value, {
    showUnit = false,
    fixed = 2,
    defaultValue = "-",
    rawValue = false,
    acceptNil = false,
    acceptArray = true,
    unit = UnitTypes.SMALL,
    unitSystem = getUnitSystem(),
    isOffset = false
} = {}) {
    if (isNil(value)) return value;
    if (isArray(value)) {
        if (!acceptArray) throw new Error("Received 'Array' but 'acceptArray' flag is set to false!");
        return value.map((v) => convertUnitTo(unitType, v, arguments[2])).join(", ");
    }
    let convertTo = units[unitSystem][unitType][unit] || units[unitSystem][unitType][0];
    if (!unitType) {
        throw new Error("convertUnitTo unitType not specified")
    }
    const valueAsNumber = parseFloat(value);
    let valueAfterConvert = null;
    if (!isNaN(valueAsNumber)) {
        const currentValue = converter(valueAsNumber, defaults[unitType], convertTo);
        if (isOffset) {
            const valueAt0 = converter(getDefaultOffset(unitType), defaults[unitType], convertTo);
            valueAfterConvert = (currentValue - valueAt0);
        } else {
            valueAfterConvert = currentValue;
        }
    }
    if (rawValue) {
        if (isFiniteNumber(valueAfterConvert)) {
            return +(valueAfterConvert.toFixed(fixed));
        } else if (acceptNil) {
            return undefined;
        }
        throw new Error(`convert${unitType[0].toUpperCase()}${unitType.substr(1)}UnitTo failed expected number got ${value}`);
    } else {
        return `${isFiniteNumber(valueAfterConvert) ? valueAfterConvert.toFixed(fixed) : defaultValue}${showUnit ? `${getUnit(unitType, unit)}` : ""}`;
    }
}

export function findCorrectUnitKey(unit) {
    let unitType;
    Object.entries(units).forEach(([key, value]) => Object.entries(value).forEach(([key, value]) => value.forEach(x => {
        if (x === unit) unitType = `${key}`
    })));
    return unitType;
}

export const baseLengthToArea = (squareLength) => {
    return converter(squareLength, `${defaults.length}2`, defaults.area);
};

export const baseLengthToVolume = (cubicLength) => {
    return converter(cubicLength, `${defaults.length}3`, defaults.volume);
}
