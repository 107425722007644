import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getQueryCaller } from '../../../../selectors/animalParametersSelector';
import { buildSummaryQuery, formatResultValue, getQueryDictionary, getRowQuery, getTargetRange, isInTargetRange } from '../../../../utils/ResultsV2Utils';
import { getAnimalUnit } from '../../../../utils/SettingsUtils';
import { allResults } from '../../../../constans/resultSQLSelects';

function ResultsV2PrintTableHeader({ column }) {

    const name = useMemo(() => {
        const headerRow = document.querySelectorAll(".results-v2-header")[column+3];
        return headerRow.textContent;
    }, [column]);

    return (
        <th>{name}</th>
    );
}

function ResultsV2PrintTableFullWidthRow({ row, printColumns, printParams }) {
    const { t } = useTranslation();

    return (
        <tr colSpan={printColumns.length + 1} style={printParams.splitSections ? { pageBreakBefore: "always" } : null}>
            <th>{t(row.name).toUpperCase()}</th>
        </tr>
    );
}

function ResultsV2PrintTableRowUnit({ row }) {

    const unit = useMemo(() => {
        console.log(row.sqlKey);
        const renderedRow = document.querySelector(`.results-v2-unit[data-name="${row.sqlKey}"]`);
        return renderedRow.textContent;
    }, [row.sqlKey]);

    return <td>{unit}</td>;
}

function ResultsV2PrintTableRowTarget({ row }) {

    const target = useMemo(() => {
        const renderedRow = document.querySelector(`.results-v2-target[data-name="${row.sqlKey}"]`);
        if (!renderedRow) return null;
        return renderedRow.textContent;
    }, [row.sqlKey]);

    return <td>{target}</td>;
}

function ResultsV2PrintTableRowColumnSummary({ queryCaller, valueKeysInPrint, row, animalUnit, resultsType, year, week }) {

    const summary = useMemo(() => {
        if (valueKeysInPrint.length === 0) return 0;
        const res = queryCaller(buildSummaryQuery(row, valueKeysInPrint, resultsType, year, week));
        return formatResultValue(res || 0, row.type, animalUnit.current);
    }, [queryCaller, valueKeysInPrint, row, animalUnit, resultsType, year, week]);

    return <td>{summary}</td>;
}

function ResultsV2PrintTableRowAllSummary({ row, valueKeys, queryCaller, animalUnit, resultsType, year, week }) {

    const summary = useMemo(() => {
        const renderedRow = document.querySelector(`.results-v2-summary [data-name="${row.sqlKey}"]`);
        if (!renderedRow) {
            const res = queryCaller(buildSummaryQuery(row, valueKeys, resultsType, year, week));
            return formatResultValue(res || 0, row.type, animalUnit.current);
        }
        return renderedRow.textContent;
    }, [queryCaller, valueKeys, row, animalUnit, resultsType, year, week]);

    return <td>{summary}</td>;
}

function ResultsV2PrintTableRowCell({ inTarget, value, colorValue }) {

    return <td className={colorValue ? inTarget ? "success" : "error" : null}>{value}</td>;
}

function ResultsV2PrintTableRow({ row, printColumns, queryCaller, valueKeys, printParams, targets, targetDictionary, resultsType, year, week }) {

    const { t } = useTranslation();
    const [target, setTarget] = useState(null);

    const animalUnit = useRef(getAnimalUnit());

    const valueKeysInPrint = useMemo(() => {
        return printColumns.map(column => valueKeys[column]);
    }, [printColumns, valueKeys]);

    useLayoutEffect(() => {
        async function getTarget() {
            if (targetDictionary[row.sqlKey].component) {
                const component = await import(`./results-v2-target/${targetDictionary[row.sqlKey].component}`);
                setTarget(() => component.calculateTarget);
                return;
            }
            setTarget(getTargetRange(row.sqlKey, targets, targetDictionary));
        }

        getTarget();
    }, [targets, targetDictionary, row.sqlKey]);

    const customTarget = useMemo(() => {
        return targetDictionary[row.sqlKey].custom;
    }, [targetDictionary, row.sqlKey]);

    const data = useMemo(() => {
        if (target === null) return [];
        if (valueKeysInPrint.length === 0) return null;
        const cells = document.querySelectorAll(`.value[data-name="${row.sqlKey}"]`);
        if (cells.length === 0) {
            const query = getRowQuery(resultsType, row.sqlKey, valueKeysInPrint, year, week);
            const res = queryCaller(query);
            const dict = getQueryDictionary(resultsType, res);
            return valueKeysInPrint.map((valueKey, index) => {
                const value = dict[valueKey.key]?.[row.valueKey] || 0;
                return { value: formatResultValue(value, row.type, animalUnit.current), inTarget: isInTargetRange(dict[valueKey.key], row.valueKey, target, customTarget) };
            });
        }
        return printColumns.map((column, index) => {
            return {
                value: cells[column].textContent,
                inTarget: cells[column].classList.contains("success"),
            };
        });
    }, [printColumns, row, queryCaller, valueKeysInPrint, target, customTarget, resultsType, year, week]);

    return (
        <tr>
            <td>{t(row.name)}</td>
            {
                printParams.showUnit &&
                <ResultsV2PrintTableRowUnit row={row} />
            }
            {
                printParams.showTarget &&
                <ResultsV2PrintTableRowTarget row={row} />
            }
            {
                data && data.map((data, index) => <ResultsV2PrintTableRowCell key={index} {...data} colorValue={printParams.colorValue} />)
            }
            {
                printParams.selectedSummary &&
                <ResultsV2PrintTableRowColumnSummary animalUnit={animalUnit} queryCaller={queryCaller} row={row} valueKeysInPrint={valueKeysInPrint} resultsType={resultsType} year={year} week={week} />
            }
            {
                printParams.allSummary &&
                <ResultsV2PrintTableRowAllSummary animalUnit={animalUnit} queryCaller={queryCaller} row={row} valueKeys={valueKeys} resultsType={resultsType} year={year} week={week} />
            }
        </tr>
    );
}

function ResultsV2PrintTable({ rows, printColumns, valueKeys, printParams, targets, targetDictionary, resultsType, year, week }) {

    const { t } = useTranslation();

    const queryCaller = useSelector(getQueryCaller);

    console.log(rows);

    return (
        <table className="printable-fetura-grid">
            <thead>
                <tr>
                    <th>{t("designation")}</th>
                    {
                        printParams.showUnit &&
                        <th>{t("unit")}</th>
                    }
                    {
                        printParams.showTarget &&
                        <th>{t("results.purpose")}</th>
                    }
                    {
                        printColumns.map((column, index) => <ResultsV2PrintTableHeader key={index} column={column} />)
                    }
                    {
                        printParams.selectedSummary &&
                        <th>{t("columnSummary")}</th>
                    }
                    {
                        printParams.allSummary &&
                        <th>{t("allSummary")}</th>
                    }
                </tr>
            </thead>
            <tbody>
                {
                    rows.map((row, index) => {
                        if (row.isFullWidth) return <ResultsV2PrintTableFullWidthRow row={row} key={index} printColumns={printColumns} printParams={printParams} />;
                        return <ResultsV2PrintTableRow
                            row={allResults[row]}
                            key={row}
                            printColumns={printColumns}
                            queryCaller={queryCaller}
                            valueKeys={valueKeys}
                            printParams={printParams}
                            targets={targets}
                            targetDictionary={targetDictionary}
                            resultsType={resultsType}
                            year={year}
                            week={week} />;
                    })
                }
            </tbody>
        </table>
    );
}

export default function ResultsV2Print({ children }) {

    const table = useMemo(() => {
        const [resultsV2Name, resultsV2Main] = children;
        const rows = resultsV2Name.props.rows;
        const printColumns = resultsV2Main.props.printColumns;
        const printParams = resultsV2Main.props.printParams;
        const targets = resultsV2Main.props.targets;
        const targetDictionary = resultsV2Main.props.targetDictionary;
        const valueKeys = resultsV2Main.props.valueKeys;
        const resultsType = resultsV2Main.props.resultsType;
        const year = resultsV2Main.props.year;
        const week = resultsV2Main.props.week;
        return <ResultsV2PrintTable
            rows={rows}
            printColumns={printColumns}
            valueKeys={valueKeys}
            printParams={printParams}
            targets={targets}
            targetDictionary={targetDictionary}
            resultsType={resultsType}
            year={year}
            week={week} />;
    }, [children]);

    return table;
}
