import React from "react";
import {Col} from "react-bootstrap";
import moment from "moment";

class CastrationTaskItem extends React.Component {

    render() {
        const {name, icon, value} = this.props;
        return (
            <Col sm={12} md={6} xl={3} className={`mb-4 d-flex flex-column align-items-center`}>
                <h2><i className={`fas ${icon} fa-fw ${value.done ? "success" : "disabled"}`}/></h2>
                <div>{name}</div>
                {
                    value.done && <div>{moment(value.done).format("DD.MM.YYYY")}</div>
                }
                {
                    !value.done && value.planned &&
                    <div>
                        {moment(value.planned.Start).format("DD.MM.YYYY")}
                        {value.planned.End && ` - ${moment(value.planned.End).format("DD.MM.YYYY")}`}
                    </div>
                }
            </Col>
        );
    }

}

export default CastrationTaskItem;
