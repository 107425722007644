export const initialValue = {
    fetching: true,
    firstFetching: true,
    data: "",
    map: {
        sql: "",
        params: []
    }
};

export default function animalsReducer(state = initialValue, action) {
    switch (action.type) {
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        case "CALCULATE_ANIMAL_PARAMETERS_PENDING":
            return { ...state, fetching: true };
        case "CALCULATE_ANIMAL_PARAMETERS_FULFILLED":
            return { ...state, fetching: false, firstFetching: false, data: action.payload };
        case "CALCULATE_ANIMAL_PARAMETERS_REJECTED":
            return { ...state, fetching: false, firstFetching: false };
        case "SET_MAP_SQL":
            return { ...state, map: { ...state.map, ...action.payload } };
        default:
            return state;
    }
}
