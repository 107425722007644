import {detect} from "detect-browser";
import {isMobile} from "../../../utils/MobileUtils";

const makeBoolean = ((browserObject) => {
    const result = {};
    for (const [key, fn] of Object.entries(browserObject)) {
        result[key] = () => fn({children: true, fallback: false});
    }
    return result;
})

export const Browser = (() => {
    // do not show a component on default
    const defaultFallback = null;
    // browser names
    const safari = ["safari"];
    const firefox = ["firefox"];
    const chromium = ["chrome", "edge-chromium", "chromium-webview"];
    const opera = ["opera"];
    // supported browser names merged together
    const supportedBrowserList = [...safari, ...firefox, ...chromium, ...opera];
    // memoized value of browser name
    let browserName = null;

    let mobile = null;

    const getBrowserName = () => {
        // browserName should not change in a single session
        // there is no need to call detect every render
        if (browserName === null) {
            browserName = detect()?.name;
        }
        return browserName;
    }

    const getIsMobile = () => {
        if (mobile === null) {
            mobile = isMobile();
        }
        return mobile;
    }

    const results = {
        Chrome: ({children, fallback = defaultFallback} = {}) => {
            return chromium.includes(getBrowserName()) ? children : fallback;
        },
        Opera: ({children, fallback = defaultFallback} = {}) => {
            return opera.includes(getBrowserName()) ? children : fallback;
        },
        Firefox: ({children, fallback = defaultFallback} = {}) => {
            return firefox.includes(getBrowserName()) ? children : fallback;
        },
        Supported: ({children, fallback = defaultFallback} = {}) => {
            return supportedBrowserList.includes(getBrowserName()) ? children : fallback;
        },
        Safari: ({children, fallback = defaultFallback} = {}) => {
            return safari.includes(getBrowserName()) ? children : fallback;
        },
        NotSupported: ({children, fallback = defaultFallback} = {}) => {
            return !supportedBrowserList.includes(getBrowserName()) ? children : fallback;
        },
        Mobile: ({children, fallback = defaultFallback} = {}) => {
            return getIsMobile() ? children : fallback;
        },
        Desktop: ({children, fallback = defaultFallback} = {}) => {
            return !getIsMobile() ? children : fallback;
        }
    }
    return {
        ...results,
        is: makeBoolean(results),
    }

})();

