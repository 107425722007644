import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import moment from "moment";
import "./_printable.scss";
import logo from "../../../resources/images/logos/fetura_cloud_colour.svg";
import PrintableTableGrid from "../table-grid/printable-table-grid/PrintableTableGrid";
import { isMobile } from "../../../utils/MobileUtils";
import { withTranslation } from "react-i18next";
import { NameSpaces } from "@wesstron/utils/Api/constants/nameSpaces";
////////////////
import PrintableResultsGrid from "../../../views/new-farm-view/tabs/PrintableResultsGrid";
import ResultsV2Print from "../../../views/new-farm-view/tabs/results-v2/ResultsV2Print";
//////////

class Printable extends React.Component {

    tableRef = React.createRef();
    state = {
        printing: false
    };

    componentDidMount() {
        if (this.props.printing) {
            this.print();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.printing !== nextProps.printing && nextProps.printing) {
            this.print();
        }
    }

    componentWillUnmount() {
        this.setState({
            printing: false
        });
    }

    print() {
        this.setState({
            printing: true
        }, () => {
            setTimeout(() => {
                window.onafterprint = () => {
                    if (!isMobile()) {
                        setTimeout(() => {
                            this.setState({
                                printing: false
                            });
                        });
                    }
                };
                window.print();
                this.props.onPrintEnd();
            }, 1000);
        });
    }

    getPrintChildren(children = this.props.children) {
        const { componentType, data, isReport } = this.props;
        children = Array.isArray(children) ? children : [children];
        const _DateFormat = "DD-MM-YYYY";
        let startTime = null;
        let endTime = null;
        if (componentType === "resultsV2") {
            return { childrenElements: <ResultsV2Print children={children} /> };
        }
        const childrenElements = children.map(child => {
            if (!child) return null;
            if (componentType === "results") {
                return <PrintableResultsGrid data={data} />;
            }
            if (isReport && Array.isArray(child.props.headers)) {
                return <PrintableTableGrid getData={this.getCurrentTableData} getHeaders={this.getHeaders} {...child.props} />;
            }
            if (!isReport) {
                if (child && child.type === React.Fragment) {
                    return (
                        <>
                            {this.getPrintChildren(child.props.children)}
                        </>
                    );
                }
                return child;
            }
            return null;
        }).filter((item) => !!item);
        const childrenProps = children.find((child) => child && child.props && child.props.hasOwnProperty("startDate") && child.props.hasOwnProperty("endDate"));
        if (childrenProps) {
            const { props: { startDate, endDate } } = childrenProps;
            startTime = moment.utc(startDate).format(_DateFormat);
            endTime = moment.utc(endDate).format(_DateFormat);
        }
        return { childrenElements, startTime, endTime };
    }

    getDatesToShow = (d1, d2) => {
        if (d1 === d2) return d1;
        const { t } = this.props;
        return `${t("controlLists.mainView.rangeOfDates")}: ${d1} - ${d2}`;
    };

    getCurrentTableData = () => {
        if (this.tableRef && this.tableRef.current !== null) {
            return this.tableRef.current.data;
        }
        return [];
    };

    getHeaders = () => {
        if (this.tableRef && this.tableRef.current !== null) {
            return this.tableRef.current.headers;
        }
        return [];
    };

    render() {
        const { children, name, additionalText, showGrid, grid, onlyChildren, isReport } = this.props;
        const { printing } = this.state;
        const { childrenElements, startTime, endTime } = this.getPrintChildren();
        return (
            <>
                {
                    showGrid && grid
                }
                {
                    !showGrid && (
                        // jesli ref nie zostal przekazany to sprawdz czy dobrze opakowales TableGrida
                        React.Children.map((Array.isArray(children) ? children : [children]), (child) => {
                            if (Array.isArray(child?.props?.data) && Array.isArray(child?.props?.headers)) {
                                return React.cloneElement(child, { ...child.props, ref: this.tableRef });
                            }
                            return child;
                        })
                    )
                }
                {
                    printing &&
                    ReactDOM.createPortal(
                        <div className="printable">
                            {
                                !onlyChildren &&
                                <>
                                    <img src={logo} alt={"logo"} />
                                    <div className="printable-title">
                                        <h2>{name}</h2>
                                        {
                                            !isReport &&
                                            <h3>{this.getDatesToShow(startTime, endTime)}</h3>
                                        }
                                        {
                                            additionalText &&
                                            <h5>{additionalText}</h5>
                                        }
                                    </div>
                                </>
                            }
                            {childrenElements}
                        </div>,
                        document.getElementById("root")
                    )
                }
            </>
        );
    }

}

Printable.propTypes = {
    printing: PropTypes.bool.isRequired,
    name: PropTypes.string,
    onPrintEnd: PropTypes.func.isRequired,
    horizontal: PropTypes.bool,
    additionalText: PropTypes.node,
    onlyChildren: PropTypes.bool,
    isReport: PropTypes.bool
};

Printable.defaultProps = {
    horizontal: false,
    isReport: false
};

export default withTranslation(NameSpaces.TRANSLATION)(Printable);
