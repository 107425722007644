import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import {isArray, isEmpty, isNil, isString, pick} from "lodash";
import {Level} from "../constans/levelTypes";
import {getDeviceGroup, getPlacementIDs, hasActiveIndex, hasPlcmntID} from "../utils/DevicesUtils";
import {isRFID} from "../utils/DispenserNRFUtils";
import {
    createAnimalGrid,
    createGrid,
    createIndexLookup,
    devicesRenderedStandalone,
    getDeviceEntityType,
    getDeviceFixedParams,
    getEntityParamsByType,
    getRectProps,
    groupAnimalsBySize,
    objectParser,
    PRE_PROCESS_SCALING,
    preProcessLevels,
    shouldCollide,
    sortDevicesByTypeAndAddr,
    textIdToObject
} from "../utils/FarmMapUtils";
import {isFiniteNumber} from "../utils/MathUtils";
import {enhancedComparer} from "../utils/TextUtils";
import {getPlacementArray} from "../utils/DeviceLocationUtils";
import memoizeOne from "memoize-one";
import Flatbush from "flatbush";
import PathParser from "../components/svg-editor/utils/PathParser";
import {createSelector} from "reselect";
import {getActiveAnimals} from "./animalSelector";
import {getManageBuildingsList} from "./buildingsSelector";
import {makeGetDevicesByType} from "./deviceSelector";
import {DeviceTypesUsedInMap} from "../components/farm-map/utils";
import {getFarmMapLevels} from "./mapSelector";
import {createKeyToIndexDictionary} from "../utils/Utils";

const levelSelector = createSelector([getFarmMapLevels], ({levels, ...other}) => ({
    ...other,
    levels: preProcessLevels(levels),
}));

const locationHelperSelector = createSelector([getManageBuildingsList], (buildings) => {
    const idToIndexLookup = {};
    const idToChildrenIdLookup = {};
    buildings.forEach((location, index) => {
        idToIndexLookup[location.id] = index;
        if (location.parentId) {
            if (!idToChildrenIdLookup[location.parentId]) idToChildrenIdLookup[location.parentId] = [];
            idToChildrenIdLookup[location.parentId].push(location.id);
        }
    });
    return {
        getById: (id) => buildings[idToIndexLookup[id] ?? -1] ?? null,
        getChildrenIds: (id) => idToChildrenIdLookup[id] ?? [],
        getChildren: (id) => {
            const ids = idToChildrenIdLookup[id] ?? [];
            const children = [];
            for (let chId of ids) {
                const location = buildings[idToIndexLookup[chId] ?? -1] ?? null
                children.push(location);
            }
            return children;
        }
    }

})

const animalHelperSelector = createSelector([getActiveAnimals, locationHelperSelector], (animals, buildingsHelper) => {
    const placementIdToAnimalsDict = {};
    const animalComparator = (obj1, obj2) => enhancedComparer(obj1.AnmNo1, obj2.AnmNo1)
    animals.sort(animalComparator);
    const addAnimal = (plcmntId, animal) => {
        const location = buildingsHelper.getById(plcmntId);
        if (!location || location.level === Level.FARM) return;
        if (!placementIdToAnimalsDict[plcmntId]) placementIdToAnimalsDict[plcmntId] = [];
        placementIdToAnimalsDict[plcmntId].push(animal);
    }
    for (let animal of animals) {
        if (isString(animal.PlcmntID)) {
            addAnimal(animal.PlcmntID, animal);
        }
    }
    return {getInPlacementId: (id) => placementIdToAnimalsDict[id] ?? []};
});

const getDeviceByType = (() => {
    const selector = makeGetDevicesByType();
    const props = {DevType: DeviceTypesUsedInMap};
    return (state) => selector(state, props);
})();

const deviceHelperSelector = createSelector([getDeviceByType], (devices) => {
    const devicesSorted = sortDevicesByTypeAndAddr(devices)
    const placementIdToDeviceDict = {};
    const deviceIdToIndex = {};
    devicesSorted.forEach((device, i) => {
        deviceIdToIndex[device.DevID] = i;
        if ([DevTypes.SCALE, DevTypes.SILO_RADAR].includes(device.DevType)) return; // dla silosow mamy osobny byt na mapie wiec pomijamy
        const d = device;
        for (let PlcmntID of getPlacementArray(d)) {
            if (!placementIdToDeviceDict[PlcmntID]) placementIdToDeviceDict[PlcmntID] = [];
            // many indexes merged as single entity
            const fullPlacementsObjArray = getPlacementIDs(d, PlcmntID) || [];
            const hasIndex = fullPlacementsObjArray.every(({Adr}) => isFiniteNumber(Adr));
            const params = hasIndex ? {Index: []} : {};
            if (hasIndex) {
                for (let {Adr} of fullPlacementsObjArray.filter(({Adr}) => hasActiveIndex(d, Adr))) {
                    params.Index.push(Adr);
                }
                if (params.Index.length === 0) continue;
            }
            placementIdToDeviceDict[PlcmntID].push({...params, device: d});
        }
    })
    return {
        getInPlacementId: (id) => placementIdToDeviceDict[id] ?? [],
        getById: (id) => {
            return devicesSorted[deviceIdToIndex[id] ?? -1] ?? null;
        }
    };
})

const getViewXY = (object) => {
    const {minX, maxX, minY, maxY} = object._view;
    return {
        x: (minX + maxX) / 2,
        y: (minY + maxY) / 2
    }
}

const DEBUG = false;

export const farmMapDataSelector = createSelector([levelSelector, deviceHelperSelector, animalHelperSelector, locationHelperSelector], (farmMap, deviceHelper, animalHelper, locationHelper) => {
    const helpers = {
        getDeviceById: deviceHelper.getById,
        getLocationById: locationHelper.getById,
        getLocationChildrenIdsByParentId: locationHelper.getChildrenIds,
        getLocationChildren: locationHelper.getChildren,
        getAnimalsInPlacementId: animalHelper.getInPlacementId,
        getDevicesInPlacementId: deviceHelper.getInPlacementId,
        findLocationInsideCurrentLevel: (() => {
            const cache = {};
            return (currentLevel, placementId) => {
                if (!cache[currentLevel]) {
                    cache[currentLevel] = createKeyToIndexDictionary(farmMap.levels[currentLevel] || [], "id");
                }
                const placementObj = (farmMap.levels[currentLevel] || [])[cache[currentLevel][placementId] ?? -1] ?? {};
                return {
                    ...placementObj,
                    location: helpers.getLocationById(placementObj?.id || "unbinded")
                }
            }
        })()
    }
    const background = [];
    const data = [];
    const levels = [];
    const levelBounds = [];
    const handler = {
        background: ({item}) => {
            item.fill = `url(#${item.id.split("_")[0]})`
            background.unshift({type: "path", params: item});
        },
        device: ({item, collider, key}) => {
            item.animals = [];
            item.devices = [];
            const {id: DevID, index: Index} = textIdToObject(item.id);
            const device = helpers.getDeviceById(DevID);
            const isValid = devicesRenderedStandalone[device?.DevType]?.(device);
            if (isValid) {
                item.devices = [{device: device, ...(Index !== undefined && {Index: [+Index]})}];
                const d = device;
                if (isNil(Index) || hasActiveIndex(d, Index)) {
                    const entityType = getDeviceEntityType(device);
                    // we override map item with parameters directly from device
                    const overrideParams = getDeviceFixedParams(d);
                    if (!isEmpty(overrideParams)) {
                        Object.assign(item, overrideParams);
                        if (overrideParams.reinitialize) {
                            // we must "update" map item by parsing it with possibly new parameters
                            Object.assign(item, objectParser(item));
                        }
                        if (overrideParams.fixRotation) {
                            item._d = item.d;
                            Object.assign(item, objectParser(item, true));
                        }
                    }

                    item.overrideLayer = 2;
                    item._entityType = entityType;
                    if (shouldCollide(entityType)) {
                        item.overrideLayer = 0;
                        collider.add(pick(item, ["_view", "rect", "_angle"]));
                    }
                    const deviceGroup = getDeviceGroup(d);
                    // set flat to true to skip adding device to map
                    let skipDevice = false;
                    // set flag if light should show on zoom
                    switch (deviceGroup) {
                        case "lights": {
                            const placements = getPlacementArray(d, Index);
                            // always show lights that are outside
                            item._displayMode = "visible";
                            // should only be assigned to one or 0 locations
                            if (placements.length >= 1) {
                                for (let plcmntId of placements) {
                                    // beware that standings might be missing at this point
                                    // cause this is the same loop which generates standings by chamber
                                    // this is valid for current case where we only do something when chamber is found
                                    const {location, d} = helpers.findLocationInsideCurrentLevel(key, plcmntId);
                                    switch (location?.level) {
                                        case Level.CHAMBER: {
                                            // show bg and label on zoom
                                            item._displayMode = "visible_on_zoom";
                                            if (placements.length === 1 && item.d === d) {
                                                // do not show label if light fills whole chamber
                                                item._displayMode = "partially_visible_on_zoom";
                                            }
                                            break;
                                        }
                                        default: {
                                            // if it's assigned to other location than chamber than we don't want to show it
                                            skipDevice = true;
                                            break;
                                        }
                                    }
                                }
                            }
                            break;
                        }
                        case "vehicleWeights": {
                            item._displayMode = "visible";
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                    if (!skipDevice) {
                        data[+key].push(item);
                    }
                }
            }
        },
        bounds: ({item, bounds}) => {
            const _view = item._view;
            if (isEmpty(bounds)) {
                bounds = Object.assign(bounds, {x1: _view.minX, x2: _view.maxX, y1: _view.minY, y2: _view.maxY});
            }
            bounds.x1 = Math.min(_view.minX, bounds.x1);
            bounds.x2 = Math.max(_view.maxX, bounds.x2);
            bounds.y1 = Math.min(_view.minY, bounds.y1);
            bounds.y2 = Math.max(_view.maxY, bounds.y2);
        },
        location: ({item, key, collider, additionalItems}) => {
            const location = helpers.getLocationById(item.id);
            if (!location) return;
            item.location = location;
            item.parentId = location.parentId;
            item.animals = [];
            item.devices = [];
            let ids = [];
            const getChildrenIDs = (id) => {
                const children = helpers.getLocationChildrenIdsByParentId(id);
                ids.push(...children);
                if (children.length) {
                    for (let child of children) {
                        getChildrenIDs(child.id);
                    }
                }
            };
            getChildrenIDs(location.id);
            item.childrenIds = ids;
            item.animals = [location.id, ...ids].reduce((allAnimalsInLocation, id) => {
                const animalsForGivenId = helpers.getAnimalsInPlacementId(id);
                return [...allAnimalsInLocation, ...animalsForGivenId];
            }, []);
            item.devices = helpers.getDevicesInPlacementId(location.id)
            data[+key].push(item);

            if (location.level === Level.CHAMBER) {
                let hideOnSmallItems = false;
                // grid z zajetymi miejscami w danej komorze,
                // jesli dodajemy stnaowisko/urzadzenie/swinie na komorze
                // to informacje o ich polozeniu nalezy przekazac tutaj
                // wyrysowanie stanowisk
                const {
                    rect,
                    _rotatedRect
                } = item;
                const {
                    width,
                    height,
                    x1,
                    y1,
                    angle
                } = _rotatedRect || rect;
                let collisionGrid = collider.get(x1, y1, width, height, angle);
                const hasGlobalItemsHovering = !!collisionGrid.length;
                const devicesInside = helpers.getDevicesInPlacementId(location.id)
                if (devicesInside.some(({device}) => getDeviceGroup(device) === "lights")) {
                    hideOnSmallItems = true;
                }
                if (location.individualFeeding) {
                    // dodanie stanowisk
                    const boxes = helpers.getLocationChildren(location.id);
                    hideOnSmallItems = true;
                    const {align, size, type} = getEntityParamsByType(location.sectorType);
                    const grid = createGrid({
                        width: width - 2,
                        height: height - 2,
                        angle,
                        position: item["aria-orientation"],
                        standsInRow: Math.min(location.standsInRow, boxes.length),
                        rows: Math.ceil(boxes.length / location.standsInRow),
                        order: location.standsOrder,
                        x: x1 + 1,
                        y: y1 + 1,
                        scaling: PRE_PROCESS_SCALING,
                        standingWidth: size.width,
                        standingHeight: size.height,
                        standingsDrawType: align
                    })
                    const lookUpTable = createIndexLookup(boxes.length, location.standsInRow, location.standsOrder);
                    for (let i = 0; i < grid.length; i++) {
                        const box = boxes[lookUpTable[i]];
                        if (box) {
                            collisionGrid.push(grid[i]);
                            // collider.add(grid[i]);
                        } else {
                            continue;
                        }
                        additionalItems.push({
                            location: box,
                            parentId: location.id,
                            standingType: type,
                            childrenIds: [],
                            ...grid[i],
                            id: box.id,
                            angle: grid[i]._angle ? grid[i]._angle.angle : 0,
                            animals: helpers.getAnimalsInPlacementId(box.id),
                            devices: helpers.getDevicesInPlacementId(box.id)
                        });
                        const stand = additionalItems[additionalItems.length - 1];
                        const lightsInside = stand.devices.filter(({device}) => getDeviceGroup(device) === "lights");
                        for (let light of lightsInside) {
                            const {device, Index} = light;
                            if (isArray(Index)) {
                                for (let i of Index) {
                                    const isActive = hasActiveIndex(device, i);
                                    if (!isActive) continue;
                                    const hasPlacement = hasPlcmntID(device, stand.id, i);
                                    if (hasPlacement) {
                                        const lightOnMapKey = `${device.DevID}_${i}`;
                                        const tmp = {
                                            ...stand,
                                            type: "devices",
                                            _displayMode: "partially_visible_on_zoom",
                                            id: lightOnMapKey,
                                            devices: [{...light, Index: [i]}],
                                            animals: []
                                        }
                                        const chamberDevice = data[+key][data[+key].length - 1].devices.find(({device: {DevID}}) => DevID === device.DevID);
                                        if (chamberDevice) {
                                            chamberDevice.Index = [...new Set([...chamberDevice.Index, i])];
                                        } else {
                                            data[+key][data[+key].length - 1].devices.push({
                                                ...light,
                                                Index: [i]
                                            })
                                        }
                                        additionalItems.push(tmp);
                                    }
                                }
                            }
                        }
                    }
                } else {
                    // dodanie grupowych dozowników
                    const groupDispensers = devicesInside.filter(({device}) => {
                        return isRFID(device);
                    });
                    let grid = [];
                    const {align, size, type} = getEntityParamsByType("group");
                    if (groupDispensers.length) {
                        hideOnSmallItems = true;
                        groupDispensers.sort((o1, o2) => o1.device.Address - o2.device.Address);
                        grid = createGrid({
                            width: width - 2,
                            height: height - 2,
                            angle,
                            position: item["aria-orientation"],
                            standsInRow: groupDispensers.length,
                            rows: 1,
                            order: location.standsOrder,
                            x: x1 + 1,
                            y: y1 + 1,
                            scaling: PRE_PROCESS_SCALING,
                            standingWidth: size.width,
                            standingHeight: size.height,
                            standingsDrawType: align
                        })
                        const lookUpTable = createIndexLookup(groupDispensers.length, groupDispensers.length, 0);
                        for (let i = 0; i < grid.length; i++) {
                            const dispenser = groupDispensers[lookUpTable[i]];
                            if (dispenser) {
                                collisionGrid.push(grid[i]);
                                // collider.add(grid[i]);
                            } else {
                                continue;
                            }
                            additionalItems.push({
                                location: location,
                                parentId: location.id,
                                standingType: type,
                                childrenIds: [],
                                ...grid[i],
                                id: dispenser.device.DevID,
                                angle: grid[i]._angle ? grid[i]._angle.angle : 0,
                                animals: [],
                                devices: [dispenser],
                                type: "groups"
                            });
                        }
                    }
                }
                // dodanie zwierząt
                const {type} = getEntityParamsByType("group");
                const animals = helpers.getAnimalsInPlacementId(location.id);
                const animalsBySize = groupAnimalsBySize(animals);
                // jesli mamy grupe swin w jednej komorze i nie ma innych reczy w niej
                // to rozkoluj świnie po całej komorze, żeby wyglądało "fajnie"
                // jesli mamy jakiegos device nad komorą to nie rysuj na całośc
                // ponieważ urządzenia są zawsze renderowane nad komorą - wieć device zasłaniałby label
                if (animals.length === 1 && animals[0].AnmCnt > 1 && !hasGlobalItemsHovering/*&& collisionGrid.length <= 1*/) {
                    hideOnSmallItems = true;
                    const animalsSize = Math.min(50, animals[0].AnmCnt, Math.floor((width * height) / Math.pow(16, 2)));
                    // zmienna mowiąca jak bardzo maja byc przyciagane/odciągane zwierzeta do srodka komory
                    // const force = {sm: 15, xs: 20, md: 5}[getAnimalSizeClassName(animals[0])] || 0;
                    // rozstaw grid na całą komore
                    let animalGrid = createAnimalGrid({
                        width,
                        angle,
                        height,
                        collisionGrid: collisionGrid,
                        animalCount: animalsSize,
                        x: x1,
                        y: y1,
                        scaling: PRE_PROCESS_SCALING,
                        maxAnimalArea: Math.pow(16, 2),
                        // meetPoint: {x: x1 + width / 2, y: y1 + height / 2, force}
                    });
                    const additionalAnimals = [];
                    for (let i = 0; i < animalsSize; i++) {
                        collisionGrid.push(animalGrid[i]);
                        // collider.add(animalGrid[i]);
                        additionalAnimals.push({
                            location: location,
                            parentId: location.id,
                            standingType: type,
                            childrenIds: [],
                            ...animalGrid[i],
                            angle,
                            id: `${animals[0].AnmID}_${i}`,
                            animals: [animals[0]],
                            devices: [],
                            type: "animals",
                            // rysujemy zwierze, ale na onClicku wybierana jest lokalizacja
                            overrideId: location.id,
                            overrideText: "",
                            // nadpisujemy ilosc zwierząt
                            overrideAnimalCount: 1
                        });
                    }
                    additionalItems.push(...additionalAnimals);
                    // const groupRect = {
                    //     x1,
                    //     y1,
                    //     width,
                    //     height,
                    //     rotateX: x1 + width / 2,
                    //     rotateY: y1 + height / 2
                    // }
                    // if (additionalAnimals.length) {
                    //     groupRect.x1 = additionalAnimals[0].rect.x1;
                    //     groupRect.y1 = additionalAnimals[0].rect.y1;
                    //     groupRect.width = x2 - groupRect.x1;
                    //     groupRect.height = y2 - groupRect.y1;
                    //     groupRect.rotateX = groupRect.x1 + groupRect.width / 2;
                    //     groupRect.rotateY = groupRect.y1 + groupRect.height / 2;
                    // }
                    additionalItems.push({
                        location: location,
                        parentId: location.id,
                        standingType: type,
                        childrenIds: [],
                        ...getRectProps(x1, y1, width, height, x1 + width / 2, y1 + height / 2, angle),
                        angle,
                        id: animals[0].AnmID,
                        animals: [animals[0]],
                        devices: [],
                        type: "animals",
                        overrideAnimalCount: 0,
                        overrideId: location.id,
                        overrideLayer: 1
                    });
                } else {
                    for (const [sizeString, animals] of Object.entries(animalsBySize)) {
                        hideOnSmallItems = true;
                        const animalSize = +sizeString;
                        DEBUG && console.log("animalGridAngle=%s", angle);
                        let animalGrid = createAnimalGrid({
                            width,
                            angle,
                            height,
                            collisionGrid: collisionGrid,
                            animalCount: animals.length,
                            x: x1,
                            y: y1,
                            scaling: PRE_PROCESS_SCALING,
                            maxAnimalArea: animalSize
                        })
                        for (let i = 0; i < animalGrid.length; i++) {
                            const animal = animals[i];
                            if (animal) {
                                collisionGrid.push(animalGrid[i]);
                                // collider.add(animalGrid[i]);
                            } else {
                                continue;
                            }
                            additionalItems.push({
                                location: location,
                                parentId: location.id,
                                standingType: type,
                                childrenIds: [],
                                ...animalGrid[i],
                                angle,
                                id: animal.AnmID,
                                animals: [animal],
                                devices: [],
                                type: "animals"
                            });

                        }
                    }
                }
                if (hasGlobalItemsHovering) hideOnSmallItems = true;
                data[+key][data[+key].length - 1].hideOnSmallItems = hideOnSmallItems;
            }
        }
    }
    const getCollider = memoizeOne((key) => {
        DEBUG && console.log("getCollider(%s)", key);
        const collisionBoxes = [];
        const getSearchCollisions = memoizeOne((colBoxes) => {
            DEBUG && console.log("getSearchCollisions colBoxes.length=%s", colBoxes.length);
            // flatbush crashes with no items so just return here
            if (!colBoxes.length) return () => [];
            const colIndex = new Flatbush(colBoxes.length);
            const transformData = ({_view: v}) => [v.minX, v.minY, v.maxX, v.maxY];
            for (let box of colBoxes) {
                colIndex.add(...transformData(box));
            }
            colIndex.finish();
            return (x, y, w, h, angle = 0) => {
                // x,y,w,h,angle is the chamber rect where user can see standings
                // rotate is applied on render due to use of svg patterns
                DEBUG && console.log("collider.get(x=%s,y=%s,w=%s,h=%s,a=%s)", x, y, w, h, angle);
                // devices on other hand have rotation applied beforehand so we must apply rotation to chamber rect in order to find them
                const {minX, maxY, maxX, minY} = PathParser.createFromRect(x, y, w, h).rotate(angle).getRect();
                DEBUG && console.log("real cords (%s,%s,%s,%s)", minX, minY, maxX, maxY);
                return colIndex.search(minX, minY, maxX, maxY).map((i) => {
                    const {_angle, _view} = colBoxes[i];
                    // now we must reverse the process and rotate the device to match the chamber X and Y (hence the minus sign)
                    const rect = PathParser.createFromRect(_view.minX, _view.minY, _view.width, _view.height).rotate(_angle?.angle || 0).rotate(-angle, x + (w / 2), y + (h / 2)).getRect()
                    DEBUG && console.log("index=%s => x1=%s,y1=%s x2=%s,y2=%s w=%s h=%s", i, rect.x, rect.y, rect.maxX, rect.maxY, rect.width, rect.height);
                    return {
                        rect: {
                            x1: rect.x,
                            x2: rect.maxX,
                            y1: rect.y,
                            y2: rect.maxY,
                            width: rect.width,
                            height: rect.height,
                        }
                    }
                });
            }
        });
        return {
            add: (box) => {
                DEBUG && console.log("collider.ADD", box);
                collisionBoxes.push(box);
            },
            get: (...args) => getSearchCollisions(collisionBoxes)(...args)
        }
    })

    for (let key in farmMap.levels) {
        if (!farmMap.levels.hasOwnProperty(key)) continue;
        const collider = getCollider(key);
        if (!data[+key]) data[+key] = [];
        const additionalItems = [];
        let bounds = {};
        // for (let i = 0; i < levelsRaw[key].length; i++) {
        for (let i = farmMap.levels[key].length - 1; i >= 0; i--) {
            const args = {item: farmMap.levels[key][i], i, collider, bounds, additionalItems, key};
            if (key === "bg") {
                handler.background(args);
            } else {
                if (args.item.type === "devices") {
                    handler.device(args);
                } else {
                    handler.bounds(args);
                    handler.location(args);
                }
            }
        }
        additionalItems.sort((o1, o2) => {
            const p1 = getViewXY(o1);
            const p2 = getViewXY(o2);
            return ((o1.overrideLayer || 0) - (o2.overrideLayer || 0)) || (p1.y === p2.y ? p1.x - p2.x : p1.y - p2.y);
        })
        data[+key].push(...additionalItems);
        if (key !== "bg" && data[+key].length) {
            levels.push(+key);
            levelBounds.push(isEmpty(bounds) ? null : bounds)
        }
    }
    return {
        type: data.some((level) => level.length) ? "completed" : "empty",
        data,
        levels,
        levelBounds,
        background,
        width: farmMap.width ?? 0,
        height: farmMap.height ?? 0
    }
});
