import {groupBy, memoize, uniq} from "lodash";
import animalsDB from "../database/animalsDB";
import eventsDB from "../database/eventsDB";
import {AnimalTypes} from "../constans/animalTypes";
import {preEvents} from "./AnimalDocumentsUtils";
import {calculateAnimalParameters} from "./AnimalParamUtils";
import groupsDB from "../database/groupsDB";
import {calculateGroupParameters} from "./GroupParamUtils";
import {getAnimalTimespanInGroupByAllEvents, isSubgroup} from "./GroupUtils";
import {isBetween} from "./MathUtils";
import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import ControlList from "./control-list-data/ControlList";
import BugsnagPerformance from "@bugsnag/browser-performance";
import {createKeyToValueDictionary} from "./Utils";

const eventHierarchy = {
    [EventTypes.PIGLET_RECLASSIFY]: 0,
    [EventTypes.TRANSFER]: 1,
    Other: 2
};

export function calculateAnimalParametersForAllAnimals(farm, utilResults, buildingsMap) {
    console.log(utilResults);
    const animals = animalsDB.getAllAnimals(farm, AnimalTypes.SOW, true, false, false);
    const groups = groupsDB.getAllGroups(farm, {showDeleted: true});
    const events = eventsDB.getAllEventsForFarm(farm).sort((a, b) => a.EvTime - b.EvTime);
    console.log(animals.length, groups.length, events.length);
    const groupedEvents = groupBy(events, "AnmID");
    console.log(groupedEvents);
    console.time("calculateAnimalParametersForAllAnimals");
    const res = [];
    for (let animal of animals) {
        const animalEvents = groupedEvents[animal.AnmID];
        const cycles = preEvents(animalEvents, animal, false, utilResults);
        const result = calculateAnimalParameters(animal, animalEvents, cycles.cycleTable, utilResults, buildingsMap);
        res.push(...result);
    }
    for (let group of groups) {
        let groupEvents = [];
        for (let AnmID of uniq([...group.AnmIDs, ...group.Rmvd])) {
            groupEvents.push(...(groupedEvents[AnmID] || []));
        }
        groupEvents.sort((a, b) => {
            const diff = a.EvTime - b.EvTime;
            if (diff !== 0) return diff;
            return (eventHierarchy[a.EvCode] || eventHierarchy.Other) - (eventHierarchy[b.EvCode] || eventHierarchy.Other);
        });
        const animalTimespan = getAnimalTimespanInGroupByAllEvents(groupEvents, group.AnmGrp);
        groupEvents = groupEvents.filter((ev) => {
            if (!animalTimespan[ev.AnmID]) return true;
            return animalTimespan[ev.AnmID].some((range) => isBetween(ev.EvTime, range[0], range[1]));
        });
        const result = calculateGroupParameters(group, groupEvents, buildingsMap, utilResults);
        // console.log(result);
        res.push(...result);
    }
    console.log(res);
    console.timeEnd("calculateAnimalParametersForAllAnimals");
    return res;
}

export async function calculateAnimalParametersForAllAnimals2(farm, utilResults, buildingsMap) {
    const span = BugsnagPerformance.startSpan("Animal parameters");
    const helper = new ControlList({FarmID: farm});
    helper.TryMakeUiResponsive.Init();
    const animals = animalsDB.getAllAnimals(farm, AnimalTypes.SOW, true, false, false);
    const groups = groupsDB.getAllGroups(farm, {showDeleted: true});
    const events = eventsDB.getAllEventsForFarm(farm).sort((a, b) => a.EvTime - b.EvTime);
    const animalDict = createKeyToValueDictionary(animals, "AnmID");
    const isAnimalEligibleForTimespan = memoize((AnmID) => {
        const animal = animalDict[AnmID];
        if (!animal) return false;
        return !isSubgroup(animal);
    })
    const groupedEvents = groupBy(events, "AnmID");
    const res = [];
    for (let animal of animals) {
        const animalEvents = groupedEvents[animal.AnmID];
        const cycles = preEvents(animalEvents, animal, false, utilResults);
        const result = calculateAnimalParameters(animal, animalEvents, cycles.cycleTable, utilResults, buildingsMap);
        res.push(...result);
        await helper.TryMakeUiResponsive();
    }
    for (let group of groups) {
        let groupEvents = [];
        for (let AnmID of uniq([...group.AnmIDs, ...group.Rmvd])) {
            groupEvents.push(...(groupedEvents[AnmID] || []));
        }
        groupEvents.sort((a, b) => {
            const diff = a.EvTime - b.EvTime;
            if (diff !== 0) return diff;
            return (eventHierarchy[a.EvCode] || eventHierarchy.Other) - (eventHierarchy[b.EvCode] || eventHierarchy.Other);
        });
        const animalTimespan = getAnimalTimespanInGroupByAllEvents(groupEvents, group.AnmGrp, {
            isAnimalSupported: isAnimalEligibleForTimespan
        });
        groupEvents = groupEvents.filter((ev) => {
            if (!animalTimespan[ev.AnmID]) return true;
            return animalTimespan[ev.AnmID].some((range) => isBetween(ev.EvTime, range[0], range[1]));
        });
        const result = calculateGroupParameters(group, groupEvents, buildingsMap, utilResults);
        res.push(...result);
        await helper.TryMakeUiResponsive();
    }
    span.end();
    return res;
}