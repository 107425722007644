import "./whydidyourender";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./containers/App";
import * as serviceWorker from "./libs/registerServiceWorker";
import { Provider } from "react-redux";
import store from "./store/store";
import { CookiesProvider } from "react-cookie";
import versionJson from "./version.json";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import FallbackComponent from "./views/fallback-error-view/FallbackErrorView";
import ThemeProvider from "./components/theme-provider/ThemeProvider";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import "./i18n";
import CustomBrowserRouter from "./components/router/CustomBrowserRouter";
import lokiDB from "./database/lokiDB";
import { initializeFirebase } from "./utils/FirebaseUtils";
import { discardBugsnagError, routingResolver } from "./utils/bugsnagUtils/BugsnagUtils";
import BugsnagPerformance, { DefaultRoutingProvider } from '@bugsnag/browser-performance';
import {isCypress} from "./utils/CypressUtils";

if (isCypress()) {
    window.store = store;
}
const stage = process.env.REACT_APP_STAGE || "local";

const bugsnagReleaseStages = [
    "development",
    "staging",
    "production",
    // "local",
];

export const bugsnagClient = Bugsnag.start({
    apiKey: "1070bf20154ed0c3a5895d8f12ff58d2",
    plugins: [new BugsnagPluginReact()],
    onError: async event => {
        if (discardBugsnagError(event)) return false;
        let state = store.getState();
        const { ClientID, LocalUserID } = state.user.user;
        const { email, login } = state.user.attributes;
        // wstawianie danych usera
        event.addMetadata("user", {
            ClientID,
            LocalUserID,
            FarmID: state.location.farm,
            email,
            login,
        });
        // wstawianie danych lokiego
        let lokiData = {};
        try {
            let collections = lokiDB.db.listCollections();
            for (let collection of collections) {
                const { name } = collection;
                const col = lokiDB.db.getCollection(name);
                if (name === "cachedTablesParams") {
                    lokiData[name] = { amount: col.data.length, data: col.data };
                } else {
                    lokiData[name] = { amount: col.data.length };
                }
            }
        } catch (e) {
            console.error(e);
        }
        event.addMetadata("loki", lokiData);
        // obliczanie dysku
        try {
            let estimatedQuota = await navigator.storage.estimate();
            event.addMetadata("capacity", {
                ...estimatedQuota,
                percent: (estimatedQuota.usage / estimatedQuota.quota) * 100,
            });
        } catch (e) {
            console.error(e);
        }
        // status połączenia
        event.addMetadata("connection status", {
            online: state.online.status,
        });
        // mqtt
        event.addMetadata("mqtt", {
            connected: state.mqtt.connected,
            connecting: state.mqtt.connecting,
            reconnecting: state.mqtt.reconnecting,
            gatewayConnection: [...state.mqtt.status.values()].map((item) => ({
                ...item,
                compilation: isFinite(item.compilation)
                    ? new Date(item.compilation).toISOString()
                    : "-",
            })),
        });
    },
    appVersion: versionJson.version,
    releaseStage: stage,
    enabledReleaseStages: bugsnagReleaseStages,
    autoTrackSessions: true,
    maxBreadcrumbs: 40,
    maxEvents: 50,
    enabledBreadcrumbTypes: ["error", "navigation", "request", "user"],
});

BugsnagPerformance.start({
    apiKey: "1070bf20154ed0c3a5895d8f12ff58d2",
    appVersion: versionJson.version,
    releaseStage: stage,
    enabledReleaseStages: bugsnagReleaseStages,
    routingProvider: new DefaultRoutingProvider(routingResolver)
});

const ErrorBoundary = bugsnagClient
    .getPlugin("react")
    .createErrorBoundary(React);

const DEBUG = process.env.REACT_APP_STAGE !== 'production';
if (!DEBUG) {
    if (!window.console) window.console = {};
    const methods = ["log", "debug", "warn", "info", "time", "timeEnd"];
    for (const method of methods) {
        // keep org method
        console[`__${method}`] = console[method];
        console[method] = () => { };
    }
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <CookiesProvider>
        <CustomBrowserRouter>
            <Provider store={store}>
                <ThemeProvider>
                    <ErrorBoundary FallbackComponent={FallbackComponent}>
                        <ScrollToTop>
                            <App />
                        </ScrollToTop>
                    </ErrorBoundary>
                </ThemeProvider>
            </Provider>
        </CustomBrowserRouter>
    </CookiesProvider>
);
serviceWorker.register();
initializeFirebase();
