// import React, {Component} from 'react';
// import {connect} from 'react-redux';
// import {withTranslation} from "react-i18next";
// import {compose} from "redux";
// import PropTypes from "prop-types";
// import {getSavedTechnologyGroups, prepareTechnologyGroupGrid} from "../../../actions/technologyGroupActions";
// import {
//     getTechnologyGroupGridLoading,
//     isFirstTimeLoadingGroups,
//     minimizeTechnologyGroups
// } from "../../../selectors/technologyGroupSelectors";
// import {getGraftingProgram} from "../../../selectors/dictionarySelectors";
// import {AnimalTypes} from "../../../constans/animalTypes";
// import {isEqual} from "lodash";

// function mapStateToProps(state) {
//     return {
//         farm: state.location.farm,
//         groups: minimizeTechnologyGroups(state),
//         graftingProgram: getGraftingProgram(state, {AnimalKind: AnimalTypes.SOW}),
//         loading: isFirstTimeLoadingGroups(state),
//         smallLoading: getTechnologyGroupGridLoading(state)
//     };
// }

// class TechnologyGroupSelect extends Component {

//     componentDidMount() {
//         this.props.dispatch(getSavedTechnologyGroups(this.props.farm, "all"));
//         this.getWorkerData();
//     }

//     componentDidUpdate(prevProps, prevState) {
//         if (!isEqual(this.props.graftingProgram, prevProps.graftingProgram)) {
//             this.getWorkerData();
//         }
//         // zamiana wartosci bo przyszly nowe
//         if (!isEqual(this.props.groups, prevProps.groups) && this.props.value) {
//             let newValue = this.props.groups.find(item => item.name === this.props.value.name);
//             if (newValue) {
//                 this.props.onChange(newValue);
//             } else {
//                 // nie odnaleziono, wiec raczej ktos zmienil ustawienia grup technologicznych wiec wyczysc
//                 this.props.onChange(null);
//             }
//         }
//     }

//     getWorkerData() {
//         const {graftingProgram, farm} = this.props;
//         this.props.dispatch(prepareTechnologyGroupGrid(farm, graftingProgram, false));
//     }

//     render() {
//         const {groups, value, onChange, loading, t, smallLoading, onFocus} = this.props;
//         if (loading) {
//             return (
//                 <div className="technology-group-select-loading">
//                     <i className="fas fa-circle-notch fa-spin"/>
//                 </div>
//             )
//         }
//         return (
//             <div className="technology-group-select">
//                 <Select value={value} onFocus={onFocus} options={groups.map(item => ({
//                     name: item.name,
//                     value: item
//                 }))} onChange={onChange} placeholder={t("technologyGroup")} callOnChangeOnSingleOption={false}/>
//                 {
//                     smallLoading &&
//                     <div className="technology-group-select-loading-small">
//                         <i className="fas fa-circle-notch fa-spin"/>
//                     </div>
//                 }
//             </div>
//         );
//     }
// }

// TechnologyGroupSelect.propTypes = {
//     value: PropTypes.object,
//     onChange: PropTypes.func.isRequired,
// }

// export default compose(
//     withTranslation(),
//     connect(mapStateToProps)
// )(TechnologyGroupSelect);

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from "../../basics/select/Select";
import { formatTechnoGroupName, getLastTechnologyGroups, getTechnologyGroupsAnimals } from '../../../utils/TechnologyGroupUtils';
import { getFirstTechnologyGroupStart, getTechnologyGroupWeeks } from '../../../utils/SettingsUtils';
import { useSelector } from 'react-redux';
import { getAnimalParametersFirstFetching, getQueryCaller } from '../../../selectors/animalParametersSelector';
import "./_technology-group-select.scss";

function TechnologyGroupSelectRenderer({ value, onFocus, onChange }) {
    const { t } = useTranslation();

    const queryCaller = useSelector(getQueryCaller);

    const groups = useMemo(() => {
        const technologyGroupStart = getFirstTechnologyGroupStart();
        const technologyGroupDuration = getTechnologyGroupWeeks();
        const lastGroups = getLastTechnologyGroups(technologyGroupStart, technologyGroupDuration, Math.ceil(21 / technologyGroupDuration));
        const tmp = lastGroups.map(item => formatTechnoGroupName(item.start, item.end, true));
        const res = getTechnologyGroupsAnimals(tmp, queryCaller);
        return tmp.map(group => ({ value: { AnmIDs: res[group]?.animals || [], group }, name: group })).reverse();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="technology-group-select">
            <Select value={value} onFocus={onFocus} options={groups} onChange={onChange} placeholder={t("technologyGroup")} callOnChangeOnSingleOption={false} />
        </div>
    );
}

export default function TechnologyGroupSelect({ value, onFocus, onChange }) {

    const firstFetchingParams = useSelector(getAnimalParametersFirstFetching);

    if (firstFetchingParams) {
        return (
            <div className="position-relative w-100 h-100">
                <div className="technology-group-select-loading">
                    <i className="fas fa-circle-notch fa-spin" />
                </div>
            </div>
        );
    }
    return (
        <TechnologyGroupSelectRenderer value={value} onFocus={onFocus} onChange={onChange} />
    );
}
