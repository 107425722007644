import React from 'react';
import { Col } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { getSelectedAnimalForDocuments } from "../../../selectors/animalDocumentsSelectors";
import GainChart from "./charts/GainChart";
import LocationChart from "./charts/LocationChart";
import FallHistogram from './charts/FallHistogram';
import GroupMortality from '../group-mortality/GroupMortality';

const EventCharts = () => {
    const group = useSelector(getSelectedAnimalForDocuments);
    if (!(group?.AnmGrp)) return null;
    return (
        <Col xs={12}>
            <GainChart />
            <GroupMortality />
            <FallHistogram />
            <LocationChart />
        </Col>
    );
};

export default EventCharts;