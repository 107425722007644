import React from "react";
import PropTypes from "prop-types";
import {myID} from "../../../libs/generateID";
import Checkbox from "./Checkbox";

export default class ReduxCheckbox extends React.Component {

    onChange = value => {
        this.props.input.onChange(value);
    };

    render() {
        const {label, id, input: {value}, required, disabled, allowedValue, margin} = this.props;
        return (
            <Checkbox margin={margin} allowedValue={allowedValue} label={label} disabled={disabled} checked={value}
                      required={required}
                      onChange={value => this.onChange(value)} id={id}/>
        );
    }
}

ReduxCheckbox.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool
};

ReduxCheckbox.defaultProps = {
    id: myID()
};