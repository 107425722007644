import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import { first, get, groupBy, last, omit } from "lodash";
import moment from "moment";
import { USG_STATE } from "../constans/eventTypes";
import { ParamTypes } from "../constans/paramTypes";
import { SectorType } from "../constans/sectorTypes";
import { getParturitionIndexForASow } from "./AnimalDocumentsUtils";
import { REMOVE_TECHNOLOGY_GROUP_REASONS, formatTechnoGroupName, getTechnologyGroupByTime } from "./TechnologyGroupUtils";

function getDateParams(event, sharedParameters) {
    if (!event && !sharedParameters?.dataEv) return {};
    const date = sharedParameters?.dataEv ? moment(sharedParameters?.dataEv) : moment(event.EvTime);
    return {
        day: date.isoWeekday(),
        month: date.month(),
        week: date.isoWeek(),
        year: date.isoWeekYear()
    };
}

export function getGroupDay(event, group) {
    if (!event) return undefined;
    const startTimeInFarmTime = moment(moment(group.start).format(moment.HTML5_FMT.DATE));
    return moment(event.EvTime).startOf("day").diff(startTimeInFarmTime, "days");
}

function getGroupParams(event, sharedParameters) {
    const obj = {};
    if (sharedParameters?.group_nr) {
        obj.group_nr = sharedParameters.group_nr;
    } else if (sharedParameters?.temporaryParams?.getGroup) {
        let group = sharedParameters.temporaryParams.getGroup(event);
        obj.group_nr = group.name;
        obj.groupStart = group.start;
        obj.groupDay = getGroupDay(event, group);
    }
    return obj;
}

export function createParamObject(key, value, sharedParameters, event, type) {
    return {
        ...omit(sharedParameters, ["temporaryParams"]),
        key,
        value,
        type,
        user: event ? event.EvData.OperID || event.OperID : undefined,
        EvID: event?.EvID,
        dataEv: sharedParameters?.dataEv ? sharedParameters.dataEv : event ? event.EvTime : sharedParameters?.temporaryParams?.groupStart,
        cycleDay: sharedParameters?.cycleDay !== undefined ? sharedParameters.cycleDay : sharedParameters?.temporaryParams?.getCycleDay ? sharedParameters.temporaryParams.getCycleDay(event) : undefined,
        ...getGroupParams(event, sharedParameters),
        ...getDateParams(event, sharedParameters)
    };
}

function getTechnologyGroupNumber(cycle, { technologyGroupStart, technologyGroupWeeks }) {
    const lastInsemination = first(cycle[EventTypes.INSEMINATION]);
    if (!lastInsemination) return [];
    const inseminationTime = moment.utc(lastInsemination.EvTime);
    const { start, end } = getTechnologyGroupByTime(inseminationTime, technologyGroupStart, technologyGroupWeeks);
    let groupRow = { name: formatTechnoGroupName(start, end, true), start: +start, from: +inseminationTime, to: Infinity };
    let groups = [];
    for (let isMommyEvent of cycle[EventTypes.IS_MOMMY]) {
        groupRow.to = isMommyEvent.EvTime;
        groups.push({ ...groupRow }); // usuniecie referencji
        const groupForEvent = getTechnologyGroupByTime(isMommyEvent.EvData.GroupStart, technologyGroupStart, technologyGroupWeeks);
        groupRow = { name: formatTechnoGroupName(groupForEvent.start, groupForEvent.end, true), start: +groupForEvent.start, from: isMommyEvent.EvTime, to: Infinity };
    }
    groups.push(groupRow);
    return groups;
}

function calculateParametersRelatedToParturition(cycle, sharedParameters, utilResults) {
    const parturition = cycle[EventTypes.PARTURITION][0];
    if (!parturition) return [];
    const params = [
        // "wszystkie to jest suma zywe martwe mumie, slabe i loszki wchodza w sklad tych trzech" - Daniel 17.01.24
        // wszystkie prosiaki
        createParamObject("bornPiglet", (parturition.EvData.HealthyCnt || 0) + (parturition.EvData.DeadCnt || 0) /*+ (parturition.EvData.WeakCnt || 0)*/ + (parturition.EvData.MummyCnt || 0) /*+ (parturition.EvData.GiltsCnt || 0)*/, sharedParameters, parturition, ParamTypes.AMOUNT),
        // prosiaki z HealthyCnt
        createParamObject("liveBornPiglet", parturition.EvData.HealthyCnt || 0, sharedParameters, parturition, ParamTypes.AMOUNT),
        // prosiaki z DeadCnt
        createParamObject("deadBornPiglet", parturition.EvData.DeadCnt || 0, sharedParameters, parturition, ParamTypes.AMOUNT),
        // prosiaki z MummyCnt
        createParamObject("mummyBornPiglet", parturition.EvData.MummyCnt || 0, sharedParameters, parturition, ParamTypes.AMOUNT),
        // prosiaki z GiltsCnt
        createParamObject("giltsBornPiglet", parturition.EvData.GiltsCnt || 0, sharedParameters, parturition, ParamTypes.AMOUNT),
        // prosiaki z WeakCnt
        createParamObject("weakBornPiglet", parturition.EvData.WeakCnt || 0, sharedParameters, parturition, ParamTypes.AMOUNT)
    ];
    if (parturition.EvData.Weight) {
        // wstawianie dla starych danych
        params.push(createParamObject("bornPigletWeight", parturition.EvData.Weight, sharedParameters, parturition, ParamTypes.WEIGHT));
    }
    if (parturition.EvData.hasOwnProperty("Type")) {
        // jezeli znaleziono typ to dodaj parametr
        params.push(createParamObject("levelParturition", parturition.EvData.Type, sharedParameters, parturition, ParamTypes.PARTURITION_LEVEL));
    }
    const insemination = first(cycle[EventTypes.INSEMINATION]);
    if (insemination) {
        // roznica dni miedzy oczekiwanym a rzeczywistym porodem
        const expected = moment.utc(insemination.EvTime).add(utilResults.TimeFromInseminationToParturition - 1, "days");
        const exact = moment(parturition.EvTime);
        const diff = exact.diff(expected, "days");
        params.push(createParamObject("shiftFarrowDay", diff, sharedParameters, parturition, ParamTypes.DAYS));
        const gestationDays = exact.diff(insemination.EvTime, "days");
        params.push(createParamObject("gestationDays", gestationDays, sharedParameters, parturition, ParamTypes.DAYS));
    }
    return params;
}

function calculateParametersRelatedToFallPiglets(cycle, sharedParameters) {
    const params = [];
    const parturition = cycle[EventTypes.PARTURITION][0];
    for (let fallPiglets of cycle[EventTypes.FALL_PIGLETS]) {
        // dla kazdego zgloszenia dodaj ilosc
        params.push(createParamObject("deadPiglet", fallPiglets.EvData.Piglets, sharedParameters, fallPiglets, ParamTypes.AMOUNT));
        if (fallPiglets.EvData.Weight) {
            params.push(createParamObject("deadPigletWeight", fallPiglets.EvData.Weight, sharedParameters, fallPiglets, ParamTypes.WEIGHT));
        }
        if (parturition) {
            // oblicz roznice w dniach dla wieku upadku
            const diff = moment(fallPiglets.EvTime).startOf("day").diff(moment(parturition.EvTime).startOf("day"), "days");
            params.push(createParamObject("deadPigletAge", diff, sharedParameters, fallPiglets, ParamTypes.DAYS));
        }
    }
    return params;
}

function calculateParametersRelatedToSeparation(cycle, sharedParameters, buildingsMap, isMommy) {
    if (cycle[EventTypes.SEPARATION].length === 0) return [];
    const params = [];
    const parturition = cycle[EventTypes.PARTURITION][0];
    let separatedCount = 0;
    const dayGroups = {};
    for (let separation of cycle[EventTypes.SEPARATION]) {
        separatedCount += separation.EvData.PiCnt;
        // ilosc odsadzonych prosiat
        // params.push(createParamObject("weanedPiglet", separation.EvData.PiCnt, sharedParameters, separation, ParamTypes.AMOUNT));
        // waga odsadzonych prosiat
        // params.push(createParamObject("weanedPigletWeight", separation.EvData.PiWeight, sharedParameters, separation, ParamTypes.WEIGHT));
        // sektor odsadzenia
        const destination = buildingsMap.get(separation.EvData.DstID);
        if (destination) {
            const day = moment(separation.EvTime).format(moment.HTML5_FMT.DATE);
            const key = `${day}_${destination.SType}`;
            if (!dayGroups[key]) dayGroups[key] = {
                weanedPiglet: 0,
                weanedPigletWeight: [],
                separation,
                destination: destination.SType
            };
            dayGroups[key].weanedPiglet += separation.EvData.PiCnt;
            dayGroups[key].weanedPigletWeight.push({
                amount: separation.EvData.PiCnt,
                weight: separation.EvData.PiWeight
            });
        }
    }
    const keys = Object.keys(dayGroups);
    for (let i = 0; i < keys.length; i++) {
        const day = keys[i];
        // przyjmowany jest pierwszy odsad znaleziony dla dnia
        params.push(createParamObject("weanedPiglet", dayGroups[day].weanedPiglet, sharedParameters, dayGroups[day].separation, ParamTypes.AMOUNT));
        params.push(createParamObject("weanedSector", dayGroups[day].destination, sharedParameters, dayGroups[day].separation, ParamTypes.SECTOR_TYPE));
        if (dayGroups[day].destination === SectorType.PIGLET_HOUSE) {
            params.push(createParamObject("weanedToPigletHouse", dayGroups[day].weanedPiglet, sharedParameters, dayGroups[day].separation, ParamTypes.AMOUNT));
            let sumWeight = 0;
            let sumAmount = 0;
            for (let row of dayGroups[day].weanedPigletWeight) {
                sumWeight += row.amount * row.weight;
                sumAmount += row.amount;
            }
            params.push(createParamObject("weanedPigletWeight", Math.round(sumWeight / sumAmount), sharedParameters, dayGroups[day].separation, ParamTypes.WEIGHT));
        }
        if (parturition) {
            // wiek odsadzonych prosiat
            const diff = moment(dayGroups[day].separation.EvTime).startOf("day").diff(moment(parturition.EvTime).startOf("day"), "days");
            params.push(createParamObject("weanedPigletAge", diff, sharedParameters, dayGroups[day].separation, ParamTypes.AGE));
        }
    }
    params.push(createParamObject("weanedPerCycleFarrow", separatedCount, sharedParameters, null, ParamTypes.AMOUNT));
    if (isMommy) {
        params.push(createParamObject("weanedPerCycleSeparation", separatedCount / 2, sharedParameters, null, ParamTypes.AMOUNT));
    } else {
        params.push(createParamObject("weanedPerCycleSeparation", separatedCount, sharedParameters, null, ParamTypes.AMOUNT));
    }
    return params;
}

function calculateParametersRelatedToCastration(cycle, sharedParameters) {
    const params = [];
    for (let castration of cycle[EventTypes.CASTRATION]) {
        if (castration.EvData.Weighting) {
            // zglaszanie wagi porodowej
            params.push(createParamObject("bornPigletWeight", castration.EvData.Weight, sharedParameters, castration, ParamTypes.WEIGHT));
        }
    }
    return params;
}

function calculateParametersRelatedToActiveNipples(cycle, sharedParameters) {
    const lastActiveNipples = last(cycle[EventTypes.ACTIVE_NIPPLES]);
    if (!lastActiveNipples) return [];
    return [
        // ilosc aktywnych sutkow, jezeli byla zgloszona w cyklu
        createParamObject("activeNipples", lastActiveNipples.EvData.Nipples, sharedParameters, lastActiveNipples, ParamTypes.AMOUNT)
    ];
}

function calculateParametersRelatedToInsemination(cycle, sharedParameters, utilResults) {
    const params = [];
    for (let insemination of cycle[EventTypes.INSEMINATION]) {
        // knury w inseminacjach
        params.push(createParamObject("boar", insemination.EvData.BoarID, sharedParameters, insemination, ParamTypes.ANIMAL));
    }
    return params;
}

function calculateLastCycleParametersRelatedToInsemination(cycleTable, sharedParameters, utilResults) {
    const params = [];
    //Maciory po planowanym terminie porodu
    const lastCycle = last(cycleTable);
    const firstInsemination = first(lastCycle[EventTypes.INSEMINATION]);
    if (firstInsemination && lastCycle[EventTypes.PARTURITION].length === 0) {
        // dni do oczekiwanego porodu
        const expectedFarrowingDate = moment.utc(firstInsemination.EvTime).startOf("day").add(utilResults.TimeFromInseminationToParturition - 1, "days");
        const diff = expectedFarrowingDate.diff(moment.utc().startOf("day"), "days");
        const USG = get(last(lastCycle[EventTypes.USG]), 'EvData.Pregnant', undefined);
        const abortion = lastCycle[EventTypes.NO_PREGNANCY];
        if (USG !== 0 && !abortion.length)//Jezeli miała USG 0 lub poronienie nie może mieć oczekiwanego porodu
            params.push(createParamObject("expectedFarrowing", diff, { ...sharedParameters, dataEv: +expectedFarrowingDate }, firstInsemination, ParamTypes.DAYS));
    }
    return params;
}

function calculateParametersRelatedToFallOrSellOfAnimal(animal, events, sharedParameters) {
    if (!animal.DtaDthTime) return [];

    const params = [];
    if (animal.DthRsn === 0) {
        // powod upadku zwierzecia
        const fall = events.find(item => item.EvCode === EventTypes.FALL && !item.DtaDelTime);
        if (fall) {
            params.push(createParamObject("fallReason", fall.EvData.Reasn, sharedParameters, fall, ParamTypes.REASON));
        }
    } else {
        // wiek w dniu sprzedazy
        const sell = events.find(item => item.EvCode === EventTypes.SELL && !item.DtaDelTime);
        if (sell) {
            const diff = moment(sell.EvTime).startOf("day").diff(moment(animal.DtaBrthTime).startOf("day"), "days");
            params.push(createParamObject("sellAge", diff, sharedParameters, sell, ParamTypes.AGE));
        }
    }
    return params;
}

function calculateParametersRelatedToUSG(cycle, sharedParameters) {
    const params = [];
    for (let usg of cycle[EventTypes.USG]) {
        // wynik badania USG
        params.push(createParamObject("usgResult", usg.EvData.Pregnant, sharedParameters, usg, ParamTypes.RESULT));
        const lastInsemination = last(cycle[EventTypes.INSEMINATION]);
        if (lastInsemination) {
            // ilosc dniu od inseminacji do badania usg
            const diff = moment(usg.EvTime).startOf("day").diff(moment.utc(lastInsemination.EvTime).startOf("day"), "days");
            params.push(createParamObject("usgDays", diff, sharedParameters, usg, ParamTypes.DAYS));
        }
    }
    return params;
}

function calculateParametersRelatedToAnimalAge(animal, sharedParameters) {
    const params = [];
    if (animal.DtaBrthTime) {
        // wiek zwierzecia
        const age = moment().startOf("day").diff(animal.DtaBrthTime, "days");
        params.push(createParamObject("age", age, sharedParameters, null, ParamTypes.AGE));
    }
    return params;
}

function getLastEvent(cycle) {
    const events = [];
    for (let key in cycle) {
        if (Array.isArray(cycle[key])) {
            events.push(...cycle[key]);
        }
    }
    if (events.length === 0) return null;
    events.sort((a, b) => b.EvTime - a.EvTime);
    return events[0];
}

function calculateParametersRelatedToRepetition(cycleTable, cyclesParams) {
    const params = [];
    const groupedByCycle = Object.values(groupBy(cycleTable, "cycle"));
    for (let repetitions of groupedByCycle) {
        if (repetitions.length > 1) {
            const lastRepetition = last(repetitions);
            const indexOfLast = cycleTable.indexOf(lastRepetition);
            params.push(createParamObject("repetition", repetitions.length - 1, cyclesParams[indexOfLast], null, ParamTypes.DAYS));
            for (let i = 0; i < repetitions.length - 1; i++) {
                const indexOfCurr = cycleTable.indexOf(repetitions[i]);
                for (let insemination of repetitions[i][EventTypes.INSEMINATION]) {
                    // mozliwe ze tu powinno byc indexOfCurr + 1, poniewaz zaliczamy powtorke do kolejnego cyklu
                    params.push(createParamObject("isRepetition", i + 1, cyclesParams[indexOfCurr], insemination, ParamTypes.AMOUNT));
                }
            }
            for (let i = 1; i < repetitions.length; i++) {
                const prev = repetitions[i - 1];
                const curr = repetitions[i];
                // ostatni event w cyklu
                const endOfPrev = first(prev[EventTypes.INSEMINATION])?.EvTime;
                // pierwsza inseminacja w cyklu
                const startOfCurr = first(curr[EventTypes.INSEMINATION])?.EvTime;
                if (startOfCurr && endOfPrev) {
                    // roznica dni miedzy cyklami
                    const indexOfPrev = cycleTable.indexOf(prev);
                    const diff = moment.utc(startOfCurr).startOf("day").diff(moment.utc(endOfPrev).startOf("day"), "days");
                    params.push(createParamObject("repetitionDays", diff, cyclesParams[indexOfPrev], first(curr[EventTypes.INSEMINATION]), ParamTypes.DAYS));
                }
            }
        }
    }
    return params;
}

function calculateTechnologyGroupRemoval(currCycle, nextCycle, index, cycleParams, events, utilResults) {
    if (currCycle[EventTypes.SEPARATION].length > 0) {
        return [createParamObject("technologyGroupRemoval", REMOVE_TECHNOLOGY_GROUP_REASONS.SEPARATION, cycleParams[index], last(currCycle[EventTypes.SEPARATION]), ParamTypes.TECHNOLOGY_GROUP_REMOVAL)];
    }
    const negativeUSG = currCycle[EventTypes.USG].find(o => o.EvData.Pregnant === USG_STATE.NEGATIVE || o.EvData.Pregnant === false);
    if (negativeUSG) {
        return [createParamObject("technologyGroupRemoval", REMOVE_TECHNOLOGY_GROUP_REASONS.NEGATIVE_USG, cycleParams[index], negativeUSG, ParamTypes.TECHNOLOGY_GROUP_REMOVAL)];
    }
    if (currCycle[EventTypes.NO_PREGNANCY].length > 0) {
        return [createParamObject("technologyGroupRemoval", REMOVE_TECHNOLOGY_GROUP_REASONS.NO_PREGNANCY, cycleParams[index], currCycle[EventTypes.NO_PREGNANCY][0], ParamTypes.TECHNOLOGY_GROUP_REMOVAL)];
    }
    if (!nextCycle) {
        for (let i = events.length - 1; i >= 0; i--) {
            let event = events[i];
            if (event.EvCode === EventTypes.FALL) {
                return [createParamObject("technologyGroupRemoval", REMOVE_TECHNOLOGY_GROUP_REASONS.FALL, cycleParams[index], event, ParamTypes.TECHNOLOGY_GROUP_REMOVAL)];
            } else if (event.EvCode === EventTypes.SELL) {
                return [createParamObject("technologyGroupRemoval", REMOVE_TECHNOLOGY_GROUP_REASONS.SELL, cycleParams[index], event, ParamTypes.TECHNOLOGY_GROUP_REMOVAL)];
            }
        }
    } else if (currCycle.cycle === nextCycle.cycle && cycleParams[index]?.temporaryParams && cycleParams[index + 1]?.temporaryParams) { // jezeli ten sam cykl, ale grupa sie dla nich rozni
        const currentGroup = cycleParams[index].temporaryParams.getGroup(first(currCycle[EventTypes.INSEMINATION]));
        const nextGroup = cycleParams[index + 1].temporaryParams.getGroup(first(nextCycle[EventTypes.INSEMINATION]));
        if (currentGroup && nextGroup && currentGroup !== nextGroup) {
            const nextInsemination = first(nextCycle[EventTypes.INSEMINATION]);
            return [createParamObject("technologyGroupRemoval", REMOVE_TECHNOLOGY_GROUP_REASONS.INSEMINATION_OTHER_WEEK, cycleParams[index], nextInsemination, ParamTypes.TECHNOLOGY_GROUP_REMOVAL)];
        }
    }
    if (currCycle[EventTypes.PARTURITION].length === 0) {
        const insemination = first(currCycle[EventTypes.INSEMINATION]);
        if (insemination) {
            // wyrzucenie zwierzecia z grupy, poniewaz nie zgloszony byl porod, po okresie opoznienia
            const expectedFarrowingDate = moment.utc(insemination.EvTime).startOf("day").add(utilResults.TimeFromInseminationToParturition, "days");
            const diff = expectedFarrowingDate.diff(moment.utc().startOf("day"), "days");
            if (diff < -utilResults.MaxDelayForBirth) {
                const time = +expectedFarrowingDate.add(utilResults.MaxDelayForBirth, "days").startOf("day");
                const params = {
                    ...cycleParams[index],
                    dataEv: time,
                    cycleDay: cycleParams[index].temporaryParams?.getCycleDay?.({ EvTime: time }),
                    groupDay: cycleParams[index].temporaryParams?.getGroupDay?.({ EvTime: time })
                };
                return [createParamObject("technologyGroupRemoval", REMOVE_TECHNOLOGY_GROUP_REASONS.NO_PARTURITION_EVENT, params, null, ParamTypes.TECHNOLOGY_GROUP_REMOVAL)];
            }
        }
    }
    return [];
}

function calculateMultiCycleParameters(cycleTable, cycleParams, events, utilResults) {
    const params = [];
    for (let i = 0; i < cycleTable.length; i++) {
        const curr = cycleTable[i];
        const next = cycleTable[i + 1];
        if (next) {
            const separation = last(curr[EventTypes.SEPARATION]);
            const insemination = first(next[EventTypes.INSEMINATION]);
            if (separation && insemination) {
                const diff = moment(insemination.EvTime).startOf("day").diff(moment(separation.EvTime).startOf("day"), "days");
                // params.push(createParamObject("separationInseminationDays", diff, cycleParams[i + i], insemination, ParamTypes.DAYS));
                // wg. Daniela informacja ta odnosi się do cylku z inseminacją (styczeń 2024, slack)
                params.push(createParamObject("separationInseminationDays", diff, cycleParams[i + 1], separation, ParamTypes.DAYS));
            }
        }
        params.push(...calculateTechnologyGroupRemoval(curr, next, i, cycleParams, events, utilResults));
    }
    return params;
}

function calcDays(row, utilResults) {
    if (row.idleDays === null) {
        // symulowanie cyklu, bo nie ma dni jalowych (mozliwe ze bedzie trzeba warunek wykrywania symulacji dlugosci cyklu zmienic)
        // trzeba dodac mamke na porodowce
        // trzeba przerobic na przekazywanie paramsow
        return utilResults.TimeFromInseminationToParturition + utilResults.TimeOnBirthRoom + utilResults.IdleDays;
    }
    return (row.productiveDays || 0) + (row.idleDays || 0);
}

function customIndexCalc(cycleResults, utilResults) {
    let cycleNumber = { notNull: new Set(), withNull: new Set() }; // liczba cykli z dniami produktywnymi (notNull) i bez (withNull), set dla liczenia bez duplikatow cyklu
    let days = { notNull: 0, withNull: 0 }; // liczba dni produktywne + jalowe
    for (let row of cycleResults) {
        let rowDays = calcDays(row, utilResults);
        if (row.productiveDays !== null) {
            cycleNumber.notNull.add(row.cycleIndex);
            days.notNull += rowDays;
        }
        cycleNumber.withNull.add(row.cycleIndex);
        days.withNull += rowDays;
    }
    const averageDaysForCycle = {
        notNull: days.notNull / cycleNumber.notNull.size,
        withNull: days.withNull / cycleNumber.withNull.size
    };
    return {
        notNull: +(365 / averageDaysForCycle.notNull).toFixed(2),
        withNull: +(365 / averageDaysForCycle.withNull).toFixed(2)
    };
}

function pushOtherParamsForCycles(cycleResults, cyclesParams) {
    const params = [];
    const groupedByCycle = Object.values(groupBy(cycleResults, "cycleIndex"));
    for (let i = 0; i < groupedByCycle.length; i++) {
        const curr = groupedByCycle[i];
        const next = groupedByCycle[i + 1];
        let idleDays = i === 0 ? curr.reduce((prev, curr) => prev + curr.idleDays, 0) : +curr[curr.length - 1].idleDays; // dla pierwszego cyklu powinno byc zsumowane
        if (next && next.length > 1) {
            const dataFromCurrCycle = next.slice(0, -1);
            idleDays += dataFromCurrCycle.reduce((prev, curr) => prev + curr.idleDays, 0);
        }
        if (cyclesParams[i] && idleDays !== 0) {
            params.push(createParamObject("idleDays", idleDays, cyclesParams[i], null, ParamTypes.AMOUNT));
        }
    }
    for (let i = 0; i < cycleResults.length; i++) {
        const { lactationDays, mommyDays } = cycleResults[i];
        if (lactationDays !== null && lactationDays !== 0) {
            params.push(createParamObject("lactationDays", lactationDays, cyclesParams[i], null, ParamTypes.AMOUNT));
        }
        if (mommyDays !== null && mommyDays !== 0) {
            params.push(createParamObject("lactationMommyDays", mommyDays, cyclesParams[i], null, ParamTypes.AMOUNT));
        }
    }
    return params;
}

function averageSeparatedPigletForCycle(cycleTable) {
    let sum = 0;
    const cycles = new Set();
    for (let row of cycleTable) {
        sum += row[EventTypes.SEPARATION].reduce((prev, curr) => prev + curr.EvData.PiCnt, 0);
        cycles.add(row.cycle);
    }
    if (!cycles.size) return 0;
    return sum / cycles.size;
}

function calculateLastCycle(animal, cycleTable, row, sharedParameters, cyclesParams, utilResults) {
    const params = [];
    const lastEvent = getLastEvent(row);
    if (lastEvent) {
        params.push(createParamObject("timeLastAction", lastEvent.EvTime, sharedParameters, lastEvent, ParamTypes.TIME));
    }
    const { cycleResults } = getParturitionIndexForASow(animal, cycleTable);
    const { notNull, withNull } = customIndexCalc(cycleResults, utilResults);
    params.push(createParamObject("index", notNull, sharedParameters, null, ParamTypes.INDEX));
    params.push(createParamObject("indexNoInsemination", withNull, sharedParameters, null, ParamTypes.INDEX));
    params.push(...pushOtherParamsForCycles(cycleResults, cyclesParams));
    params.push(...calculateLastCycleParametersRelatedToInsemination(cycleTable, sharedParameters, utilResults));
    params.push(createParamObject("weanedPerYear", notNull * averageSeparatedPigletForCycle(cycleTable), sharedParameters, null, ParamTypes.AMOUNT));
    return params;
}

function calculateParturitionDurationParameter(cycle, sharedParameters) {
    const params = [];
    const start = cycle[EventTypes.PARTURITION_START][0];
    const end = cycle[EventTypes.PARTURITION_END][0];
    if (start && end) {
        const diff = end.EvTime - start.EvTime;
        params.push(createParamObject("durationParturition", diff, sharedParameters, null, ParamTypes.TIME));
    }
    return params;
}
function calculateP2Parameters(cycle, sharedParameters) {
    const params = [];
    for (let event of cycle[EventTypes.FAT_MEASUREMENT] || []) {
        params.push(createParamObject("fatMeasurement", event?.EvData?.Fat, sharedParameters, event, ParamTypes.AMOUNT));
    }
    return params;
}

function calculateParametersRelatedToGilts(cycle, sharedParameters) {
    let separatedGiltsCount = 0;
    for (let tattoo of cycle[EventTypes.TATTOO]) {
        separatedGiltsCount += get(tattoo, "EvData.TattooedAnimals", []).filter(item => !!item.SeparationTime).length;
    }
    for (let separatedGilts of cycle[EventTypes.SEPARATION_TO_MOMMY_GILTS]) {
        if (separatedGilts.EvData.SeparationTime) {
            separatedGiltsCount += 1;
        }
    }
    if (separatedGiltsCount === 0) return [];
    return [
        createParamObject("weanedGilts", separatedGiltsCount, sharedParameters, null, ParamTypes.AMOUNT)
    ];
}

function calculateParametersRelatedToFostering(cycle, sharedParameters) {
    const params = [];
    for (let event of cycle[EventTypes.MOMMY]) {
        params.push(createParamObject("fostering", event.EvData.PiCnt, sharedParameters, event, ParamTypes.AMOUNT));
    }
    for (let event of cycle[EventTypes.SEPARATION_TO_MOMMY]) {
        params.push(createParamObject("fostering", -event.EvData.PiCnt, sharedParameters, event, ParamTypes.AMOUNT));
    }
    for (let event of cycle[EventTypes.TATTOO]) {
        const animals = event.EvData.TattooedAnimals?.filter(item => !!item.MommyTime)?.length || 0;
        if (animals !== 0) {
            params.push(createParamObject("fostering", -animals, sharedParameters, event, ParamTypes.AMOUNT));
        }
    }
    for (let event of cycle[EventTypes.SEPARATION_TO_MOMMY_GILTS]) {
        params.push(createParamObject("fostering", event.AnmCnt, sharedParameters, event, ParamTypes.AMOUNT));
    }
    return params;
}

function calculateParametersRelatedToPigStatus(cycle, sharedParameters) {
    const params = [];
    const events = [
        ...cycle[EventTypes.PARTURITION],
        ...cycle[EventTypes.FALL_PIGLETS],
        ...cycle[EventTypes.SEPARATION_TO_MOMMY],
        ...cycle[EventTypes.SEPARATION_TO_MOMMY_GILTS],
        ...cycle[EventTypes.MOMMY],
        ...cycle[EventTypes.TATTOO],
        ...cycle[EventTypes.SEPARATION]
    ].sort((a, b) => a.EvTime - b.EvTime);
    let currentPiglets = 0;
    let lastEv = null;
    for (let event of events) {
        switch (event.EvCode) {
            case EventTypes.FALL_PIGLETS:
                currentPiglets -= event.EvData.Piglets;
                ;
                break;
            case EventTypes.PARTURITION:
                currentPiglets += event.EvData.HealthyCnt;
                break;
            case EventTypes.SEPARATION_TO_MOMMY:
                currentPiglets -= event.EvData.PiCnt;
                break;
            case EventTypes.MOMMY:
                currentPiglets += event.EvData.PiCnt;
                break;
            case EventTypes.SEPARATION:
                currentPiglets -= event.EvData.PiCnt;
                break;
            case EventTypes.SEPARATION_TO_MOMMY_GILTS:
                currentPiglets += event.AnmCnt;
                break;
            case EventTypes.TATTOO:
                currentPiglets -= event.EvData.TattooedAnimals?.filter(item => !!item.MommyTime)?.length || 0;
                break;
            default:
                console.log(event);
        }
        if (currentPiglets === 0) {
            lastEv = event;
        }
    }
    if (currentPiglets === 0 && lastEv !== null) {
        const diff = moment().startOf("day").diff(moment(lastEv.EvTime).startOf("day"), "days");
        params.push(createParamObject("zeroPigletsDays", diff, sharedParameters, lastEv, ParamTypes.DAYS));
    }
    return params;
}

function createNextTransferParam(destinationID, time, event, buildingsMap, utilResults, isMommy, sharedParameters) {
    const destination = buildingsMap.get(destinationID);
    if (!destination) return [];
    if ([SectorType.DELIVERY, SectorType.MATING, SectorType.SOWS].includes(destination.SType)) {
        const timeOnLocation = destination.SType === SectorType.MATING ? utilResults.TimeOnMating : destination.SType === SectorType.SOWS ? utilResults.TimeOnSows : isMommy ? utilResults.TimeOnBirthRoomMommy : utilResults.TimeOnBirthRoom;
        const expectedTransferDate = moment(time).startOf("day").add(timeOnLocation, "days");
        const diff = expectedTransferDate.diff(moment().startOf("day"), "days");
        return [createParamObject("nextTransfer", diff, sharedParameters, event, ParamTypes.DAYS)];
    }
    return [];
}

function calculateTransferParams(animal, events, buildingsMap, utilResults, isMommy, sharedParameters) {
    const params = [];
    for (let i = events.length - 1; i >= 0; i--) {
        const event = events[i];
        if (event.EvCode === EventTypes.TRANSFER) {
            params.push(...createNextTransferParam(event.EvData.DstID, event.EvTime, event, buildingsMap, utilResults, isMommy, sharedParameters));
            break;
        }
    }
    if (params.length === 0) {
        params.push(...createNextTransferParam(animal.PlcmntID, animal.DtaInTime, null, buildingsMap, utilResults, isMommy, sharedParameters));
    }
    const currentLocation = buildingsMap.get(animal.PlcmntID);
    if (currentLocation?.hasOwnProperty("SType")) {
        params.push(createParamObject("currentSector", currentLocation.SType, sharedParameters, null, ParamTypes.SECTOR_TYPE));
    }
    return params;
}

function calculateParametersRelatedToIsMommy(cycle, sharedParameters) {
    let isMommy = true;
    const params = [];
    for (let event of cycle[EventTypes.IS_MOMMY]) {
        params.push(createParamObject("isMommy", true, sharedParameters, event, ParamTypes.BOOLEAN));
    }
    if (params.length === 0) {
        isMommy = false;
        params.push(createParamObject("isMommy", false, sharedParameters, null, ParamTypes.BOOLEAN));
    }
    return {
        isMommy,
        params
    };
}

function getCycleParams(event, cycleParams) {
    const cycle = cycleParams.find(item => item.temporaryParams && item.temporaryParams.cycleDates.start <= event.EvTime && item.temporaryParams.cycleDates.end >= event.EvTime);
    if (cycle) return cycle;
    const lastCycle = last(cycleParams);
    if (lastCycle?.temporaryParams && lastCycle.temporaryParams.cycleDates.start <= event.EvTime) return lastCycle;
    return null;
}

function calculateVaccinationParameters(events, cyclesParams) {
    let params = [];
    const vaccinations = (events || []).filter(item => item.EvCode === EventTypes.GRAFTING || item.EvCode === EventTypes.PIGLETS_TREATMENT);
    for (let row of vaccinations) {
        const cycleParams = getCycleParams(row, cyclesParams);
        params.push(createParamObject(row.EvCode === EventTypes.PIGLETS_TREATMENT ? "pigletVaccination" : "vaccination", row.EvData.Medicine, cycleParams, row, ParamTypes.MEDICINE));
    }
    return params;
}

function calculateParametersForCycle(row, index, cyclesParams, animal, events, cycleTable, utilResults, buildingsMap) {
    const cycleParams = cyclesParams[index];
    const { isMommy, params: mommyParams } = calculateParametersRelatedToIsMommy(row, cycleParams);
    const params = [
        ...mommyParams,
        ...calculateParametersRelatedToActiveNipples(row, cycleParams),
        ...calculateParametersRelatedToInsemination(row, cycleParams, utilResults),
        ...calculateParametersRelatedToParturition(row, cycleParams, utilResults),
        ...calculateParametersRelatedToFallPiglets(row, cycleParams),
        ...calculateParametersRelatedToCastration(row, cycleParams),
        ...calculateParametersRelatedToUSG(row, cycleParams),
        ...calculateParturitionDurationParameter(row, cycleParams),

        ...calculateParametersRelatedToGilts(row, cycleParams),
        ...calculateParametersRelatedToFostering(row, cycleParams),
        ...calculateParametersRelatedToSeparation(row, cycleParams, buildingsMap, isMommy),
        ...calculateParametersRelatedToPigStatus(row, cycleParams),
        ...calculateP2Parameters(row, cycleParams)
    ];
    if (cycleParams.actCycle) {
        params.push(...calculateParametersRelatedToFallOrSellOfAnimal(animal, events, cycleParams));
        params.push(...calculateParametersRelatedToAnimalAge(animal, cycleParams));
        params.push(...calculateLastCycle(animal, cycleTable, row, cycleParams, cyclesParams, utilResults));
        params.push(...calculateTransferParams(animal, events, buildingsMap, utilResults, isMommy, cycleParams));
    }
    return params;
}

function calculateInsertionParams(events, cycleParams) {
    if (!events) return [];
    const insertion = events.find(item => item.EvCode === EventTypes.INSERTION);
    if (!insertion) {
        return [];
    }
    return [createParamObject("sowInsertion", insertion.EvTime, cycleParams[0], insertion, ParamTypes.TIME)];
}

function makeGetCycleDay(insemination) {
    if (!insemination) return null;
    const inseminationInFarmTime = moment(moment(insemination.EvTime).format(moment.HTML5_FMT.DATE));
    return function getCycleDay(event) {
        if (!event) return undefined;
        return moment(event.EvTime).startOf("day").diff(inseminationInFarmTime, "days");
    };
}

function makeGetGroup(groups) {
    return function getGroupNumber(event) {
        if (!event) return groups[0];
        const group = groups.find(item => event.EvTime >= item.from && event.EvTime < item.to);
        if (!group) return groups[0];
        return group;
    };
}

export function calculateAnimalParameters(animal, events, cycleTable, utilResults, buildingsMap) {
    const params = [];

    const technologyGroupStart = utilResults.TechnologyGroupStart;
    const technologyGroupWeeks = utilResults.TechnologyGroupWeeks;

    const cyclesParams = [];

    for (let i = 0; i < cycleTable.length; i++) {
        const row = cycleTable[i];
        const actCycle = i === cycleTable.length - 1;
        const groups = getTechnologyGroupNumber(row, { technologyGroupStart, technologyGroupWeeks });
        cyclesParams[i] = {
            cycle: row.cycle,
            actCycle,
            AnmID: animal.AnmID,
            AnmNo1: animal.AnmNo1,
            isPresent: !animal.DtaDelTime && !animal.DtaDthTime,
        };
        if (groups.length > 0) {
            // cyclesParams[i].group_nr = groupNumber.name;
            cyclesParams[i].temporaryParams = {
                // groupStart: groupNumber.start,
                getCycleDay: makeGetCycleDay(first(row[EventTypes.INSEMINATION])),
                getGroup: makeGetGroup(groups),
                cycleDates: { start: row.StartTime, end: row.EndTime }
            };
            params.push(...calculateParametersForCycle(row, i, cyclesParams, animal, events, cycleTable, utilResults, buildingsMap));
        } else {
            params.push(...calculateParametersRelatedToFostering(row, cyclesParams[i]));
        }
    }
    if (cyclesParams.length === 0) {
        // sytuacja z tych mało prawdopodobnych, ale prawdziwa
        console.log("animalId=%s [%s] has no cycle", animal?.AnmID, animal?.AnmNo1);
        cyclesParams.push({
            cycle: utilResults.FirstCycleIndex ?? 0,
            actCycle: utilResults.FirstCycleIndex ?? 0,
            AnmID: animal.AnmID,
            AnmNo1: animal.AnmNo1,
            isPresent: !animal.DtaDelTime && !animal.DtaDthTime,
        });
    }
    params.push(...calculateParametersRelatedToRepetition(cycleTable, cyclesParams));
    params.push(...calculateInsertionParams(events, cyclesParams));
    params.push(...calculateMultiCycleParameters(cycleTable, cyclesParams, events, utilResults));
    params.push(...calculateVaccinationParameters(events, cyclesParams));
    return params;
}

export const ForTests = {
    createParamObject,
    getTechnologyGroupNumber,
    calculateParametersRelatedToParturition,
    calculateParametersRelatedToFallPiglets,
    calculateParametersRelatedToSeparation,
    calculateParametersRelatedToCastration,
    calculateParametersRelatedToActiveNipples,
    calculateParametersRelatedToInsemination,
    calculateParametersRelatedToFallOrSellOfAnimal,
    calculateParametersRelatedToUSG,
    calculateParametersRelatedToAnimalAge,
    calculateParametersRelatedToRepetition,
    calculateParametersRelatedToGilts,
    calculateLastCycle,
    calculateParturitionDurationParameter,
    customIndexCalc,
    pushOtherParamsForCycles,
    averageSeparatedPigletForCycle,
    calcDays,
    getLastEvent,
    calculateMultiCycleParameters,
    calculateLastCycleParametersRelatedToInsemination,
    calculateParametersRelatedToPigStatus,
    calculateTransferParams,
    calculateInsertionParams,
    calculateVaccinationParameters,
    calculateP2Parameters,
    getCycleParams
};