import { createSelector } from "reselect";
import { medicineTypes } from "../constans/medicine";
import AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";

export const getDictionaries = state => state.dictionary;

export const getGraftingPrograms = state => state.dictionary.graftingPrograms;

const getAnimalKindFromProps = (state, props) => props.AnimalKind;

export const getGraftingProgram = createSelector(getGraftingPrograms, getAnimalKindFromProps, (programs, AnimalKind) => programs.find(gp => gp.WData.AnimalKind === AnimalKind) || null);

export const makeGetGraftingProgram = () => getGraftingProgram;

const _emptyArray = []; // make sure that the default value is always the same instance or selector might recalculate with every store change
export const getDictionary = (state, name) => state.dictionary[name].WData || _emptyArray;

export const getTaskCategory = state => getDictionary(state, "taskCategory");

export const getMedicines = state => state.dictionary.medicine;

export const getControlLists = state => state.dictionary.controlLists;

export const getAutomatedActions = state => state.dictionary.automatedActions;

export const getFallReasons = state => state.dictionary.fallReasons;

export const getNoPregnancyReasons = state => state.dictionary.noPreganancy;

export const getSelectionReasons = state => state.dictionary.selectionReason;

export const getRaces = state => state.dictionary.race;

export const getRacesData = state => state.dictionary.race.WData;

export const getComments = state => state.dictionary.comments;

export const getCrossBreeding = state => state.dictionary.crossBreeding;

export function makeGetSimpleMedicines() {
    return getSimpleMedicines;
}

export const getSimpleMedicines = createSelector(getMedicines, medicines => medicines.filter(item => item.WData.Type !== medicineTypes.DOSATRON));

export const getDosatrons = createSelector(getMedicines, medicines => medicines.filter(item => item.WData.Type === medicineTypes.DOSATRON));

export const getDictionaryTypeFromProps = (state, props) => props.type;

export const getDictionaryByType = createSelector(getDictionaries, getDictionaryTypeFromProps, (dictionaries, type) => {
    for (let key in dictionaries) {
        if (Array.isArray(dictionaries[key])) continue;
        if (dictionaries[key].Type === type) return dictionaries[key];
    }
    return null;
});

export function makeGetDictionaryByType() {
    return getDictionaryByType;
}

const getControlListID = (state, props) => typeof props === "string" ? props : props.listID;

export const getControlListByID = createSelector(getControlLists, getControlListID, (lists, id) => {
    return lists.find(item => item.WordID === id) || null;
})

export const getVaccines = createSelector(getMedicines, medicines => medicines.filter(item => item.WData.Type === medicineTypes.VACCINE));

export const getTreatmentAutomatedAction = createSelector(getAutomatedActions, actions => actions.find(item => item.WData.Type === "treatment"));

const getTypeFromHistoryParams = (state, props) => props.match.params.type;

export const getAutomatedActionFromHistoryParams = createSelector(getAutomatedActions, getTypeFromHistoryParams, (actions, type) => actions.find(item => item.WData.Type === type));

const getMedicineFromPropsTask = (state, props) => props.task.Medicine;

export const getMedicineFromTask = createSelector(getMedicines, getMedicineFromPropsTask, (medicines, WordID) => medicines.find(item => item.WordID === WordID) || null)

export const makeGetMedicineFromTask = () => getMedicineFromTask;

export const getCommentDictionary = state => state.dictionary.comments.WData || [];

export const getPigletsGraftingProgram = createSelector(getGraftingPrograms, graftingPrograms => graftingPrograms.find(item => item.WData.AnimalKind === AnimalTypes.PIG));

export const getSowGraftingProgram = createSelector(getGraftingPrograms, graftingPrograms => graftingPrograms.find(item => item.WData.AnimalKind === AnimalTypes.SOW))